<div class="modal-content-wrapper">
  <header class="modal-header">
      <h4 ><i class="fa-regular fa-credit-card"></i> Payment Methods and Settings</h4>
      <button (click)="modalRef.close(true)" class="icon-button border-0" pButton [icon]="'fa-solid fa-xmark'"></button>
  </header>
  <div class="modal-body">
      <div class="modal-body-content">
          <div class="card mb-5">
            <div class="card-body flex md:flex-row flex-column-reverse gap-4 align-items-center">
              <div>
                <h4 >Billing and Payments method</h4>
                <ul class="pl-4">
                  <li>The payment methods and settings are saved in your app account. We use Stripe and Plaid and you can se up these payment methods to receive funds from your audience. Please note that the transactions data policies for security and compliance apply for the respective vendors—Stripe and Plaid.</li>
                  <li>In Stripe, the <strong>Account Name</strong> will show in your invoices or in the payment reports or receipts.</li>
                </ul>
              </div>
              <img class="flex-shrink-0" src="/assets/images/payment-img.png" width="250" alt="payment-image">
            </div>
          </div>

          @if(!isLoading){
            <div class="main-tabs-card">
              @if (configs?.paymentGateway == paymentGateways.Stripe){
              <div class="">
                <p-tabView>
                  <p-tabPanel header="Stripe Setup">
                    <div>
                      <calimatic-stripe-transaction-config [configs]="configs"></calimatic-stripe-transaction-config>
                    </div>
                  </p-tabPanel>
                  <p-tabPanel header="Plaid Setup">
                    <div>
                      <calimatic-plaid-transaction-config [configs]="configs"></calimatic-plaid-transaction-config>
                    </div>
                  </p-tabPanel>
                </p-tabView>
              </div>
              }
              @else if (configs?.paymentGateway == paymentGateways.NMI) {
              <calimatic-nmi-transaction-config [configs]="configs"></calimatic-nmi-transaction-config>
              }
            </div>
          }
          @else {
            <div>
              <div class="flex gap-3 justify-content-center">
                <calimatic-skeleton [configs]="{height:'4rem',width:'12rem',repeat:1, styleClass:'mb-3'}"></calimatic-skeleton>
                <calimatic-skeleton [configs]="{height:'4rem',width:'12rem',repeat:1, styleClass:'mb-3'}"></calimatic-skeleton>
              </div>
              <calimatic-skeleton [configs]="{height:'3.5rem',width:'100%',repeat:5, styleClass:'mb-2'}"></calimatic-skeleton>
            </div>
          }
      </div>
  </div>
  <!-- <footer class="modal-footer">
      <button pButton class="clm-secondary-btn border-0" [rounded]="true" label="Cancel"></button>
      <button pButton class="clm-primary-btn" [rounded]="true" label="Submit"></button>
  </footer> -->
</div>






