import {Component, Input} from '@angular/core';
import {SkeletonModule} from "primeng/skeleton";

@Component({
  selector: 'calimatic-chart-skeleton',
  standalone: true,
  imports: [
    SkeletonModule
  ],
  templateUrl: './chart-skeleton.component.html',
  styleUrl: './chart-skeleton.component.scss'
})
export class ChartSkeletonComponent {
@Input() chartSkeletonHeight: any;
}
