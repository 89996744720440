<div class="board-content-wrapper">
  <div class="stripe-payment-wrapper">
    <form [formGroup]="plaidForm">
      <div class="s_p_header mb-5">
        <h4 >Add Plaid Informations</h4>
        <p class="text-400">Get the following details from your Plaid account. Check their documentation or contact their support if you have questions.</p>
      </div>
      <div class="s_p_content-form">
        <div class="flex flex-column gap-2 mb-4">
          <p-floatLabel>
            <input class="w-full"
              [forceValid]="validateInput"
              formControlName="secretKey"
              pInputText
              validateInput="Secret key is required"/>
            <label for="account-name">Secret Key</label>
          </p-floatLabel>
        </div>
        <div class="flex flex-column gap-2 mb-4">
          <p-floatLabel>
            <input class="w-full"
              [forceValid]="validateInput"
              formControlName="clientKey"
              pInputText
              validateInput="Client key is required"/>
            <label for="account-name">Client Key</label>
          </p-floatLabel>
        </div>
        
        <div class="h-2rem"></div>

        <div class="card mb-5 p-0">
          <div class="card-body p-3 flex md:flex-row flex-column-reverse gap-4 align-items-center">
            <div>
              <h4 >Plaid Dashboard</h4>
              <p class="line-height-3">provides tools for accessing and analyzing transaction data, verifying account information, and integrating with financial applications.</p>
            </div>
            <img class="flex-shrink-0" src="/assets/images/1200px-Plaid_logo.svg.png" width="180" alt="payment-image">
          </div>
          <div class="card-footer-with-link">
            <a href="{{dashboardURL}}" target="_blank">Manage Plaid Payments <i class="fa-solid fa-arrow-up-right-from-square ml-2"></i></a>
          </div>
        </div>

        <div class="text-right mt-4">
          <button pButton class="clm-primary-btn" [loading]="processing" [rounded]="true" label="Save" 
          (click)="save()" [disabled]="processing"></button>
        </div>
      </div>
    </form>
  </div>
</div>







<!-- <div>
  <p-fieldset legend="">
    <ng-template pTemplate="header">
      <h4 class="mb-3 px-2">Plaid</h4>
    </ng-template>
    @if (!loading) {
      <form [formGroup]="plaidForm">
        <div class="flex flex-column gap-2 my-3">
          <label>Secret Key</label>
          <input
            [forceValid]="validateInput"
            formControlName="secretKey"
            pInputText
            placeholder="Enter plaid secret key"
            validateInput="Secret key is required"/>
        </div>
        <div class="flex flex-column gap-2 my-3">
          <label>Client Key</label>
          <input
            [forceValid]="validateInput"
            formControlName="clientKey"
            pInputText
            placeholder="Enter plaid client key"
            validateInput="Client key is required"/>
        </div>
        <div class="flex flex-column gap-2 my-3">
          <label>Plaid Dashboard URL</label>
          <a href="{{dashboardURL}}" target="_blank">{{ dashboardURL }}</a>
        </div>
        <div>
          <button [disabled]="processing" [loading]="processing" (click)="save()" pButton> &nbsp; Save</button>
        </div>
      </form>
    } @else {
      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>
    }
  </p-fieldset>
</div> -->
