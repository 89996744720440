import {Component, OnInit} from '@angular/core';
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ActivatedRoute, Router} from "@angular/router";
import {appRoutePaths} from "../../../app.routes";
import {AuthService} from "../../../core/services/auth/auth.service";
import {BrandingComponent} from "../../../shared/components/branding/branding.component";

@Component({
  selector: 'calimatic-account-activation',
  standalone: true,
  imports: [
    ProgressSpinnerModule,
    BrandingComponent
  ],
  templateUrl: './account-activation.component.html',
  styleUrl: './account-activation.component.scss'
})
export class AccountActivationComponent implements OnInit {
  loading = false;
  message = '';

  constructor(private router: ActivatedRoute, private route: Router, private authService: AuthService) {
  }

  async ngOnInit() {
    this.router.queryParams.subscribe(async (p) => {
      const id = p.id;
      const guid = p.guid;
      if (id && guid) {
        this.loading = true;
        this.message = 'Verifying';
        const res = await this.authService.verifyActivationEmail(guid, id);
        this.loading = false;
        this.message = res.response.message;
        if (res.isSuccessful) {
          setTimeout(async () => {
            await this.route.navigate([`/${appRoutePaths.login.v3}`]);
          }, 1000);
        }
      }
    });
  }
}
