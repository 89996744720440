<h6>Online {{ ('Class' | terminology:terminologyEnum.Clas) }}</h6>
<button (click)="getOnlineClasses(op,$event)" class="clm-secondary-btn btn-sm" pButton>
  <span class="material-symbols-outlined mr-1">exit_to_app</span>
  <span>Join online {{ ('class' | terminology:terminologyEnum.Clas) }}</span>
  <span class="material-symbols-outlined ml-3">keyboard_arrow_down</span>
</button>
@if (joinClassLoading) {
  <p-progressSpinner
    styleClass="ml-1 w-2rem h-2rem"
    strokeWidth="4"
    fill="var(--surface-ground)"
    animationDuration=".5s"/>
}

<p-overlayPanel #op>
  <div class="general-dropdown w-25rem">
    @if (loadingClasses) {
      <calimatic-skeleton [configs]="{styleClass:'m-2',width:'5rem',height:'1rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'m-2', repeat:3,width:'24rem',height:'2rem'}"></calimatic-skeleton>
    } @else {
      <small
        class="dd-info-label mx-3 mb-2">All {{ ('classes' | terminology:terminologyEnum.Clas:terminologyWordType.PLURAL) }}</small>
      <ul class="">
        @for (cls of classes; track cls.classId) {
          <li class="flex align-items-center gap-2">
            <a (click)="joinClass(cls);op.hide()" class="font-medium flex">
              <i class="fa-regular fa-address-card mr-2 pt-1"></i>
              <span>{{ cls.className }}</span>
            </a>
          </li>
        }
      </ul>
    }
  </div>
</p-overlayPanel>
