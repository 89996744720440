export enum DashboardWidgetsEnum {

  //Current month counters
  EnrollmentsCount = 1,
  DropOffCount,
  RevenueGenerated,
  Attendance,
  UsersCount,
  StudentsEngagement,
  FamiliesCount,
  StudentsCount,
  StaffCount,
  ContactsCount,
  LeadsCount,
  PendingPaymentsCount,
  AssignedStudents,
  AssignedCourses,
  HoursLogged,
  ClassesAssigned,
  CoursesEnrolled,
  StudentResults,
  TopFranchises,
  ListOfLeads,

  // Monthly trio counters (today, this & last month)
  MonthlyTrioEnrollmentsCount,
  MonthlyTrioDropOffCount,
  MonthlyTrioRevenueGenerated,
  MonthlyTrioAttendance,
  MonthlyTrioUsersCount,
  MonthlyTrioStudentsEngagement,
  MonthlyTrioFamiliesCount,
  MonthlyTrioStudentsCount,
  MonthlyTrioStaffCount,
  MonthlyTrioContactsCount,
  MonthlyTrioLeadsCount,
  MonthlyTrioPendingPaymentsCount,
  MonthlyTrioAssignedStudents,
  MonthlyTrioAssignedCourses,
  MonthlyTrioHoursLogged,
  MonthlyTrioClassesAssigned,
  //Counters Charts
  ClassEnrollmentChart,
  EventEnrollmentChart,
  EnrollmentPerformanceChart,
  PartnersEnrollmentChart,
  ClassDropOffChart,
  EventDropOffChart,
  CourseTypeCountChart,

  //Revenue Charts
  ClassEnrollmentRevenueChart,

  //Lead Charts
  LeadsGenerationChart,

  //Student details charts
  StudentDemographicsChart,
  StudentAttendanceChart,

  //Widgets
  Map,
  QuickLinks,
  Calendar,
  Announcements,
  ExternalLink1,
  ExternalLink2,
  ExternalLink3,
  ExternalLink4,
  ExternalLink5
}

export enum DashboardWidgetTypeEnum {
  Counter = 1,
  Comparison,
  Chart,
  Widget
}

export enum DashboardWidgetSymbolEnum {
  Amount = '$',
  Percentage = '%',
  None = ''
}
