import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UrlResolverService {
  private urlSubject = new BehaviorSubject<string>('');
  url$ = this.urlSubject.asObservable();

  load(url: string) {
    this.urlSubject.next(url);
  }
}
