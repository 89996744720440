<div class="new-settings__content-container">
    @if(!hideForOnboard){
        <header class="p-4">
            <h4 class="m-0">Email Settings</h4>
        </header>
    }
    <main>
        <div class="main-content-inner">
            <div class="side-bar-main">
                <main class="user-body-guide">
                    <div class="main-content">
                        @if(hideForOnboard){
                            <div class="main-headings-form pt-4">
                                <p class="main-subtitle text-start text-500 px-3 mb-3">Get started by setting up user communication details
                                    in the platform as per your needs</p>
                            </div>
                        }
            
                        <div class="main-tabs-card">
                            <div class="">
                                <p-tabView class="{{hideForOnboard?'':'tiny-tab'}}" (activeIndexChange)="onTabViewChange($event)">
                                    <p-tabPanel header="Emails Account Sender">
                                        <div class="email-configs-form max-w-850">
                                            @if(isEmailSenderDataLoaded){
                                                <form [formGroup]="emailForm" (ngSubmit)="saveEmailSenderConfig()" novalidate>
                                                    <div class="card-info-title mb-3">
                                                        <h5 >Email Account Sender</h5>
                                                        <p class="text-500">You can update the email account sender information by
                                                            setting up <i>Email Account Sender Details</i> and <i>Sendgrid Key</i>.</p>
                                                    </div>
                                                    <div class="card p-4 mb-5">
                                                        <div class="form-title card-header">
                                                            <i class="pi pi-envelope"></i>
                                                            <p class="main-subtitle text-start m-0">Email Account Sender Details</p>
                                                        </div>
                
                                                        <div class="card-body">
                                                            <div class="flex flex-column gap-2 mb-4">
                                                                <p-floatLabel>
                                                                    <input class="w-full" 
                                                                    pInputText id="senderName" formControlName="senderName" validateInput="Sender name is required" />
                                                                    <label for="senderName">Sender Name</label>
                                                                </p-floatLabel>
                                                            </div>
                                                            <div class="flex flex-column gap-2 mb-4">
                                                                <p-floatLabel>
                                                                    <input class="w-full" validateInput="Reply email is required" 
                                                                    pInputText id="replyEmail" formControlName="replyEmail" />
                                                                    <label for="replyEmail">Reply Email</label>
                                                                </p-floatLabel>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card p-4 mb-5">
                                                        <div class="form-title card-header">
                                                            <i class="pi pi-key"></i>
                                                            <p class="main-subtitle text-start m-0">Sendgrid Key</p>
                                                        </div>
                
                                                        <div class="card-body">
                                                            <div class="flex flex-column gap-2 mb-4">
                                                                <p-floatLabel>
                                                                    <input class="w-full" (blur)="OnSendgridKeyEnter()" validateInput="Send grid key is required"
                                                                    pInputText id="sendgridKey" formControlName="sendgridKey" />
                                                                    <label for="sendgridKey">Sendgrid Key</label>
                                                                </p-floatLabel>
                                                            </div>
                                                            <div class="flex flex-column gap-2 mb-4">
                                                                <p-floatLabel>
                                                                    <input class="w-full" validateInput="From email is required" 
                                                                    pInputText id="fromEmail" formControlName="fromEmail" />
                                                                    <label for="fromEmail">From Email</label>
                                                                </p-floatLabel>
                                                            </div>
                                                        </div>
                                                    </div>
                
                                                    <div class="text-right mt-4">
                                                           <button pButton type="submit" class="clm-primary-btn" [disabled]="emailForm.invalid" [rounded]="true" label="Save"></button>
                                                      </div>
                                                </form>
                                            }@else {
                                                <div class="m-3">
                                                  <calimatic-skeleton [configs]="{height:'4rem',width:'100%',repeat:6,styleClass:'mb-4'}"></calimatic-skeleton>
                                                </div>
                                              }
                                         
                                        </div>
                                     </p-tabPanel>
                                     @if(!hideForOnboard){
                                        <p-tabPanel header="Email Notifications">
                                            <div class="max-w-850">
                                                <div class="email-frequency">
                                                    <div class="card-info-title mb-5">
                                                        <h5 >Email Notifications</h5>
                                                        <p class="text-500">Update the email notification details by using the given
                                                            setting.</p>
                                                    </div>
                    
                                                    <h6 class="font-semibold mb-3">Email Frequency</h6>
                                                    <div class="card p-0">
                                                        @if(isFrequencyDataLoaded){
                                                        <div class="card-list-main">
                                                            <ul class="card-list-items">
                                                                @if(emailFrenquencyWithSettings && (emailFrenquencyWithSettings||[]).length){
                                                                @for (item of emailFrenquencyWithSettings; track $index) {
                                                                <li class="card-list-item">
                                                                    <div>
                                                                        <label class="block mb-1">{{item.labelName}}</label>
                                                                        <p class="text-500">{{description}} </p>
                                                                    </div>
                                                                    <p-inputSwitch [(ngModel)]="item.isSettingOn"
                                                                        (onChange)="setEmailFrequency($event,item.type)"
                                                                        optionLabel="label" optionValue="value" />
                                                                        @if(item.type == emailFrenquencyToogleEnum.Course || item.type == emailFrenquencyToogleEnum.Challenge || item.type == emailFrenquencyToogleEnum.Level){
                                                                            <button class="clm-primary-btn"  [loading]="false" (click)="openImageModal(item)" pButton>Manage Image</button>
                                                                        }
                                                                </li>
                                                                @if(item.type == emailFrenquencyToogleEnum.Course && item.isSettingOn) {
                                                                <div class="card-toggle-items-main">
                                                                    <div class="toggle-content">
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toParent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToParent"   />
                                                                            <label for="sendToParent">Send to Parent</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toStudent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToStudent" />
                                                                            <label for="sendToStudent">Send to Student</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toCC"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type,false,true)"
                                                                                [binary]="true" inputId="sendToCc" />
                                                                            <label for="sendToCc">Send to CC Email</label>
                                                                        </div> 
                                                                
                                                                        @if(item.emailSettings.toCC){
                                                                        <div class="toggle-content-list-inner">
                                                                            <div class="flex">
                                                                                <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                    emails to</label>
                                                                                <p-chips
                                                                                    (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                    (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [(ngModel)]="item.emailSettings.emails"
                                                                                    optionLabel="name"
                                                                                    
                                                                                    [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                }
                    
                                                                @if(item.type == emailFrenquencyToogleEnum.Level && item.isSettingOn) {
                                                                <div class="card-toggle-items-main">
                                                                    <div class="toggle-content">
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toParent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToParent" />
                                                                            <label for="sendToParent">Send to Parent</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toStudent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToStudent" />
                                                                            <label for="sendToStudent">Send to Student</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toCC"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type,false,true)"
                                                                                [binary]="true" inputId="sendToCc" />
                                                                            <label for="sendToCc">Send to CC Email</label>
                                                                        </div>
                                                                        @if (item.emailSettings.toCC){
                                                                        <div class="toggle-content-list-inner">
                                                                            <div class="flex">
                                                                                <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                    emails to</label>
                                                                                <p-chips
                                                                                    (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                    (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [(ngModel)]="item.emailSettings.emails"
                                                                                    [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                }
                    
                                                                @if(item.type == emailFrenquencyToogleEnum.Challenge && item.isSettingOn) {
                                                                <div class="card-toggle-items-main">
                                                                    <div class="toggle-content">
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toParent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToParent" />
                                                                            <label for="sendToParent">Send to Parent</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toStudent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToStudent" />
                                                                            <label for="sendToStudent">Send to Student</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toCC"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type,false,true)"
                                                                                [binary]="true" inputId="sendToCc" />
                                                                            <label for="sendToCc">Send to CC Email</label>
                                                                        </div>
                                                                        @if (item.emailSettings.toCC){
                                                                        <div class="toggle-content-list-inner">
                                                                            <div class="flex">
                                                                                <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                    emails to</label>
                                                                                <p-chips [(ngModel)]="item.emailSettings.emails   "
                                                                                    (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                    (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                }
                    
                                                                @if(item.type == emailFrenquencyToogleEnum.Inventory && item.isSettingOn) {
                                                                <div class="card-toggle-items-main">
                                                                    <div class="toggle-content">
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toBusinessAdmin"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="SendtoBusinessAdmin" />
                                                                            <label for="SendtoBusinessAdmin">Send to Business Admin</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toBusinessExecutive"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="SendtoBusinessExecutive" />
                                                                            <label for="SendtoBusinessExecutive">Send to Business
                                                                                Executive</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox
                                                                                [(ngModel)]="item.emailSettings.toBusinessCordinator"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToCc" />
                                                                            <label for="SendtoBusinessCoordinator">Send to Business
                                                                                Coordinator</label>
                                                                        </div>
                    
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toFranhiseAdmin"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="SendtoFranchiseAdmin" />
                                                                            <label for="SendtoFranchiseAdmin">Send to Franchise
                                                                                Admin</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox
                                                                                [(ngModel)]="item.emailSettings.toFranhiseCoordinator"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="SendtoFranchiseCoordinators" />
                                                                            <label for="SendtoFranchiseCoordinators">Send to Franchise
                                                                                Coordinators</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox
                                                                                [(ngModel)]="item.emailSettings.toFranhiseExecutive"
                                                                                (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="SendtoFranchiseExecutive" />
                                                                            <label for="SendtoFranchiseExecutive">Send to Franchise
                                                                                Executive</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                }
                    
                                                                @if(item.type == emailFrenquencyToogleEnum.OnlineAssessment &&item.isSettingOn) {
                                                                <div class="card-toggle-items-main">
                                                                    <div class="toggle-content">
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toParent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToParent" />
                                                                            <label for="sendToParent">Send to Parent</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toStudent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToStudent" />
                                                                            <label for="sendToStudent">Send to Student</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toCC"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type,false,true)"
                                                                                [binary]="true" inputId="sendToCc" />
                                                                            <label for="sendToCc">Send to CC Email</label>
                                                                        </div>
                                                                        @if (item.emailSettings.toCC){
                                                                        <div class="toggle-content-list-inner">
                                                                            <div class="flex">
                                                                                <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                    emails to</label>
                                                                                <p-chips [(ngModel)]="item.emailSettings.emails   "
                                                                                    (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                    (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                }
                                                                @if(item.type == emailFrenquencyToogleEnum.Task && item.isSettingOn) {
                                                                    <div class="card-toggle-items-main">
                                                                        <div class="toggle-content">
                                                                            <div class="toggle-content-list">
                                                                                <p-checkbox [(ngModel)]="item.emailSettings.toParent"
                                                                                    (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [binary]="true" inputId="sendToParent" />
                                                                                <label for="sendToParent">Send to Parent</label>
                                                                            </div>
                                                                            <div class="toggle-content-list">
                                                                                <p-checkbox [(ngModel)]="item.emailSettings.toStudent"
                                                                                    (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [binary]="true" inputId="sendToStudent" />
                                                                                <label for="sendToStudent">Send to Student</label>
                                                                            </div>
                                                                            <div class="toggle-content-list">
                                                                                <p-checkbox [(ngModel)]="item.emailSettings.toCC"
                                                                                    (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type,false,true)"
                                                                                    [binary]="true" inputId="sendToCc" />
                                                                                <label for="sendToCc">Send to CC Email</label>
                                                                            </div>
                                                                            @if (item.emailSettings.toCC){
                                                                            <div class="toggle-content-list-inner">
                                                                                <div class="flex">
                                                                                    <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                        emails to</label>
                                                                                    <p-chips [(ngModel)]="item.emailSettings.emails   "
                                                                                        (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                        (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                        [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                                </div>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    }
                        
                                                                @if(item.type == emailFrenquencyToogleEnum.SecureEnrollmentEmail && item.isSettingOn) {
                                                                <div class="card-toggle-items-main">
                                                                    <div class="toggle-content">
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toParent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToParent" />
                                                                            <label for="sendToParent">Send to Parent</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toStudent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToStudent" />
                                                                            <label for="sendToStudent">Send to Student</label>
                                                                        </div>
                    
                                                                        <div class="toggle-content-list-inner">
                                                                            <div class="flex">
                                                                                <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                    emails to</label>
                                                                                <p-chips [(ngModel)]="item.emailSettings.emails   "
                                                                                    (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                    (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                            </div>
                                                                        </div>
                    
                                                                    </div>
                                                                </div>
                                                                }
                                                                @if(item.type == emailFrenquencyToogleEnum.PortalEnrollmentEmail && item.isSettingOn) {
                                                                <div class="card-toggle-items-main">
                                                                    <div class="toggle-content">
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toParent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToParent" />
                                                                            <label for="sendToParent">Send to Parent</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toStudent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToStudent" />
                                                                            <label for="sendToStudent">Send to Student</label>
                                                                        </div>
                    
                                                                        <div class="toggle-content-list-inner">
                                                                            <div class="flex">
                                                                                <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                    emails to</label>
                                                                                <p-chips [(ngModel)]="item.emailSettings.emails"
                                                                                    (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                    (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                            </div>
                                                                        </div>
                    
                                                                    </div>
                                                                </div>
                                                                }
                    
                                                                @if(item.type == emailFrenquencyToogleEnum.RecurringEnrollmentEmail && item.isSettingOn) {
                                                                <div class="card-toggle-items-main">
                                                                    <div class="toggle-content">
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toParent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToParent" />
                                                                            <label for="sendToParent">Send to Parent</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toStudent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToStudent" />
                                                                            <label for="sendToStudent">Send to Student</label>
                                                                        </div>
                    
                                                                        <div class="toggle-content-list-inner">
                                                                            <div class="flex">
                                                                                <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                    emails to</label>
                                                                                <p-chips [(ngModel)]="item.emailSettings.emails   "
                                                                                    (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                    (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                            </div>
                                                                        </div>
                    
                                                                    </div>
                                                                </div>
                                                                }
                                                                @if(item.type == emailFrenquencyToogleEnum.Refund && item.isSettingOn) {
                                                                <div class="card-toggle-items-main">
                                                                    <div class="toggle-content">
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toParent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToParent" />
                                                                            <label for="sendToParent">Send to Parent</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toStudent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToStudent" />
                                                                            <label for="sendToStudent">Send to Student</label>
                                                                        </div>
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toCC"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type,false,true)"
                                                                                [binary]="true" inputId="sendToCc" />
                                                                            <label for="sendToCc">Send to CC Email</label>
                                                                        </div>
                                                                        @if (item.emailSettings.toCC){
                                                                        <div class="toggle-content-list-inner">
                                                                            <div class="flex">
                                                                                <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                    emails to</label>
                                                                                <p-chips [(ngModel)]="item.emailSettings.emails   "
                                                                                    (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                    (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                }
                    
                                                                @if(item.type == emailFrenquencyToogleEnum.WaitlistNotification && item.isSettingOn) {
                                                                <div class="card-toggle-items-main">
                                                                    <div class="toggle-content">
                                                                        <div class="toggle-content-list-inner">
                                                                            <div class="flex">
                                                                                <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                    emails to</label>
                                                                                <p-chips [(ngModel)]="item.emailSettings.emails   "
                                                                                    (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                    (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                            </div>
                                                                        </div>
                    
                                                                    </div>
                                                                </div>
                                                                }
                                                                @if(item.type == emailFrenquencyToogleEnum.AttendanceEnrollment && item.isSettingOn) {
                                                                <div class="card-toggle-items-main">
                                                                    <div class="toggle-content">
                                                                        <div class="toggle-content-list">
                                                                            <p-checkbox [(ngModel)]="item.emailSettings.toParent"
                                                                                (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                [binary]="true" inputId="sendToParent" />
                                                                            <label for="sendToParent">Send to Parent</label>
                                                                        </div>
                                                                        @if (item.emailSettings.toParent){
                                                                        <div class="toggle-content-list-inner">
                                                                            <div class="flex">
                                                                                <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                    emails to</label>
                                                                                <p-chips [(ngModel)]="item.emailSettings.emails   "
                                                                                    (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                    (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                }
                    
                                                                @if(item.type == emailFrenquencyToogleEnum.AbandonedCartInfoDailyEmail && item.isSettingOn) {
                                                                    <div class="card-toggle-items-main">
                                                                        <div class="toggle-content">
                                                                            <div class="toggle-content-list-inner">
                                                                                <div class="flex">
                                                                                    <label class="col-4 font-14 px-0" for="sendgridKey">Send To</label>
                                                                                    <p-chips [(ngModel)]="item.emailSettings.emails"
                                                                                    (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                    (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                                </div>
                                                                            </div>
                                                                           
                                                                            <div class="toggle-content-list-inner">
                                                                                <div class="flex">
                                                                                    <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                        emails to</label>
                                                                                    <p-chips [(ngModel)]="item.emailSettings.emailsTo"
                                                                                    (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                    (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                }
                    
                                                                @if(item.type == emailFrenquencyToogleEnum.ClassUpdate && item.isSettingOn) {
                                                                    <div class="card-toggle-items-main">
                                                                        <div class="toggle-content">
                                                                            <div class="toggle-content-list">
                                                                                <p-checkbox [(ngModel)]="item.emailSettings.toParent"
                                                                                    (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [binary]="true" inputId="sendToParent" />
                                                                                <label for="sendToParent">Send to Parent</label>
                                                                            </div>
                                                                            <div class="toggle-content-list">
                                                                                <p-checkbox [(ngModel)]="item.emailSettings.toStudent"
                                                                                    (onChange)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                    [binary]="true" inputId="sendToStudent" />
                                                                                <label for="sendToStudent">Send to Student</label>
                                                                            </div>
                                                                         
                                                                            @if (item.emailSettings.toStudent || item.emailSettings.toParent){
                                                                            <div class="toggle-content-list-inner">
                                                                                <div class="flex">
                                                                                    <label class="col-4 font-14 px-0" for="sendgridKey">Send cc
                                                                                        emails to</label>
                                                                                    <p-chips
                                                                                        (onAdd)="setEmailFrequecySettings($event,item.emailSettings,item.type,true)"
                                                                                        (onRemove)="setEmailFrequecySettings($event,item.emailSettings,item.type)"
                                                                                        [(ngModel)]="item.emailSettings.emails   "
                                                                                        [styleClass]="'p-chips-w-full'" class="w-full" />
                                                                                </div>
                                                                            </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                    
                                                                }
                    
                                                                }
                    
                                                            </ul>
                                                        </div>
                                                    }@else{
                                                        <div class="m-3">
                                                            <calimatic-skeleton [configs]="{height:'4rem',width:'100%',repeat:6,styleClass:'mb-4'}"></calimatic-skeleton>
                                                          </div>
                                                    }
                                                    </div>
                                                    <h6 class="font-semibold mb-3 mt-5">Time Tracker Email/ Notifications Settings</h6>
                                                    <div class="card p-0">
                                                        @if(isTimeTrackerDataLoaded){
                                                        <div class="card-list-main">
                                                            <ul class="card-list-items">
                                                                @if(timeTracker && (timeTracker||[]).length){
                                                                    @for (timetrack of timeTracker; track $index) {
                                                                        <li class="card-list-item cursor-pointer" (click)="OnOpenTimeTracker(timetrack.moduleType)">
                                                                            <div>
                                                                                <label class="block mb-1">{{timeTrackerLabels[$index]}}</label>
                                                                                <p class="text-500">{{description}}</p>
                                                                            </div>
                                                                            <span
                                                                                class="fa fa-caret-right text-500 rotating-caret {{emailTrackerSection == timetrack.moduleType?'rotate-90':''}}"></span>
                                                                        </li>
                                                                        @if ((emailTrackerSection == timetrack.moduleType)) {
                                                                        <div class="card-toggle-items-main">
                                                                            <div class="toggle-content">
                                                                                <div class="toggle-content-list">
                                                                                    <p-checkbox [(ngModel)]="timetrack.approvedRejectEmail" (onChange)="setTimeTrackerEmailSettings(timetrack)" [binary]="true"
                                                                                        inputId="sendToParent" />
                                                                                    <label for="sendToParent">Approve/Reject Email</label>
                                                                                </div>
                                                                                <div class="toggle-content-list">
                                                                                    <p-checkbox [(ngModel)]="timetrack.submitEmail" (onChange)="setTimeTrackerEmailSettings(timetrack)" [binary]="true"
                                                                                        inputId="sendToStudent" />
                                                                                    <label for="sendToStudent">Submit Email</label>
                                                                                </div>
                                                                                <div class="toggle-content-list">
                                                                                    <p-checkbox [(ngModel)]="timetrack.notification" [binary]="true" 
                                                                                    (onChange)="setTimeTrackerEmailSettings(timetrack)"
                                                                                        inputId="sendToCc" />
                                                                                    <label for="sendToCc">Notification</label>
                                                                                </div>
                            
                                                                            </div>
                                                                        </div>
                                                                        }
                                                                        }
                                                                }
                                                            
                                                            </ul>
                                                        </div>
                                                    }@else {
                                                        <div class="m-3">
                                                            <calimatic-skeleton [configs]="{height:'4rem',width:'100%',repeat:6,styleClass:'mb-4'}"></calimatic-skeleton>
                                                          </div>
                                                    }
                                                    </div>
                        
                                                </div>
                                                <h6 class="font-semibold mb-3 mt-5">Challenge Notifications To Staff</h6>
                                                <div class="card p-0">
                                                    @if(isNotificationToStaffLoaded){
                                                    <div class="card-list-main">
                                                        <ul class="card-list-items">
                    
                                                            <li class="card-list-item cursor-pointer"
                                                                (click)="showChallengeCompletion =  !showChallengeCompletion">
                                                                <div>
                                                                    <label class="block mb-1">Send Notifications </label>
                                                                    <p class="text-500">Send Notifications to specific roles when a
                                                                        student achieves Points in multiple hundreds</p>
                                                                </div>
                                                                <span
                                                                    class="fa fa-caret-right text-500 rotating-caret {{showChallengeCompletion?'rotate-90':''}}"></span>
                                                            </li>
                                                            @if (showChallengeCompletion) {
                                                            <div class="card-toggle-items-main">
                                                                <div class="toggle-content">
                                                                    <div class="toggle-content-list">
                                                                        <label class="white-space-nowrap col-4" for="sendToParent ">Send
                                                                            Notifications </label>
                                                                        <p-multiSelect styleClass="w-full" [maxSelectedLabels]="5"
                                                                            class="min-w-1 w-full" showToggleAll="false"
                                                                            [options]="defaultChallengeRoles" [(ngModel)]="selectedChallengeRoles"
                                                                            placeholder="Select Roles" optionLabel="privilegeCode"
                                                                            display="chip" [showClear]="true" [appendTo]="'body'"
                                                                            (onChange)="onChallengeRolesChange()"
                                                                            />
                                                                    </div>
                                                                    <div class="toggle-content-list">
                                                                        <label class="white-space-nowrap col-4" for="ChallengeCompletionEmail">Send
                                                                            Challenge Completion Email At </label>
                                                                        <input type="number" styleClass="w-full" class="w-full"
                                                                        [(ngModel)]="challengeCompletionEmailAt" (change)="updateCompletionEmailAt()"
                                                                            pInputText>
                                                                    </div>
                    
                    
                                                                </div>
                                                            </div>
                                                            }
                    
                    
                                                        </ul>
                                                    </div>
                                                }@else {
                                                    <div class="m-3">
                                                        <calimatic-skeleton [configs]="{height:'4rem',width:'100%',repeat:6,styleClass:'mb-4'}"></calimatic-skeleton>
                                                      </div>
                                                }
                                                </div>
                                                <h6 class="font-semibold mb-3 mt-5">Homework & Assessments Due Date Notification</h6>
                                                <div class="card p-0">
                                                    @if(isHomeworkAndAssessmentLoaded){
                                                    <div class="card-list-main">
                                                        <ul class="card-list-items">
                    
                                                            <li class="card-list-item cursor-pointer"
                                                                (click)="showHomeworkAssessments = !showHomeworkAssessments">
                                                                <div>
                                                                    <label class="block mb-1">Email and Mobile </label>
                                                                    <p class="text-500">An email and mobile notification will be sent to
                                                                        these roles one day before and on the same day of due date (if
                                                                        due date is set & it is not yet submitted) for Assessments and
                                                                        homework.</p>
                                                                </div>
                                                                <span
                                                                    class="fa fa-caret-right text-500 rotating-caret {{showHomeworkAssessments?'rotate-90':''}}"></span>
                                                            </li>
                                                            @if (showHomeworkAssessments) {
                                                            <div class="card-toggle-items-main">
                                                                <div class="toggle-content">
                                                                    <div class="toggle-content-list justify-content-end">
                                                                        
                                                                        <div class="flex gap-3 align-items-center">
                                                                            <p-multiSelect styleClass="w-full" class="min-w-1"
                                                                                showToggleAll="false" [options]="defaultHomeworkAssessmentsRoles"
                                                                                [(ngModel)]="selectedHomeworkAssessments" placeholder="Selected Role"
                                                                                optionLabel="Name" display="chip" [showClear]="true" [appendTo]="'body'"
                                                                                 (onChange)="OnSaveHomeworkAssessments()" />
                    
                                                                            <p-dropdown [options]="homeworkAssessmentsTimezone" [(ngModel)]="selectedUTC" (onChange)="OnSaveHomeworkAssessments()"
                                                                                optionLabel="tzName" [filter]="true" filterBy="tzName"
                                                                                [showClear]="true" placeholder="select Time zones" [virtualScroll]="true"
                                                                                [virtualScrollItemSize]="38" [appendTo]="'body'">
                                                                                <ng-template pTemplate="selectedItem" let-selectedOption>
                                                                                    <div class="flex align-items-center gap-2">
                                                                                        <div>{{ selectedOption.tzName }}</div>
                                                                                    </div>
                                                                            </ng-template>
                                                                                <ng-template let-timezone pTemplate="item">
                                                                                    <div class="flex align-items-center gap-2">
                                                                                     <div>{{timezone.tzName}}</div>
                                                                                    </div>
                                                                                </ng-template>
                                                                                
                                                                            </p-dropdown>
                                                                        </div>
                    
                                                                    </div>
                    
                                                                </div>
                                                            </div>
                                                            }
                    
                    
                                                        </ul>
                                                    </div>
                                                }@else {
                                                    <div class="m-3">
                                                        <calimatic-skeleton [configs]="{height:'4rem',width:'100%',repeat:6,styleClass:'mb-4'}"></calimatic-skeleton>
                                                      </div>
                                                }
                                                </div>
                                            </div>
                                        </p-tabPanel>
                                        <p-tabPanel header="Email Setting">
                                            <div class="max-w-850">
                                                <div class="card-info-title mb-5">
                                                    <h5 >Email Settings</h5>
                                                    <p class="text-500">Update the email setting for enrollment informaition email, crm email and who to send copy of email settings.</p>
                                                </div>
                                                <h6 class="font-semibold mb-3 mt-5">Email Settings</h6>
                                                <div class="card p-0">
                                                    @if(isCCEmailsDataLoaded){
                                                    <div class="card-list-main">
                                                        <ul class="card-list-items">
                                                            @if(emailSettings && emailSettings?.ccEmails){
                                                                <li class="card-list-item cursor-pointer {{emailSettingsOptionsIndex == emailSettingsEnum.CCEmails ? 'is-active':''}}"
                                                                (click)="onEmailSettingOption(emailSettingsEnum.CCEmails)">
                                                                <div>
                                                                    <label class="block mb-1">Send A Copy Of All Emails To</label>
                                                                    <p class="text-500">When on, user can set to whom they need to send
                                                                        email amoung parents, student, send to cc emails </p>
                                                                </div>
                                                                <span
                                                                    class="fa fa-caret-right text-500 rotating-caret {{emailSettingsOptionsIndex == emailSettingsEnum.CCEmails?'rotate-90':''}}"></span>
                                                            </li>
                                                            @if (emailSettingsOptionsIndex == emailSettingsEnum.CCEmails) {
                                                            <div class="card-toggle-items-main">
                                                                <div class="toggle-content">
                                                                    <div class="toggle-content-list">
                                                                        <label for="sendToParent" class="col-4">CC
                                                                            Email(s)</label>
                                                                        <p-multiSelect styleClass="w-full"  [options]="emailSettings.ccEmails" #CCEmailsMultiSelect
                                                                            class="min-w-1 w-full" showToggleAll="false"
                                                                             [(ngModel)]="emailSettings.ccEmails"
                                                                            placeholder="Select CC Emails" optionLabel="name"
                                                                            (ngModelChange)="SetEmailSettingsValue(emailSettingsEnum.CCEmails)"
                                                                            (onFilter)="filterVaildEmail($event,emailSettingsEnum.CCEmails)"
                                                                            display="chip" [showClear]="true" [appendTo]="'body'" [maxSelectedLabels]="6"
                                                                            [filterValue]="this.newEmail"
                                                                            (onPanelShow)="clearNewEmail()"
                                                                            >
                                                                            <ng-template let-ccEmail pTemplate="footer">
                                                                                <div class="p-2">
                                                                                    <button (click)="CCEmailsMultiSelect.hide(); SetEmailSettingsValue(emailSettingsEnum.CCEmails,true)" [disabled]="(!isCCEmailValid || isDublicateEmail) || (newEmail||'').length == 0" pButton class="clm-primary-btn w-full multiselect-add-item-btn">
                                                                                        <i class="fa-solid fa-plus"></i>
                                                                                        <div class="add-btn-info">
                                                                                            <small class="block">Add cc email</small>
                                                                                            <span class="block ellipsis">{{newEmail}}</span>
                                                                                        </div>
                                                                                    </button>
                                                                                </div>
                                                                            </ng-template>
                                                                        </p-multiSelect>
                                                                    </div>
                    
                    
                                                                </div>
                                                            </div>
                                                            }
                                                            }
                                       
                                                            <li class="card-list-item cursor-pointer {{emailSettingsOptionsIndex == emailSettingsEnum.EnrollmentInfoEmail ? 'is-active':''}}"
                                                                (click)="onEmailSettingOption(emailSettingsEnum.EnrollmentInfoEmail)">
                                                                <div>
                                                                    <label class="block mb-1">Enrollment Info Email</label>
                                                                    <p class="text-500">When on, user can set to whom they need to send
                                                                        email amoung parents, student, send to cc emails </p>
                                                                </div>
                                                                <span
                                                                    class="fa fa-caret-right text-500 rotating-caret {{emailSettingsOptionsIndex == emailSettingsEnum.EnrollmentInfoEmail ?'rotate-90':''}}"></span>
                                                            </li>
                                                            @if (emailSettingsOptionsIndex == emailSettingsEnum.EnrollmentInfoEmail) {
                                                            <div class="card-toggle-items-main">
                                                                <div class="toggle-content">
                                                                    <div class="toggle-content-list">
                                                                        <label for="sendToParent" class="col-4">Emails</label>
                                                                        <p-multiSelect styleClass="w-full" #enrollmentEmailsMultiSelect
                                                                            class="min-w-1 w-full" showToggleAll="false"
                                                                            [options]="emailSettings.enrollmentEmails" [(ngModel)]="emailSettings.enrollmentEmails"
                                                                            placeholder="Select Cities" optionLabel="name"
                                                                            (ngModelChange)="SetEmailSettingsValue(emailSettingsEnum.EnrollmentInfoEmail)"
                                                                            (onFilter)="filterVaildEmail($event,emailSettingsEnum.EnrollmentInfoEmail)"
                                                                            display="chip" [showClear]="true" [appendTo]="'body'"
                                                                            [filterValue]="this.newEmail"
                                                                            (onPanelShow)="clearNewEmail()">
                                                                            <ng-template let-enrollmentEmails pTemplate="footer">
                                                                                <div class="p-2">
                                                                                    <button (click)="enrollmentEmailsMultiSelect.hide(); SetEmailSettingsValue(emailSettingsEnum.EnrollmentInfoEmail,true)" [disabled]="(!isEnrollmentEmailValid || isDublicateEmail) || (newEmail||'').length == 0" pButton class="clm-primary-btn w-full multiselect-add-item-btn">
                                                                                        <i class="fa-solid fa-plus"></i>
                                                                                        <div class="add-btn-info">
                                                                                            <small class="block">Add cc email</small>
                                                                                            <span class="block ellipsis">{{newEmail}}</span>
                                                                                        </div>
                                                                                    </button>
                                                                                </div>
                                                                            </ng-template>
                
                                                                        </p-multiSelect>
                                                                    </div>
                    
                    
                                                                </div>
                                                            </div>
                                                            }
                                                            <li class="card-list-item cursor-pointer {{emailSettingsOptionsIndex == emailSettingsEnum.CRMEmail ? 'is-active':''}}"
                                                                (click)="onEmailSettingOption(emailSettingsEnum.CRMEmail)">
                                                                <div>
                                                                    <label class="block mb-1">CRM Email</label>
                                                                    <p class="text-500">When on, user can set to whom they need to send
                                                                        email amoung parents, student, send to cc emails </p>
                                                                </div>
                                                                <span
                                                                    class="fa fa-caret-right text-500 rotating-caret {{emailSettingsOptionsIndex == emailSettingsEnum.CRMEmail?'rotate-90':''}}"></span>
                                                            </li>
                                                            @if (emailSettingsOptionsIndex == emailSettingsEnum.CRMEmail) {
                                                            <div class="card-toggle-items-main">
                                                                <div class="toggle-content">
                                                                    <div class="toggle-content-list">
                                                                        <label for="sendToParent " class="col-4">Email</label>
                                                                        <p-multiSelect styleClass="w-full"  #crmEmailemailMultiSelect
                                                                            class="min-w-1 w-full" showToggleAll="false"
                                                                            [options]="emailSettings.crmEmail" [(ngModel)]="emailSettings.crmEmail"
                                                                            placeholder="Select Cities" optionLabel="name"
                                                                            (ngModelChange)="SetEmailSettingsValue(emailSettingsEnum.CRMEmail)"
                                                                            (onFilter)="filterVaildEmail($event,emailSettingsEnum.CRMEmail)"
                                                                            display="chip" [showClear]="true" [appendTo]="'body'"
                                                                            [filterValue]="this.newEmail"
                                                                            (onPanelShow)="clearNewEmail()">
                                                                            <ng-template let-crmEmail pTemplate="footer">
                                                                                <div class="p-2">
                                                                                    <button (click)="crmEmailemailMultiSelect.hide(); SetEmailSettingsValue(emailSettingsEnum.CRMEmail,true)" [disabled]="(!isCRMEmailValid || isDublicateEmail) || (newEmail||'').length == 0" pButton class="clm-primary-btn w-full multiselect-add-item-btn">
                                                                                        <i class="fa-solid fa-plus"></i>
                                                                                        <div class="add-btn-info">
                                                                                            <small class="block">Add cc email</small>
                                                                                            <span class="block ellipsis">{{newEmail}}</span>
                                                                                        </div>
                                                                                    </button>
                                                                                </div>
                                                                            </ng-template>
                                                                            </p-multiSelect>
                                                                    </div>
                    
                    
                                                                </div>
                                                            </div>
                                                            }
                    
                                                        </ul>
                                                    </div>
                                                }@else {
                                                    <div class="m-3">
                                                        <calimatic-skeleton [configs]="{height:'4rem',width:'100%',repeat:6,styleClass:'mb-4'}"></calimatic-skeleton>
                                                      </div>
                                                }
                                                </div>
                                            </div>
                                        </p-tabPanel> 
                                     }
                                        
                                    
                                
                                    <p-tabPanel header="Email Footer">
                                        @if(loadSummerNote){
                                            <calimatic-summernote [content]="emailFooter" (onChange)="SaveEmailFooter($event)"></calimatic-summernote>
                                        }
                                    </p-tabPanel>
                                </p-tabView>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </main>
</div>

@if (showImageSetupModal) {
    <p-dialog [modal]="true" [showHeader]="false" [draggable]="false"  [visible]="showImageSetupModal" styleClass="clm-lg-modal clm-modal-dialog"  >
        <calimatic-manage-email-image  [backlogType]="backlogType" (onCloseModal)="closeImageSetupModal($event)"></calimatic-manage-email-image>
   </p-dialog>
}

