import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/accordion';
import { FieldsetModule } from 'primeng/fieldset';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TreeModule } from 'primeng/tree';
import { AvatarModule } from 'primeng/avatar';
import { ChipsModule } from 'primeng/chips';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';
import { TabViewModule } from 'primeng/tabview';
import { InputSwitchModule } from 'primeng/inputswitch'
import { EmailFrequencySettingsDTO, EmailFrequencyWithSettingsDto, EmailFrequenyDto, EmailSenderDto, FormattedEmailsSettingDto, HomeworkAssessmentsDto, HomeworkAssessmentsResponseDto, ManageEmailImageConfigDto, NotificationToStaffDto, TimeTrackerEmailSettingDto, TimeZoneDto } from '../../../../../shared/dtos/on-board.dto';
import { AppConstants, AppTokens } from '../../../../../shared/utils/global';
import { IToaster } from '../../../../../shared/interfaces/toaster/IToaster';
import { ToasterTypeEnum } from '../../../../../shared/enums/toaster-type.enum';
import { CheckboxModule } from 'primeng/checkbox';
import { SystemConfigService } from '../../../../../core/services/system-config.service';
import { EmailFrenquencyToogleEnum, EmailSettingTabEnum, EmailTimeTrackerEnum, OnboardSetionStepsEnum, } from '../../../../../shared/enums/onboard.enum';
import { DropdownModule } from 'primeng/dropdown';
import { SummernoteComponent } from "../../../../../shared/components/summernote/summernote.component";
import { AuthService } from '../../../../../core/services/auth/auth.service';
import { UserRoleEnum } from '../../../../../shared/enums/user-role.enum';
import { AppUtils } from '../../../../../shared/utils/app-utilities';
import { FloatLabelModule } from 'primeng/floatlabel';
import { OnboardService } from '../../../../../core/services/onboard.service';
import { ValidateInputDirective } from '../../../../../shared/directives/validate-input.directive';
import { SkeletonComponent } from '../../../../../shared/components/skeleton/skeleton.component';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { ManageEmailImageComponent } from '../../../../../shared/components/set-up-image/manage-email-image.component';
import { ModuleConfig } from '../../../../../shared/enums/mics-enums/module.enum';
import { DialogModule } from 'primeng/dialog';
@Component({
  selector: 'calimatic-email-configs',
  standalone: true,
  imports: [DialogModule, ReactiveFormsModule, DropdownModule, InputTextModule, ButtonModule, ToastModule, AccordionModule, FieldsetModule, SelectButtonModule, FormsModule, TreeModule, AvatarModule, MultiSelectModule, InputNumberModule, TabViewModule, InputSwitchModule, CheckboxModule, ChipsModule, SummernoteComponent, FloatLabelModule, ValidateInputDirective, SkeletonComponent, ManageEmailImageComponent],
  templateUrl: './email-configs.component.html',
  styleUrl: './email-configs.component.scss',
  providers:[DynamicDialogConfig]
  
})
export class EmailConfigComponent  implements OnInit{
emailForm: FormGroup;
@Output() onEmailConfigDiagCloseEmiter:EventEmitter<boolean> = new  EventEmitter(false);
emailFrenquencyToogleEnum = EmailFrenquencyToogleEnum;
emailFrenquencyWithSettings:EmailFrequencyWithSettingsDto[] = [] as  EmailFrequencyWithSettingsDto[];
emailTimeTrackerToogleEnum = EmailTimeTrackerEnum;
emailTrackerSection:number = EmailTimeTrackerEnum.None; 
description : string = 'When on, user can set to whom they need to send email amoung parents, student, send to cc emails';
timeTrackerLabels = ["Timesheet Email","Expense Email","Time Off Email","Trade Schedule Emails","Cover Schedule Emails"];
timeTracker:any[] = [];
//Email Settings
emailSettings:FormattedEmailsSettingDto = {} as FormattedEmailsSettingDto;
emailSettingsEnum = EmailSettingTabEnum;
emailSettingsOptionsIndex = EmailSettingTabEnum.CCEmails;
isCCEmailValid :boolean = false;
isEnrollmentEmailValid :boolean = false;
isCRMEmailValid: boolean =  false;
newEmail:string = '';
isDublicateEmail:boolean = false;

//Challenge Notification To Staff
defaultChallengeRoles:NotificationToStaffDto[] = [] as NotificationToStaffDto[]
selectedChallengeRoles:  NotificationToStaffDto[] = [] as NotificationToStaffDto[]
//Send Challenge Completion Email At
showChallengeCompletion:boolean = true
challengeCompletionEmailAt:number = 0;
// Homework & Assessments Due Date Notification
showHomeworkAssessments:boolean = true
defaultHomeworkAssessmentsRoles:HomeworkAssessmentsDto[] = [] as HomeworkAssessmentsDto[];
selectedHomeworkAssessments:HomeworkAssessmentsDto[] = [] as HomeworkAssessmentsDto [];
homeworkAssessments:HomeworkAssessmentsResponseDto = {} as HomeworkAssessmentsResponseDto;
homeworkAssessmentsTimezone:TimeZoneDto [] = [] as TimeZoneDto[];
selectedUTC:TimeZoneDto = {} as TimeZoneDto;
homeAssesmentRoles = [
  {Name: "Parent", Code: UserRoleEnum.BusinessParent},
  {Name: "Student", Code: UserRoleEnum.BusinessStudent},
  {Name: "Parent", Code: UserRoleEnum.FranchiseParent},
  {Name: "Student", Code: UserRoleEnum.FranchiseStudent},
]; 

// Email Footer
emailFooter:string = "";
loadSummerNote:boolean = false;

//loaders bits
isHomeworkAndAssessmentLoaded:boolean = false;
isEmailSenderDataLoaded:boolean = false;
isEmailFooterDataLoaded:boolean = false;
isTimeTrackerDataLoaded:boolean = false;
isCCEmailsDataLoaded:boolean = false;
isFrequencyDataLoaded:boolean = false;
isNotificationToStaffLoaded: boolean = false;

// this bit used for hiding tabs which are not required for on boarding process
hideForOnboard:boolean = false;

//Manage image after Course/badge/level completion
imageConfigs:ManageEmailImageConfigDto = {} as ManageEmailImageConfigDto;
showImageSetupModal:boolean = false;
backlogType:number = ModuleConfig.Course;

constructor(@Inject(AppTokens.toaster) private toasterService: IToaster, private formBuilder:FormBuilder, private systemConfigsService:SystemConfigService,private authService:AuthService, private onboardService:OnboardService,private router: Router){
  this.buildForm();
}

async ngOnInit() {
  this.checkRoute();
  await this.loadComponentData()
}

checkRoute(){
  const currentUrl = this.router.url;  
    this.hideForOnboard = (currentUrl.includes('email-configs')) ? false : true; 
}
onTabViewChange(event:any){}

//#region Load Component Data
async loadComponentData(){
  this.setDefaultAssementsRolesAndTimeZone();
  await this.apiCalls();
}

//#region API Calls
async apiCalls(){
await this.getEmailSenderConfigs();
await this.getSystemEmailFooter();
if(!this.hideForOnboard){
  await this.getTimeTrackerEmailSetting();
  await this.getEmailFrequencyWithSetting()
  await this.getCompanyCCEmails();
  await this.getChallengeNotificationToStaff();
  await this.getHomeworkAndAssessmentsDueDate();
}
}
//#endregion 
//#endregion

//#region Email Sender Configs 
  buildForm(){
      this.emailForm = this.formBuilder.group({
        sendgridKey:['',[Validators.required, Validators.pattern(AppConstants.regularExpressions.space)]],
        fromEmail:new FormControl({value :'',disabled:true},[Validators.required,Validators.pattern(AppConstants.regularExpressions.space),Validators.pattern(AppConstants.regularExpressions.email)]) ,
        senderName:['',[Validators.required, Validators.pattern(AppConstants.regularExpressions.space)]],
        replyEmail:['',[Validators.required, Validators.pattern(AppConstants.regularExpressions.space),Validators.pattern(AppConstants.regularExpressions.email)]]
      })
  }
  async saveEmailSenderConfig(){
    if(this.emailForm.valid){
      let formValues = this.emailForm.value as EmailSenderDto; 
      let res = await this.systemConfigsService.saveEmailSenderConfigs(formValues);
      if(res.isSuccessful){
        // when user make any minor change or send save call then this step will be consider as complete for On board.  
        this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.EmailConfig);
      }
    }
  }
 async getEmailSenderConfigs(){
   let res = await this.systemConfigsService.getEmailSenderConfigs(); 
    if(res){
      this.emailForm.patchValue(res);
      this.OnSendgridKeyEnter();
    }
    this.isEmailSenderDataLoaded = true;
  }

  get sendgridKeyControl(){
    return this.emailForm.get('sendgridKey');
  }
  get fromEmailControl(){
    return this.emailForm.get('fromEmail');
  }

  OnSendgridKeyEnter(){
    if((this.sendgridKeyControl?.value || '').trim().length){
      this.fromEmailControl?.enable();
    }else{
      this.fromEmailControl?.reset();
      this.fromEmailControl?.disable();
    }
  }
  //#endregion 

  //#region Email Frequency and Settings 
  async getEmailFrequencyWithSetting(){
    let res = await this.systemConfigsService.getEmailFrequenciesSetting();
    let emailFrequencyType = AppUtils.getEnumKeysAndValuesArray(EmailFrenquencyToogleEnum);
    if(res && (res?.emailFrequencySetting||[]).length){
      for(let type of emailFrequencyType){
        if(res.emailFrequencySetting.some(x=>x.type == type.value && x.type !=EmailFrenquencyToogleEnum.AbandonedCartInfoDailyEmailCC)){
          let frequecyWithSetting:EmailFrequencyWithSettingsDto = new EmailFrequencyWithSettingsDto() 
          this.systemConfigsService.mapFrequencySettings(res.emailFrequency,frequecyWithSetting,res.emailFrequencySetting,type.value)
          this.emailFrenquencyWithSettings.push(frequecyWithSetting); 
        }
      }
      }

     this.isFrequencyDataLoaded = true; 
  }

  async setEmailFrequency(event:any,type:number){
    let res = await this.systemConfigsService.updateEmailFrequency(type, event?.checked);
    if(res.isSuccessful){
      this.emailFrenquencyWithSettings = this.emailFrenquencyWithSettings.map(x => ({...x,isSettingOn: x.type === type ? event.checked  : x.isSettingOn}));
     // when user make any minor change or send save call then this step will be consider as complete for On board.  
      this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.EmailConfig);
      return;
    }
  }

async setEmailFrequecySettings(event:any, frequencySetting:EmailFrequencySettingsDTO, type:number, isOnAdd:boolean = false,isToCc:boolean = false){
     
      //this logic is for on and off tocc email option for send email
      if(!isToCc){
      frequencySetting.toCC = (!frequencySetting.toStudent && !frequencySetting.toParent && !frequencySetting.toCC) ? false : true;
      }
      let email = event?.value || '';
      if(frequencySetting.toCC && (frequencySetting?.emails||[]).length == 0 && (email||'').length){
        frequencySetting?.emails.push(email)
        return ;
      }
      //this logic is written for validating valid email if email is not valid then we should remove it form variable and chips also
     
      if(isOnAdd && !this.isEmailValild(email)){
      //if email is not valid then we should remove it from frequencySetting?.emails beacuse we have used ngModel and value is already binded with variable. 
        frequencySetting.emails = (frequencySetting?.emails||[]).filter(e => e !== email);
        frequencySetting.emailsTo = (frequencySetting?.emailsTo ||[]).filter(e => e !== email);
        this.toasterService.show(ToasterTypeEnum.Error,'Error' ,'Please enter the valild email')
        return;
      }
      frequencySetting.emailAddress = ((frequencySetting?.emails||[]).map(x => x)).join(','); 
      frequencySetting.toEmailAddress = ((frequencySetting?.emailsTo ||[]).map(x=>x)).join(',')
      let response =  await this.systemConfigsService.updateEmailFrequencySettings(frequencySetting, type);
      if(response.isSuccessful){
       return;
      }

}

isEmailValild(currentAddedEmail:string){
  return  AppConstants.regularExpressions.email.test(currentAddedEmail) ? true : false;
}
//#endregion 

//#region Time Tracker Email Settings 
OnOpenTimeTracker(moduleType:number){
  this.emailTrackerSection = this.emailTrackerSection == moduleType ? EmailTimeTrackerEnum.None : moduleType;
 }
  async getTimeTrackerEmailSetting(){
   this.timeTracker = await this.systemConfigsService.getTimeTrackerEmailSettings();
   this.isTimeTrackerDataLoaded = true;
  }

 async setTimeTrackerEmailSettings(trackerEmailSettings:TimeTrackerEmailSettingDto ){
  let response = await this.systemConfigsService.setTimeTrackerEmailSettings(trackerEmailSettings);
  if(response.isSuccessful){
    // when user make any minor change or send save call then this step will be consider as complete for On board.  
    this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.EmailConfig);
  return
  }
}
  //#endregion 

//#region Company CC Email
async getCompanyCCEmails(){
    let response = await this.systemConfigsService.getEmailsSettings();
    this.emailSettings.ccEmails = (response.ccEmails || '').length ?  (response.ccEmails.split(',') ||[]).map((email:any,index:number) => ({ name: email, value: index})) : [];
    this.emailSettings.enrollmentEmails = (response.enrollmentEmails || '').length ?  (response.enrollmentEmails.split(',') ||[]).map((email:any,index:number) => ({ name: email, value: index})) : [];
    this.emailSettings.crmEmail = (response.crmEmail || '').length ? (response.crmEmail.split(',')||[]).map((email:any,index) => ({ name: email, value: index})) : [];
    this.isCCEmailsDataLoaded = true;

  }

onEmailSettingOption(optionIndex:number){
  this.emailSettingsOptionsIndex = optionIndex;
}
async SetEmailSettingsValue(type:number, isNewEmailAdded:boolean = false){
  let emailsValues:any=[];
  //IF NEW EMAIL ADDED THEN WE HAVE UPDATED  OUR LIST AND PUSH FIRST NEW EMIAL IN TO IT
  if(isNewEmailAdded){
    this.addNewEmail(type);
  }
  if(type == EmailSettingTabEnum.CCEmails){
    emailsValues = (this.emailSettings.ccEmails || []).map(x=>x.name) 
  }else if(type == EmailSettingTabEnum.CRMEmail){
    emailsValues = (this.emailSettings.crmEmail||[]).map(x=>x.name); 
  }else {
    emailsValues = (this.emailSettings.enrollmentEmails||[]).map(x=>x.name);
  }
  let res = await this.systemConfigsService.setEmailsSetttingValues(emailsValues, type);
  if(res.isSuccessful){
    // when user make any minor change or send save call then this step will be consider as complete for On board.  
    this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.EmailConfig);
  }
}
addNewEmail(type:number){

   //IN OUR EXISTING LIST WE NEED TO ADD NEW ITEM AT LAST INDEX, FOR LAST INDEX I USED TOTAL LENGTH OF LIST.     
  switch(type){
    case EmailSettingTabEnum.CCEmails: 
    (this.emailSettings.ccEmails || []).push({name:this.newEmail,value:(this.emailSettings.ccEmails||[]).length})
    break;
    case EmailSettingTabEnum.EnrollmentInfoEmail:
      (this.emailSettings.enrollmentEmails || []).push({name:this.newEmail,value:(this.emailSettings.enrollmentEmails||[]).length})
    break;
    case EmailSettingTabEnum.CRMEmail:
    (this.emailSettings.crmEmail || []).push({name:this.newEmail,value:(this.emailSettings.crmEmail||[]).length})
    break;
  } 
  this.newEmail ='';
}
//#endregion

//#region Challenge Notification To Staff 
async getChallengeNotificationToStaff(){
 let res = await this.systemConfigsService.getChallengeNotificationToStaff(); 
 if(res){
  this.selectedChallengeRoles = (res.defaultRoles ||[]).filter(x=> (res.selectedRoles || []).includes(x.roleGuid));
  this.defaultChallengeRoles =  res.defaultRoles || [];
  this.challengeCompletionEmailAt = res.challengeComppletionPoints || 0;
 }
 this.isNotificationToStaffLoaded = true;
}
async onChallengeRolesChange(){
  let selectedRolesGuids = (this.selectedChallengeRoles ||[]).map(x=>x.roleGuid).join(",");
  let response  = await this.systemConfigsService.updateEmailFrequency(EmailFrenquencyToogleEnum.EmailToRoles,false,selectedRolesGuids);   
  if(response.isSuccessful){
    // when user make any minor change or send save call then this step will be consider as complete for On board.  
    this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.EmailConfig);
  }
}

async updateCompletionEmailAt(){
let res = await this.systemConfigsService.saveCompletionEmailAt(this.challengeCompletionEmailAt);
  if(res.isSuccessful){
    // when user make any minor change or send save call then this step will be consider as complete for On board.  
    this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.EmailConfig);
  }
}
//#endregion

//#region  Homework and Assesments Due Date Notification
setDefaultAssementsRolesAndTimeZone(){
  //time zone options
  this.homeworkAssessmentsTimezone = AppUtils.getTimezones();
  if(this.authService.isFranchiseAdmin()){
    this.defaultHomeworkAssessmentsRoles = this.homeAssesmentRoles.filter(x=>x.Code == UserRoleEnum.FranchiseParent || x.Code == UserRoleEnum.FranchiseStudent);
  }else if(this.authService.isBusinessAdmin()){
    this.defaultHomeworkAssessmentsRoles = this.homeAssesmentRoles.filter(x=>x.Code == UserRoleEnum.BusinessParent || x.Code == UserRoleEnum.BusinessStudent);
  }
}

async getHomeworkAndAssessmentsDueDate(){
  let res = await this.systemConfigsService.getHomeworkAndAssessmentsDueDate();
  if(res){
  //res.roles comes in form of comma  Separated string, we need roles Types in form of numer.
  //converted comma separated string into string array  
  let CommaSeparatedStrRolesTypesIntoArr = (res.roles || '').split(',') ||[]
  //Now convert string into number array. 
  let StrRolesTypesIntoNumArr = (CommaSeparatedStrRolesTypesIntoArr||[]).map(x=>Number(x)) ||[]; 
  this.selectedHomeworkAssessments = this.homeAssesmentRoles.filter(x=>StrRolesTypesIntoNumArr.includes(x.Code));
  this.selectedUTC = (this.homeworkAssessmentsTimezone ||[]).find(x=>x.tzName == res.tzName);
  }
  this.isHomeworkAndAssessmentLoaded = true;
}
 
async OnSaveHomeworkAssessments(){
  this.homeworkAssessments.roles = (this.selectedHomeworkAssessments||[]).map(x=>x.Code).join(',');
  this.homeworkAssessments.tzAbbrv = this.selectedUTC.tzAbbrv ||'';
  this.homeworkAssessments.tzName = this.selectedUTC.tzName || '';
  this.homeworkAssessments.tzOffset = (this.selectedUTC.tzOffSet||0).toString() || ''
  let res = await this.systemConfigsService.updateEmailFrequency(EmailFrenquencyToogleEnum.HomeworkAssessmentDueDateNotification,false,this.homeworkAssessments.roles,this.homeworkAssessments.tzOffset,this.homeworkAssessments.tzAbbrv,this.homeworkAssessments.tzName);
  if(res.isSuccessful){
    // when user make any minor change or send save call then this step will be consider as complete for On board.  
    this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.EmailConfig);
  }
}
//#endregion

//#region Email Footer
async getSystemEmailFooter(){
this.emailFooter = await this.systemConfigsService.getSystemEmailFooter(); 
this.loadSummerNote = true;
}
async SaveEmailFooter(emailFooter:string){
  if(this.emailFooter !== emailFooter){
    let res = await this.systemConfigsService.SaveEmailFooter(emailFooter);
    // when user make any minor change or send save call then this step will be consider as complete for On board.  
    this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.EmailConfig);
  }
}
//#endregion

isVaildEmail(email:string = ""){
  return  AppConstants.regularExpressions.email.test(email);
}

filterVaildEmail(event:any,emailType:number){
  let email = event?.filter;
  this.newEmail = email;
  switch(emailType){
    case EmailSettingTabEnum.CCEmails: 
    this.isDublicateEmail = (this.emailSettings.ccEmails||[]).some(x=>x.name.trim().toLowerCase() === (this.newEmail ||'').trim().toLowerCase());
    this.isCCEmailValid = this.isEmailValild(email);
    break;
    case EmailSettingTabEnum.EnrollmentInfoEmail: 
    this.isEnrollmentEmailValid = this.isEmailValild(email);
    this.isDublicateEmail = (this.emailSettings.enrollmentEmails||[]).some(x=>x.name.trim().toLowerCase() === (this.newEmail ||'').trim().toLowerCase());
    break; 
    case EmailSettingTabEnum.CRMEmail: 
    this.isDublicateEmail = (this.emailSettings.crmEmail||[]).some(x=>x.name.trim().toLowerCase() === (this.newEmail ||'').trim().toLowerCase());
    this.isCRMEmailValid = this.isEmailValild(email);
    break; 
  }
}
clearNewEmail(){
  this.newEmail ='';
}

async openImageModal(emailSetting:EmailFrequencyWithSettingsDto){
 this.setUpImageConfigs(emailSetting);
}
setUpImageConfigs(emailSetting:EmailFrequencyWithSettingsDto){
  switch(emailSetting.type){
    case EmailFrenquencyToogleEnum.Course:
      this.backlogType = ModuleConfig.Course;
      break;
    case EmailFrenquencyToogleEnum.Level:
      this.backlogType = ModuleConfig.Level;

      break;
    case EmailFrenquencyToogleEnum.Challenge: 
    this.backlogType = ModuleConfig.Challange;
     break;
  }
  this.showImageSetupModal = true;
 }
closeImageSetupModal(close:any){
  this.showImageSetupModal = false;
}

}
 
