import {CommonModule} from '@angular/common';
import {Component, input, output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {CompanyConfigDto} from '../../../shared/dtos/company-config-dto';
import {AuthService} from '../../../core/services/auth/auth.service';
import {InputOtpModule} from 'primeng/inputotp';
import {PasswordModule} from 'primeng/password';
import {AppUtils} from "../../../shared/utils/app-utilities";
import {ForgotPasswordSlidesEnum} from '../../../shared/enums/login.enum';
import {ForgotPasswordDTO, ResetPasswordDTO} from '../../../shared/dtos/login-dto';
import {CardModule} from 'primeng/card';
import {interval, map, Subscription, takeWhile} from 'rxjs';
import {AppConstants,} from '../../../shared/utils/global';
import {AppLabelComponent} from "../../../shared/components/app-label/app-label.component";

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextModule,
    CommonModule,
    InputOtpModule,
    PasswordModule,
    CardModule,
    AppLabelComponent
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  companyConfigs = input.required<CompanyConfigDto>();
  error = output<string>();
  close = output<boolean>();

  username = '';
  email = '';
  isLoading = false;
  resendingOtp = false;

  //used for opening different sections of forgot password process.
  activeSlide = ForgotPasswordSlidesEnum.UserValidateForm;
  forgotPasswordEnum = ForgotPasswordSlidesEnum;

  forgotPasswordForm: FormGroup = {} as FormGroup;
  resetPasswordForm: FormGroup = {} as FormGroup;

  //OTP
  otpCode: any;
  passwordLength = 8;
  isValidPassLength = true;
  // user verification Guid only comes (during forgot password process) when username and email verified
  verifiedUserGuid = "";
  passwordErrorMessage = 'Passwords must contain uppercase and lowercase letters, digits, and special characters (e.g., @, #, .).';
  showMessage = false;
  otpExpiryTimeLeft: number;
  formattedTime: string;
  isOtpSent = false;
  private readonly expiredTimeInterval = 2 * 60.5;
  private subscriptions: Subscription [] = [];

  constructor(private authService: AuthService, private fb: FormBuilder) {
    this.buildForms();
  }

  get formControls() {
    return this.forgotPasswordForm.controls;
  }

  get resetPasswordFormControls() {
    return this.resetPasswordForm.controls;
  }

  async onValidateUser() {
    if (this.forgotPasswordForm.invalid) {
      this.formControls.userName.markAsDirty();
      this.formControls.email.markAsDirty();
      return;
    }
    this.isLoading = true;
    let res = await this.validateUser();
    this.isLoading = false;
    if (res.isSuccessful) {
      this.error.emit('');
      this.activeSlide = ForgotPasswordSlidesEnum.OtpVerificationForm;
      this.verifiedUserGuid = res.response;
      this.setOTPExpiredTimeInterval();
    } else {
      this.error.emit(res.response);
    }
  }

  async onVerifyOtpCode() {
    this.isLoading = true;
    let res = await this.authService.forgotPasswordOtpVerification(this.otpCode, this.verifiedUserGuid, AppUtils.getPortalUrl());
    this.isLoading = false;
    if (res.isSuccessful) {
      this.error.emit('');
      this.activeSlide = ForgotPasswordSlidesEnum.PasswordResetForm;
    } else {
      this.otpCode = null;
      this.error.emit(res.response);
    }
  }

  async resetPassword() {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordFormControls.password.markAsDirty();
      this.resetPasswordFormControls.confirmPassword.markAsDirty();
      return;
    }

    let data: ResetPasswordDTO = new ResetPasswordDTO();
    data.password = this.resetPasswordFormControls.password.value;
    data.confirmPassword = this.resetPasswordFormControls.confirmPassword.value;
    data.userGuid = this.verifiedUserGuid;
    data.companyUrl = AppUtils.getPortalUrl();
    this.isLoading = true;
    let res = await this.authService.resetPassword(data);
    this.isLoading = false;
    if (res.isSuccessful) {
      this.error.emit('');
      this.activeSlide = ForgotPasswordSlidesEnum.PasswordVerificationComplete;
    } else {
      this.error.emit(res.response);
    }
  }

  async resendOtpCode() {
    this.resendingOtp = true;
    let res = await this.validateUser();
    this.resendingOtp = false;
    if (res.isSuccessful) {
      this.error.emit('');
      this.isOtpSent = false;
      this.setOTPExpiredTimeInterval();
    } else {
      this.error.emit('Unable to send a new OTP. Please try again.');
    }
  }

  isValidPasswordLength() {
    this.isValidPassLength = (this.resetPasswordFormControls.password?.value || '').length >= this.passwordLength;
  }

  ngOnDestroy() {
    (this.subscriptions || []).forEach(subscription => subscription?.unsubscribe());
  }

  private async validateUser() {
    let formValue = this.forgotPasswordForm.value as ForgotPasswordDTO;
    return await this.authService.forgotPassword(formValue.email, formValue.userName, AppUtils.getPortalUrl());
  }

  private passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password')?.value;
    const confirmPassword = formGroup.get('confirmPassword')?.value;
    return password === confirmPassword ? null : {mismatch: true};
  }

  private setOTPExpiredTimeInterval() {
    this.otpExpiryTimeLeft = this.expiredTimeInterval;

    this.subscriptions.push(interval(1000).pipe(
      takeWhile(() => this.otpExpiryTimeLeft >= 0),
      map(() => this.otpExpiryTimeLeft--)
    ).subscribe(() => {
      this.isOtpSent = true;
      this.formattedTime = AppUtils.formatTime(this.otpExpiryTimeLeft);
    }));
  }

  private buildForms() {
    this.forgotPasswordForm = this.fb.group({
      userName: new FormControl('', [Validators.required, Validators.pattern(AppConstants.regularExpressions.space)]),
      email: new FormControl('', [Validators.pattern(AppConstants.regularExpressions.email), Validators.required]),
    });

    this.resetPasswordForm = this.fb.group({
      password: new FormControl('', [Validators.required, Validators.pattern(AppConstants.regularExpressions.password)]),
      confirmPassword: new FormControl('', Validators.required),
    }, {validator: this.passwordMatchValidator});
  }
}
