import {Injectable} from '@angular/core';
import {ApiHandlerService} from './base/api-handler.service';
import {lastValueFrom} from 'rxjs';
import {API_ENDPOINTS} from '../../shared/utils/global';
import {TerminologiesDto} from '../../shared/dtos/on-board.dto';
import {TerminologyEnum} from "../../shared/enums/terminology.enum";


@Injectable({
  providedIn: 'root'
})
export class TerminologyService {
  constructor(private http: ApiHandlerService) {
  }

  async getTerminologies() {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.Teminologies.list));
    if (res.isSuccessful) {
      let terminologies = (res.response as TerminologiesDto[]) || [];
      this.setDefaultIfNull(TerminologyEnum.Assessment, terminologies, 'Assessment', 'Assessments');
      this.setDefaultIfNull(TerminologyEnum.Question, terminologies, 'Question', 'Questions');
      this.setDefaultIfNull(TerminologyEnum.Template, terminologies, 'Template', 'Templates');
      this.setDefaultIfNull(TerminologyEnum.Type, terminologies, 'Type', 'Types');
      this.setDefaultIfNull(TerminologyEnum.Category, terminologies, 'Category', 'Categories');
      this.setDefaultIfNull(TerminologyEnum.CourseType, terminologies, 'Type', 'Types');
      this.setDefaultIfNull(TerminologyEnum.CourseCategory, terminologies, 'Category', 'Categories');
      this.setDefaultIfNull(TerminologyEnum.Course, terminologies, 'Course', 'Courses');
      this.setDefaultIfNull(TerminologyEnum.Level, terminologies, 'Level', 'Levels');
      this.setDefaultIfNull(TerminologyEnum.Clas, terminologies, 'Class', 'Classes');
      this.setDefaultIfNull(TerminologyEnum.Challenge, terminologies, 'Challenge', 'Challenges');
      this.setDefaultIfNull(TerminologyEnum.ChallengeStep, terminologies, 'Step', 'Steps');
      this.setDefaultIfNull(TerminologyEnum.Franchise, terminologies, 'Franchise', 'Franchises');
      this.setDefaultIfNull(TerminologyEnum.Coupon, terminologies, 'Coupon', 'Coupons');
      this.setDefaultIfNull(TerminologyEnum.Family, terminologies, 'Family', 'Families');
      this.setDefaultIfNull(TerminologyEnum.Session, terminologies, 'Session', 'Sessions');
      this.setDefaultIfNull(TerminologyEnum.Event, terminologies, 'Event', 'Events');
      this.setDefaultIfNull(TerminologyEnum.Activity, terminologies, 'Activity', 'Activities');
      return terminologies;
    }
    return [] as TerminologiesDto[];
  }

  async updateTerminology(data: TerminologiesDto[]) {
    return lastValueFrom(this.http.Post(API_ENDPOINTS.Teminologies.update, data));
  }

  private setDefaultIfNull(term: TerminologyEnum, terminologies: TerminologiesDto[], defaultLabel: string, pluralDefaultLabel: string) {
    const dbTerm = terminologies.find(x => x.name == term);
    if (dbTerm) {
      if ((dbTerm.label ?? "").length == 0) {
        dbTerm.label = defaultLabel;
      }

      if ((dbTerm.pluralLabel ?? "").length == 0) {
        dbTerm.pluralLabel = pluralDefaultLabel;
      }

    } else {
      terminologies.push({
        name: term,
        label: defaultLabel,
        pluralLabel: pluralDefaultLabel,
        id: 0
      });
    }
  }
}
