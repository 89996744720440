@if (loading) {
  <calimatic-chart-skeleton></calimatic-chart-skeleton>
} @else {
  <div class="clm-widget-item card">
    <div class="card-action-wrapper">
      @if (edit()) {
        <span (click)="onRemove.emit()" class="action_btn" [pTooltip]="'remove widget'" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-xmark"></i></span>
      }
    </div>
    <div class="card-header">
      <h5>Student Demographics
        <span class="card-icon-inline info-icon" [pTooltip]="widget().description" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}">
        <i class="fa-solid fa-circle-info"></i>
      </span>
        <span (click)="loadData()" class="card-icon-inline refresh-btn" [pTooltip]="'Refresh widget'"
              tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-rotate"></i></span>
      </h5>
      <div class="card-options {{edit()?'visibility-hidden':''}}">
        <div class="card-multiple-options">
          @if (!isFranchise()) {
            <div class="course-info-dropdown">
              <calimatic-dropdown (valueChange)="onFranchiseChange($event)" [configs]="{
                              options:franchises(),
                              label:'name',
                              value:'id',
                              placeholder:'Select franchise',
                              appendTo: 'body',
                              selectable:true,
                            }" [value]="selectedFranchise">
              </calimatic-dropdown>
            </div>
          }

          <div class="course-info-dropdown">
            <calimatic-dropdown (valueChange)="onTypeChange($event)" [configs]="{
                              options:chartOpt,
                              label:'name',
                              value:'id',
                              placeholder:'Select',
                              appendTo: 'body',
                              selectable:true,
                            }" [value]="selectedType">
            </calimatic-dropdown>
          </div>
          <div class="course-info-dropdown">
            <calimatic-dropdown (valueChange)="onStateChange($event)" [configs]="{
                              options:stateOpts,
                              label:'name',
                              value:'id',
                              placeholder:'Select Status',
                              appendTo: 'body',
                              selectable:true,
                            }" [value]="selectedState">
            </calimatic-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <calimatic-bar-chart [data]="data" label="Total"></calimatic-bar-chart>
    </div>
  </div>
}
