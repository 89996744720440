import {Component, input, OnInit} from '@angular/core';
import mapboxgl from 'mapbox-gl';
import {MapDto} from "../../../../shared/dtos/map.dto";
import {AppConstants} from "../../../../shared/utils/global";
import {MapService} from "../../../../shared/services/map.service";

@Component({
  selector: 'calimatic-map',
  standalone: true,
  imports: [],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent implements OnInit {

  //inputs
  data = input.required<MapDto[]>();
  askLocation = input(false);
  companyId = input.required<number>();

  //local
  mapInstance: mapboxgl.Map;

  constructor(private mapService: MapService) {
  }

  async ngOnInit() {
    if (this.data()?.length) {
      const companyLoc = this.data().find(x => x.id == this.companyId());
      this.mapInstance = await this.mapService.init(this.askLocation(), {
        lat: companyLoc?.lat,
        lng: companyLoc?.lng
      });
      this.setMarkers(this.data());
    } else {
      this.mapInstance = await this.mapService.init(this.askLocation());
    }
  }

  private setMarkers(data: MapDto[]) {
    const _this = this;
    data.forEach((x) => {
      const el = document.createElement("map");
      el.className = 'user-marker';
      el.innerHTML = `<span><img src="${AppConstants.map.marker}" alt="marker"></span>`;
      el.style.borderColor = AppConstants.map.borderColor;

      let popup = new mapboxgl.Popup({offset: [-10, -10], closeButton: false}).setHTML(
        `
        <div class="map-popup-inner-content">
          <h5 class="ellipsis">${x.name}</h5>
          <div class="map-popup-icon-info">
            <i class="fa-solid fa-location-dot mr-2"></i>
            <span class="ellipsis">${x.address?.trim()}</span>
          </div>
          <div class="map-popup-icon-info">
            <i class="fa-solid fa-envelope mr-2"></i>
            <span class="ellipsis">${x.email?.trim()}</span>
          </div>
          <div class="map-popup-icon-info">
            <i class="fa-solid fa-phone mr-2"></i>
            <span class="ellipsis">${x.phoneNumber?.trim()}</span>
          </div>
          
          <div class="map-popup-row-section">
            <ul>
              <li><b>No of Admins:</b><span>${x.admins}</span></li>
              <li><b>No of Executives:</b><span>${x.executives}</span></li>
              <li><b>No of Coordinators:</b><span>${x.coordinators}</span></li>
            </ul>
          </div>

          <div class="map-popup-card-section">
            <div class="map-popup_card card-1">
              <span>${x.instructors}</span>
              <h6>Instructors</h6>
            </div>
            <div class="map-popup_card card-2">
              <span>${x.contacts}</span>
              <h6>Contacts</h6>
            </div>
            <div class="map-popup_card card-3">
              <span>${x.students}</span>
              <h6>Students</h6>
            </div>
          </div>
        </div>
        `
      );
      new mapboxgl.Marker(el)
        .setLngLat([x.lng, x.lat])
        .setPopup(popup)
        .addTo(_this.mapInstance)
    });
  }
}
