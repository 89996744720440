import {Component} from '@angular/core';
import {SkeletonModule} from "primeng/skeleton";

@Component({
  selector: 'calimatic-counter-skeleton',
  standalone: true,
  imports: [
    SkeletonModule
  ],
  templateUrl: './counter-skeleton.component.html',
  styleUrl: './counter-skeleton.component.scss'
})
export class CounterSkeletonComponent {

}
