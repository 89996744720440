import {inject, Pipe, PipeTransform} from '@angular/core';
import {terminologyStore} from "../../core/stores/terminology.store";
import {TerminologyEnum, TerminologyWordEnum} from "../enums/terminology.enum";

@Pipe({
  name: 'terminology',
  standalone: true,
  pure: false
})
export class TerminologyPipe implements PipeTransform {
  #terminologyStore = inject(terminologyStore);

  transform(word: string, term: TerminologyEnum, wordType: TerminologyWordEnum = TerminologyWordEnum.SINGULAR) {
    const terminologies = this.#terminologyStore.terminologies();
    if (!terminologies?.length)
      return word;

    const terminology = terminologies.find(t => t.name === term);
    if (terminology) {
      return wordType == TerminologyWordEnum.SINGULAR ? terminology.label : terminology.pluralLabel;
    }
    return word;
  }
}
