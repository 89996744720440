@if (!loading) {
  <div class="clm-widget-item card">
    <div class="card-action-wrapper">
      @if (edit()) {
        <span (click)="onRemove.emit()" class="action_btn" [pTooltip]="'remove widget'" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-xmark"></i></span>
      }
    </div>
    <div class="card-header">
      <h5>{{ widget().title }}
        <span class="card-icon-inline info-icon" [pTooltip]="widget().description" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}">
        <i class="fa-solid fa-circle-info"></i>
      </span>
        <span (click)="loadData()" class="card-icon-inline refresh-btn" [pTooltip]="'Refresh widget'"
              tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-rotate"></i></span>
      </h5>
      <div class="card-options {{edit()?'visibility-hidden':''}}">
        <div class="card-multiple-options">
          @if (!isFranchise()) {
            <div class="course-info-dropdown">
              <calimatic-multi-select (valueChange)="onFranchiseChange($event)" [configs]="{
            options:franchises(),
            label:'name',
            value:'id',
            placeholder:'Select ' + ('franchise' | terminology:terminologyEnum.Franchise: terminologyWordType.SINGULAR),
            display:multiSelectDisplay.COMMA,
            appendTo: 'body',
            isSelectable:true
          }" [value]="selectedFranchises">
              </calimatic-multi-select>
            </div>
          }
          <div class="course-info-dropdown">
            <calimatic-multi-select (valueChange)="onTypeChange($event)" [configs]="{
            options:types(),
            label:'name',
            value:'name',
            placeholder:'Select ' + ('Type' | terminology:terminologyEnum.Type: terminologyWordType.SINGULAR),
            display:multiSelectDisplay.COMMA,
            appendTo: 'body',
            isSelectable:true,
          }" [value]="selectedType">
            </calimatic-multi-select>
          </div>

        </div>
      </div>
    </div>
    <div class="card-body">
      <calimatic-stack-bar-chart [data]="chartData" label="Enrollments"
                                 [barThickness]="'0.3'"></calimatic-stack-bar-chart>
    </div>
  </div>
} @else {
  <calimatic-chart-skeleton></calimatic-chart-skeleton>
}
