import {Component, input, OnInit, output} from '@angular/core';
import {CalendarModule} from "primeng/calendar";
import {SharedModule} from "primeng/api";
import {FormsModule} from "@angular/forms";
import {DashboardService} from "../../../../../core/services/dashboard.service";
import {
  DashboardCalendarClassDataDto,
  DashboardCalendarDataDto,
  DashboardCalendarEventDataDto
} from "../../../../../shared/dtos/dashboard-calendar-data.dto";
import {DateTimeFormats, MomentHelper} from "../../../../../shared/services/moment-helper";
import {DatePipe} from "@angular/common";
import {ChartSkeletonComponent} from "../skeletons/chart-skeleton/chart-skeleton.component";
import {TooltipModule} from 'primeng/tooltip';
import {TabViewModule} from 'primeng/tabview';
import {DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";
import {TerminologyPipe} from "../../../../../shared/pipes/terminology.pipe";
import {TerminologyEnum, TerminologyWordEnum} from "../../../../../shared/enums/terminology.enum";
import {RouterLink} from "@angular/router";
import {appRoutePaths} from "../../../../../app.routes";

interface CalendarDate {
  day: number;
  month: number;
  selectable: boolean;
  today: number;
  year: number;
  otherMonth: boolean;
}

@Component({
  selector: 'calimatic-dashboard-calendar-widget',
  standalone: true,
  imports: [
    CalendarModule,
    SharedModule,
    FormsModule,
    DatePipe,
    ChartSkeletonComponent,
    TooltipModule,
    TabViewModule,
    TerminologyPipe,
    RouterLink
  ],
  templateUrl: './dashboard-calendar-widget.component.html',
  styleUrl: './dashboard-calendar-widget.component.scss'
})
export class DashboardCalendarWidgetComponent implements OnInit {

  //inputs
  widget = input.required<DashboardWidgetsDTO>();
  edit = input.required();

  //output
  onRemove = output();

  selectedDate: Date = new Date();
  calendarData: DashboardCalendarDataDto | null;
  classes: DashboardCalendarClassDataDto[] = [];
  events: DashboardCalendarEventDataDto[] = [];
  loader = false;
  protected readonly terminologyEnum = TerminologyEnum;
  protected readonly terminologyWordType = TerminologyWordEnum;
  protected readonly appRoutePaths = appRoutePaths;
  private cachedClassDates = new Set<string>();
  private cachedEventDates = new Set<string>();

  constructor(private dashboardService: DashboardService) {
  }

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    this.loader = true;
    this.calendarData = this.formatCalendarData(await this.dashboardService.getCalendarData());
    this.filterDataByDate(this.selectedDate)
    this.loader = false;
  }

  filterDataByDate(date: Date) {
    this.filterClassesEvents(date);
    this.cacheDates();
  }

  hasClasses(date: CalendarDate): boolean {
    return this.cachedClassDates.has(this.cacheCalendarDateformat(date));
  }

  hasEvents(date: CalendarDate): boolean {
    return this.cachedEventDates.has(this.cacheCalendarDateformat(date));
  }

  private filterClassesEvents(date: Date) {
    this.classes = (this.calendarData?.classes || []).filter(x => {
      return this.matchingDateFilter(date, x.startDate, x.endDate, x.days);
    });

    this.events = (this.calendarData?.events || []).filter(x => {
      return this.matchingDateFilter(date, x.startDate, x.endDate, []);
    });
  }

  private formatCalendarData(data: DashboardCalendarDataDto | null) {
    if (data) {
      (data.classes || []).concat(data.events || []).forEach(x => {
        x.formattedStartTime = MomentHelper.Parse(x.startTime, DateTimeFormats.Long24Time).format(DateTimeFormats.TimeFormat12Col);
        x.formattedEndTime = MomentHelper.Parse(x.endTime, DateTimeFormats.Long24Time).format(DateTimeFormats.TimeFormat12Col);
        if (x.days?.length) {
          x.days = this.formatDays(x.days);
        }
      });
    }
    return data;
  }

  private matchingDateFilter(date: Date, startDate: Date, endDate: Date, days: string[]) {
    return MomentHelper.IsBetween(date, startDate, endDate) &&
      (!days?.length || days.includes(MomentHelper.Parse(date).format('ddd').toLowerCase()));
  }

  private getDayAbbr(d: string) {
    switch (d.toLowerCase()) {
      case "su":
        return "sun";
      case "m":
        return "mon";
      case "tu":
        return "tue";
      case "w":
        return "wed";
      case "th":
        return "thu";
      case "f":
        return "fri";
      case "sa":
        return "sat";
      default:
        return "";
    }
  }

  private formatDays(days: string) {
    const formattedDays: string[] = [];
    (days || "").split(',').forEach(d => {
      formattedDays.push(this.getDayAbbr(d));
    });
    return formattedDays;
  }

  private cacheDates() {
    this.cachedClassDates.clear();
    this.cachedEventDates.clear();

    (this.calendarData?.classes || []).forEach(x => {
      const dates = this.generateDates(x.startDate, x.endDate, x.days);
      dates.forEach(date => this.cachedClassDates.add(date));
    });

    (this.calendarData?.events || []).forEach(x => {
      const dates = this.generateDates(x.startDate, x.endDate, []);
      dates.forEach(date => this.cachedEventDates.add(date));
    });
  }

  private generateDates(startDate: Date, endDate: Date, days: string[]): string[] {
    const dates = [];
    let currentDate = new Date(startDate);
    const parsedEndDate = new Date(endDate);
    while (currentDate <= parsedEndDate) {
      const day = MomentHelper.Parse(currentDate).format('ddd').toLowerCase();
      if (!days.length || days.includes(day)) {
        dates.push(this.cacheDateFormat(currentDate));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }

  private cacheCalendarDateformat(date: CalendarDate) {
    return `${date.year}-${date.month}-${date.day}`
  }

  private cacheDateFormat(date: Date) {
    return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
  }
}
