import {Component, inject} from '@angular/core';
import {TerminologyPipe} from "../../pipes/terminology.pipe";
import {OverlayPanel, OverlayPanelModule} from "primeng/overlaypanel";
import {OnlineClassInterface} from "../../interfaces/online-class.interface";
import {ClassesService} from "../../../core/services/classes.service";
import {AuthService} from "../../../core/services/auth/auth.service";
import {TerminologyEnum, TerminologyWordEnum} from "../../enums/terminology.enum";
import {SkeletonComponent} from "../skeleton/skeleton.component";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {MessageService} from "primeng/api";
import {ButtonModule} from 'primeng/button';
import {OnlineClassTypeEnum} from "../../enums/online-class-type.enum";

@Component({
  selector: 'calimatic-join-class-widget',
  standalone: true,
  imports: [
    TerminologyPipe,
    OverlayPanelModule,
    SkeletonComponent,
    ProgressSpinnerModule,
    ButtonModule
  ],
  templateUrl: './join-class-widget.component.html',
  styleUrl: './join-class-widget.component.scss'
})
export class JoinClassWidgetComponent {
  classesService = inject(ClassesService);
  authService = inject(AuthService);
  messageService = inject(MessageService);
  terminologyWordType = TerminologyWordEnum;
  //terminologyPipe = inject(TerminologyPipe);

  terminologyEnum = TerminologyEnum;
  loadingClasses = false;
  joinClassLoading = false;
  classes: OnlineClassInterface[];

  async getOnlineClasses(op: OverlayPanel, evt: any) {
    op.toggle(evt);
    this.loadingClasses = true;
    const res = await this.classesService.getOnlineClasses(Number(this.authService.getUserModal().Id));
    this.loadingClasses = false;
    if (res.isSuccessful) {
      this.classes = res.response as OnlineClassInterface[];
    }
  }

  async joinClass(cls: OnlineClassInterface) {
    if (cls.classType == OnlineClassTypeEnum.OnlineClassWithCalimatic) {
      this.joinClassLoading = true;
      const um = this.authService.getUserModal();
      const res = await this.classesService.joinClass(um.FirstName + ' ' + um.LastName, cls.classId);
      this.joinClassLoading = false;
      if (res.isSuccessful) {
        this.openLink(res.response);
      } else {
        this.messageService.add({
          severity: 'info',
          summary: `The class has either not started yet or has already ended. Please wait for the instructor to begin the class, or contact them if you have any questions.`
        });
      }
    } else if (cls.classType == OnlineClassTypeEnum.OnlineClassWithZoom) {
    } else if (cls.classType == OnlineClassTypeEnum.OnlineClassWithGoogleMeet) {
      this.openLink(cls.classUrl);
    }
  }

  private openLink(url: string) {
    if (url?.length) {
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = url;
      a.click();
    }
  }
}
