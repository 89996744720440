import {Injectable} from "@angular/core";
import {ApiHandlerService} from "./base/api-handler.service";
import {API_ENDPOINTS} from "../../shared/utils/global";
import {lastValueFrom} from "rxjs";
import {
  DashboardTrioWidgetsDTO,
  DashboardWidgetsDTO,
  DashboardWidgetsSettingsDTO
} from "../../shared/dtos/dashboard-widgets.dto";
import {
  DashboardWidgetsEnum,
  DashboardWidgetSymbolEnum,
  DashboardWidgetTypeEnum
} from "../../shared/enums/dashboard/dashboard-widgets.enum";
import {ChartDataDto, StackBarChartDataDto} from "../../shared/dtos/chart-data.dto";
import {LeadsStatusCountDto} from "../../shared/dtos/leads-status-count.dto";
import {TopFranchisesRevenueDto} from "../../shared/dtos/top-franchises-revenue.dto";
import {DashboardCalendarDataDto} from "../../shared/dtos/dashboard-calendar-data.dto";
import {ExternalLinkDto} from "../../shared/dtos/external-link.dto";
import {AnnouncementDataDTO} from "../../shared/dtos/announcement.dto";
import {MapDto} from "../../shared/dtos/map.dto";
import {TerminologyEnum} from "../../shared/enums/terminology.enum";
import {TerminologiesDto} from "../../shared/dtos/on-board.dto";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  externalLinkCodes = [DashboardWidgetsEnum.ExternalLink1, DashboardWidgetsEnum.ExternalLink2, DashboardWidgetsEnum.ExternalLink3, DashboardWidgetsEnum.ExternalLink4, DashboardWidgetsEnum.ExternalLink5];

  constructor(private http: ApiHandlerService) {
  }

  async getStudentPointCounter() {
    return await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.getStudentPointsCounter));
  }

  async getWidgets(companyId: number, isFranchise: boolean, paymentGatewaySymbol: string) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.widgets));
    if (res.isSuccessful) {
      let widgets = res.response as DashboardWidgetsDTO[];
      (widgets || []).forEach(x => {
        if (x.links?.length && companyId > 0 && isFranchise) {
          const franchiseLink = x.links.find(f => f.companyId == companyId);
          if (franchiseLink) {
            x.link = franchiseLink.link;
            x.icon = franchiseLink.icon;
            x.title = franchiseLink.title;
          }
        }
        x.widgetType = this.getWidgetType(x.code);
        x.symbol = this.getWidgetSymbol(x.code, paymentGatewaySymbol);
        if (x.code == DashboardWidgetsEnum.Calendar) {
          x.minWidth = 4;
          x.minHeight = 14;
        }
      });
      return widgets;
    }

    return [] as DashboardWidgetsDTO[];
  }

  applyWidgetsTerminologies(widgets: DashboardWidgetsDTO[], terminologies: TerminologiesDto[]) {
    if (terminologies?.length && widgets?.length) {
      widgets.forEach(x => {
        x.title = this.applyTerminology(x.code, x.title, terminologies);
        x.description = this.applyDescriptionTerminology(x.code, x.description, terminologies);
      });
    }
    return widgets;
  }

  addWidget(widget: DashboardWidgetsDTO) {
    widget.isEnabled = true;
    widget.autoPosition = true;

    const {w, h} = this.getWidgetDefaultDimensions(widget.widgetType, widget.code);
    widget.width = w;
    widget.height = h;

    widget.xaxis = 0;
    widget.yaxis = 3;

    // this.saveWidgetsSettings([{
    //   width: widget.width,
    //   height: widget.height,
    //   xaxis: widget.xaxis,
    //   yaxis: widget.yaxis,
    //   id: widget.id,
    //   isEnabled: widget.isEnabled
    // }] as DashboardWidgetsSettingsDTO[]);
  }

  removeWidget(w: DashboardWidgetsDTO) {
    w.isEnabled = false;

    if (this.externalLinkCodes.includes(w.code)) {
      w.icon = 'fa-solid fa-up-right-from-square';
      w.title = 'External Link ' + this.getExternalLinkNumber(w.code);
      w.description = 'External link widget';
    }

    this.saveWidgetsSettings([{
      width: w.width,
      height: w.height,
      xaxis: w.xaxis,
      yaxis: w.yaxis,
      id: w.id,
      isEnabled: w.isEnabled,
      title: w.title,
      icon: w.icon,
      description: w.description
    }] as DashboardWidgetsSettingsDTO[]);
  }

  async saveExternalLink(data: ExternalLinkDto) {
    return lastValueFrom(this.http.Post(API_ENDPOINTS.dashboard.externalLink, {
      Name: data.name,
      Link: data.link,
      Type: data.type,
      Icon: data.icon,
      Sync: data.sync
    }));
  }

  async getEnrollmentPerformance(types: string, franchiseIds: number[], type: number, performance: number) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.enrollmentPerformance, {
      types,
      franchiseIds,
      type,
      performance
    }));
    if (res.isSuccessful) {
      return res.response as StackBarChartDataDto[];
    }
    return [];
  }

  async getPartnerEnrollments(franchises: string) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.partnerEnrollments, {franchises}));
    if (res.isSuccessful) {
      return res.response as StackBarChartDataDto[];
    }
    return [];
  }

  async getClassEnrollmentRevenueByType(types: string, franchises: string) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.classEnrollmentRevenueByType, {
      types,
      franchises
    }));
    if (res.isSuccessful) {
      return res.response as StackBarChartDataDto[];
    }
    return [];
  }

  async getClassDropOffCountByType(type: string, franchises: string) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.classDropOffCountByType, {type, franchises}));
    if (res.isSuccessful) {
      return res.response as StackBarChartDataDto[];
    }
    return [];
  }

  async getClassEnrollmentCountByType(type: string, franchises: string) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.classEnrollmentCountByType, {
      type,
      franchises
    }));
    if (res.isSuccessful) {
      return res.response as StackBarChartDataDto[];
    }
    return [];
  }

  async getEventDropOffCountByType(type: string, franchises: string) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.eventDropOffCountByType, {type, franchises}));
    if (res.isSuccessful) {
      return res.response as StackBarChartDataDto[];
    }
    return [];
  }

  async getEventEnrollmentCountByType(type: string, franchises: string) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.eventEnrollmentCountByType, {
      type,
      franchises
    }));
    if (res.isSuccessful) {
      return res.response as StackBarChartDataDto[];
    }
    return [];
  }

  async getActiveCoursesCountByType() {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.activeCourseCountByType));
    if (res.isSuccessful) {
      return res.response as ChartDataDto[];
    }
    return [];
  }

  async getLeadsStatusCount() {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.listOfLeadsCount));
    if (res.isSuccessful) {
      return res.response as LeadsStatusCountDto[];
    }
    return [];
  }

  async getTopFranchiseByRevenue() {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.topFranchiseByRevenue));
    if (res.isSuccessful) {
      return res.response as TopFranchisesRevenueDto[];
    }
    return [];
  }

  async getCalendarData() {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.calendar));
    if (res.isSuccessful) {
      return res.response as DashboardCalendarDataDto;
    }
    return null;
  }

  async getLeadsGenerationData(type: number, companyId: number) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.leadsGeneration, {type, companyId}));
    if (res.isSuccessful) {
      return res.response as StackBarChartDataDto[];
    }
    return [];
  }

  async getStudentDemographicsData(type: number, state: number, companyId: number) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.studentDemographics, {
      type,
      state,
      companyId
    }));
    if (res.isSuccessful) {
      return res.response as ChartDataDto[];
    }
    return [];
  }

  async getStudentAttendanceData(type: string, franchises: string) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.studentAttendance, {
      type,
      franchises
    }));
    if (res.isSuccessful) {
      return res.response as StackBarChartDataDto[];
    }
    return [];
  }

  async updateWidgets(data: DashboardWidgetsSettingsDTO[]) {
    if (data?.length) {
      await this.saveWidgetsSettings(data);
    }
  }

  async getWidgetData(widgetType: DashboardWidgetsEnum) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.currentMonthWidgetsCount, {widgetType}));
    return res.isSuccessful ? res.response : '';
  }

  async getTrioWidgetsData(widgetType: DashboardWidgetsEnum) {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.trioWidgetsCount, {widgetType}));
    return res.isSuccessful ? (res.response as DashboardTrioWidgetsDTO) : null;
  }

  async getAnnouncements() {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.announcements));
    if (res.isSuccessful) {
      return res.response as AnnouncementDataDTO;
    }
    return null;
  }

  async getMapData() {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.dashboard.map));
    if (res.isSuccessful) {
      return res.response as MapDto[];
    }
    return [];
  }

  async restoreToDefault(role: number) {
    return await lastValueFrom(this.http.Post(API_ENDPOINTS.dashboard.restoreWidgetsToDefault, {Role: role}));
  }

  private getWidgetType(code: DashboardWidgetsEnum) {
    switch (code) {
      case DashboardWidgetsEnum.EnrollmentsCount:
      case DashboardWidgetsEnum.DropOffCount:
      case DashboardWidgetsEnum.RevenueGenerated:
      case DashboardWidgetsEnum.Attendance:
      case DashboardWidgetsEnum.UsersCount:
      case DashboardWidgetsEnum.StudentsEngagement:
      case DashboardWidgetsEnum.FamiliesCount:
      case DashboardWidgetsEnum.StudentsCount:
      case DashboardWidgetsEnum.StaffCount:
      case DashboardWidgetsEnum.ContactsCount:
      case DashboardWidgetsEnum.LeadsCount:
      case DashboardWidgetsEnum.PendingPaymentsCount:
      case DashboardWidgetsEnum.AssignedStudents:
      case DashboardWidgetsEnum.AssignedCourses:
      case DashboardWidgetsEnum.HoursLogged:
      case DashboardWidgetsEnum.ClassesAssigned:
      case DashboardWidgetsEnum.CoursesEnrolled:
      case DashboardWidgetsEnum.StudentResults:
        return DashboardWidgetTypeEnum.Counter;

      case DashboardWidgetsEnum.TopFranchises:
      case DashboardWidgetsEnum.ListOfLeads:
      case DashboardWidgetsEnum.MonthlyTrioEnrollmentsCount:
      case DashboardWidgetsEnum.MonthlyTrioDropOffCount:
      case DashboardWidgetsEnum.MonthlyTrioRevenueGenerated:
      case DashboardWidgetsEnum.MonthlyTrioAttendance:
      case DashboardWidgetsEnum.MonthlyTrioUsersCount:
      case DashboardWidgetsEnum.MonthlyTrioStudentsEngagement:
      case DashboardWidgetsEnum.MonthlyTrioFamiliesCount:
      case DashboardWidgetsEnum.MonthlyTrioStudentsCount:
      case DashboardWidgetsEnum.MonthlyTrioStaffCount:
      case DashboardWidgetsEnum.MonthlyTrioContactsCount:
      case DashboardWidgetsEnum.MonthlyTrioLeadsCount:
      case DashboardWidgetsEnum.MonthlyTrioPendingPaymentsCount:
      case DashboardWidgetsEnum.MonthlyTrioAssignedStudents:
      case DashboardWidgetsEnum.MonthlyTrioAssignedCourses:
      case DashboardWidgetsEnum.MonthlyTrioHoursLogged:
      case DashboardWidgetsEnum.MonthlyTrioClassesAssigned:
        return DashboardWidgetTypeEnum.Comparison;

      case DashboardWidgetsEnum.ClassEnrollmentChart:
      case DashboardWidgetsEnum.EventEnrollmentChart:
      case DashboardWidgetsEnum.EnrollmentPerformanceChart:
      case DashboardWidgetsEnum.PartnersEnrollmentChart:
      case DashboardWidgetsEnum.ClassDropOffChart:
      case DashboardWidgetsEnum.EventDropOffChart:
      case DashboardWidgetsEnum.CourseTypeCountChart:
      case DashboardWidgetsEnum.ClassEnrollmentRevenueChart:
      case DashboardWidgetsEnum.LeadsGenerationChart:
      case DashboardWidgetsEnum.StudentDemographicsChart:
      case DashboardWidgetsEnum.StudentAttendanceChart:
        return DashboardWidgetTypeEnum.Chart;

      case DashboardWidgetsEnum.Map:
      case DashboardWidgetsEnum.QuickLinks:
      case DashboardWidgetsEnum.Calendar:
      case DashboardWidgetsEnum.Announcements:
      case DashboardWidgetsEnum.ExternalLink1:
      case DashboardWidgetsEnum.ExternalLink2:
      case DashboardWidgetsEnum.ExternalLink3:
      case DashboardWidgetsEnum.ExternalLink4:
      case DashboardWidgetsEnum.ExternalLink5:
        return DashboardWidgetTypeEnum.Widget;
      default:
        return DashboardWidgetTypeEnum.Counter;
    }
  }

  private getWidgetSymbol(code: DashboardWidgetsEnum, paymentGatewaySymbol: string) {
    switch (code) {
      case DashboardWidgetsEnum.EnrollmentsCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.DropOffCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.RevenueGenerated:
      case DashboardWidgetsEnum.PendingPaymentsCount:
      case DashboardWidgetsEnum.MonthlyTrioRevenueGenerated:
      case DashboardWidgetsEnum.MonthlyTrioPendingPaymentsCount:
      case DashboardWidgetsEnum.TopFranchises:
        return paymentGatewaySymbol;
      case DashboardWidgetsEnum.Attendance:
        return DashboardWidgetSymbolEnum.Percentage;
      case DashboardWidgetsEnum.UsersCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.StudentsEngagement:
        return DashboardWidgetSymbolEnum.Percentage;
      case DashboardWidgetsEnum.FamiliesCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.StudentsCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.StaffCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.ContactsCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.LeadsCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.StudentResults:
        return DashboardWidgetSymbolEnum.Percentage;
      case DashboardWidgetsEnum.AssignedStudents:
      case DashboardWidgetsEnum.AssignedCourses:
      case DashboardWidgetsEnum.HoursLogged:
      case DashboardWidgetsEnum.ClassesAssigned:
      case DashboardWidgetsEnum.CoursesEnrolled:
      case DashboardWidgetsEnum.ListOfLeads:
      case DashboardWidgetsEnum.MonthlyTrioEnrollmentsCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.MonthlyTrioDropOffCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.MonthlyTrioAttendance:
        return DashboardWidgetSymbolEnum.Percentage;
      case DashboardWidgetsEnum.MonthlyTrioUsersCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.MonthlyTrioStudentsEngagement:
        return DashboardWidgetSymbolEnum.Percentage;
      case DashboardWidgetsEnum.MonthlyTrioFamiliesCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.MonthlyTrioStudentsCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.MonthlyTrioStaffCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.MonthlyTrioContactsCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.MonthlyTrioLeadsCount:
        return DashboardWidgetSymbolEnum.None;
      case DashboardWidgetsEnum.MonthlyTrioAssignedStudents:
      case DashboardWidgetsEnum.MonthlyTrioAssignedCourses:
      case DashboardWidgetsEnum.MonthlyTrioHoursLogged:
      case DashboardWidgetsEnum.MonthlyTrioClassesAssigned:
      case DashboardWidgetsEnum.ClassEnrollmentChart:
      case DashboardWidgetsEnum.EventEnrollmentChart:
      case DashboardWidgetsEnum.EnrollmentPerformanceChart:
      case DashboardWidgetsEnum.PartnersEnrollmentChart:
      case DashboardWidgetsEnum.ClassDropOffChart:
      case DashboardWidgetsEnum.EventDropOffChart:
      case DashboardWidgetsEnum.CourseTypeCountChart:
      case DashboardWidgetsEnum.ClassEnrollmentRevenueChart:
      case DashboardWidgetsEnum.LeadsGenerationChart:
      case DashboardWidgetsEnum.StudentDemographicsChart:
      case DashboardWidgetsEnum.Map:
      case DashboardWidgetsEnum.QuickLinks:
      case DashboardWidgetsEnum.Calendar:
      case DashboardWidgetsEnum.Announcements:
      case DashboardWidgetsEnum.ExternalLink1:
      case DashboardWidgetsEnum.ExternalLink2:
      case DashboardWidgetsEnum.ExternalLink3:
      case DashboardWidgetsEnum.ExternalLink4:
      case DashboardWidgetsEnum.ExternalLink5:
        return DashboardWidgetSymbolEnum.None;
      default:
        return DashboardWidgetSymbolEnum.None;
    }
  }

  private applyTerminology(code: DashboardWidgetsEnum, title: string, terminologies: TerminologiesDto[]) {
    const clsTerm = terminologies.find(x => x.name == TerminologyEnum.Clas);
    const crsTerm = terminologies.find(x => x.name == TerminologyEnum.Course);
    const familyTerm = terminologies.find(x => x.name == TerminologyEnum.Family);
    const frTerm = terminologies.find(x => x.name == TerminologyEnum.Franchise);
    const eventTerm = terminologies.find(x => x.name == TerminologyEnum.Event);
    const announcementTerm = terminologies.find(x => x.name == TerminologyEnum.Announcement);

    switch (code) {
      case DashboardWidgetsEnum.FamiliesCount:
      case DashboardWidgetsEnum.MonthlyTrioFamiliesCount:
        return `${familyTerm.pluralLabel} Count`;
      case DashboardWidgetsEnum.ClassesAssigned:
      case DashboardWidgetsEnum.MonthlyTrioClassesAssigned:
        return `${clsTerm.pluralLabel} Assigned`;
      case DashboardWidgetsEnum.CoursesEnrolled:
      case DashboardWidgetsEnum.MonthlyTrioAssignedCourses:
        return `${crsTerm.pluralLabel} Assigned`;
      case DashboardWidgetsEnum.TopFranchises:
        return `Top ${frTerm.pluralLabel}`;
      case DashboardWidgetsEnum.ClassEnrollmentChart:
        return `${crsTerm.label}/${clsTerm.label} Enrollments`;
      case DashboardWidgetsEnum.EventEnrollmentChart:
        return `${eventTerm.label} Enrollments`;
      case DashboardWidgetsEnum.ClassDropOffChart:
        return `${crsTerm.label}/${clsTerm.label} Drop-offs`;
      case DashboardWidgetsEnum.EventDropOffChart:
        return `${eventTerm.label} Drop-offs`;
      case DashboardWidgetsEnum.CourseTypeCountChart:
        return `${crsTerm.pluralLabel} Count by Type`;
      case DashboardWidgetsEnum.ClassEnrollmentRevenueChart:
        return `${crsTerm.pluralLabel}/${clsTerm.pluralLabel} Revenue`;
      case DashboardWidgetsEnum.Announcements:
        return `${announcementTerm.pluralLabel}`;
      default:
        return title;
    }
  }

  private applyDescriptionTerminology(code: DashboardWidgetsEnum, description: string, terminologies: TerminologiesDto[]) {
    const clsTerm = terminologies.find(x => x.name == TerminologyEnum.Clas);
    const crsTerm = terminologies.find(x => x.name == TerminologyEnum.Course);
    const familyTerm = terminologies.find(x => x.name == TerminologyEnum.Family);
    const frTerm = terminologies.find(x => x.name == TerminologyEnum.Franchise);
    const eventTerm = terminologies.find(x => x.name == TerminologyEnum.Event);
    const announcementTerm = terminologies.find(x => x.name == TerminologyEnum.Announcement);

    switch (code) {
      case DashboardWidgetsEnum.EnrollmentsCount:
        return `Current Month Paid/Unpaid Enrollments (from ${crsTerm.pluralLabel}, ${clsTerm.pluralLabel} & ${eventTerm.pluralLabel})`;
      case DashboardWidgetsEnum.DropOffCount:
        return `Current Month Drop-offs (from Paid/Unpaid ${crsTerm.pluralLabel}, ${clsTerm.pluralLabel} & ${eventTerm.pluralLabel})`;
      case DashboardWidgetsEnum.RevenueGenerated:
        return `Current Month Payments Received (from ${crsTerm.pluralLabel}, ${clsTerm.pluralLabel} & ${eventTerm.pluralLabel})`;
      case DashboardWidgetsEnum.FamiliesCount:
      case DashboardWidgetsEnum.MonthlyTrioFamiliesCount:
        return `The total count of all the ${familyTerm.pluralLabel}`;
      case DashboardWidgetsEnum.AssignedCourses:
        return `Total active assigned ${crsTerm.pluralLabel}`;
      case DashboardWidgetsEnum.ClassesAssigned:
        return `Total active assigned ${clsTerm.pluralLabel}`;
      case DashboardWidgetsEnum.CoursesEnrolled:
        return `Total ${crsTerm.pluralLabel} enrollment`;
      case DashboardWidgetsEnum.TopFranchises:
        return `Top ${frTerm.pluralLabel} by revenue`;
      case DashboardWidgetsEnum.MonthlyTrioEnrollmentsCount:
        return `For Today, This Month & Last Month - Display the corresponding Paid/Unpaid Enrollments (from ${crsTerm.pluralLabel}, ${clsTerm.pluralLabel} & ${eventTerm.pluralLabel})`;
      case DashboardWidgetsEnum.MonthlyTrioDropOffCount:
        return `For Today, This Month & Last Month - Display the corresponding Drop-offs (from ${crsTerm.pluralLabel}, ${clsTerm.pluralLabel} & ${eventTerm.pluralLabel})`;
      case DashboardWidgetsEnum.MonthlyTrioRevenueGenerated:
        return `For Today, This Month & Last Month - Display the corresponding Payments Received (from ${crsTerm.pluralLabel}, ${clsTerm.pluralLabel} & ${eventTerm.pluralLabel})`;
      case DashboardWidgetsEnum.MonthlyTrioAssignedCourses:
        return `For Today, This Month & Last Month - Total active assigned ${crsTerm.pluralLabel}`;
      case DashboardWidgetsEnum.MonthlyTrioClassesAssigned:
        return `For Today, This Month & Last Month - Total active assigned ${clsTerm.pluralLabel}`;
      case DashboardWidgetsEnum.ClassEnrollmentChart:
        return `Number of ${crsTerm.label}/${clsTerm.label} Enrollments for last 12 months`;
      case DashboardWidgetsEnum.PartnersEnrollmentChart:
        return `Number of Partner Enrollments for last 12 months`;
      case DashboardWidgetsEnum.EventEnrollmentChart:
        return `Number of ${eventTerm.label} Enrollments for last 12 months`;
      case DashboardWidgetsEnum.ClassDropOffChart:
        return `Number of ${crsTerm.label}/${clsTerm.label} Drop-offs for last 12 months`;
      case DashboardWidgetsEnum.EventDropOffChart:
        return `Number of ${eventTerm.label} Drop-offs for last 12 months`;
      case DashboardWidgetsEnum.CourseTypeCountChart:
        return `Number of active ${crsTerm.pluralLabel} by ${crsTerm.label} Type`;
      case DashboardWidgetsEnum.ClassEnrollmentRevenueChart:
        return `Total Revenue Generated for ${crsTerm.pluralLabel}/${clsTerm.pluralLabel} for last 12 months`;
      case DashboardWidgetsEnum.StudentAttendanceChart:
        return `Attendance count for last 12 months`;
      case DashboardWidgetsEnum.LeadsGenerationChart:
        return `Total Leads Generated for last 12 months`;
      case DashboardWidgetsEnum.Announcements:
        return `${announcementTerm.pluralLabel}`;
      default:
        return description;
    }
  }

  private saveWidgetsSettings(data: DashboardWidgetsSettingsDTO[]) {
    return lastValueFrom(this.http.Post(API_ENDPOINTS.dashboard.saveSettings, data));
  }

  private getWidgetDefaultDimensions(widgetType: DashboardWidgetTypeEnum, widget: DashboardWidgetsEnum) {
    switch (widgetType) {
      case DashboardWidgetTypeEnum.Counter:
        return {w: 3, h: 3};
      case DashboardWidgetTypeEnum.Chart:
        return {w: 4, h: 9};
      case DashboardWidgetTypeEnum.Widget:
        return (widget == DashboardWidgetsEnum.ExternalLink1 || widget == DashboardWidgetsEnum.ExternalLink2 || widget == DashboardWidgetsEnum.ExternalLink3 || widget == DashboardWidgetsEnum.ExternalLink4 || widget == DashboardWidgetsEnum.ExternalLink5) ? {
          w: 6,
          h: 2
        } : (widget == DashboardWidgetsEnum.QuickLinks) ? {
          w: 12,
          h: 12
        } : (widget == DashboardWidgetsEnum.Calendar) ? {w: 12, h: 17} : {w: 6, h: 12};
      case DashboardWidgetTypeEnum.Comparison:
        return {w: 3, h: 5};
    }
  }

  private getExternalLinkNumber(code: number) {
    switch (code) {
      case DashboardWidgetsEnum.ExternalLink1:
        return 1;
      case DashboardWidgetsEnum.ExternalLink2:
        return 2;
      case DashboardWidgetsEnum.ExternalLink3:
        return 3;
      case DashboardWidgetsEnum.ExternalLink4:
        return 4;
      case DashboardWidgetsEnum.ExternalLink5:
        return 5;
      default:
        return 1;
    }
  }

}
