@if (!loading) {
  <div class="clm-widget-item card">
    <div class="card-action-wrapper">
      @if (edit()) {
        <span (click)="onRemove.emit()" class="action_btn" [pTooltip]="'remove widget'" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-xmark"></i></span>
      }
    </div>
    <div class="card-header">
      <h5>Enrollment Performance
        <span [pTooltip]="widget().description" [tooltipOptions]="{showDelay:500}" class="card-icon-inline info-icon"
              tooltipPosition="bottom">
      <i class="fa-solid fa-circle-info"></i>
    </span>
        <span (click)="loadData()" [pTooltip]="'Refresh widget'" [tooltipOptions]="{showDelay:500}"
              class="card-icon-inline refresh-btn"
              tooltipPosition="bottom"><i class="fa-solid fa-rotate"></i></span>
      </h5>
      <div class="card-options {{edit()?'visibility-hidden':''}}">
        <div class="card-multiple-options">
          <div>
            <calimatic-dropdown (valueChange)="onEnrollmentTypeChange($event)"
                                [(value)]="selectedEnrollmentType"
                                [configs]="{
                                                        options:[{
                                                           name: ('Courses' | terminology:terminologyEnum.Course: terminologyWordType.PLURAL) + '/' + ('Classes' | terminology:terminologyEnum.Clas: terminologyWordType.PLURAL),
                                                           id: 1
                                                         },
                                                         {
                                                           name: ('Events' | terminology:terminologyEnum.Event: terminologyWordType.PLURAL),
                                                           id: 2
                                                         }],
                                                        label:'name',
                                                        value:'id',
                                                        placeholder:'Select ' + ('Type' | terminology:terminologyEnum.CourseType: terminologyWordType.SINGULAR),
                                                        appendTo: 'body',
                                                        selectable:true,
                                                    }"
                                [pTooltip]="widget().description"
                                [tooltipOptions]="{showDelay:500}" tooltipPosition="bottom">
            </calimatic-dropdown>
          </div>
          @if (!isFranchise()) {
            <div>
              <calimatic-multi-select (valueChange)="onFranchiseChange($event)" [(value)]="selectedFranchises"
                                      [configs]="{
                          options:franchises(),
                          label:'name',
                          value:'id',
                          placeholder:'Select ' + ('franchise' | terminology:terminologyEnum.Franchise: terminologyWordType.SINGULAR),
                          display:multiSelectDisplay.COMMA,
                          appendTo: 'body',
                          isSelectable:true,
                          }">
              </calimatic-multi-select>
            </div>
          }

          <div class="course-info-dropdown">
            <calimatic-multi-select (valueChange)="onTypeChange($event)" [configs]="{
            options:getType(),
            label:'name',
            value:'name',
            placeholder:'Select ' + ('Type' | terminology:terminologyEnum.CourseType: terminologyWordType.SINGULAR),
            appendTo: 'body',
            display:multiSelectDisplay.COMMA,
            isSelectable:true,
          }" [value]="selectedType">
            </calimatic-multi-select>
          </div>
        </div>
      </div>
    </div>
    @if (!isFranchise()) {
      <div class="text-center w-full">
        <calimatic-select-button (valueChange)="onPerformingChange($event)" [configs]="{
                    options:enrollmentPerformingTypes,
                    label:'name',
                    value:'id'
                    }" [value]="selectedPerformingType">

        </calimatic-select-button>
      </div>
    }

    <div class="card-body">
      <calimatic-stack-bar-chart [data]="chartData" label="Enrollments"
                                 [barThickness]="'0.3'"></calimatic-stack-bar-chart>
    </div>
  </div>
} @else {
  <calimatic-chart-skeleton></calimatic-chart-skeleton>
}
