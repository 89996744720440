<div [ngStyle]="{'background-color':companyConfigs().loginBgColor}" class="flex grid-nogutter h-full">
  @if (companyConfigs().loginImage) {
    <div class="xl:col-7 lg:col-7 md:col-7 col-12 clm-login__left h-full hidden lg:block">
      <div class="left-wrapper h-full">
        @if (companyConfigs().loginImageType == loginImageTypEnum.Svg) {
          <div class="left-wrapper-svg" [ngStyle]="{'background-color':companyConfigs().loginBgColor}">
            <div class="svg-main">
              @if (showFirst) {
                <div (mouseover)="onMouseOver()" (mouseout)="onMouseOut()" [@fadeInOut] class="svgs"
                     [innerHTML]="getSelectedSvg() | sanitize"></div>
              } @else {
                <div (mouseover)="onMouseOver()" (mouseout)="onMouseOut()" [@fadeInOut] class="svgs"
                     [innerHTML]="getSelectedSvg() | sanitize"></div>
              }
              <div class="svg-carousal-dots">
                @for (item of svgItems; track $index) {
                  <span (click)="selectSlider(item)" class="{{showSvgIndex === item?'active':''}}"></span>
                }
              </div>
            </div>
          </div>
        } @else {
          <div
            [ngStyle]="{'background-image': 'url(' + companyConfigs().loginImage + ')', 'background-size': companyConfigs().loginImageSize}"
            class="left-wrapper-image">
          </div>
        }
      </div>
    </div>
  }
  <div class="clm-login__right h-full w-full overflow-auto">
    <div [ngStyle]="{'background-color':companyConfigs().loginBgColorRight, 'color':companyConfigs().loginFontColor}"
         class="right-wrapper relative">
      <calimatic-login-form [companyConfigs]="companyConfigs()" class="w-full"></calimatic-login-form>
    </div>
  </div>
</div>
