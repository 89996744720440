import { Injectable } from "@angular/core";
import { ApiHandlerService } from "../../core/services/base/api-handler.service";
import { API_ENDPOINTS } from "../utils/global";
import { lastValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor( private http: ApiHandlerService) {
  }
  uploadFile(file: File, companyGuid: string) {
    const headers = {
      CompanyGuid: companyGuid,
    };
    const data = new FormData();
    data.append('File', file);  
    data.append('CompanyGuid', companyGuid.toString());
    return lastValueFrom(this.http.Post(`${API_ENDPOINTS.uploadFile}`, data, undefined, headers));
}
}

