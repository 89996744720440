@if (loading) {
  <calimatic-counter-trio-skeleton></calimatic-counter-trio-skeleton>
} @else {
  <div class="card _countercards">
    <div class="card-action-wrapper">
      <span (click)="loadData()" class="action_btn refresh-btn" [pTooltip]="'Refresh widget'" tooltipPosition="bottom"
            [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-rotate"></i></span>
      @if (edit()) {
        <span (click)="onRemove.emit()" class="action_btn" [pTooltip]="'Remove widget'" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-xmark"></i></span>
      }
      @else {
        <span class="card-info-tooltip-top action_btn" [pTooltip]="widget().description" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}">
                 <i class="fa-solid fa-circle-info"></i>
        </span>
      }
    </div>
   
    <div class="counter-card-header">
      <h5>{{ widget().title }}</h5>
    </div>
    <div>
      <ul>
        <li>
          <span class="circle circle-1"></span>
          <span class="count_title">Today</span>
          <span class="counts">
            <ng-template [ngTemplateOutlet]="ws" [ngTemplateOutletContext]="{value:data?.today}"></ng-template>
        </span>
        </li>
        <li>
          <span class="circle circle-2"></span>
          <span class="count_title">Current</span>
          <span class="counts">
          <ng-template [ngTemplateOutlet]="ws" [ngTemplateOutletContext]="{value:data?.current}"></ng-template>
        </span>
        </li>
        <li>
          <span class="circle circle-3"></span>
          <span class="count_title">Previous</span>
          <span class="counts">
          <ng-template [ngTemplateOutlet]="ws" [ngTemplateOutletContext]="{value:data?.previous}"></ng-template>
        </span>
        </li>
      </ul>
    </div>
  </div>
}

<ng-template #ws let-value='value'>
  @if (widget().symbol == widgetSymbolEnum.Percentage) {
    <span>{{ value || '0' }}{{ widget().symbol || '' }}</span>
  } @else {
    <span>{{ widget().symbol || '' }}{{ value || '0' }}</span>
  }
</ng-template>
