<div>
  <p-fieldset legend="">
    <ng-template pTemplate="header">
      <h4  class="mb-3 px-2">NMI</h4>
    </ng-template>
    @if (!loading) {
      <form [formGroup]="nmiForm">
        <div class="flex flex-column gap-2 my-3">
          <label>API Key</label>
          <input
            [forceValid]="validateInput"
            formControlName="apiKey"
            pInputText
            placeholder="Enter api key" validateInput="Api key is required"/>
        </div>
        <div class="flex flex-column gap-2 my-3">
          <label>Collect.js Key</label>
          <input
            [forceValid]="validateInput"
            formControlName="collectJsKey"
            pInputText
            placeholder="Enter collect.js key"
            validateInput="Collect.js key is required"/>
        </div>
        <div class="flex flex-column gap-2 my-3">
          <label>Currency</label>
          <calimatic-dropdown (valueChange)="onCurrencyChange($event)" [configs]="{
          options:currencies,
          label:'Text',
          value:'Value',
          placeholder:'Select currency',
          selectable:true,
          filter:true,
          buttonClass:'w-full'
        }" [value]="nmiFormControls.currencyCode.value">
          </calimatic-dropdown>
        </div>
        <div class="flex flex-column gap-2 my-3">
          <label>Account URL</label>
          <a href="{{accountURL}}" target="_blank">{{ accountURL }}</a>
        </div>
        <div>
          <button [disabled]="processing" [loading]="processing" (click)="save()" pButton> &nbsp; Save</button>
        </div>
      </form>
    } @else {
      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>
    }
  </p-fieldset>
</div>
