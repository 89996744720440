import { animate, state, style, transition, trigger } from "@angular/animations";

export const fadeInOut = trigger('fadeInOut', [
    state('void', style({ 
        opacity: 0,
        visibility: 'hidden',
    })),
    transition('void <=> *', [
        animate('0.5s')
    ])
]);

export const SkeletonLoader = trigger("skeletonLoader", [
  
    transition(':enter', [
      style({opacity: 0}),
      animate('0.4s 0.2s cubic-bezier(0.05, 0.7, 0.1, 1)', style({opacity: 1}))
    ]),
    transition(':leave', [
      animate('0.2s cubic-bezier(0.3, 0, 0.8, 0.15)', style({opacity: 0}))
    ]),
  
  ]);