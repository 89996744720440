<p-dialog [(visible)]="dialogVisible" [closable]="false" [draggable]="false" [modal]="true"
          [styleClass]="'user-guide-main'"
          header="header">
  <ng-template pTemplate="header">
    <header>
      <div class="header-logo">
        <img alt="calimatic-logo"
             src="https://calimaticstore.blob.core.windows.net/company-1536/CM_a7deaa0e-37c3-40ab-8990-aa5a6f21c200.png">
      </div>
      <button (click)="this.onDiagCloseEmiter.emit(!this.dialogVisible)" class="clm-tertiary-btn" pButton>
        <i class="fa-solid fa-circle-info"
           pTooltip="If you skip, click on the ? next to your profile image at the top, and select Climatic Onboarding to come back for these steps."
           tooltipPosition="bottom"></i>
        <span class="mx-2">Skip Onboarding</span>
        <i class="fa-solid fa-arrow-right"></i>
      </button>
    </header>
  </ng-template>

  <main class="user-guide-body">
    <div class="main-content-left lg:flex hidden">
      <div class="nav-steps">
        @if (isDataLoaded) {
          @if(userRole == userRoles.BusinessAdmin){
            <ul class="ug-stepper-wrapper">

              @for (section of onboardSections; track $index; let i = $index) {
                <li class="ug-stepper-list {{activeTabIndex == i?'is-active':''}} {{section.complete?'is-complete':''}}"
                    (click)="gotoSection(section.code)">
                  <div class="ug-steps">
                    <div class="ug-steps-inner">
                      <span class="ug-steps-title">{{ section.name }}</span>
                      <h5 class="ug-steps-subtitle">Choose the relevant modules</h5>
                    </div>
                  </div>
                </li>
              }
            </ul>
          }@else{
            <ul class="ug-stepper-wrapper">

              @for (section of onboardSections; track $index; let i = $index) {
  
  
                <li class="ug-stepper-list {{activeTabIndex == i?'is-active':''}} {{section.complete?'is-complete':''}}"
                    (click)="gotoSection(0)">
                  <div class="ug-steps">
                    <div class="ug-steps-inner">
                      <span class="ug-steps-title">{{ section.name }}</span>
                      <h5 class="ug-steps-subtitle">Choose the relevant modules</h5>
                    </div>
                  </div>
                </li>
              }
            </ul>
          }
        
        } @else {
          <div>
            <calimatic-skeleton
              [configs]="{height:'1rem',width:'5rem',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
            <calimatic-skeleton
              [configs]="{height:'1rem',width:'100%',repeat:1, styleClass:'mb-6'}"></calimatic-skeleton>
          </div>
          <div>
            <calimatic-skeleton
              [configs]="{height:'1rem',width:'5rem',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
            <calimatic-skeleton
              [configs]="{height:'1rem',width:'100%',repeat:1, styleClass:'mb-6'}"></calimatic-skeleton>
          </div>
          <div>
            <calimatic-skeleton
              [configs]="{height:'1rem',width:'5rem',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
            <calimatic-skeleton
              [configs]="{height:'1rem',width:'100%',repeat:1, styleClass:'mb-6'}"></calimatic-skeleton>
          </div>
          <div>
            <calimatic-skeleton
              [configs]="{height:'1rem',width:'5rem',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
            <calimatic-skeleton
              [configs]="{height:'1rem',width:'100%',repeat:1, styleClass:'mb-6'}"></calimatic-skeleton>
          </div>
        }
      </div>
      <div class="nav-footer">
        <span class="block mb-2 font-semibold">Need Custom Onboarding?</span>
        <span class="block mb-3">Tailor your onboarding experience to fit your unique needs.</span>
        <a (click)="startMeeting()" class="clm-secondary-btn" pButton>
          <i class="fa-solid fa-video meet-icon mr-2"></i>
          <span class="font-semibold">Book a Meeting</span>
        </a>
      </div>
    </div>
    <div class="main-content-right">
      @if (isDataLoaded) {
        <div class="ob_content-header">
          <h4>Welcome to Calimatic Platform</h4>
          <button pButton class="clm-tertiary-btn p-0 bg-transparent" (click)="goToCalimaticHelpCenter()">
            <span class="mr-2 underline">Help Center</span>
            <i class="fa-regular fa-circle-question text-400"
               pTooltip="Knowledge Base" tooltipPosition="bottom"></i>
          </button>
        </div>


        <div class="user-guide-stepper-main">

          @if (isDataLoaded) {
            <p-stepper (activeStepChange)="onActiveStepChange($event)" [activeStep]="activeTabIndex">
              @for (section of onboardSections; track $index) {
                @if (userRole == userRoles.BusinessAdmin) {
                  @if (section.code == onBoardSectionEnum.Module) {
                    <p-stepperPanel header="Header I">
                      <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
                        <div class="steps-title">
                          <h3>Select the Required Modules</h3>
                          <p>Get a streamlined view of Calimatic by choosing the modules relevant to you</p>
                        </div>
                        <div class="ug-stepper-card">
                          <div class="ug-stepper-card-body">
                            @for (moduleSteps of section.steps; track $index) {
                              @if (moduleSteps.code == stepsEnum.FranchiseManagementModule) {
                                <label for="binary"
                                       class="user-guide-card select_required_modules {{moduleSteps.complete?'module-is-checked':''}}">
                                  <div class="icon-placeholder">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                         fill="none">
                                      <path
                                        d="M10.8333 9.16667H14.8333C15.7667 9.16667 16.2334 9.16667 16.5899 9.34832C16.9035 9.50811 17.1585 9.76308 17.3183 10.0767C17.5 10.4332 17.5 10.8999 17.5 11.8333V17.5M10.8333 17.5V5.16667C10.8333 4.23325 10.8333 3.76654 10.6516 3.41002C10.4918 3.09641 10.2369 2.84144 9.92328 2.68166C9.56676 2.5 9.10005 2.5 8.16663 2.5H5.16663C4.23321 2.5 3.76649 2.5 3.40998 2.68166C3.09637 2.84144 2.8414 3.09641 2.68162 3.41002C2.49996 3.76654 2.49996 4.23325 2.49996 5.16667V17.5M18.3333 17.5H1.66663M5.41663 5.83333H7.91663M5.41663 9.16667H7.91663M5.41663 12.5H7.91663"
                                        stroke="#344054" stroke-width="1.66667" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    </svg>
                                  </div>
                                  <div class="s_m__card_l">
                                    <h5 class="mb-1">{{ ('Franchise' | terminology:terminologyEnum.Franchise) }}
                                      Management Module</h5>
                                    <span>Your Organization has multiple Franchisees or Locations or Centers</span>
                                  </div>
                                  <div class="toggle-btn">
                                    <p-checkbox [(ngModel)]="moduleSteps.complete" [binary]="true" inputId="binary"/>
                                  </div>
                                </label>
                              }
                              @if (moduleSteps.code == stepsEnum.LMSModule) {
                                <label for="binary-1"
                                       class="user-guide-card select_required_modules {{moduleSteps.complete?'module-is-checked':''}}">
                                  <div class="icon-placeholder">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                         fill="none">
                                      <path
                                        d="M4.16663 8.33331V13.3426C4.16663 13.6417 4.16663 13.7913 4.21217 13.9233C4.25245 14.0401 4.31818 14.1465 4.40461 14.2347C4.50235 14.3345 4.63613 14.4014 4.90367 14.5352L9.40367 16.7852C9.62231 16.8945 9.73163 16.9491 9.84629 16.9707C9.94785 16.9897 10.0521 16.9897 10.1536 16.9707C10.2683 16.9491 10.3776 16.8945 10.5962 16.7852L15.0962 14.5352C15.3638 14.4014 15.4976 14.3345 15.5953 14.2347C15.6817 14.1465 15.7475 14.0401 15.7877 13.9233C15.8333 13.7913 15.8333 13.6417 15.8333 13.3426V8.33331M1.66663 7.08331L9.70182 3.06571C9.81114 3.01105 9.86579 2.98372 9.92313 2.97297C9.97391 2.96344 10.026 2.96344 10.0768 2.97297C10.1341 2.98372 10.1888 3.01105 10.2981 3.06571L18.3333 7.08331L10.2981 11.1009C10.1888 11.1556 10.1341 11.1829 10.0768 11.1936C10.026 11.2032 9.97391 11.2032 9.92313 11.1936C9.86579 11.1829 9.81114 11.1556 9.70182 11.1009L1.66663 7.08331Z"
                                        stroke="#344054" stroke-width="1.66667" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    </svg>
                                  </div>
                                  <div class="s_m__card_l">
                                    <h5 class="mb-1">LMS (Learning Management System) Module</h5>
                                    <span>
                          You need a Self-Learning or Instructor-led learning management includes
                          Gamification and Assessments/Exams
                        </span>
                                  </div>
                                  <div class="toggle-btn">
                                    <p-checkbox [(ngModel)]="moduleSteps.complete" [binary]="true" inputId="binary-1"/>
                                  </div>
                                </label>
                              }
                              @if (moduleSteps.code == stepsEnum.BillingAndPaymentsModule) {
                                <label for="binary-2"
                                       class="user-guide-card select_required_modules {{moduleSteps.complete?'module-is-checked':''}}">
                                  <div class="icon-placeholder">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                         fill="none">
                                      <path
                                        d="M18.3333 8.33366H1.66663M9.16663 11.667H4.99996M1.66663 6.83366L1.66663 13.167C1.66663 14.1004 1.66663 14.5671 1.84828 14.9236C2.00807 15.2372 2.26304 15.4922 2.57664 15.652C2.93316 15.8337 3.39987 15.8337 4.33329 15.8337L15.6666 15.8337C16.6 15.8337 17.0668 15.8337 17.4233 15.652C17.7369 15.4922 17.9919 15.2372 18.1516 14.9236C18.3333 14.5671 18.3333 14.1004 18.3333 13.167V6.83366C18.3333 5.90024 18.3333 5.43353 18.1516 5.07701C17.9919 4.76341 17.7369 4.50844 17.4233 4.34865C17.0668 4.16699 16.6 4.16699 15.6666 4.16699L4.33329 4.16699C3.39987 4.16699 2.93316 4.16699 2.57664 4.34865C2.26304 4.50844 2.00807 4.7634 1.84828 5.07701C1.66663 5.43353 1.66663 5.90024 1.66663 6.83366Z"
                                        stroke="#344054" stroke-width="1.66667" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    </svg>
                                  </div>
                                  <div class="s_m__card_l">
                                    <h5 class="mb-1">Billing & Payments Module</h5>
                                    <span>You need to collect payments from your customers</span>
                                  </div>
                                  <div class="toggle-btn">
                                    <p-checkbox [(ngModel)]="moduleSteps.complete" [binary]="true" inputId="binary-2"/>
                                  </div>
                                </label>
                              }
                            }
                          </div>
                          <div class="ug-stepper-card-footer">
                            <span class="flex-grow-1"></span>
                            <button pButton (click)="nextCallback.emit()" class="clm-secondary-btn"
                                    label="Skip Step"></button>
                            <button (click)="onModuleComplete(section); nextCallback.emit()"  [loading]="section.isLoading" pButton
                                    [icon]="'fa-solid fa-arrow-right'" iconPos="right" class="clm-primary-btn"
                                    label="Save and Next" ></button>
                          </div>
                        </div>
                      </ng-template>
                    </p-stepperPanel>
                  }
                  @if (section.code == onBoardSectionEnum.whiteLabeling) {
                    <p-stepperPanel header="Header II">
                      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
                                   let-index="index">
                        <div class="steps-title">
                          <h3>White Labeling</h3>
                          <p>Make Calimatic your own by white labeling the platform</p>
                        </div>
                        <div class="ug-stepper-card">
                          <div class="ug-stepper-card-body">
                            <div class="white_labeling">
                              @for (whiteLabelingSteps of section.steps; track $index) {
                                @if (whiteLabelingSteps.code == stepsEnum.PlatformBrading) {
                                  <div class="user-guide-card select_required_modules">
                                    <div class="icon-placeholder">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                           fill="none">
                                        <path
                                          d="M2.5 6.66699L12.5 6.66699M12.5 6.66699C12.5 8.0477 13.6193 9.16699 15 9.16699C16.3807 9.16699 17.5 8.0477 17.5 6.66699C17.5 5.28628 16.3807 4.16699 15 4.16699C13.6193 4.16699 12.5 5.28628 12.5 6.66699ZM7.5 13.3337L17.5 13.3337M7.5 13.3337C7.5 14.7144 6.38071 15.8337 5 15.8337C3.61929 15.8337 2.5 14.7144 2.5 13.3337C2.5 11.9529 3.61929 10.8337 5 10.8337C6.38071 10.8337 7.5 11.9529 7.5 13.3337Z"
                                          stroke="#344054" stroke-width="1.66667" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                      </svg>
                                    </div>
                                    <div class="s_m__card_l">
                                      <h5 class="mb-3">White Label the Platform to your brand</h5>
                                      <span class="block mb-2"><a (click)="openAppImageConfigPopup()"
                                                                  class="cursor-pointer">Click here</a> to update the Login Page image and Logo</span>
                                      <span class="block"><a (click)="openEmailConfigPopup()" class="cursor-pointer">Click here</a> to update the Email footer and Email Sender details</span>
                                    </div>
                                  </div>
                                }
                                @if (whiteLabelingSteps.code == stepsEnum.CustomURL) {
                                  <div class="user-guide-card select_required_modules">
                                    <div class="icon-placeholder">
                                      <i class="fa-solid fa-link"></i>
                                    </div>
                                    <div class="s_m__card_l">
                                      <h5 class="mb-3">Custom URL:</h5>
                                      <span class="block mb-2">
                            <a (click)="openCustomUrlConfigPopup()" class="cursor-pointer">
                              Click
                              here
                            </a> to update custom domain URL for your application
                          </span>
                                      <span class="block mb-2">
                            <i class="guide_icon fa-solid fa-check"></i>
                            To get the Custom URL (Example – Instead of https://"your-site-name"-lms.calimatic.com,
                            if you
                            prefer something like https://portal."your-domain".com)
                          </span>
                                      <span class="block">
                            <i class="guide_icon fa-solid fa-check"></i>
                            To update the Favicon and Browser Title
                          </span>
                                    </div>
                                  </div>
                                }
                                @if (whiteLabelingSteps.code == stepsEnum.BrandedMobileApp) {
                                  <div class="user-guide-card select_required_modules">
                                    <div class="icon-placeholder">
                                      <i class="fa-solid fa-mobile-screen-button"></i>
                                    </div>
                                    <div class="s_m__card_l">
                                      <h5 class="mb-3">Branded Mobile App:</h5>
                                      <span class="block mb-2">
                            <a (click)="openmobileAppConfigPopup()" class="cursor-pointer">
                              Click
                              here
                            </a> to update mobile application customization
                          </span>
                                      <span class="block">
                            Get your own Branded Mobile App in Apple & Google Stores (with same Calimatic
                            features)
                          </span>
                                    </div>
                                  </div>
                                }

                              }
                            </div>
                          </div>
                          <div class="ug-stepper-card-footer">
                            <button pButton (click)="prevCallback.emit()" class="clm-tertiary-btn" label="Go Back"
                                    [icon]="'fa-solid fa-arrow-left'" iconPos="left"></button>
                            <span class="flex-grow-1"></span>
                            <button pButton (click)="nextCallback.emit()" class="clm-secondary-btn"
                                    label="Skip Step"></button>
                            <button (click)="onModuleComplete(section);nextCallback.emit()" pButton
                             [icon]="'fa-solid fa-arrow-right'" iconPos="right" class="clm-primary-btn"
                                    label="Save and Next" [loading]="section.isLoading"></button>
                          </div>
                        </div>
                      </ng-template>
                    </p-stepperPanel>
                  }
                  @if (section.code == onBoardSectionEnum.Terminologies) {
                    <p-stepperPanel header="Header III">
                      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
                                   let-index="index">
                        <div class="steps-title">
                          <h3>Terminology Setup </h3>
                          <p>Set up terminology for your organization's brand message, business goals, and the
                            audiance.</p>
                        </div>
                        <div class="ug-stepper-card">
                          <div class="ug-stepper-card-body">
                            <div class="user-guide-card select_required_modules">
                              <div class="icon-placeholder">
                                <i class="fa-solid fa-font"></i>
                              </div>
                              <div class="s_m__card_l">
                                <h5 class="mb-1">Update Platform Terminologies</h5>
                                @for (terminoogySteps of section.steps; track $index) {
                                  @if (terminoogySteps.code == stepsEnum.Terminology) {
                                    <span>
                          <a (click)="openTerminologyPopup()" class="cursor-pointer">Click here</a> to make the
                          changes
                        </span>
                                  }
                                }
                              </div>
                            </div>
                          </div>
                          <div class="ug-stepper-card-footer">
                            <button pButton (click)="prevCallback.emit()" class="clm-tertiary-btn" label="Go Back"
                                    [icon]="'fa-solid fa-arrow-left'" iconPos="left"></button>
                            <span class="flex-grow-1"></span>
                            <button pButton (click)="nextCallback.emit()" class="clm-secondary-btn"
                                    label="Skip Step"></button>
                            <button (click)="onModuleComplete(section);nextCallback.emit()" pButton
                                    [icon]="'fa-solid fa-arrow-right'" iconPos="right" class="clm-primary-btn"
                                    label="Save and Next" [loading]="section.isLoading"></button>
                          </div>
                        </div>
                      </ng-template>
                    </p-stepperPanel>
                  }
                }
                @if (section.code == onBoardSectionEnum.MarketPlaceSteps) {
                  <p-stepperPanel header="Header IV">
                    <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
                                 let-index="index">
                      <div class="steps-title">
                        <h3>Marketplace Course</h3>
                        <p>This involves steps that will guide you in publishing a course to your platform</p>
                      </div>
                      <div class="ug-stepper-card">
                        <div class="ug-stepper-card-body">
                          <div class="ug-stepper-card-body">
                            <div class="user-guide-card select_required_modules">
                              <div class="icon-placeholder">
                                <i class="fa-solid fa-font"></i>
                              </div>
                              <div class="s_m__card_l steps-wrapper">
                                <h5 class="mb-4">Marketplace course publish steps</h5>
                                <ul>
                                  @for (marketPlaceSteps of section.steps; track $index) {
                                    @if (marketPlaceSteps.code == stepsEnum.TransactionInformation) {
                                      <li>
                                        <h6>Step 1:</h6>
                                        <span class="block mb-2">
                                Transaction information for <b>Stripe</b> or <b>Plaid</b> must be
                                filled.
                              </span>
                                        <span class="block mb-2">
                                If you haven't updated the information yet <a (click)="openTransactionConfigPopup()"
                                                                              class="cursor-pointer">Click here</a>.
                              </span>
                                      </li>
                                    }
                                    @if (marketPlaceSteps.code == stepsEnum.paymentInformation) {
                                      <li>
                                        <h6>Step 2:</h6>
                                        <span class="block mb-2">Visit course page and add course information.</span>
                                        <span class="block mb-2">
                                <a href="https://help-lcm.calimatic.com/article?categoryId=54&solutionId=348"
                                   target="_blank" class="cursor-pointer">Click here</a> to get detail information about how to publish course.
                              </span>
                                      </li>
                                    }
                                  }
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="ug-stepper-card-footer">
                          @if (userRole == userRoles.BusinessAdmin) {
                            <button pButton (click)="prevCallback.emit()" class="clm-tertiary-btn" label="Go Back"
                                    [icon]="'fa-solid fa-arrow-left'" iconPos="left"></button>
                          }
                          <span class="flex-grow-1"></span>
                          <button (click)="onModuleComplete(section)" pButton
                          [loading]="section.isLoading"  class="clm-primary-btn" label="Finish"></button>
                        </div>
                      </div>
                    </ng-template>
                  </p-stepperPanel>
                }
              }
            </p-stepper>
          }
        </div>
      } @else {
        <div class="">
          <calimatic-skeleton
            [configs]="{height:'2rem',width:'25%',repeat:1, styleClass:'m-auto mb-3'}"></calimatic-skeleton>
          <calimatic-skeleton
            [configs]="{height:'1rem',width:'75%',repeat:1, styleClass:'m-auto'}"></calimatic-skeleton>
        </div>

        <div class="p-stepper">
          <div class="p-stepper-panels">
            <calimatic-skeleton
              [configs]="{height:'2rem',width:'25%',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
            <calimatic-skeleton
              [configs]="{height:'1rem',width:'80%',repeat:1, styleClass:'mb-4'}"></calimatic-skeleton>
            <calimatic-skeleton
              [configs]="{height:'6rem',width:'100%',repeat:3, styleClass:'mb-3'}"></calimatic-skeleton>
          </div>
        </div>
      }
    </div>
  </main>

</p-dialog>
