import {Component, input, OnInit, output} from '@angular/core';
import {DashboardService} from "../../../../../core/services/dashboard.service";
import {LeadsStatusCountDto} from "../../../../../shared/dtos/leads-status-count.dto";
import {CounterTrioSkeletonComponent} from "../skeletons/counter-trio-skeleton/counter-trio-skeleton.component";
import {TooltipModule} from 'primeng/tooltip';
import {DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";

@Component({
  selector: 'calimatic-leads-widget',
  standalone: true,
  imports: [
    CounterTrioSkeletonComponent,
    TooltipModule
  ],
  templateUrl: './leads-widget.component.html',
  styleUrl: './leads-widget.component.scss'
})
export class LeadsWidgetComponent implements OnInit {
  widget = input.required<DashboardWidgetsDTO>();
  edit = input.required();
  onRemove = output();

  //local
  leadCountByStatus: LeadsStatusCountDto[];
  loading = false;

  constructor(private dashboardService: DashboardService) {
  }

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    this.loading = true;
    this.leadCountByStatus = await this.dashboardService.getLeadsStatusCount();
    this.loading = false;
  }

}
