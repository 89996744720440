import {Injectable} from "@angular/core";
import {ApiHandlerService} from "./base/api-handler.service";
import {lastValueFrom} from "rxjs";
import {API_ENDPOINTS} from "../../shared/utils/global";
import {DropdownDto} from "../../shared/dtos/dropdown-dto";

@Injectable({
  providedIn: "root"
})
export class TypeService {
  constructor(private http: ApiHandlerService) {
  }

  async getTypes() {
    const res = await lastValueFrom(this.http.Get(API_ENDPOINTS.types.list));
    if (res.isSuccessful) {
      return res.response as DropdownDto[];
    }
    return [];
  }
}
