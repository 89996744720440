<div class="dashboard-quick-links">

  <!--  Admin & Executive -->
  @if (authService.isAdmin() || authService.isExecutive() || authService.isInstructor() || authService.isCoordinator()) {
    <ul>
      @if (hasMenuPermission([subMenusEnum.FamiliesInfo, subMenusEnum.StudentInfo, subMenusEnum.StaffInfo])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-admin1"></i></span>
          <div class="link-info-items">

            <h6>USERS</h6>
            @if (hasMenuPermission([subMenusEnum.FamiliesInfo])) {
              <a
                [routerLink]="['/'+appRoutePaths.families.v3]">Add {{ 'Family' | terminology:terminologyEnum.Family }}</a>
            }

            @if (hasMenuPermission([subMenusEnum.StudentInfo])) {
              <a [routerLink]="['/'+appRoutePaths.students.v3]">Add Student</a>
            }

            @if ((authService.isAdmin() || authService.isExecutive()) && hasMenuPermission([subMenusEnum.StaffInfo])) {
              <a [routerLink]="['/'+appRoutePaths.staff.v3]">Add Staff</a>
            }

          </div>
        </li>
      }
      @if (hasMenuPermission([subMenusEnum.Courses])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-notepad"></i></span>
          <div class="link-info-items">
            <h6>CURRICULUM</h6>
            <a [routerLink]="['/'+appRoutePaths.courses.v3]">Add {{ 'Course' | terminology:terminologyEnum.Course }}</a>
          </div>
        </li>
      }
      @if (hasMenuPermission([subMenusEnum.Class, subMenusEnum.Events, subMenusEnum.Attendance])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-TimecardApproval"></i></span>
          <div class="link-info-items">
            <h6>{{ ('Class' | terminology:terminologyEnum.Clas: terminologyWordType.PLURAL)?.toLocaleUpperCase() }} AND
              {{ ('Events' | terminology:terminologyEnum.Event: terminologyWordType.PLURAL)?.toLocaleUpperCase() }}</h6>
            @if (hasMenuPermission([subMenusEnum.Class])) {
              <a [routerLink]="['/'+appRoutePaths.classes.v3]">Add {{ 'Class' | terminology:terminologyEnum.Clas }}</a>
            }

            @if (hasMenuPermission([subMenusEnum.Events])) {
              <a [routerLink]="['/'+appRoutePaths.events.v3]">Add {{ 'Event' | terminology:terminologyEnum.Event }}</a>
            }

            @if (hasMenuPermission([subMenusEnum.Attendance])) {
              <a [routerLink]="['/'+appRoutePaths.attendance.v3]">Take {{ 'Class' | terminology:terminologyEnum.Clas }}
                Attendance</a>
            }

          </div>
        </li>
      }
      @if ((authService.isAdmin() || authService.isExecutive()) && hasMenuPermission([subMenusEnum.StudentPayments])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-PortfolioPlanBudgetAssets"></i></span>
          <div class="link-info-items">
            <h6>PAYMENTS</h6>
            <a [routerLink]="['/'+appRoutePaths.payments.v3]">View & Collect Payments</a>
            <a [routerLink]="['/'+appRoutePaths.payments.v3]">View & Collect Fees</a>
          </div>
        </li>
      }

      @if (hasMenuPermission([subMenusEnum.TimeTracker])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-checklist"></i></span>
          <div class="link-info-items">
            <h6>SCHEDULES</h6>
            <a [routerLink]="['/'+appRoutePaths.timeTracker.v3]">View Staff Schedule</a>
          </div>
        </li>
      }
      @if ((authService.isAdmin() || authService.isExecutive()) && hasMenuPermission([subMenusEnum.Leads])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-customerSupport"></i></span>
          <div class="link-info-items">
            <h6>CRM</h6>
            <a [routerLink]="['/'+appRoutePaths.leads.v3]">Manage Leads</a>
          </div>
        </li>
      }
      @if (hasMenuPermission([subMenusEnum.Calendar])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-Calendar"></i></span>
          <div class="link-info-items">
            <h6>CALENDAR</h6>
            <a [routerLink]="['/'+appRoutePaths.calendar.v3]">View current and
              upcoming {{ 'Events' | terminology:terminologyEnum.Event: terminologyWordType.PLURAL }}</a>
          </div>
        </li>
      }

    </ul>
  }

  <!--  Parent -->
  @if (authService.isParent()) {
    <ul>
      @if (hasMenuPermission([subMenusEnum.StudentInfo, subMenusEnum.FamiliesInfo])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-admin1"></i></span>
          <div class="link-info-items">

            <h6>STUDENTS</h6>

            @if (hasMenuPermission([subMenusEnum.StudentInfo])) {
              <a [routerLink]="['/'+appRoutePaths.students.v3]">Add Student</a>
            }

            @if (hasMenuPermission([subMenusEnum.StudentInfo])) {
              <a [queryParams]="{ dashboardLink: true, openTab: 'classes_tab' }"
                 [routerLink]="['/'+appRoutePaths.families.v3]">Add Student
                to
                a {{ 'Class' | terminology:terminologyEnum.Clas }}</a>
            }

          </div>
        </li>
      }

      @if (hasMenuPermission([subMenusEnum.FamiliesInfo, subMenusEnum.StudentPayments, subMenusEnum.Transactions])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-PortfolioPlanBudgetAssets"></i></span>
          <div class="link-info-items">
            <h6>PAYMENTS</h6>
            @if (hasMenuPermission([subMenusEnum.FamiliesInfo])) {
              <a [queryParams]="{ dashboardLink: true, openTab: 'billing_tab' }"
                 [routerLink]="['/'+appRoutePaths.families.v3]">Add Billing</a>
            }
            @if (hasMenuPermission([subMenusEnum.StudentPayments])) {
              <a [routerLink]="['/'+appRoutePaths.payments.v3]">View Payments</a>
            }
            @if (hasMenuPermission([subMenusEnum.Transactions])) {
              <a [routerLink]="['/'+appRoutePaths.transactions.v3]">View Transactions</a>
            }
          </div>
        </li>
      }

      @if (hasMenuPermission([subMenusEnum.StudentProgressReport])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-History"></i></span>
          <div class="link-info-items">
            <h6>REPORTS</h6>
            <a [routerLink]="['/'+appRoutePaths.studentProgressReport.v3]">View Student Progress</a>
            <a
              [routerLink]="['/'+appRoutePaths.studentProgressReport.v3]">View {{ ('Assessments' | terminology:terminologyEnum.Assessment: terminologyWordType.PLURAL) }}
              Progress</a>
          </div>
        </li>
      }

      @if (hasMenuPermission([subMenusEnum.StudentInfo])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-risk-identity"></i></span>
          <div class="link-info-items">
            <h6>PROFILE</h6>
            <a [routerLink]="['/'+appRoutePaths.students.v3]" [queryParams]="{ dashboardLink: true }">View Profile</a>
          </div>
        </li>
      }
      @if (hasMenuPermission([subMenusEnum.Calendar])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-Calendar"></i></span>
          <div class="link-info-items">
            <h6>CALENDAR</h6>
            <a [routerLink]="['/'+appRoutePaths.calendar.v3]">View current and
              upcoming {{ 'Events' | terminology:terminologyEnum.Event: terminologyWordType.PLURAL }}</a>
          </div>
        </li>
      }

    </ul>
  }

  <!--  Student-->
  @if (authService.isStudent()) {
    <ul>
      @if (hasMenuPermission([subMenusEnum.Class])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-admin1"></i></span>
          <div class="link-info-items">
            <calimatic-join-class-widget></calimatic-join-class-widget>
          </div>
        </li>
      }

      @if (hasMenuPermission([subMenusEnum.StudentBoard, subMenusEnum.Courses])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-risk-accpetance"></i></span>
          <div class="link-info-items">

            <h6>ASSIGNMENTS</h6>

            @if (hasMenuPermission([subMenusEnum.StudentBoard])) {
              <a href="#">View Board</a>
            }

            @if (hasMenuPermission([subMenusEnum.Courses])) {
              <a
                [routerLink]="['/'+appRoutePaths.courses.v3]">
                View {{ 'Course' | terminology:terminologyEnum.Course }} Player</a>
            }

          </div>
        </li>
      }

      @if (hasMenuPermission([subMenusEnum.StudentAssessments])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-qm-assignedTestCase"></i></span>
          <div class="link-info-items">
            <h6>{{ ('ASSESSMENTS' | terminology:terminologyEnum.Assessment: terminologyWordType.PLURAL) }}</h6>
            @if (hasMenuPermission([subMenusEnum.StudentAssessments])) {
              <a
                [routerLink]="['/'+appRoutePaths.assessments.v3]">View/Take {{ ('ASSESSMENTS' | terminology:terminologyEnum.Assessment: terminologyWordType.PLURAL) }} </a>
            }
          </div>
        </li>
      }

      @if (hasMenuPermission([subMenusEnum.StudentBoard])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-progress"></i></span>
          <div class="link-info-items">
            <h6>WHITE BOARD</h6>
            <a href="#">View White Board</a>
          </div>
        </li>
      }

      @if (hasMenuPermission([subMenusEnum.StudentInfo])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-risk-identity"></i></span>
          <div class="link-info-items">
            <h6>PROFILE</h6>
            <a [routerLink]="['/'+appRoutePaths.students.v3]" [queryParams]="{ dashboardLink: true }">View Profile</a>
          </div>
        </li>
      }
      @if (hasMenuPermission([subMenusEnum.Calendar])) {
        <li>
          <span class="ql_icon"><i class="ctxicon-Calendar"></i></span>
          <div class="link-info-items">
            <h6>CALENDAR</h6>
            <a [routerLink]="['/'+appRoutePaths.calendar.v3]">View current and
              upcoming {{ 'Events' | terminology:terminologyEnum.Event: terminologyWordType.PLURAL }}</a>
          </div>
        </li>
      }

    </ul>
  }

</div>
