import {Component, output} from '@angular/core';
import {iconList} from "../../utils/icon-list";
import {InputTextModule} from "primeng/inputtext";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'calimatic-icons',
  standalone: true,
  imports: [
    InputTextModule,
    InputGroupAddonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './icons.component.html',
  styleUrl: './icons.component.scss'
})
export class IconsComponent {
  selectedIcon = output<string>();

  //local
  icons = iconList;
  iconSearch = '';

  searchIcons(kw: string) {
    this.iconSearch = kw;
    this.icons = this.iconSearch?.length ? this.icons.filter(x => x?.trim()?.toLowerCase().includes(this.iconSearch)) : iconList;
  }
}
