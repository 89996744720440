export enum ModuleConfig {  
  Challange = 1,  
  Course = 2,  
  Level = 3,  
  AgileDefect = 4,  
  AgileTask = 5,  
  WaterfallRequirement = 6,  
  Incident = 7,  
  ServiceRequest = 8,  
  WaterfallDefect = 9,  
  Tickets = 10,  
  DefinitionofDone = 11,  
  ProcessEvents = 12,  
  Idea = 13,  
  Retrospective = 14,  
  DefinitionofDoneGeneric = 15,  
  RetrospectiveGeneric = 16,  
  Portfolio = 17,  
  Goal = 18,  
  ReleaseCheckList = 19,  
  PortfolioTask = 20,  
  TimeOff = 21,  
  Expenses = 22,  
  Timesheet = 23,  
  Assessment = 24,  
  PortfolioStrategic = 25,  
  TestCases = 26,  
  Solution = 27,  
  Problems = 28,  
  Changes = 29,  
  Solutions = 30,  
  Challenge = 37,  
  CompanyDocuments = 38,  
  CompanyAgreement = 39,  
  CompanyTraining = 40,  
  Inventory = 41,  
  onBoarding = 42,  
  EmailTemplate = 43,  
  EnrollmentForm = 44,  
  Lead = 45,  
  StudentForm = 46,  
  ContactForm = 47,  
  Family = 48,  
  Student = 49,  
  LeadContactForm = 50,  
  AttchmentForGoogleDrive = 51,  
  AttchmentForOneDrive = 52,  
  StaffForm = 53,  
  ClassNotes = 54,  
  StaffTask = 55,  
  Articles = 56,  
  EventEnrollmentForm = 57,  
  Events = 58,  
  HomeView = 59,  
  ChallengeSteps = 60,  
  WhiteBoard = 61,  
  HomeWorkView = 62,  
  CourseNotes = 63,  
  SubmitWorkChallenge = 64,  
  SubmitCoursePlayerHomework = 65,  
  StudentWhiteboardAttachments = 66,  
  StudentExcelAttachments = 67,  
  InstructorHomeWorkAttachments = 68,  
  StudentDriveAttachments = 69,  
  ScheduleEmailAttachments = 70,  
  CorrectedCoursePlayerHomework = 71,  
  SISStudentInfoAttachments = 72,  
  SISFamilyInfoAttachments = 73,  
  StudentAdminNotes = 74,  
  StudentInternalNotes = 75,  
  FranchiseEnrollment = 76,  
  GamificationStreaks = 77,  
  GamificationBadges = 78  
}


export enum AttachmentFileType
{
    General = 1,
    Code = 2,
    WhiteBoard = 3
}
