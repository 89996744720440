import {Component, input} from '@angular/core';
import {LabelTypeEnum} from "../../enums/label-type.enum";

@Component({
  selector: 'calimatic-app-label',
  standalone: true,
  imports: [],
  templateUrl: './app-label.component.html',
  styleUrl: './app-label.component.scss'
})
export class AppLabelComponent {
  text = input.required<string>();
  type = input<LabelTypeEnum>(LabelTypeEnum.Error);
  protected readonly labelTypeEnum = LabelTypeEnum;
}
