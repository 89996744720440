import {CanActivateFn, Router} from "@angular/router";
import {effect, inject} from "@angular/core";
import {appRoutePaths} from "../../../app.routes";
import {menuStore} from "../../stores/menu.store";

export const menuGuard: CanActivateFn = async (route, state) => {
  const menuStr = inject(menuStore);
  const router = inject(Router);
  const menuIds = (route.data.menuId || []) as number[];
  const isSubMenu = route.data.subMenu;
  effect(async () => {
    if (isSubMenu) {
      const subMenus = menuStr.menus().flatMap(x => x.subMenus);
      if (menuIds?.length && subMenus?.length && !subMenus.some(x => menuIds.includes(x.code))) {
        await router.navigate([`/${appRoutePaths.dashboard.v3}`]);
      }
    } else {
      const menus = menuStr.menus();
      if (menus?.length && !menus.some(x => menuIds.includes(x.code))) {
        await router.navigate([`/${appRoutePaths.dashboard.v3}`]);
      }
    }
  });
  return true;
};

