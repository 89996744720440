import {Component, effect, input, output} from '@angular/core';
import {DropdownComponent} from "../../../../../shared/components/dropdown/dropdown.component";
import {DropdownDto} from "../../../../../shared/dtos/dropdown-dto";
import {DashboardService} from "../../../../../core/services/dashboard.service";
import {StackBarChartDataDto} from "../../../../../shared/dtos/chart-data.dto";
import {BarChartComponent} from "../../charts/bar-chart/bar-chart.component";
import {ChartSkeletonComponent} from "../skeletons/chart-skeleton/chart-skeleton.component";
import {TooltipModule} from 'primeng/tooltip';
import {DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";
import {StackBarChartComponent} from "../../charts/stack-bar-chart/stack-bar-chart.component";

@Component({
  selector: 'calimatic-leads-generation-widget',
  standalone: true,
  imports: [
    DropdownComponent,
    BarChartComponent,
    ChartSkeletonComponent,
    TooltipModule,
    StackBarChartComponent
  ],
  templateUrl: './leads-generation-widget.component.html',
  styleUrl: './leads-generation-widget.component.scss'
})
export class LeadsGenerationWidgetComponent {

  //inputs
  widget = input.required<DashboardWidgetsDTO>();
  franchises = input.required<DropdownDto[]>();
  companyId = input.required<number>();
  isFranchise = input.required<boolean>();
  edit = input.required();

  //outputs
  onRemove = output();

  leadsOpts: DropdownDto[] = [
    {name: 'Lead Source', id: 1},
    {name: 'Zip Code', id: 2},
    {name: 'City', id: 3},
    {name: 'Country', id: 4}];

  selectedType: number;
  selectedFranchise: number;
  loading = true;
  data: StackBarChartDataDto[] = [];

  constructor(private dashboardService: DashboardService) {
    effect(async () => {
      this.selectedType = this.leadsOpts[0].id;
      this.selectedFranchise = this.franchises().find(x => x.id == this.companyId())?.id || 0;
      await this.loadData();
    });
  }

  async onTypeChange(value: any) {
    this.selectedType = value;
    await this.loadData();
  }

  async onFranchiseChange(value: any) {
    this.selectedFranchise = value;
    await this.loadData();
  }

  async loadData() {
    if (this.isFranchise()) {
      this.selectedFranchise = this.companyId();
    }

    if (this.selectedType && this.selectedFranchise) {
      this.loading = true;
      this.data = await this.dashboardService.getLeadsGenerationData(this.selectedType, this.selectedFranchise);
      this.loading = false;
    }
  }
}
