<calimatic-branding></calimatic-branding>
<div class="clm-login__wrapper h-full">
  @if (!configStore.isLoading()) {
    @switch (configStore.configs().loginTemplate) {
      @case (loginTemplateEnums.standard) {
        <ng-container [ngTemplateOutlet]="standardLoginTemplate"/>
      }
      @case (loginTemplateEnums.smavyTemplate) {
        <!--other template-->
      }
      @default {
        <ng-container [ngTemplateOutlet]="standardLoginTemplate"/>
      }
    }
  }

  <ng-template #standardLoginTemplate>
    <calimatic-standard-template [companyConfigs]="configStore.configs()"></calimatic-standard-template>
  </ng-template>
  <ng-template #defaultLoginTemplate>
    <calimatic-default-template [companyConfigs]="configStore.configs()"></calimatic-default-template>
  </ng-template>
</div>
