import {Component, Inject, input, OnInit, output} from '@angular/core';
import {AccordionModule} from "primeng/accordion";
import {ButtonModule} from "primeng/button";
import {PrimeNGConfig, SharedModule} from "primeng/api";
import {AnnouncementDataDTO, AnnouncementDTO} from "../../../../../shared/dtos/announcement.dto";
import {DashboardService} from "../../../../../core/services/dashboard.service";
import {CounterSkeletonComponent} from "../skeletons/counter-skeleton/counter-skeleton.component";
import {DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";
import {AppConstants, AppTokens} from "../../../../../shared/utils/global";
import {RippleModule} from 'primeng/ripple';
import {NgStyle} from "@angular/common";
import {IToaster} from "../../../../../shared/interfaces/toaster/IToaster";
import {ToasterTypeEnum} from "../../../../../shared/enums/toaster-type.enum";
import {TooltipModule} from "primeng/tooltip";
import {TerminologyPipe} from "../../../../../shared/pipes/terminology.pipe";
import {TerminologyEnum, TerminologyWordEnum} from "../../../../../shared/enums/terminology.enum";

@Component({
  selector: 'calimatic-announcements-widget',
  standalone: true,
  imports: [
    AccordionModule,
    ButtonModule,
    SharedModule,
    RippleModule,
    CounterSkeletonComponent,
    NgStyle,
    TooltipModule,
    TerminologyPipe
  ],
  templateUrl: './announcements-widget.component.html',
  styleUrl: './announcements-widget.component.scss'
})
export class AnnouncementsWidgetComponent implements OnInit {

  //inputs
  widget = input.required<DashboardWidgetsDTO>();
  edit = input.required<boolean>();

  //outputs
  onRemove = output();
  onToggle = output<number>();

  //local
  loading = true;
  data: AnnouncementDataDTO | null;
  defaultCellHeight = AppConstants.defaultCellHeight;
  cardHeight = '100%';
  announcementId = 'dashboard__announcement';
  terminologyEnum = TerminologyEnum;
  terminologyWordType = TerminologyWordEnum;

  constructor(private dashboardService: DashboardService, private primengConfig: PrimeNGConfig, @Inject(AppTokens.toaster) private toaster: IToaster) {
  }

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    this.primengConfig.ripple = true;
    this.loading = true;
    this.data = await this.dashboardService.getAnnouncements();
    this.data?.expandSection && this.sectionToggle();
    this.loading = false;
  }

  onSectionToggle() {
    if (this.edit()) {
      this.toaster.show(ToasterTypeEnum.Warning, 'Editing In Progress', 'Please finish editing dashboard first');
      return;
    }

    this.data.expandSection = !this.data.expandSection;
    this.sectionToggle();
  }

  onChildSectionToggle(item: AnnouncementDTO) {
    if (this.edit()) {
      this.toaster.show(ToasterTypeEnum.Warning, 'Editing In Progress', 'Please finish editing dashboard first');
      return;
    }

    item.expanded = !item.expanded;
    this.childToggle();
  }

  private childToggle() {
    this.cardHeight = 'auto';
    setTimeout(() => {
      this.onToggle.emit(this.getCalHeight());
      this.cardHeight = '100%';
    });
  }

  private sectionToggle() {
    setTimeout(() => {
      this.onToggle.emit(this.data.expandSection ? this.getCalHeight() : this.widget().height);
    });
  }

  private getParentHeight() {
    return (document.getElementById(this.announcementId)?.scrollHeight || 0) + 1;
  }

  private getCalHeight() {
    return Math.ceil(this.getParentHeight() / this.defaultCellHeight);
  }
}
