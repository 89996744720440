<p-carousel [value]="StudentPoints" [numVisible]="4" [numScroll]="1" [circular]="false"
    [responsiveOptions]="responsiveOptions">
    <ng-template let-data pTemplate="item">
        <div class="p-2 w-full h-full" >
            <div class="flex gap-3 p-2 justify-content-center border-round-lg  align-items-center w-full h-full" [ngStyle]="{'background-color':data.isStudentPoints?'#00639B':'#6864AD' }" >
                <span class="text-7xl text-white">
                    <i class="ctxicon-payementCoin"></i>
                </span>
                @if (!data.isStudentPoints) {
                <div class="flex flex-column align-items-center justify-content-center">
                    <h4 class="text-900 mb-3 text-white justify-content-start  ">{{ data.courseName }}</h4>
                    <div class="flex flex-column gap-1 mb-2 justify-content-center align-items-center" >
                        <span class="font-medium text-600 text-white">Achieved Points:</span>
                        <span class="text-lg text-primary text-white">{{ data.courseChallengesAchievedPoints }}</span>
                    </div>
                </div>
                }
                @else {
                <div class="flex flex-column align-items-center justify-content-center">
                    <h4 class="text-900 mb-3 text-white">Student Points</h4>
                    <div class="flex flex gap-1 mb-2 justify-content-center align-items-center">
                        <span class="font-medium text-600 text-white">Bonus Points:</span>
                        <span class="text-lg text-primary text-white">{{ data.studentTotalBonusPoints }}</span>
                    </div>
                    <div class="flex flex gap-1 mb-2 ">
                        <span class="font-medium text-600 text-white">CHALLENGE1(es) Achieved Points:</span>
                        <span class="text-lg text-primary text-white">{{ data.studentAchievedChallengesPoints }}</span>
                    </div>
                    <div class="flex flex gap-1 mb-2">
                        <span class="font-medium text-600 text-white">Redeem Points:</span>
                        <span class="text-lg text-primary text-white">{{ data.studentTotalRedeemedPoints }}</span>
                    </div>
                    <div class="flex flex gap-1 mb-2">
                        <span class="font-medium text-600 text-white">Available Points:</span>
                        <span class="text-lg text-primary text-white">{{ data.availablePoints }} </span>
                    </div>
                </div>
                }
            </div>
        </div>
    </ng-template>
</p-carousel>