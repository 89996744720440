import {Component, effect, input, output} from '@angular/core';
import {DropdownDto} from "../../../../../shared/dtos/dropdown-dto";
import {DropdownComponent} from "../../../../../shared/components/dropdown/dropdown.component";
import {BarChartComponent} from "../../charts/bar-chart/bar-chart.component";
import {DashboardService} from "../../../../../core/services/dashboard.service";
import {ChartDataDto} from "../../../../../shared/dtos/chart-data.dto";
import {ChartSkeletonComponent} from "../skeletons/chart-skeleton/chart-skeleton.component";
import {TooltipModule} from "primeng/tooltip";
import {
  DashboardStudentDemographicsEnum,
  DashboardStudentDemographicsStateEnum
} from "../../../../../shared/enums/dashboard/dashboard-student-demographics.enum";
import {DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";

@Component({
  selector: 'calimatic-student-demographics-widget',
  standalone: true,
  imports: [
    DropdownComponent,
    BarChartComponent,
    ChartSkeletonComponent,
    TooltipModule
  ],
  templateUrl: './student-demographics-widget.component.html',
  styleUrl: './student-demographics-widget.component.scss'
})
export class StudentDemographicsWidgetComponent {

  //inputs
  widget = input.required<DashboardWidgetsDTO>();
  franchises = input.required<DropdownDto[]>();
  companyId = input.required<number>();
  isFranchise = input.required<boolean>();
  edit = input.required();

  //outputs
  onRemove = output();

  chartOpt: DropdownDto[] = [{
    name: 'Age',
    id: DashboardStudentDemographicsEnum.Age
  },
    {
      name: 'Grade',
      id: DashboardStudentDemographicsEnum.Grade
    },
    {
      name: 'Location',
      id: DashboardStudentDemographicsEnum.Location
    },
    {
      name: 'State',
      id: DashboardStudentDemographicsEnum.State
    },
    {
      name: 'City',
      id: DashboardStudentDemographicsEnum.City
    },
    {
      name: 'Country',
      id: DashboardStudentDemographicsEnum.Country
    }];

  stateOpts: DropdownDto[] = [{
    id: DashboardStudentDemographicsStateEnum.All,
    name: 'All'
  }, {
    id: DashboardStudentDemographicsStateEnum.Active,
    name: 'Active'
  }, {id: DashboardStudentDemographicsStateEnum.InActive, name: 'In-Active'}]

  selectedType: number;
  selectedState: number;
  selectedFranchise: number;
  loading = false;
  data: ChartDataDto[] = [];

  constructor(private dashboardService: DashboardService) {
    effect(async () => {
      this.selectedType = this.chartOpt[0].id;
      this.selectedState = this.stateOpts[0].id;
      this.selectedFranchise = this.franchises().find(x => x.id == this.companyId())?.id || 0;
      await this.loadData();
    });
  }

  async onTypeChange(value: any) {
    this.selectedType = value;
    await this.loadData();
  }

  async onStateChange(value: any) {
    this.selectedState = value;
    await this.loadData();
  }

  async onFranchiseChange(value: any) {
    this.selectedFranchise = value;
    await this.loadData();
  }

  async loadData() {
    if (this.isFranchise()) {
      this.selectedFranchise = this.companyId();
    }

    if (this.selectedType && this.selectedFranchise && this.selectedState) {
      this.loading = true;
      this.data = await this.dashboardService.getStudentDemographicsData(this.selectedType, this.selectedState, this.selectedFranchise);
      this.loading = false;
    }
  }

}
