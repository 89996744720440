export enum DashboardStudentDemographicsEnum {
  Age = 1,
  Grade,
  Location,
  State,
  City,
  Country
}

export enum DashboardStudentDemographicsStateEnum {
  All = 1,
  Active,
  InActive
}
