import {Component, input, output} from '@angular/core';
import {MultiSelectModule} from "primeng/multiselect";
import {MultiSelectConfigsInterface} from "../../interfaces/multi-select/multi-select-configs.interface";
import {FormsModule} from "@angular/forms";
import {JsonPipe} from "@angular/common";
import {MultiSelectDisplayEnums} from "../../enums/multi-select/multi-select.enums";
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox';
import { FloatLabelModule } from 'primeng/floatlabel';

@Component({
  selector: 'calimatic-multi-select',
  standalone: true,
  imports: [
    MultiSelectModule,
    FormsModule,
    JsonPipe,
    OverlayPanelModule,
    ButtonModule,
    CheckboxModule,
    FloatLabelModule
  ],
  templateUrl: './multi-select.component.html',
  styleUrl: './multi-select.component.scss'
})
export class MultiSelectComponent {
  configs = input.required<Partial<MultiSelectConfigsInterface>>();
  value = input.required();
  valueChange = output<any>();
  protected readonly multiSelectDisplayEnums = MultiSelectDisplayEnums;
}
