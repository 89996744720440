import {Component, input, OnInit, output} from '@angular/core';
import {DashboardService} from "../../../../../core/services/dashboard.service";
import {DashboardTrioWidgetsDTO, DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";
import {TooltipModule} from "primeng/tooltip";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {SkeletonModule} from "primeng/skeleton";
import {DateTimeFormats, MomentHelper} from "../../../../../shared/services/moment-helper";
import {
  DashboardWidgetsEnum,
  DashboardWidgetSymbolEnum
} from "../../../../../shared/enums/dashboard/dashboard-widgets.enum";
import {CounterSkeletonComponent} from "../skeletons/counter-skeleton/counter-skeleton.component";

@Component({
  selector: 'calimatic-counter-widget',
  standalone: true,
  imports: [
    TooltipModule,
    ProgressSpinnerModule,
    SkeletonModule,
    CounterSkeletonComponent
  ],
  templateUrl: './counter-widget.component.html',
  styleUrl: './counter-widget.component.scss'
})
export class CounterWidgetComponent implements OnInit {
  widget = input.required<DashboardWidgetsDTO>();
  edit = input.required<boolean>();
  onRemove = output();

  //local
  loading = true;
  value: any;
  formattedDate: string;
  showComparison = false;
  percentChange: number;
  protected readonly widgetSymbolEnum = DashboardWidgetSymbolEnum;

  constructor(private dashboardService: DashboardService) {
  }

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    this.loading = true;
    this.formattedDate = MomentHelper.Now().format(DateTimeFormats.Monthly);
    this.value = await this.dashboardService.getWidgetData(this.widget().code);
    if (this.widget().code == DashboardWidgetsEnum.EnrollmentsCount) {
      const data = this.value as DashboardTrioWidgetsDTO;
      this.showComparison = true;
      if (data.previous) {
        this.percentChange = Math.round(((data.current - data.previous) / data.previous) * 100);
      }
    } else {
      this.value = (this.value || '').toString().replace('.00', '');
    }
    this.loading = false;
  }
}
