<calimatic-branding></calimatic-branding>
<div class="flex account-info-container">
  <h4 class="mr-3"> {{ message }} </h4>
  @if (loading) {
    <p-progressSpinner
      styleClass="w-2rem h-2rem"
      strokeWidth="5"
      fill="var(--surface-ground)"
      animationDuration=".5s"/>
  }
</div>
