import {Component, effect, input} from '@angular/core';
import {TerminologyPipe} from "../../../../shared/pipes/terminology.pipe";
import {TerminologyEnum, TerminologyWordEnum} from "../../../../shared/enums/terminology.enum";
import {MenuInterface, SubMenuInterface} from "../../../../shared/interfaces/menu.interface";
import {SubMenusEnum} from "../../../../shared/enums/menu.enum";
import {appRoutePaths} from "../../../../app.routes";
import {RouterLink} from "@angular/router";
import {AuthService} from "../../../../core/services/auth/auth.service";
import {JoinClassWidgetComponent} from "../../../../shared/components/join-class-widget/join-class-widget.component";

@Component({
  selector: 'calimatic-quick-links',
  standalone: true,
  imports: [
    TerminologyPipe,
    RouterLink,
    JoinClassWidgetComponent
  ],
  templateUrl: './quick-links.component.html',
  styleUrl: './quick-links.component.scss'
})
export class QuickLinksComponent {
  //input
  menus = input.required<MenuInterface[]>();

  subMenus: SubMenuInterface[] = [];
  terminologyEnum = TerminologyEnum;
  terminologyWordType = TerminologyWordEnum;
  subMenusEnum = SubMenusEnum;
  userRole: number;
  protected readonly appRoutePaths = appRoutePaths;

  constructor(protected authService: AuthService) {
    effect(() => {
      this.subMenus = this.menus().flatMap(x => x.subMenus);
    });
    this.userRole = Number(authService.getUserModal().Role);
  }

  hasMenuPermission(menuCode: number[]) {
    return this.subMenus.some(x => menuCode.includes(x.code));
  }
}
