@if((configs().isSelectable || configs().isGrouped) && configs().floatLabel){
<p-floatLabel>
  <p-multiSelect (ngModelChange)="valueChange.emit($event)"
    [display]="$any(configs().display || multiSelectDisplayEnums.CHIP)" [group]="configs().isGrouped"
    [ngModel]="value()" [optionLabel]="configs().label" [optionValue]="configs().value" [options]="configs().options"
    [placeholder]="configs().placeholder||'Select'" [disabled]="configs().disabled"
    [virtualScroll]="configs().virtualScroll" [virtualScrollItemSize]="configs().virtualScrollItemSize"
    [selectedItemsLabel]="'{0} '+ (configs().selectedItemLabel || ' selected')" [style]="configs().css"
    [appendTo]="configs().appendTo" [styleClass]="configs().buttonClass">
    @if(configs().isGrouped){
    <ng-template let-group pTemplate="group">
      <div class="flex align-items-center">
        <span>{{ group[configs().groupLabel] }}</span>
      </div>
    </ng-template>
    }</p-multiSelect>
  <label for="float-label">{{ configs().floatLabel }}</label>
</p-floatLabel>
}
@else if(configs().isSelectable || configs().isGrouped){
<p-multiSelect (ngModelChange)="valueChange.emit($event)" [group]="configs().isGrouped"
  [display]="$any(configs().display || multiSelectDisplayEnums.CHIP)" [ngModel]="value()"
  [disabled]="configs().disabled" [virtualScroll]="configs().virtualScroll"
  [virtualScrollItemSize]="configs().virtualScrollItemSize" [optionLabel]="configs().label"
  [optionValue]="configs().value" [options]="configs().options" [placeholder]="configs().placeholder||'Select'"
  [selectedItemsLabel]="'{0} '+ (configs().selectedItemLabel || ' selected')" [style]="configs().css"
  [appendTo]="configs().appendTo" [styleClass]="configs().buttonClass">
  @if(configs().isGrouped){
  <ng-template let-group pTemplate="group">
    <div class="flex flex-column">
      <span>{{ group[configs().groupLabel] }}</span>
      @if(!group.items.length) {
        <div class="flex align-items-center justify-content-center" style="min-height: 50px; padding: 1rem;">
          <span class="text-primary font-bold">{{ group.notFoundMessage }}</span>
        </div>
      }
    </div>
  </ng-template>
  }
</p-multiSelect>
}
<!-- @else if(configs().isGrouped){
<p-multiSelect [options]="configs().options" [group]="true"(ngModelChange)="valueChange.emit($event)"
  [ngModel]="value()" [placeholder]="configs().placeholder||'Select'" scrollHeight="250px"
  [disabled]="configs().disabled"
  [appendTo]="configs().appendTo" [styleClass]="configs().buttonClass"
  [display]="$any(configs().display || multiSelectDisplayEnums.CHIP)">
  <ng-template let-group pTemplate="group">
    <div class="flex align-items-center">
      <span>{{ group[configs().groupLabel] }}</span>
    </div>
  </ng-template>
</p-multiSelect>
} -->
@else{
<p-overlayPanel #op styleClass="grid-multiselect">
  <ng-template pTemplate="content">
    @for (item of configs().options; track $index){
    <div class="field-checkbox">
      <p-checkbox [label]="item.nameText" name="group" [value]="item" (ngModelChange)="valueChange.emit($event)"
        [(ngModel)]="value">
        <span class="pi pi-sort-down-fill"></span>
      </p-checkbox>
    </div>
    }
  </ng-template>
</p-overlayPanel>
<!-- <p-button icon="pi pi-bars" (click)="op.toggle($event)" severity="secondary"></p-button> -->
<p-button (click)="op.toggle($event)" label="" id="testButton" severity="secondary"
  [styleClass]="configs().buttonClass">
  @if (configs().dropdownIcon) {
  <span class="icon-set flex align-items-center gap-2">
    <svg class="interface-icon o__standard o__standard__columns" width="16" height="16" viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 13C1.44772 13 1 12.5523 1 12L1 4C1 3.44772 1.44772 3 2 3L3 3C3.55228 3 4 3.44772 4 4L4 12C4 12.5523 3.55229 13 3 13H2Z"
        fill="#1271c4"></path>
      <path
        d="M7 13C6.44772 13 6 12.5523 6 12L6 4C6 3.44772 6.44771 3 7 3L8 3C8.55228 3 9 3.44772 9 4L9 12C9 12.5523 8.55228 13 8 13H7Z"
        fill="#1271c4"></path>
      <path
        d="M12 13C11.4477 13 11 12.5523 11 12V4C11 3.44772 11.4477 3 12 3L13 3C13.5523 3 14 3.44772 14 4V12C14 12.5523 13.5523 13 13 13H12Z"
        fill="#1271c4"></path>
    </svg>
    <i class="fa-solid fa-caret-down"></i>
  </span>
  }
  @else {
  <i class="fa-regular fa-ellipsis-vertical"></i>
  }
</p-button>
}