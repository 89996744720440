import { Component, Input, OnInit, effect, input } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { ChartDataDto } from '../../../../../shared/dtos/chart-data.dto';

@Component({
  selector: 'calimatic-pie-chart',
  standalone: true,
  imports: [
    ChartModule
  ],
  templateUrl: './pie-chart.component.html',
  styleUrl: './pie-chart.component.scss'
})
export class PieChartComponent implements OnInit  {
  data = input.required<ChartDataDto[]>();
  chartData: any;
  chartOpts: any;
  
  constructor() {
    effect(() => {
      if(this.data()) {
        this.chartData = this.prepareChartData(this.data());
      }
    });
  }

  ngOnInit() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    this.chartOpts = {
        plugins: {
            legend: {
                labels: {
                    usePointStyle: true,
                    color: textColor,
                  },
                  position:'left',
            },
        }
    };
}
    
  prepareChartData(data: ChartDataDto[]) {
    return  {
      labels: data.map(x => x.key),
      datasets: [
          {
           data: data.map(x => x.value),
           backgroundColor: ['#00589c', '#016fc4', '#1891c3', '#3ac0da', '#3dc6c3', '#50e3c2'],
          }
      ]
  };
  }
}
