import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { EmbeddedFormService } from '../embedded-form.service';

@Injectable({
  providedIn: 'root'
})
export class ShortUrlRedirectionGuard implements CanActivate {

  constructor(private embeddedFormService: EmbeddedFormService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const id = route.paramMap.get('id');
    const httpResponse = await this.embeddedFormService.getShortUrlRedirection(id)
    if (httpResponse.isSuccessful) {
      this.router.navigateByUrl(httpResponse.response);
        return false; 
      } else {
        console.log("Unable to navigate to the specified route. Please check if the URL is correct or if the route exists.")
        return false; 
      }
  }
}
