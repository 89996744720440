<div class="forgot-password-wrapper">
  <div class="forgot-password-carousel">
    @if (activeSlide == forgotPasswordEnum.UserValidateForm) {
      <div class="fp-item {{activeSlide == forgotPasswordEnum.UserValidateForm ?'isVisible':''}}">
        <form (keyup.enter)="onValidateUser()" [formGroup]="forgotPasswordForm">
          <div class="login-card">
            <div class="form-card-header">
              <span class="form-icon"><i class="fa-solid fa-fingerprint"></i></span>
              <div>
                <h4 class="mb-1">Forgot Password?</h4>
                <p class="text-500">No worries. We'll send you reset instructions.</p>
              </div>
            </div>

            <div class="flex flex-column gap-2">
              <label for="username">Username</label>
              <input autocomplete="off" pInputText required id="username" aria-describedby="username-help"
                     class="p-inputtext-lg {{formControls.userName.invalid && (formControls.userName.dirty || !formControls.userName.untouched) ? 'ng-invalid' : ''}}"
                     formControlName="userName" placeholder="Enter your username"/>
              @if (formControls.userName.invalid && (formControls.userName.dirty ||
                !formControls.userName.untouched)) {
                <small class="form-invalid">Username is required</small>
              }
            </div>

            <div class="flex flex-column gap-2">
              <label for="email">Email</label>
              <input autocomplete="off" pInputText required type="email" id="email" aria-describedby="email-help"
                     class="p-inputtext-lg {{formControls.email.invalid && (formControls.email.dirty || !formControls.email.untouched) ? 'ng-invalid' : ''}}"
                     formControlName="email" placeholder="Enter your email"/>
              @if (formControls.email.invalid && (formControls.email.dirty || !formControls.email.untouched)) {
                <small class="form-invalid">A valid email is required</small>
              }
            </div>

            <button [loading]="isLoading" type="submit" (click)="onValidateUser()"
                    [disabled]="!forgotPasswordForm.valid"
                    [ngStyle]="{ background:companyConfigs().loginButtonColor,borderColor:companyConfigs().loginButtonColor}"
                    class="w-full btn-large clm-primary-btn" label="Reset password" pButton></button>

          </div>
        </form>
      </div>
    }

    @if (activeSlide == forgotPasswordEnum.OtpVerificationForm) {
      <div class="fp-item {{activeSlide == forgotPasswordEnum.OtpVerificationForm ?'isVisible':''}}">
        <div class="login-card">
          <div class="form-card-header">
            <span class="form-icon"><i class="fa-regular fa-envelope-open"></i></span>
            <div>
              <h4 class="mb-1">Password reset</h4>
              <p class="text-500">We've sent a code to <strong>{{ formControls.email.value }}</strong></p>
            </div>
          </div>

          <div class="flex flex-column gap-2">
            <label for="username">Enter OTP code</label>
            <p-inputOtp [length]="6" [(ngModel)]="otpCode" [integerOnly]="true"
                        class="custom-otp-input"/>
          </div>

          @if (isOtpSent) {
            <div class="flex flex-column gap-2 text-center align-items-center">
              @if (otpExpiryTimeLeft >= 0) {
                <span class="text-danger">OTP code will expire in</span>
                <div class="card otp-card">
                  <h5>{{ formattedTime }}</h5>
                </div>
              } @else {
                <span class="text-danger">OTP has expired.
                  <p-button [styleClass]="'resent-otp-link-btn'" [loading]="resendingOtp" (click)="resendOtpCode()"
                            label="Resend OTP" [link]="true"/>
                  </span>
              }
            </div>
          }

          <button type="submit"
                  [loading]="isLoading"
                  [ngStyle]="{background:companyConfigs().loginButtonColor,borderColor:companyConfigs().loginButtonColor}"
                  class="w-full mt-3 btn-large clm-primary-btn" label="Continue" [disabled]="((otpCode||'').length < 6)"
                  (click)="onVerifyOtpCode()" pButton>
          </button>

        </div>
      </div>
    }

    @if (activeSlide == forgotPasswordEnum.PasswordResetForm) {
      <div class="fp-item {{activeSlide == forgotPasswordEnum.PasswordResetForm ?'isVisible':''}}">
        <form (keyup.enter)="resetPassword()" [formGroup]="resetPasswordForm">
          <div class="login-card">
            <div class="form-card-header">
              <span class="form-icon"><i class="fa-regular fa-keyboard"></i></span>
              <div>
                <h4 class="mb-1">Set new password</h4>
                <p class="text-500">Must be at least 8 characters long</p>
              </div>
            </div>

            <div class="flex flex-column gap-2">
              <label for="password">Password</label>
              <p-password [feedback]="false"
                          [ngClass]="{'ng-valid' : resetPasswordFormControls.password.invalid && (resetPasswordFormControls.password.dirty || !resetPasswordFormControls.password.untouched) }"
                          [toggleMask]="true" aria-describedby="password" class="p-inputtext-lg"

                          formControlName="password" id="password" placeholder="Enter your password"
                          (onFocus)="showMessage = false"
                          (onBlur)="showMessage = true;isValidPasswordLength()"></p-password>
              @if (!isValidPassLength) {
                <small class="form-invalid">Password must be 8 characters long</small>
              } @else if (resetPasswordFormControls.password.invalid && (resetPasswordFormControls.password.dirty || !resetPasswordFormControls.password.untouched) && showMessage) {
                <small class="form-invalid">{{ passwordErrorMessage }}</small>
              }

            </div>

            <div class="flex flex-column gap-2">
              <label for="password">Confirm Password</label>
              <p-password [feedback]="false"
                          [ngClass]="{'ng-valid' : resetPasswordFormControls.confirmPassword.invalid && (resetPasswordFormControls.confirmPassword.dirty || !resetPasswordFormControls.confirmPassword.untouched) }"
                          [toggleMask]="true" aria-describedby="password" class="p-inputtext-lg"
                          formControlName="confirmPassword" id="confirmPassword"
                          placeholder="Confirm password"></p-password>
              @if (resetPasswordFormControls.confirmPassword.invalid &&
              (resetPasswordFormControls.confirmPassword.dirty ||
                !resetPasswordFormControls.confirmPassword.untouched)) {
                <small class="form-invalid">Confirm Password is required</small>
              }
              @if (resetPasswordForm.errors?.mismatch && (resetPasswordFormControls.password.valid && resetPasswordFormControls.confirmPassword.valid)) {
                <small class="form-invalid">Passwords do not match</small>
              }
            </div>

            <button [loading]="isLoading" type="submit" (click)="resetPassword()" [disabled]="resetPasswordForm.invalid"
                    [ngStyle]="{background:companyConfigs().loginButtonColor,borderColor:companyConfigs().loginButtonColor}"
                    class="w-full btn-large clm-primary-btn" label="Reset password" pButton>
            </button>

          </div>
        </form>
      </div>
    }
    @if (activeSlide == forgotPasswordEnum.PasswordVerificationComplete) {
      <div class="fp-item {{activeSlide == forgotPasswordEnum.PasswordVerificationComplete ?'isVisible':''}}">
        <div class="login-card">
          <div class="form-card-header">
            <span class="form-icon"><i class="fa-solid fa-check"></i></span>
            <div>
              <h4 class="mb-1">All done!</h4>
              <p class="text-500">Your password has been reset. To continue please click on the login button
                below.</p>
            </div>
          </div>
          <button type="submit" (click)="close.emit(true)"
                  [ngStyle]="{background:companyConfigs().loginButtonColor,borderColor:companyConfigs().loginButtonColor}"
                  class="w-full mt-3 btn-large clm-primary-btn" label="Login" pButton>
          </button>
        </div>
      </div>
    }
  </div>

</div>
