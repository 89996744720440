
import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUploadModule, UploadEvent } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { CustomeURLConfigDto } from '../../../../../shared/dtos/on-board.dto';
import { AppConstants, AppTokens } from '../../../../../shared/utils/global';
import { SystemConfigService } from '../../../../../core/services/system-config.service';
import { IToaster } from '../../../../../shared/interfaces/toaster/IToaster';
import { ToasterTypeEnum } from '../../../../../shared/enums/toaster-type.enum';
import { OnboardService } from '../../../../../core/services/onboard.service';
import { OnboardSetionStepsEnum } from '../../../../../shared/enums/onboard.enum';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { TooltipModule } from 'primeng/tooltip';
import { companyConfigStore } from '../../../../../core/stores/company-config-store';

@Component({
  selector: 'calimatic-custom-url',
  standalone: true,
  imports: [DialogModule, ButtonModule, FormsModule, InputTextModule, FileUploadModule, FloatLabelModule, ReactiveFormsModule, InputGroupModule, InputGroupAddonModule, TooltipModule  ],
  templateUrl: './custom-url.component.html',
  styleUrl: './custom-url.component.scss',
  providers: [DialogService]
})
export class CustomUrlComponent {
  portalUrlPrefix:string  = 'https://portal.';
  secureUrlPrefix:string = 'https://secure.'

  companyConfigsStore = inject(companyConfigStore)
  favIcon:File;
  customUrlConfig: FormGroup = new FormGroup({
    portalUrl: new FormControl('', [Validators.required,Validators.pattern(AppConstants.regularExpressions.domain)]),
    secureUrl: new FormControl('', [Validators.required, Validators.pattern(AppConstants.regularExpressions.domain)]),
    // browserTitle: new FormControl('', Validators.required)
  })

  constructor(@Inject(AppTokens.toaster) private toasterService: IToaster, public ref: DynamicDialogRef, private systemConfigService:SystemConfigService, private onboardService:OnboardService) {}

  closeModal(){
    this.ref.close(true);
  }

  onUpload(event: any) {  
    if(event && (event?.files||[]).length){
      let file = event?.files[0];
      if(file &&  file.size > 1000000){
        this.toasterService.show(ToasterTypeEnum.Error, 'Success', 'Image size should 1mb')
        return;
      }
      this.favIcon = file;

    }
  }

  get formControls () {
    return this.customUrlConfig.controls;
  }

  async onSubmitCustomURLConfigs() {
    if(this.customUrlConfig.valid){
      
      let formValues = this.customUrlConfig.value as CustomeURLConfigDto;
      formValues.emailTo = AppConstants.customerSupportMail;
      formValues.favIcon = this.companyConfigsStore.configs().brandingFavicon;
      formValues.browserTitle = this.companyConfigsStore.configs().brandingTitle; 
      formValues.portalUrl = this.portalUrlPrefix + formValues.portalUrl + '.com'
      formValues.secureUrl = this.secureUrlPrefix + formValues.secureUrl + '.com'
      
      let res = await this.systemConfigService.sendEmailForCustomURL(formValues);
      if(res.isSuccessful){
        // when user make any minor change or send save call then this step will be consider as complete for On board.  
        this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.CustomURL);
        this.toasterService.show(ToasterTypeEnum.Success, 'Success', 'Custom url configs email sent successfully')
        this.closeModal();
      }
      
    }
  }
}


