export enum TerminologyEnum {
  Assessment = "Assessment",
  Question = "Question",
  Template = "Template",
  Type = "Type(Event)",
  Category = "Category(Event)",
  CourseType = "Type(Course)",
  CourseCategory = "Category(Course)",
  Course = "Course",
  Level = "Level",
  Clas = "Class",
  Challenge = "Challenge",
  ChallengeStep = "Step(Challenge)",
  Franchise = "Franchise",
  Coupon = "Coupon",
  Family = "Family",
  Session = "Session",
  Event = "Event",
  Activity = "Activity",
  Announcement = "Announcement"
}

export enum TerminologyWordEnum {
  SINGULAR = 'singular',
  PLURAL = 'plural'
}
