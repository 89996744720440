import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OnboardService {
  private OnsaveSubject = new Subject<any>();
  saveObservable = this.OnsaveSubject.asObservable();

  OnEmitOnboardStepSave(value: number) {
    this.OnsaveSubject.next(value);
  }
}
