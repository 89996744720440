import {Component, input, output} from '@angular/core';
import {DashboardTrioWidgetsDTO, DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";
import {DashboardService} from "../../../../../core/services/dashboard.service";
import {DashboardWidgetSymbolEnum} from "../../../../../shared/enums/dashboard/dashboard-widgets.enum";
import {CounterTrioSkeletonComponent} from "../skeletons/counter-trio-skeleton/counter-trio-skeleton.component";
import {NgTemplateOutlet} from "@angular/common";
import {TooltipModule} from 'primeng/tooltip';

@Component({
  selector: 'calimatic-counter-trio-widget',
  standalone: true,
  imports: [
    CounterTrioSkeletonComponent,
    NgTemplateOutlet,
    TooltipModule
  ],
  templateUrl: './counter-trio-widget.component.html',
  styleUrl: './counter-trio-widget.component.scss'
})
export class CounterTrioWidgetComponent {
  widget = input.required<DashboardWidgetsDTO>();
  edit = input.required<boolean>();
  onRemove = output();

  //local
  loading = true;
  data: DashboardTrioWidgetsDTO | null;
  protected readonly widgetSymbolEnum = DashboardWidgetSymbolEnum;

  constructor(private dashboardService: DashboardService) {
  }

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    this.loading = true;
    this.data = await this.dashboardService.getTrioWidgetsData(this.widget().code);
    this.loading = false;
  }
}
