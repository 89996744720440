export enum AttendanceTypeEnum {
  Present = 1,
  Absent,
  Casual,
  Trial,
  Makeup,
  CancelledClass,
  LeftEarly,
  ExpectedAbsent,
  FirstDayEver,
  FirstDayAtLocation,
  FirstDayInClass,
  FirstDayInCourse,
  FirstDayInLevel,
  ExcuseAbsent,
  UnExcuseAbsent,
  ArrivedLate
}
