export class ForgotPasswordDTO {
  userName: string = "";
  email: string = "";
}

export class ResetPasswordDTO {
  password: string = "";
  confirmPassword: string = "";
  userGuid: string = "";
  companyUrl: string = "";
}
