import {Injectable} from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class BrowserStorageService {
  Add(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  Get(key: string): string {
    return localStorage.getItem(key) || '';
  }

  Delete(key: string) {
    localStorage.removeItem(key);
  }
}
