import {Component, inject, OnInit} from '@angular/core';
import {StandardTemplateComponent} from './login-templates/standard-template/standard-template.component';
import {companyConfigStore} from "../../../core/stores/company-config-store";
import {LoginTemplateEnums} from "../../../shared/enums/login.enum";
import {NgTemplateOutlet} from "@angular/common";
import {AuthService} from "../../../core/services/auth/auth.service";
import {ToastModule} from "primeng/toast";
import {MenuService} from "../../../core/services/menu.service";
import {DefaultTemplateComponent} from './login-templates/default-template/default-template.component';
import {BrandingComponent} from "../../../shared/components/branding/branding.component";

@Component({
  selector: 'calimatic-login',
  standalone: true,
  imports: [
    StandardTemplateComponent,
    NgTemplateOutlet,
    ToastModule,
    DefaultTemplateComponent,
    BrandingComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  configStore = inject(companyConfigStore);
  authService = inject(AuthService);
  menuService = inject(MenuService);
  loginTemplateEnums = LoginTemplateEnums;

  async ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.menuService.goToDashboard();
    }
  }
}
