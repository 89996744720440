<div>
  <div class="p-inputgroup">
    <input (ngModelChange)="searchIcons($event)" [ngModel]="iconSearch" class="w-full"
           pInputText placeholder="Search icon" type="text">
    <p-inputGroupAddon>
      <i class="fa fa-search font-sm"></i>
    </p-inputGroupAddon>
  </div>
  <div class="icon-list-wrapper gap-3 mt-2">
    @for (icon of icons; track $index) {
      <span (click)="selectedIcon.emit(icon)" class="flex align-items-center justify-content-center p-2 cursor-pointer"
            title="fa {{icon}}">
       <i class=" fa {{icon}}"></i>
      </span>
    }
  </div>
</div>
