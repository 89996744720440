import {Component, input, OnInit, output} from '@angular/core';
import {PieChartComponent} from '../../charts/pie-chart/pie-chart.component';
import {ChartDataDto} from '../../../../../shared/dtos/chart-data.dto';
import {DashboardService} from '../../../../../core/services/dashboard.service';
import {ChartSkeletonComponent} from '../skeletons/chart-skeleton/chart-skeleton.component';
import {TooltipModule} from 'primeng/tooltip';
import {DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";

@Component({
  selector: 'calimatic-course-type-widget',
  standalone: true,
  imports: [PieChartComponent, ChartSkeletonComponent, TooltipModule],
  templateUrl: './course-type-widget.component.html',
  styleUrl: './course-type-widget.component.scss'
})
export class CourseTypeWidgetComponent implements OnInit {
  widget = input.required<DashboardWidgetsDTO>();
  edit = input.required();
  onRemove = output();

  //local
  chartData: ChartDataDto[];
  loading = false;

  constructor(private dashboardService: DashboardService) {
  }

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    this.loading = true;
    this.chartData = await this.dashboardService.getActiveCoursesCountByType();
    this.loading = false;
  }
}
