import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {AppConstants, AppTokens} from '../../utils/global';
import {IToaster} from '../../interfaces/toaster/IToaster';
import {ToasterTypeEnum} from '../../enums/toaster-type.enum';
import {SystemConfigService} from '../../../core/services/system-config.service';
import {ManageEmailImageConfigDto} from '../../dtos/on-board.dto';
import {ModuleConfig} from '../../enums/mics-enums/module.enum';
import {TerminologyEnum} from '../../enums/terminology.enum';
import {TerminologyPipe} from '../../pipes/terminology.pipe';
import {SkeletonComponent} from '../skeleton/skeleton.component';

@Component({
  selector: 'calimatic-manage-email-image',
  standalone: true,
  imports: [ButtonModule, SkeletonComponent],
  templateUrl: './manage-email-image.component.html',
  styleUrl: './manage-email-image.component.scss',
  providers: [TerminologyPipe],
})
export class ManageEmailImageComponent {
  configs: ManageEmailImageConfigDto = {} as ManageEmailImageConfigDto;
  @Input() backlogType: number = ModuleConfig.Course;
  logoLoader: boolean = false;
  resetLoader: boolean = false;
  allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  isDataLoaded: boolean = false;
  isProcessing: boolean = false;

  @Output() onCloseModal: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(AppTokens.toaster) private toasterService: IToaster, private systemConfigService: SystemConfigService, private terminologyPipe: TerminologyPipe) {
  }

  async ngOnInit() {
    await this.getEmailImage(this.backlogType);
  }

  async getEmailImage(backlogType: number) {
    this.setUpImageConfigs();
    this.isDataLoaded = false
    let imageUrl = await this.systemConfigService.getCompletionEmailImage(backlogType);
    this.configs.image = imageUrl;
    this.isDataLoaded = true;
  }

  async resetDefaultImage() {
    this.isProcessing = true
    const apiResponse = await this.systemConfigService.addUpdateCompletionImage(null, this.backlogType);
    if (apiResponse.isSuccessful) {
      this.configs.image = apiResponse.response;
    }
    this.isProcessing = false;
  }

  async changeImage(event: any) {
    const input = event?.target as HTMLInputElement;
    let ImageFile = (input && (input?.files || []).length > 0) ? input.files[0] : null;
    if (ImageFile) {
      if (!this.allowedImageTypes.includes(ImageFile.type)) {
        this.toasterService.show(ToasterTypeEnum.Error, 'Error', 'Only jpeg jpg and png file acceptable');
        return;
      }
      // if(brandlogoFile?.size > this.companyLogoMaxSize) {
      //   this.toasterService.show(ToasterTypeEnum.Error, 'Error', 'Image size should not exceed above 200kb');
      //   return;
      // }
      this.logoLoader = true;
      await this.onUploadImage(ImageFile);
      this.logoLoader = false;
    }
  }

  closeModal() {
    this.onCloseModal.emit();
  }

  setUpImageConfigs() {

    switch (this.backlogType) {
      case ModuleConfig.Course:
        this.configs = this.patchImageConfigsValues(AppConstants.defaultConfigs.courseCompletion);
        this.configs.title = `Manage ${this.terminologyPipe.transform('course', TerminologyEnum.Course)} Completetion Image`;
        break;
      case ModuleConfig.Level:
        this.configs = this.patchImageConfigsValues(AppConstants.defaultConfigs.levelCompletion,);
        this.configs.title = `Manage ${this.terminologyPipe.transform('level', TerminologyEnum.Level)} Completetion Image`;

        break;
      case ModuleConfig.Challange:
        this.configs = this.patchImageConfigsValues(AppConstants.defaultConfigs.challengeCompletion);
        this.configs.title = `Manage ${this.terminologyPipe.transform('challenge', TerminologyEnum.Challenge)} Completetion Image`;
        break;
    }
  }

  patchImageConfigsValues(defaultImage: string) {
    let imageConfigs: ManageEmailImageConfigDto = new ManageEmailImageConfigDto();
    imageConfigs.defaultImage = defaultImage;
    imageConfigs.description = 'The feature allows educators to customize images for certificates, enhancing the recognition of student achievements.'
    return imageConfigs;

  }

  async onUploadImage(image: File) {
    this.isProcessing = true;
    const apiResponse = await this.systemConfigService.addUpdateCompletionImage(image, this.backlogType);
    if (apiResponse.isSuccessful) {
      this.configs.image = apiResponse.response;
    }
    this.isProcessing = false;
  }

}
