import {patchState, signalStore, withHooks, withMethods, withState} from "@ngrx/signals";
import {MenuInterface} from "../../shared/interfaces/menu.interface";
import {inject} from "@angular/core";
import {MenuService} from "../services/menu.service";
import {AuthService} from "../services/auth/auth.service";

type MenuState = {
  menus: MenuInterface[];
}
export const menuStore = signalStore({providedIn: 'root'},
  withState<MenuState>({menus: [] as MenuInterface[]}),
  withMethods((state) => {
    const menuService = inject(MenuService);
    const authService = inject(AuthService);
    return {
      async loadMenus() {
        if (!authService.isAuthenticated()) return;

        this.clearState();
        const userModel = authService.getUserModal();
        const menus = await menuService.getMenus(userModel.CompanyGuid);
        if (menus?.length) {
          patchState(state, {menus});
        }
      },
      clearState() {
        patchState(state, {menus: []});
      }
    }
  }),
  withHooks({
    async onInit(state) {
      await state.loadMenus();
    },
    onDestroy(state) {
      state.clearState();
    }
  })
)
