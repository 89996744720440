@if (loading) {
  <calimatic-counter-skeleton class="w-full"></calimatic-counter-skeleton>
} @else {
  @if (data) {
    <div class="dashboard__announcement h-full">
      <div [id]="announcementId" [ngStyle]="{height:cardHeight}"
           class="card align-items-center justify-content-center">
        <div class="card-action-wrapper">
             <span (click)="loadData()" class="action_btn refresh-btn" [pTooltip]="'Refresh widget'"
                   tooltipPosition="bottom"
                   [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-rotate"></i></span>
          @if (edit()) {
            <span (click)="onRemove.emit()" class="action_btn" [pTooltip]="'Remove widget'" tooltipPosition="bottom"
                  [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-xmark"></i></span>
          }
        </div>
        <div class="card-inner">
          <div class="ann_header cursor-pointer "
               (click)="onSectionToggle()">
                  <span class="ann_icon">
                    <i class="fa-solid fa-bullhorn"></i>
                    @if ((data.announcements || []).length) {
                      <span class="ann_badge">{{ data.announcements.length }}</span>
                    }
                  </span>
            <div class="ann_content">
              <h4
                class="mb-0">{{ ('Announcements' | terminology:terminologyEnum.Announcement: terminologyWordType.PLURAL) }}</h4>
              <small class="mb-2 tagline">Notify you about important updates and news.</small>
              @if (!(data.announcements || []).length) {
                <p>Currently there are
                  no {{ ('Announcements' | terminology:terminologyEnum.Announcement: terminologyWordType.PLURAL)?.toLocaleLowerCase() }}
                  available.</p>
              } @else {
                <p>Currently there are
                  <strong>{{ data.announcements.length }}</strong> {{ ('Announcements' | terminology:terminologyEnum.Announcement: terminologyWordType.PLURAL)?.toLocaleLowerCase() }}
                  available.</p>
              }
            </div>
          </div>
          <div class="ann_content mt-3 {{data.expandSection ? 'ann_content_height' : 'hidden'}}">
            @if ((data.announcements || []).length) {
              @for (item of data.announcements; track item.id) {
                <div class="ann-accor-wrap  border-round"
                     (click)="onChildSectionToggle(item)">
                  <p
                    class="ann_item_header styled-box-green cursor-pointer p-3 flex justify-content-between"
                    pRipple> {{ item.title }}
                    <span class="fa fa-chevron-right {{item.expanded?'rotate-90':''}}"></span></p>
                  @if (item.expanded) {
                    <div
                      class="ann_item_content border-top-1 border-gray-200 mb-3 p-3 border-round-bottom"
                      [innerHTML]="item.description">
                    </div>
                  }
                </div>
              }

              <!--              <p-accordion (onOpen)="toggleAfter(400)" class="w-full block mb-3" [multiple]="true"-->
                <!--                           [activeIndex]="activeAnnouncements">-->
                <!--                @for (item of data.announcements; track item.id) {-->
                <!--                  <p-accordionTab>-->
                <!--                    <ng-template pTemplate="header">-->
                <!--                      <div class="ann_item_header">-->
                <!--                        <i class="fa-solid fa-plus mr-2"></i>-->
                <!--                        <i class="fa-solid fa-minus mr-2"></i>-->
                <!--                        <span>{{ item.title }}</span>-->
                <!--                      </div>-->
                <!--                    </ng-template>-->
                <!--                    <div class="ann_item_content" [innerHTML]="item.description">-->
                <!--                    </div>-->
                <!--                  </p-accordionTab>-->
                <!--                }-->
                <!--              </p-accordion>-->
            }
          </div>
        </div>
      </div>
    </div>
  }
}
