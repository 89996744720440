import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {AccordionModule} from 'primeng/accordion';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {companyConfigStore} from '../../../../core/stores/company-config-store';
import {SidebarModule} from 'primeng/sidebar';
import {TerminologiesComponent} from '../../system-config/terminologies/terminologies.component';
import {
  OnboardComponentsEnum,
  OnboardSectionEnum,
  OnboardSectionNamesEnum,
  OnboardSetionStepsEnum,
  OnboardSetionStepsNameEnum
} from '../../../../shared/enums/onboard.enum';
import {OnboardExtenedDto, OnboardStepsDto} from '../../../../shared/dtos/on-board.dto';
import {EmailConfigComponent} from '../../system-config/communication/email/email-configs.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {
  TransactionConfigComponent
} from "../../system-config/integrations/transaction-config/transaction-config.component";
import {AuthService} from "../../../../core/services/auth/auth.service";
import {UserRoleEnum} from "../../../../shared/enums/user-role.enum";
import {CheckboxModule} from 'primeng/checkbox';
import {NgClass} from '@angular/common';
import {CustomUrlComponent} from './custom-url/custom-url.component';
import {CustomMobileAppComponent} from './custom-mobile-app/custom-mobile-app.component';
import {SystemConfigService} from '../../../../core/services/system-config.service';
import {OnboardService} from '../../../../core/services/onboard.service';
import {Subscription} from 'rxjs';
import {SkeletonModule} from 'primeng/skeleton';
import {TableModule} from 'primeng/table';
import {SkeletonComponent} from "../../../../shared/components/skeleton/skeleton.component";
import {SkeletonLoader} from '../../../../shared/animation/animation';
import {BadgeModule} from 'primeng/badge';
import {StepsModule} from 'primeng/steps';
import {StepperModule} from 'primeng/stepper';
import {AppConstants} from '../../../../shared/utils/global';
import {TerminologyEnum} from "../../../../shared/enums/terminology.enum";
import {TerminologyPipe} from "../../../../shared/pipes/terminology.pipe";
import { Router } from '@angular/router';
import { appRoutePaths } from '../../../../app.routes';
import { PlatformBrandingComponent } from '../../system-config/general/platform-branding/platform-branding.component';

declare var Calendly: any;


@Component({
  selector: 'calimatic-user-guide',
  standalone: true,
  templateUrl: './user-guide.component.html',
  styleUrl: './user-guide.component.scss',
  providers: [DialogService],
  animations: [SkeletonLoader],
  imports: [
    DialogModule,
    ButtonModule,
    AccordionModule,
    DropdownModule,
    FormsModule,
    InputSwitchModule,
    SidebarModule,
    PlatformBrandingComponent,
    TerminologiesComponent,
    EmailConfigComponent,
    CheckboxModule,
    NgClass,
    SkeletonModule,
    TableModule,
    SkeletonComponent,
    BadgeModule,
    StepsModule,
    StepperModule,
    TerminologyPipe
  ]
})
export class UserGuideComponent implements OnInit {
  @Input() dialogVisible: boolean = false;
  @Output() onDiagCloseEmiter: EventEmitter<boolean> = new EventEmitter(false);
  companyConfigStore = inject(companyConfigStore);

  onBoardSectionEnum = OnboardSectionEnum;
  stepsEnum = OnboardSetionStepsEnum;
  onboardSections: OnboardExtenedDto[] = [] as OnboardExtenedDto[]
  steps: OnboardStepsDto[] = [] as OnboardStepsDto [];
  //open Modals for required components
  completedCount: number = 0;
  sysConfigComponentEnum = OnboardComponentsEnum;

  franchiseManagement: boolean = false;
  // ref: DynamicDialogRef | undefined;
  userRole: number;
  userRoles = UserRoleEnum;
  isMarkedCompleted: boolean = false;
  activeTabIndex: number = 0;
  onboardStepSub: Subscription = null as any;
  isDataLoaded: boolean = false;
  protected readonly terminologyEnum = TerminologyEnum;

  constructor(public dialogService: DialogService, private authService: AuthService, private sysemConfigService: SystemConfigService, private onboardService: OnboardService,private router:Router) {
  }

  async ngOnInit() {
    this.userRole = Number(this.authService.getUserModal().Role);
    await this.getOnboardSections();
    this.isDataLoaded = true;
    this.onBoardStepsEmitter();
  }

//#region Open Components through Dialog
  openEmailConfigPopup() {
    this.router.navigate([appRoutePaths.dashboard]);
    let ref: DynamicDialogRef | undefined
    ref = this.dialogService.open(EmailConfigComponent, {
      header: 'Email Configuration',
      contentStyle: {overflow: 'auto'},
      styleClass: 'clm-full-w-modal',
    })
  }

  openAppImageConfigPopup() {
    let ref: DynamicDialogRef | undefined
    ref = this.dialogService.open(PlatformBrandingComponent, {
      header: 'Branding Configuration',
      contentStyle: {overflow: 'auto'},
      styleClass: 'clm-full-w-modal'
    });
  }

  openCustomUrlConfigPopup() {
    let ref: DynamicDialogRef | undefined
    ref = this.dialogService.open(CustomUrlComponent, {
      // header: 'Custom url Configuration',
      showHeader: false,
      styleClass: 'clm-sm-modal clm-modal-dialog'
    });

  }

  openmobileAppConfigPopup() {
    let ref: DynamicDialogRef | undefined
    ref = this.dialogService.open(CustomMobileAppComponent, {
      // header: 'Custom url Configuration',
      showHeader: false,
      styleClass: 'clm-md-modal clm-modal-dialog'
    })
  }

  openTerminologyPopup() {
    let ref: DynamicDialogRef | undefined
    ref = this.dialogService.open(TerminologiesComponent, {
      header: 'Termininology Configuration',
      contentStyle: {overflow: 'auto'},
      styleClass: 'clm-full-w-modal'
    });
  }


//#endregion

  openTransactionConfigPopup() {
    let ref: DynamicDialogRef | undefined
    ref = this.dialogService.open(TransactionConfigComponent, {
      header: 'Payment Methods and Settings',
      showHeader: false,
      // contentStyle: {overflow: 'auto'},
      styleClass: 'clm-md-modal clm-modal-dialog no-footer'
    })
  }

  activeIndexChange(index: any) {
    this.activeTabIndex = index;
  }

  // for making template code  dynamically we have created dynamic sections. which will render through for loop initially
  initOnboardSectionData() {
    let sections: OnboardExtenedDto[] = [
      {
        id: 0,
        isLoading: false,
        code: OnboardSectionEnum.Module,
        name: OnboardSectionNamesEnum.Module,
        active: true,
        complete: false,
        steps: [{
          id: 0,
          isLoading: false,
          code: OnboardSetionStepsEnum.FranchiseManagementModule,
          name: OnboardSetionStepsNameEnum.FranchiseManagementModule,
          sectionCode: OnboardSectionEnum.Module,
          complete: false
        }, {
          id: 0,
          isLoading: false,
          code: OnboardSetionStepsEnum.LMSModule,
          name: OnboardSetionStepsNameEnum.LMSModule,
          sectionCode: OnboardSectionEnum.Module,
          complete: false
        },
          {
            id: 0,
            isLoading: false,
            code: OnboardSetionStepsEnum.BillingAndPaymentsModule,
            name: OnboardSetionStepsNameEnum.BillingAndPaymentsModule,
            sectionCode: OnboardSectionEnum.Module,
            complete: false
          },]
      },
      {
        id: 0,
        isLoading: false,
        code: OnboardSectionEnum.whiteLabeling,
        name: OnboardSectionNamesEnum.whiteLabeling,
        active: false,
        complete: false,
        steps: [{
          id: 0,
          isLoading: false,
          code: OnboardSetionStepsEnum.PlatformBrading,
          name: OnboardSetionStepsNameEnum.PlatformBrading,
          sectionCode: OnboardSectionEnum.whiteLabeling,
          complete: false
        }, {
          id: 0,
          isLoading: false,
          code: OnboardSetionStepsEnum.EmailConfig,
          name: OnboardSetionStepsNameEnum.EmailConfig,
          sectionCode: OnboardSectionEnum.whiteLabeling,
          complete: false
        }, {
          id: 0,
          isLoading: false,
          code: OnboardSetionStepsEnum.CustomURL,
          name: OnboardSetionStepsNameEnum.CustomURL,
          sectionCode: OnboardSectionEnum.whiteLabeling,
          complete: false
        }, {
          id: 0,
          isLoading: false,
          code: OnboardSetionStepsEnum.BrandedMobileApp,
          name: OnboardSetionStepsNameEnum.BrandedMobileApp,
          sectionCode: OnboardSectionEnum.whiteLabeling,
          complete: false
        }]
      },
      {
        id: 0,
        isLoading: false,
        code: OnboardSectionEnum.Terminologies,
        name: OnboardSectionNamesEnum.Terminologies,
        active: false,
        complete: false,
        steps: [{
          id: 0,
          isLoading: false,
          code: OnboardSetionStepsEnum.Terminology,
          name: OnboardSetionStepsNameEnum.Terminology,
          sectionCode: OnboardSectionEnum.Terminologies,
          complete: false
        }]
      },
      {
        id: 0,
        isLoading: false,
        code: OnboardSectionEnum.MarketPlaceSteps,
        name: OnboardSectionNamesEnum.MarketPlaceSteps,
        active: false,
        complete: false,
        steps: [{
          id: 0,
          isLoading: false,
          code: OnboardSetionStepsEnum.TransactionInformation,
          name: OnboardSetionStepsNameEnum.TransactionInformation,
          sectionCode: OnboardSectionEnum.MarketPlaceSteps,
          complete: false
        }, {
          id: 0,
          isLoading: false,
          code: OnboardSetionStepsEnum.paymentInformation,
          name: OnboardSetionStepsNameEnum.paymentInformation,
          sectionCode: OnboardSectionEnum.MarketPlaceSteps,
          complete: false
        }]
      }
    ];
    return sections;
  }

  // this method will run when user click in save and next button  
  async onModuleComplete(section: OnboardExtenedDto) {
    section.complete = true;
    // this method is responsiple for saving current section as complete along with it's steps
    await this.onSectionOrStepCompletation(section);
   
    // mark On boarding as complete if all section has been save and complete or all section are mark as green.
    await this.markOnboardingAsComplete();
  
    // if section is last or where you see finsh buttn then we have to close popup on that.
    if (section.code == OnboardSectionEnum.MarketPlaceSteps) {
      this.onDiagCloseEmiter.emit(false);
    }
  }
 
  async markOnboardingAsComplete(){
    //checking if any section is incomplete if not found any incomplete section then mark on boarding as complete.
    if(!((this.onboardSections || []).some(x=>!x.complete))){
     await this.sysemConfigService.saveIsOnOnboardigComplete(true);
    } 
 
  }

  async onSectionOrStepCompletation(section: OnboardExtenedDto) {
    if (!section.isLoading) {
      section.isLoading = true
      let res = await this.sysemConfigService.saveUserGuideSectionAndSteps(section);
      if (res.isSuccessful) {
        await this.getOnboardSections();
        section.isLoading = false
      }
    }
  }

  async getOnboardSections() {
    let res = await this.sysemConfigService.getUserGuide();
    //in this line respose will give us list of saved section in db along with it's steps, and we will concat
    // those section which are coming from db and filter fronted section where sections present in res should be removed from InitOnboardSectionData;
    if((res ||[]).length > 0) {
      const initSections = this.initOnboardSectionData();
    // Filter initSections to remove those that are present in res based on 'code'
      const filteredSections = initSections.filter(sec => !res.some(resSec => resSec.code === sec.code));
      initSections.forEach(x=>{x.active = false});
    // Combine the filtered sections with the response
      this.onboardSections = [...filteredSections, ...res];
      let updatedSection = (this.onboardSections || []).find(x => x.active);

      //if current section is mark as commplete then we have to display next section open
      if (updatedSection && updatedSection.complete) {
        // this.activeTabIndex = (this.onboardSections || []).some(x => !x.complete) ? this.onboardSections.find(x => !x.complete)?.code : OnboardSectionEnum.MarketPlaceSteps;
        this.setIndexAfterSectionComplete(updatedSection.code);
      } else {
        this.activeTabIndex = updatedSection.code || 0;
      }
      // user can attempt mark as complete any section first, so in db that section will store first,  need to sort list based on our UI requirements.
      (this.onboardSections || []).sort((a, b) => a.code - b.code);
      this.completedCount = (this.onboardSections.filter(x => x.complete) || []).length;
    }else{
     this.onboardSections =  this.initOnboardSectionData();
    }
    this.showRoleWiseSection();
  }

  showRoleWiseSection() {
    if (!(this.userRole == UserRoleEnum.BusinessAdmin)) {
      this.onboardSections = this.onboardSections.filter(x => x.code == OnboardSectionEnum.MarketPlaceSteps);
      this.activeTabIndex = 0;
    }
  }


  setIndexAfterSectionComplete(sectionCode: number) {  
    // Check if all sections are complete  
    if (!this.onboardSections.some(x => !x.complete)) {  
      this.activeTabIndex = OnboardSectionEnum.MarketPlaceSteps;  
      return;  
    }  
  
    // Helper function to find a section by code  
    const findSection = (code: number) => this.onboardSections.find(x => x.code === code);  
    const section = findSection(sectionCode);  
    if (!section) return; // Invalid section code, exit function  
  
    // If the current section is complete, move to the next section  
    if (section.complete) {  
      const nextSectionMap = {  
        [OnboardSectionEnum.Module]: OnboardSectionEnum.whiteLabeling,  
        [OnboardSectionEnum.whiteLabeling]: OnboardSectionEnum.Terminologies,  
        [OnboardSectionEnum.Terminologies]: OnboardSectionEnum.MarketPlaceSteps,  
        [OnboardSectionEnum.MarketPlaceSteps]: OnboardSectionEnum.Module  
      } as any;  
      
      this.setIndexAfterSectionComplete(nextSectionMap[sectionCode]);  
    } else {  
      // Current section is not complete, set active tab index to the current section  
      this.activeTabIndex = sectionCode;  
    }  
  }

  markStepAsComplete(section: OnboardExtenedDto, stepCode: number) {
    (section.steps || []).forEach((step: OnboardStepsDto) => {
      if (step.code == stepCode) {
        step.complete = true;
      }
    })
  }

  onBoardStepsEmitter() {
    this.onboardStepSub = this.onboardService.saveObservable.subscribe(stepcode => {
      this.performOnBoardSteps(stepcode)
    })
  }

  performOnBoardSteps(stepcode: number) {
    const allSteps = (this.onboardSections || []).flatMap(section => section.steps);
    const specificStep = (allSteps || []).find(step => step.code === stepcode);
    // No need to send call on db if step is already completed
    if (!specificStep.complete) {
      let section = (this.onboardSections || []).find(x => x.steps.some(s => s.code == stepcode));
      this.markStepAsComplete(section, stepcode);
      // this.onSectionOrStepCompletation(section);
    }
  }

  onActiveStepChange(event: any) {
    this.activeTabIndex = event;
  }

  goToCalimaticHelpCenter() {
    window.open(AppConstants.calimaticHelpUrl, '_blank');
  }

  startMeeting() {
    this.initCalendly();
  }

  initCalendly() {
    // Create and append the script for the Calendly widget
    const script = document.createElement('script');
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;

    // Define what happens when the script loads successfully
    script.onload = () => {
      console.log('Calendly script loaded successfully');

      // Optionally, you can open the Calendly popup directly
      Calendly.initPopupWidget({url: 'https://calendly.com/hello-p0/custom-onboarding'});
    };

    // Handle script loading errors
    script.onerror = () => {
      console.error('Failed to load the Calendly script');
    };

    // Append the script to the document body
    document.body.appendChild(script);

    // Create and append the link for the Calendly stylesheet
    const link = document.createElement('link');
    link.href = 'https://assets.calendly.com/assets/external/widget.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }

  gotoSection(sectionCode: number) {
    this.activeTabIndex = sectionCode;
  }

  ngOnDestroy() {
    this.onboardStepSub?.unsubscribe();
  }
}
