import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { AppConstants, AppTokens } from '../../../../../shared/utils/global';
import { MobileAppConfigDto } from '../../../../../shared/dtos/on-board.dto';
import { SystemConfigService } from '../../../../../core/services/system-config.service';
import { ToasterTypeEnum } from '../../../../../shared/enums/toaster-type.enum';
import { IToaster } from '../../../../../shared/interfaces/toaster/IToaster';
import { OnboardSetionStepsEnum } from '../../../../../shared/enums/onboard.enum';
import { OnboardService } from '../../../../../core/services/onboard.service';
import { ValidateInputDirective } from '../../../../../shared/directives/validate-input.directive';

@Component({
  selector: 'calimatic-custom-mobile-app',
  standalone: true,
  imports: [DialogModule, ButtonModule, FormsModule, InputTextModule, FileUploadModule, FloatLabelModule, ReactiveFormsModule, ValidateInputDirective],
  templateUrl: './custom-mobile-app.component.html',
  styleUrl: './custom-mobile-app.component.scss'
})
export class CustomMobileAppComponent {
  value: string | undefined;

  brandedMobileAppForm: FormGroup = new FormGroup({
    applicationName: new FormControl('', [Validators.required]),
    subtitle: new FormControl('', [Validators.required]),
    fName: new FormControl('', [Validators.required]),
    lName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required,Validators.pattern(AppConstants.regularExpressions.email)]),
    phone: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    privacyPolicyUrl: new FormControl('', [Validators.required,Validators.pattern(AppConstants.regularExpressions.url)]),
  })

  constructor(@Inject(AppTokens.toaster) private toasterService: IToaster, private ref: DynamicDialogRef, private systemConfigService:SystemConfigService,private onboardService:OnboardService) {}

  closeModal(){
    this.ref.close(true);
  }

  get formControls() {
    return this.brandedMobileAppForm.controls
  }

  async onSubmitMobileConfig() {
    if(this.brandedMobileAppForm.valid){
      this.closeModal();

    let formValues = this.brandedMobileAppForm.value as MobileAppConfigDto;  
    formValues.emailTo = AppConstants.customerSupportMail;
    let res = await this.systemConfigService.sendMobileAppConfiEmail(formValues);  
    if(res.isSuccessful){
    // when user make any minor change or send save call then this step will be consider as complete for On board.  
    this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.BrandedMobileApp);
    this.toasterService.show(ToasterTypeEnum.Success, 'Success', 'Mobile App Update mail sent Successfully');
    }
}
  }
  
}
