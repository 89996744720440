@if (loading) {
  <calimatic-counter-skeleton></calimatic-counter-skeleton>
} @else {
  <div class="card _topcards">
    <div class="card-action-wrapper">
      <span (click)="loadData()" class="action_btn refresh-btn" [pTooltip]="'Refresh widget'" tooltipPosition="bottom"
            [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-rotate"></i></span>
      @if (edit()) {
        <span (click)="onRemove.emit()" class="action_btn" [pTooltip]="'Remove widget'" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-xmark"></i></span>
      } @else {
        <span class="card-info-tooltip-top action_btn" [pTooltip]="widget().description" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}">
                 <i class="fa-solid fa-circle-info"></i>
        </span>
      }
    </div>
    <div class="card-content">
         <span class="icon-placeholder">
             <i class="{{widget().icon}}"></i>
         </span>
      <div>
        <small class="mb-1">{{ formattedDate || '' }}</small>
        <h4 class="c_title mb-1">
          @if (showComparison) {
            @if (widget().symbol == widgetSymbolEnum.Percentage) {
              <span>{{ value.current || '0' }}{{ widget().symbol || '' }}</span>
            } @else {
              <span>{{ widget().symbol || '' }}{{ value.current || '0' }}</span>
            }
            @if (value.previous) {
              <span class="previous-enrollment">vs prev {{ value.previous || 0 }}</span>
            }
            @if (percentChange > 0) {
              <i class="c_trend trend-up fa-solid fa-arrow-up ml-1"></i>
              <span class="c_trend_percent">+{{ percentChange }}%</span>
            } @else if (percentChange < 0) {
              <i class="c_trend trend-down fa-solid fa-arrow-down ml-1"></i>
              <span class="c_trend_percent">{{ percentChange }}%</span>
            }
          } @else {
            @if (widget().symbol == widgetSymbolEnum.Percentage) {
              <span>{{ value || '0' }}{{ widget().symbol || '' }}</span>
            } @else {
              <span>{{ widget().symbol || '' }}{{ value || '0' }}</span>
            }
          }


        </h4>
        <span class="item-name">{{ widget().title }}</span>
      </div>
    </div>
  </div>
}
