import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {UrlResolverService} from "../services/url-resolver.service";
import {AuthService} from "../../core/services/auth/auth.service";
import {API_ENDPOINTS} from "../utils/global";

@Injectable({
  providedIn: 'root'
})
export class UrlResolver implements Resolve<void> {
  constructor(private urlResolverService: UrlResolverService, private authService: AuthService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    let v2Path = route.data['v2Path'];
    let url = '';
    if (v2Path?.length) {
      const v2Url = this.authService.getUserModal().DomainUrl;
      if (route.queryParams) {
        const qs = this.serializeQueryParams(route.queryParams);
        if (qs) {
          v2Path = v2Path + "?" + qs;
        }
      }
      url = `${v2Url}/${API_ENDPOINTS.v2AutoLogin}?returnUrl=${v2Path}&auth=${this.authService.getAccessToken()}`
      this.urlResolverService.load(url);
    } else {
      this.urlResolverService.load('');
    }
  }

  private serializeQueryParams(params: any): string {
    return Object.keys(params)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
  }
}
