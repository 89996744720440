@if (loader) {
  <calimatic-chart-skeleton [chartSkeletonHeight]="'46rem'"></calimatic-chart-skeleton>
} @else {
  <div class="clm-widget-item card p-0">
    <div class="card-action-wrapper">

      @if (edit()) {
        <span (click)="onRemove.emit()" class="action_btn" [pTooltip]="'remove widget'" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-xmark"></i></span>
      }
    </div>
    <div class="card-header p-3 pb-0">
      <h5>Calendar
        <span (click)="loadData()" class="card-icon-inline refresh-btn" [pTooltip]="'Refresh widget'"
              tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-rotate"></i></span>
      </h5>
    </div>
    <div class="card-body pt-0">
      <div class="dash-calendar">
        <p-calendar (ngModelChange)="filterDataByDate($event)" [(ngModel)]="selectedDate" [inline]="true"
                    [showWeek]="false"
                    class="max-w-full">
          <ng-template let-date pTemplate="date">
            <span class="calendar-date">{{ date.day }}</span>
            <span class="dots-list">
              @if (hasClasses(date)) {
                <svg class="dot" height="6" width="6"
                     xmlns="http://www.w3.org/2000/svg">
                      <circle r="2.5" cx="3" cy="3" fill="#fb7c55"/>
                  </svg>
              }

              @if (hasEvents(date)) {
                <svg class="dot" height="6" width="6"
                     xmlns="http://www.w3.org/2000/svg">
                                  <circle r="2.5" cx="3" cy="3" fill="#18ae15"/>
                              </svg>
              }

            </span>
          </ng-template>
        </p-calendar>
        <div class="mini-cal-content">
          <div class="mini-cal-tab-wrapper">
            <div class="mini-cal-date flex justify-content-end font-bold">
              <i class="pi pi-calendar mr-2"></i>
              <span>{{ selectedDate | date }}</span>
            </div>
            <p-tabView styleClass="tabview-custom">
              <p-tabPanel>
                <ng-template pTemplate="header">
                  <div class="cal-cont-header">
                    <div class="head-left">
                      <span class="event-number event-number-classes">{{ classes.length }}</span>
                      <span
                        class="px-3">{{ 'Classes' | terminology:terminologyEnum.Clas: terminologyWordType.PLURAL }}</span>
                    </div>
                  </div>
                </ng-template>
                <div class="cal-cont-body">
                  <div class="text-end">
                    <a [routerLink]="['/' + appRoutePaths.calendar.v3]">View all</a>
                  </div>
                  <div class="cal-events">
                    @if (classes.length) {
                      @for (cls of classes; track cls.id) {
                        <div class="cal-event">
                          <span>{{ cls.formattedStartTime }} - {{ cls.formattedEndTime }}</span>
                          <h6 class="head-1"><span
                            class="ellipsis-two">{{ cls.name }}</span>
                          </h6>
                        </div>
                      }
                    } @else {
                      <span>No {{ 'classes' | terminology:terminologyEnum.Clas: terminologyWordType.PLURAL }}
                        found</span>
                    }
                  </div>
                </div>
              </p-tabPanel>
              <p-tabPanel header="Header II">
                <ng-template pTemplate="header">
                  <div class="cal-cont-header">
                    <div class="head-left">
                      <span class="event-number event-number-events">{{ events.length }}</span>
                      <span
                        class="px-3">{{ 'Events'| terminology:terminologyEnum.Event: terminologyWordType.PLURAL }}</span>
                    </div>
                  </div>
                </ng-template>
                <div class="cal-cont-body">
                  <div class="text-end">
                    <a [routerLink]="['/' + appRoutePaths.eventCalendar.v3]">View all</a>
                  </div>
                  <div class="cal-events">
                    @if (events.length) {
                      @for (evt of events; track evt.id) {
                        <div class="cal-event">
                          <span>{{ evt.formattedStartTime }} - {{ evt.formattedEndTime }}</span>
                          <h6 class="head-2"><span
                            class="ellipsis-two">{{ evt.name }}</span>
                          </h6>
                        </div>
                      }
                    } @else {
                      <span>No {{ 'events'| terminology:terminologyEnum.Event: terminologyWordType.PLURAL }}
                        found</span>
                    }
                  </div>
                </div>
              </p-tabPanel>
            </p-tabView>
          </div>

        </div>
      </div>
    </div>
  </div>
}
