import {inject, Injectable} from "@angular/core";
import {ApiHandlerService} from "./base/api-handler.service";
import {lastValueFrom} from "rxjs";
import {API_ENDPOINTS} from "../../shared/utils/global";
import {CustomeURLConfigDto, EmailFrequencyAndSettingsDto, EmailFrequencyPayloadDto, EmailFrequencySettingDto, EmailFrequencySettingsDTO, EmailFrequencyWithSettingsDto, EmailFrequenyDto, EmailSenderDto, EmailSettingsPayloadDto, EmailsSettingDto, HomeworkAssessmentsResponseDto, MobileAppConfigDto, NotificationToStaffResponseDto, OnboardExtenedDto, OnBoardingSettings, OnboardStepsDto, PlatformBrandingDto, TimeTrackerEmailSettingDto} from '../../shared/dtos/on-board.dto';
import {
  BirdEyeCompaniesDto,
  BusinessProfile,
  CompanyConfigurationDto,
  DepartmentDto,
  DropboxDto,
  GoogleDriveConfigDto,
  QuickbookConfigDto,
  ZoomConfigDto
} from "../../shared/interfaces/system-config.type";
import { StripeConfigDto } from "../../shared/dtos/stripe-config.dto";
import { PlaidConfigDto } from "../../shared/dtos/plaid-config.dto";
import { NmiConfigDto } from "../../shared/dtos/nmi-config.dto";
import { EmailFrenquencyToogleEnum, EmailSettingTabEnum, LMSDefaultRoles } from '../../shared/enums/onboard.enum';
import { UserRoleEnum } from "../../shared/enums/user-role.enum";
import { IGpaConfig } from "../../shared/interfaces/grading-config/IGpaConfig.interface";
import { GradingGridWithDetailDto } from "../../shared/interfaces/grading-config/IGradeConfig.interface";
import { DynamicClassColorsDTO } from "../../shared/interfaces/class-color-manage/class-color-manage.interface";

@Injectable({
  providedIn: 'root',
})
export class SystemConfigService {
  _httpContext = inject(ApiHandlerService);

  async getBusinessProfile() {
    return await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.systemConfig.getBusinessProfile)
    );
  }

  async saveBusinessProfile(profile: BusinessProfile) {
    return await lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.systemConfig.saveBusinessProfile,
        profile
      )
    );
  }

  async getUserConfiguration() {
    return await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.systemConfig.getUserConfiguration)
    );
  }

  async saveUserConfiguration(data: CompanyConfigurationDto) {
    return await lastValueFrom(
      this._httpContext.Put(
        API_ENDPOINTS.systemConfig.saveUserConfiguration,
        data
      )
    );
  }

  // interfaces end points
  async getDriveConfig() {
    return await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.systemConfig.getDriveConfig)
    );
  }

  async saveDriveConfig(data: GoogleDriveConfigDto) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.systemConfig.saveDriveConfig, data)
    );
  }

  async getZoomConfig() {
    return await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.systemConfig.getZoomConfig)
    );
  }

  async saveZoomConfig(data: ZoomConfigDto) {
    return await lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.systemConfig.saveZoomConfig, data)
    );
  }

  async getDropBoxConfig() {
    return await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.systemConfig.getDropBoxConfig)
    );
  }

  async saveDropBoxConfig(data: DropboxDto) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.systemConfig.saveDropBoxConfig, data)
    );
  }

  async getQuickbookConfig() {
    return await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.systemConfig.getQuickbookConfig)
    );
  }

  async saveQuickbookConfig(data: QuickbookConfigDto) {
    return await lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.systemConfig.saveQuickbookConfig,
        data
      )
    );
  }

  async getBirdEyeConfig() {
    return await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.systemConfig.getBirdEyeConfig)
    );
  }

  async saveEmailSenderConfigs(data: EmailSenderDto) {
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.CompanyEmailConfig.saveSenderEmailConfigs,
        data
      )
    );
  }

  async getEmailSenderConfigs() {
    const res = await lastValueFrom(
      this._httpContext.Get(
        API_ENDPOINTS.CompanyEmailConfig.getSenderEmailConfigs
      )
    );
    if (res.isSuccessful) {
      return res.response as EmailSenderDto;
    }
    return {} as EmailSenderDto;
  }

  async getEmailFrequencies() {
    const res = await lastValueFrom(
      this._httpContext.Get(
        API_ENDPOINTS.CompanyEmailConfig.getEmailFrequencies
      )
    );
    if (res.isSuccessful) {
      return res.response as EmailFrequenyDto;
    }
    return {} as EmailFrequenyDto;
  }

  async saveIsOnOnboardigComplete(isOnboardingComplete:boolean){
    return await lastValueFrom( this._httpContext.Post(API_ENDPOINTS.UserGuideSection.saveCompanyOnBoardingCompletion,{value:isOnboardingComplete})
    );
  }

  async getOnboardingSettings(){
    const apiResponse = await lastValueFrom(this._httpContext.Get(API_ENDPOINTS.UserGuideSection.getCompanyOnboardingSettings));
    if(apiResponse.isSuccessful){
      return apiResponse.response as OnBoardingSettings;
    }
    return new  OnBoardingSettings();
    
  }

  async getEmailFrequenciesSetting() {
    const res = await lastValueFrom(
      this._httpContext.Get(
        API_ENDPOINTS.CompanyEmailConfig.getEmailFrequenciesSetting
      )
    );
    if (res.isSuccessful) {
      return res.response as EmailFrequencyAndSettingsDto;
    }
    return {} as EmailFrequencyAndSettingsDto;
  }

  updateEmailFrequency(
    type: number,
    value: boolean,
    emailRoles: string = '',
    tzOffset: string = '',
    tzAbbrv: string = '',
    tzName: string = ''
  ) {
    let data: EmailFrequencyPayloadDto = new EmailFrequencyPayloadDto();
    data.type = type;
    data.value = value;
    data.emailToRoles = emailRoles;
    data.tzOffset = tzOffset;
    data.tzAbbrv = tzAbbrv;
    data.tzName = tzName;
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.CompanyEmailConfig.setEmailFrequency,
        data
      )
    );
  }

  updateEmailFrequencySettings(data: EmailFrequencySettingsDTO, type: number) {
    data.emailAddress ||= '';
    data.toEmailAddress ||= '';
    data.emails ||= [];
    data.emailsTo ||= [];
    data.companyEmailList ||= [];
    data.type = type || 0;
    data.emailToRoles ||= '';
    data.id ||= 0;
    data.toBusinessAdmin ||= false;
    data.toBusinessCordinator ||= false;
    data.toBusinessExecutive ||= false;
    data.toCC ||= false;
    data.toFranhiseAdmin ||= false;
    data.toFranhiseCoordinator ||= false;
    data.toFranhiseExecutive ||= false;
    data.toParent ||= false;
    data.toStaff ||= false;
    data.toStudent ||= false;
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.CompanyEmailConfig.setEmailFrequencySettings,
        data
      )
    );
  }


  async getCompletionEmailImage(backlogType:number){
  const apiResponse = await lastValueFrom(this._httpContext.Get( `${API_ENDPOINTS.CompanyEmailConfig.getCompletionEmailImage}?backlogType=${backlogType}`));
  if(apiResponse.isSuccessful){
    return apiResponse.response as string;
  }
  return '' as string;
  }
  

  addUpdateCompletionImage(image:File,backlogType:number) {
    const data = new FormData();
    data.append('Image',image );
    data.append('BacklogType', backlogType.toString())
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.CompanyEmailConfig.addUpdateCompletionImage,
        data
      )
    );
  }


  async getChallengeNotificationToStaff() {
    let res = await lastValueFrom(
      this._httpContext.Get(
        API_ENDPOINTS.CompanyEmailConfig.getChallengeNotificationToStaff
      )
    );
    if (res.isSuccessful) return res.response as NotificationToStaffResponseDto;
    return {} as NotificationToStaffResponseDto;
  }

  saveCompletionEmailAt(points: number) {
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.CompanyEmailConfig.saveCompletionEmailAt,
        { points }
      )
    );
  }
  async getHomeworkAndAssessmentsDueDate() {
    let res = await lastValueFrom(
      this._httpContext.Get(
        API_ENDPOINTS.CompanyEmailConfig
          .getHomeworkAndAssessmentsDueDateNotification
      )
    );
    if (res.isSuccessful) return res.response as HomeworkAssessmentsResponseDto;
    return {} as HomeworkAssessmentsResponseDto;
  }

  async saveBirdEyeApi(apiKey: string) {
    return await lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.systemConfig.saveBirdEyeApi, {
        apiKey,
      })
    );
  }

  async updateBusinessIds(data: BirdEyeCompaniesDto[]) {
    return await lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.systemConfig.saveBusinessIds, data)
    );
  }

  async getTimeTrackerEmailSettings() {
    const res = await lastValueFrom(
      this._httpContext.Get(
        API_ENDPOINTS.CompanyEmailConfig.getTimeTrackerEmailSettings
      )
    );
    if (res.isSuccessful) {
      return res.response as TimeTrackerEmailSettingDto[];
    }
    return [] as TimeTrackerEmailSettingDto[];
  }
  setTimeTrackerEmailSettings(data: TimeTrackerEmailSettingDto) {
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.CompanyEmailConfig.setTimeTrackingEmailSettings,
        data
      )
    );
  }
  async getEmailsSettings() {
    const res = await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.CompanyEmailConfig.getEmailsSettings)
    );
    if (res.isSuccessful) {
      return res.response as EmailsSettingDto;
    }
    return {} as EmailsSettingDto;
  }

  setEmailsSetttingValues(emails: any, type: number) {
    let data: EmailSettingsPayloadDto = new EmailSettingsPayloadDto();
    data.emails = emails;
    data.type = type;
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.CompanyEmailConfig.setEmailSettingsValues,
        data
      )
    );
  }

  async getSystemEmailFooter() {
    let res = await lastValueFrom(
      this._httpContext.Get(
        API_ENDPOINTS.CompanyEmailConfig.getSystemEmailFooter
      )
    );
    if (res.isSuccessful) return res.response as string;
    return '';
  }

  async SaveEmailFooter(templateBody: string) {
    return await lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.CompanyEmailConfig.SaveSystemEmailFooter,
        { templateBody }
      )
    );
  }
  saveStripeConfig(config: StripeConfigDto) {
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.systemConfig.saveStripeConfig,
        config
      )
    );
  }

  savePlaidConfig(config: PlaidConfigDto) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.systemConfig.savePlaidConfig, config)
    );
  }

  saveNmiConfig(config: NmiConfigDto) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.systemConfig.saveNmiConfig, config)
    );
  }

  async getTransactionConfig() {
    return await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.systemConfig.getTransactionConfig)
    );
  }
  async getPlatformConfig() {
    let res = await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.PlatformConfig.getPlatformConfig)
    );
    if (res.isSuccessful) {
      return res.response as PlatformBrandingDto;
    }
    return {} as PlatformBrandingDto;
  }
  async saveCompanyLogo(imageFile: File) {
    const logo = new FormData();
    logo.append('logo', imageFile);
    return await lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.PlatformConfig.saveCompanyLogo, logo)
    );
  }
  async saveLoginPageImage(imageFile: File) {
    const image = new FormData();
    image.append('image', imageFile);
    return await lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.PlatformConfig.saveLoginPageImage,
        image
      )
    );
  }
  async resetToDefaultCompanyLogo() {
    return await lastValueFrom(
      this._httpContext.Get(
        API_ENDPOINTS.PlatformConfig.resetToDefaultCompanyLogo
      )
    );
  }
  async resetToDefaultLoginImage() {
    return await lastValueFrom(
      this._httpContext.Get(
        API_ENDPOINTS.PlatformConfig.resetToDefaultLoginImage
      )
    );
  }
  async saveMenuBarLogoBgColor(color: string) {
    return await lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.PlatformConfig.saveMenuBarLogoBgColor,
        { color }
      )
    );
  }
  async saveFranchiseSecureLogoBgColor(color: string) {
    return await lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.PlatformConfig.saveFrachiseSecureLogoBgColor,
        { color }
      )
    );
  }

  async saveFavIcon(imageFile: File) {
    const FavIcon = new FormData();
    FavIcon.append('FavIcon', imageFile);
    return await lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.PlatformConfig.saveFavIcon, FavIcon)
    );
  }

  async resetToDefaultFavIcon() {
    return await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.PlatformConfig.resetToDefaultFavIcon)
    );
  }
  async saveLoginLogo(loginLogoFile: File) {
    const loginLogo = new FormData();
    loginLogo.append('loginLogo', loginLogoFile);
    return await lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.PlatformConfig.saveLoginLogo,
        loginLogo
      )
    );
  }

  async resetToDefaultLoginLogo() {
    return await lastValueFrom(
      this._httpContext.Get(
        API_ENDPOINTS.PlatformConfig.resetToDefaultLoginLogo
      )
    );
  }

  async saveBrandingTitle(title: string) {
    return await lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.PlatformConfig.saveBrandingTitle, {
        title,
      })
    );
  }

  mapFrequencySettings(
    frequency: EmailFrequenyDto,
    frequecyWithSetting: EmailFrequencyWithSettingsDto,
    frequecySettings: EmailFrequencySettingDto[],
    type: number
  ) {
    frequecyWithSetting.emailSettings = this.mapEmailFrequnecyByType(
      frequecySettings,
      type
    );
    frequecyWithSetting.type = type;

    switch (type) {
      case EmailFrenquencyToogleEnum.Course:
        frequecyWithSetting.isSettingOn = frequency.isCourseComplete;
        frequecyWithSetting.labelName = 'After Course Completion';
        frequecyWithSetting.courseCompletationImage = frequency.courseCompletationImage;
        break;
      case EmailFrenquencyToogleEnum.Level:
        frequecyWithSetting.isSettingOn = frequency.isBadgeComplete;
        frequecyWithSetting.labelName = 'After Level Completion';
        frequecyWithSetting.badgeCompletationImage = frequency.badgeCompletationImage;
     
        break;
      case EmailFrenquencyToogleEnum.Challenge:
        frequecyWithSetting.isSettingOn = frequency.isChallengeComplete;
        frequecyWithSetting.labelName = 'After Challenge Completion';
        frequecyWithSetting.challengeCompletationImage = frequency.challengeCompletationImage;
      break;
      case EmailFrenquencyToogleEnum.Inventory:
        frequecyWithSetting.labelName = 'After Inventory is Ordered/Shipped';
        frequecyWithSetting.isSettingOn = true;
        break;
      case EmailFrenquencyToogleEnum.OnlineAssessment:
        frequecyWithSetting.isSettingOn = frequency.onlineAssessmentSubmit;
        frequecyWithSetting.labelName = 'Online Assessments Submission Email';
        break;
      case EmailFrenquencyToogleEnum.Task:
        frequecyWithSetting.isSettingOn = frequency.staffTaskCompletePortal;
        frequecyWithSetting.labelName = 'After Staff Task Completion';
        break;
      case EmailFrenquencyToogleEnum.AbandonedCartInfoDailyEmail:
        frequecyWithSetting.isSettingOn = frequency.abandonedCartInfoDailyEmail;
        frequecyWithSetting.labelName = 'Abandoned Cart Email (Daily)';
        break;
      case EmailFrenquencyToogleEnum.Refund:
        frequecyWithSetting.isSettingOn = frequency.sendRefundEmail;
        frequecyWithSetting.labelName = 'Refund Email';
        break;
      case EmailFrenquencyToogleEnum.SecureEnrollmentEmail:
        frequecyWithSetting.isSettingOn = frequency.enrollmentMailSecure;
        frequecyWithSetting.labelName = 'Enrollment Email (Secure)';
        break;
      case EmailFrenquencyToogleEnum.PortalEnrollmentEmail:
        frequecyWithSetting.isSettingOn = frequency.enrollmentMailPortal;
        frequecyWithSetting.labelName = 'Enrollment Email (Portal Payments)';
        break;
      case EmailFrenquencyToogleEnum.RecurringEnrollmentEmail:
        frequecyWithSetting.isSettingOn =
          frequency.enrollmentMailRecurringPayments;
        frequecyWithSetting.labelName = 'Enrollment Email (Recurring Payments)';
        break;
      case EmailFrenquencyToogleEnum.WaitlistNotification:
        frequecyWithSetting.isSettingOn =
          frequency.joinWaitlistNotificationEmail;
        frequecyWithSetting.labelName = 'Waitlist Notification Email';
        break;
      case EmailFrenquencyToogleEnum.AttendanceEnrollment:
        frequecyWithSetting.isSettingOn = frequency.attendanceEnrollmentMail;
        frequecyWithSetting.labelName = 'Attendance';
        break;
      case EmailFrenquencyToogleEnum.ClassUpdate:
        frequecyWithSetting.isSettingOn = frequency.classUpdate;
        frequecyWithSetting.labelName = 'Class Updates';
        break;
    }
  }

  async getLanguageSettings() {
    return await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.systemConfig.getLanguageSettings)
    );
  }

  async saveLanguageSettings(lang: string) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.systemConfig.saveLanguageSettings, {
        lang,
      })
    );
  }

  private mapEmailFrequnecyByType(
    freqSettings: EmailFrequencySettingDto[],
    type: number
  ) {
    var data: EmailFrequencySettingsDTO = new EmailFrequencySettingsDTO();
    if (freqSettings && (freqSettings || []).length) {
      if (
        type == EmailFrenquencyToogleEnum.AbandonedCartInfoDailyEmail ||
        type == EmailFrenquencyToogleEnum.AbandonedCartInfoDailyEmailCC
      ) {
        var abadonCartInfoDailyEmailsettings = (freqSettings || []).filter(
          (x) =>
            x.type == EmailFrenquencyToogleEnum.AbandonedCartInfoDailyEmail ||
            EmailFrenquencyToogleEnum.AbandonedCartInfoDailyEmailCC
        );
        if ((abadonCartInfoDailyEmailsettings || []).length) {
          data.emails = (
            abadonCartInfoDailyEmailsettings.find(
              (c) =>
                c.type ==
                EmailFrenquencyToogleEnum.AbandonedCartInfoDailyEmailCC
            )?.emailAddress || ''
          ).split(',');
          data.emailsTo = (
            abadonCartInfoDailyEmailsettings.find(
              (c) =>
                c.type == EmailFrenquencyToogleEnum.AbandonedCartInfoDailyEmail
            ).emailAddress || ''
          ).split(',');
          return data;
        }
      } else {
        var setting = (freqSettings || []).find((x) => x.type == type);
        if (setting) {
          var emailAddress = (setting?.emailAddress || []).length
            ? (setting.emailAddress || '').split(',')
            : [];
          data.emails = emailAddress;
          if (
            type == EmailFrenquencyToogleEnum.Task &&
            (data.emails || []).length
          ) {
            data.toStaff = (setting?.frequencyEmailAccesses || []).some(
              (x) => x.roleType == LMSDefaultRoles.BusinessInstructor
            );
          } else {
            data.toStudent = (setting?.frequencyEmailAccesses || []).some(
              (x) =>
                x.roleType == UserRoleEnum.FranchiseStudent ||
                UserRoleEnum.BusinessStudent
            );
            data.toParent = (setting?.frequencyEmailAccesses || []).some(
              (x) =>
                x.roleType == UserRoleEnum.FranchiseParent ||
                UserRoleEnum.BusinessParent
            );
            data.toFranhiseAdmin = (setting?.frequencyEmailAccesses || []).some(
              (x) => x.roleType == UserRoleEnum.FranchiseAdmin
            );
            data.toFranhiseCoordinator = (
              setting?.frequencyEmailAccesses || []
            ).some((x) => x.roleType == UserRoleEnum.FranchiseCoordinator);
            data.toFranhiseExecutive = (
              setting?.frequencyEmailAccesses || []
            ).some((x) => x.roleType == UserRoleEnum.FranchiseExecutive);
            data.toBusinessAdmin = (setting?.frequencyEmailAccesses || []).some(
              (x) => x.roleType == UserRoleEnum.BusinessAdmin
            );
            data.toBusinessCordinator = (
              setting?.frequencyEmailAccesses || []
            ).some((x) => x.roleType == UserRoleEnum.BusinessCoordinator);
            data.toBusinessExecutive = (
              setting?.frequencyEmailAccesses || []
            ).some((x) => x.roleType == UserRoleEnum.BusinessExecutive);

            if (
              type == EmailFrenquencyToogleEnum.SecureEnrollmentEmail ||
              type == EmailFrenquencyToogleEnum.PortalEnrollmentEmail ||
              type == EmailFrenquencyToogleEnum.RecurringEnrollmentEmail ||
              type == EmailFrenquencyToogleEnum.WaitlistNotification ||
              type == EmailFrenquencyToogleEnum.AttendanceEnrollment ||
              type == EmailFrenquencyToogleEnum.ClassUpdate
            ) {
              data.toCC = true;
            } else {
              data.toCC = (emailAddress || []).length ? true : false;
            }
          }
        } else if (
          type == EmailFrenquencyToogleEnum.OnlineAssessment ||
          type == EmailFrenquencyToogleEnum.Task ||
          EmailFrenquencyToogleEnum.SecureEnrollmentEmail ||
          type == EmailFrenquencyToogleEnum.PortalEnrollmentEmail ||
          type == EmailFrenquencyToogleEnum.RecurringEnrollmentEmail ||
          type == EmailFrenquencyToogleEnum.WaitlistNotification ||
          type == EmailFrenquencyToogleEnum.AttendanceEnrollment ||
          type == EmailFrenquencyToogleEnum.ClassUpdate
        ) {
          data.toStudent = false;
          data.toParent = false;
        } else if (
          type == EmailFrenquencyToogleEnum.SecureEnrollmentEmail ||
          type == EmailFrenquencyToogleEnum.PortalEnrollmentEmail ||
          type == EmailFrenquencyToogleEnum.RecurringEnrollmentEmail ||
          type == EmailFrenquencyToogleEnum.WaitlistNotification ||
          type == EmailFrenquencyToogleEnum.AttendanceEnrollment ||
          type == EmailFrenquencyToogleEnum.ClassUpdate
        ) {
          data.toCC = true;
        }
      }
    }

    return data;
  }

  async sendMobileAppConfiEmail(data: MobileAppConfigDto) {
    return await lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.sendMobileAppConfiEmail, data)
    );
  }
  async sendEmailForCustomURL(data: CustomeURLConfigDto) {
    return await lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.sendEmailForCustomURL, data)
    );
  }
  async saveUserGuideSectionAndSteps(section: OnboardExtenedDto) {
    return await lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.UserGuideSection.saveUserGuide,
        section
      )
    );
  }
  async getUserGuide() {
    const res = await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.UserGuideSection.getUserGuide)
    );
    if (res.isSuccessful) {
      return res.response as OnboardExtenedDto[];
    }
    return [] as OnboardExtenedDto[];
  }

  async getTimeZone() {
    return await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.systemConfig.getTimeZone)
    );
  }
  async SaveTimeZone(timeZone: string) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.systemConfig.saveTimeZone, {
        timeZone,
      })
    );
  }
  async getDepartments() {
    return await lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.department.getDepartments)
    );
  }
  async saveDepartment(dept: DepartmentDto) {
    return await lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.department.saveDepartment, dept)
    );
  }
  async deleteDepartment(dept: DepartmentDto) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.department.deleteDepartment, dept)
    );
  }
  async getDefaultOnlineClassType() {
    return lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.studentRelated.getDefaultOnlineClass)
    );
  }
  async setDefaultOnlineClassType(classId: number) {
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.studentRelated.setDefaultOnlineClass,
        { classId }
      )
    );
  }
  async GetBoardStatusSetting() {
    return lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.studentRelated.getBoardStatusSetting)
    );
  }
  async SaveBoardStatusSetting(isStudentAllowed: boolean) {
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.studentRelated.saveBoardStatusSetting,
        { isStudentAllowed }
      )
    );
  }
  async getGpaConfiguration() {
    return lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.studentRelated.getGpaConfiguration)
    );
  }
  async saveGpaConfiguration(configs: IGpaConfig[]) {
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.studentRelated.saveGpaConfiguration,
        configs
      )
    );
  }
  async getGradingConfiguration() {
    return lastValueFrom(
      this._httpContext.Get(
        API_ENDPOINTS.studentRelated.getGradingConfiguration
      )
    );
  }
  async getGradingFromData(configId: number) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.studentRelated.getGradingFormData, {
        configId,
      })
    );
  }
  async saveGradingForm(data: GradingGridWithDetailDto) {
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.studentRelated.saveGradingFormData,
        data
      )
    );
  }
  async deleteGradeConfig(configId: number) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.studentRelated.deleteGrading, {
        configId,
      })
    );
  }
  async getRubricGrid() {
    return lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.studentRelated.getRubricGrid)
    );
  }
  async saveRubric(data: { rubricTitle: string; rubricId: number }) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.studentRelated.saveRubric, data)
    );
  }
  async copyRubric(rubricId: number) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.studentRelated.copyRubric, {
        rubricId,
      })
    );
  }
  async verifyRubricEditPermission(rubricId: number) {
    return lastValueFrom(
      this._httpContext.Get(
        `${API_ENDPOINTS.studentRelated.verifyRubric}?rubricId=${rubricId}`
      )
    );
  }
  async deleteRubric(rubricId: number) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.studentRelated.deleteRubric, {
        rubricId,
      })
    );
  }
  async getAssessmentConfig() {
    return lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.studentRelated.getAssessmentConfig)
    );
  }

  async showToAllInstructor(status: boolean) {
    return lastValueFrom(
      this._httpContext.Post(
        API_ENDPOINTS.studentRelated.saveAssessmentConfig,
        { status }
      )
    );
  }
  async autoAssignStep(status: boolean) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.studentRelated.autoAssignStep, {
        status,
      })
    );
  }
  async autoAssignChallenge(status: boolean) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.studentRelated.autoAssignChallenge, {
        status,
      })
    );
  }
  async applyToAllFranchise(settingsFor: string) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.studentRelated.applyToAllFranchise, {
        settingsFor,
      })
    );
  }
  async getClassConfig() {
    return lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.studentRelated.getClassConfig)
    );
  }
  async setClassConfig(configName: string, status: boolean, text: string = '') {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.studentRelated.setClassConfig, {
        configName,
        status,
        text,
      })
    );
  }
  async getPageConfig() {
    return lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.studentRelated.getPageConfig)
    );
  }
  async saveFontColors(data: {
    mostPopularTagColor: string;
    mostPopularTagFontColor: string;
    almostFullTagColor: string;
    almostFullTagFontColor: string;
  }) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.studentRelated.saveFontColors, data)
    );
  }
  async getClassColorConfig() {
    return lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.studentRelated.getClassColorConfig)
    );
  }
  async saveClassColorConfig(data:DynamicClassColorsDTO[]) {
    return lastValueFrom(
      this._httpContext.Post(API_ENDPOINTS.studentRelated.saveClassColorConfig,data)
    );
  }
  async getWeekStartDay() {
    return lastValueFrom(
      this._httpContext.Get(API_ENDPOINTS.staffRelated.getWeekStartDay)
    );
  }
  async saveWeekStartDay(day:string) {
    return lastValueFrom(
      this._httpContext.Post(`${API_ENDPOINTS.staffRelated.saveWeekStartDay}?day=${day}`,{})
    );
  }
}
