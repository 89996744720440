@if (configs().selectable && configs().floatLabel) {
  <p-floatLabel>
    <p-dropdown (ngModelChange)="valueChange.emit($event)" [ngModel]="value()" [optionLabel]="configs().label"
                [optionValue]="configs().value" [options]="configs().options"
                [placeholder]="configs().placeholder||'Select'"
                [showClear]="configs().showClear" [filter]="configs().filter" [appendTo]="configs().appendTo"
                [styleClass]="configs().buttonClass" [virtualScroll]="configs().virtualScroll"
                [virtualScrollItemSize]="configs().virtualScrollSize" [disabled]="configs().disabled"/>
    <label for="float-label">{{ configs().floatLabel }}</label>
  </p-floatLabel>
} @else if (configs().selectable) {
  <p-dropdown (ngModelChange)="valueChange.emit($event)" [ngModel]="value()" [optionLabel]="configs().label"
              [optionValue]="configs().value" [options]="configs().options"
              [placeholder]="configs().placeholder||'Select'"
              [showClear]="configs().showClear" [filter]="configs().filter" [appendTo]="configs().appendTo"
              [styleClass]="configs().buttonClass" [virtualScroll]="configs().virtualScroll"
              [virtualScrollItemSize]="configs().virtualScrollSize" [styleClass]="configs().buttonClass"
              [disabled]="configs().disabled"></p-dropdown>

} @else {
  <p-overlayPanel #op styleClass="clm-dropdown-menu">
    <ng-template pTemplate="content">
      @for (item of configs().options; track $index) {
        <div class="menu-item flex align-items-center gap-1" (click)="valueChange.emit(item);op.hide()">
          <i class="{{item.icon}}"></i>
          <span>{{ item.name }}</span>
        </div>
      }
    </ng-template>
  </p-overlayPanel>
  <!-- <p-button icon="pi pi-bars" (click)="op.toggle($event)" severity="secondary"></p-button> -->

  <button pButton (click)="op.toggle($event)" id="testButton"
          class="clm-secondary-btn btn-sm {{configs().buttonClass}}">
    @if (configs().dropdownIcon) {
      <span class="icon-set flex align-items-center gap-2">
    <i class="{{configs().dropdownIcon}}"></i>
        <!-- <i class="fa-solid fa-caret-down "></i> -->
  </span>
    } @else {
      <span class="mr-2">{{ configs().label }}</span>
      <i class="fa-solid fa-caret-down "></i>
    }
  </button>
}
