import {Component, effect, input, output} from '@angular/core';
import {BarChartComponent} from "../../charts/bar-chart/bar-chart.component";
import {ChartSkeletonComponent} from "../skeletons/chart-skeleton/chart-skeleton.component";
import {DropdownComponent} from "../../../../../shared/components/dropdown/dropdown.component";
import {DropdownDto} from "../../../../../shared/dtos/dropdown-dto";
import {StackBarChartDataDto} from "../../../../../shared/dtos/chart-data.dto";
import {DashboardService} from "../../../../../core/services/dashboard.service";
import {MultiSelectComponent} from "../../../../../shared/components/multi-select/multi-select.component";
import {MultiSelectDisplayEnums} from '../../../../../shared/enums/multi-select/multi-select.enums';
import {TooltipModule} from 'primeng/tooltip';
import {DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";
import {TerminologyEnum, TerminologyWordEnum} from "../../../../../shared/enums/terminology.enum";
import {TerminologyPipe} from "../../../../../shared/pipes/terminology.pipe";
import {StackBarChartComponent} from "../../charts/stack-bar-chart/stack-bar-chart.component";

@Component({
  selector: 'calimatic-event-enrollment-count-widget',
  standalone: true,
  imports: [
    BarChartComponent,
    ChartSkeletonComponent,
    DropdownComponent,
    MultiSelectComponent,
    TooltipModule,
    TerminologyPipe,
    StackBarChartComponent
  ],
  templateUrl: './event-enrollment-count-widget.component.html',
  styleUrl: './event-enrollment-count-widget.component.scss'
})
export class EventEnrollmentCountWidgetComponent {

  //inputs
  widget = input.required<DashboardWidgetsDTO>();
  isFranchise = input.required<boolean>();
  companyId = input.required<number>();
  types = input.required<DropdownDto[]>();
  franchises = input.required<DropdownDto[]>();
  edit = input.required();

  //outputs
  onRemove = output();

  //local
  selectedType: string[];
  selectedFranchises: number[] = [];
  chartData: StackBarChartDataDto[];
  loading = false;
  multiSelectDisplay = MultiSelectDisplayEnums;
  protected readonly terminologyEnum = TerminologyEnum;
  protected readonly terminologyWordType = TerminologyWordEnum;

  constructor(private dashboardService: DashboardService) {
    effect(async () => {
      this.selectedFranchises = this.franchises().map(x => x.id);
      this.selectedType = this.types().map(x => x.name);
      await this.loadData();
    });
  }

  async onTypeChange(value: any) {
    this.selectedType = value;
    await this.loadData();
  }

  async onFranchiseChange(value: any) {
    this.selectedFranchises = value;
    await this.loadData();
  }

  async loadData() {
    if (this.isFranchise()) {
      this.selectedFranchises = [this.companyId()];
    }

    if (this.selectedType?.length && this.selectedFranchises?.length) {
      this.loading = true;
      this.chartData = await this.dashboardService.getEventEnrollmentCountByType(this.selectedType.toString(), this.selectedFranchises.toString());
      this.loading = false;
    }
  }
}
