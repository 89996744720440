import {Component, OnInit} from '@angular/core';
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {FieldsetModule} from "primeng/fieldset";
import {ReactiveFormsModule} from "@angular/forms";
import {DropdownComponent} from "../../../../../shared/components/dropdown/dropdown.component";
import {StripeTransactionConfigComponent} from "./stripe-transaction-config/stripe-transaction-config.component";
import {SystemConfigService} from "../../../../../core/services/system-config.service";
import {TransactionConfigurationDto} from "../../../../../shared/dtos/transaction-config.dto";
import {PlaidTransactionConfigComponent} from "./plaid-transaction-config/plaid-transaction-config.component";
import {PaymentGatewayEnum} from "../../../../../shared/enums/payment-gateway.enum";
import {NmiTransactionConfigComponent} from "./nmi-transaction-config/nmi-transaction-config.component";
import { TabViewModule } from 'primeng/tabview';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SkeletonComponent } from "../../../../../shared/components/skeleton/skeleton.component";

@Component({
  selector: 'calimatic-transaction-config',
  standalone: true,
  imports: [
    InputTextModule,
    ButtonModule,
    FieldsetModule,
    ReactiveFormsModule,
    DropdownComponent,
    StripeTransactionConfigComponent,
    PlaidTransactionConfigComponent,
    NmiTransactionConfigComponent,
    TabViewModule,
    SkeletonComponent
],
  templateUrl: './transaction-config.component.html',
  styleUrl: './transaction-config.component.scss'
})
export class TransactionConfigComponent implements OnInit {
  configs: TransactionConfigurationDto;
  paymentGateways = PaymentGatewayEnum;
  modalRef: DynamicDialogRef<any>;
  isLoading = true;

  constructor(private configService: SystemConfigService, private ref: DynamicDialogRef) {
  }

  async ngOnInit() {
    this.modalRef = this.ref;
    let res = await this.configService.getTransactionConfig();
    this.isLoading = false;
    if (res.isSuccessful) {
      this.configs = res.response as TransactionConfigurationDto;
    }
  }

}
