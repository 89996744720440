import {Component, effect, Inject, input} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {DropdownComponent} from "../../../../../../shared/components/dropdown/dropdown.component";
import {FieldsetModule} from "primeng/fieldset";
import {InputTextModule} from "primeng/inputtext";
import {PaginatorModule} from "primeng/paginator";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {SharedModule} from "primeng/api";
import {getStripeCurrencies, getSymbolFromCurrencyCode} from "../../../../../../shared/utils/currency-list";
import {AppConstants, AppTokens} from "../../../../../../shared/utils/global";
import {NgClass} from "@angular/common";
import {ValidateInputDirective} from "../../../../../../shared/directives/validate-input.directive";
import {StripeConfigDto} from "../../../../../../shared/dtos/stripe-config.dto";
import {IToaster} from "../../../../../../shared/interfaces/toaster/IToaster";
import {ToasterTypeEnum} from "../../../../../../shared/enums/toaster-type.enum";
import {SystemConfigService} from "../../../../../../core/services/system-config.service";
import {TransactionConfigurationDto} from "../../../../../../shared/dtos/transaction-config.dto";
import {SkeletonComponent} from "../../../../../../shared/components/skeleton/skeleton.component";
import { FloatLabelModule } from 'primeng/floatlabel';
import { OnboardService } from '../../../../../../core/services/onboard.service';
import { OnboardSetionStepsEnum } from '../../../../../../shared/enums/onboard.enum';

@Component({
  selector: 'calimatic-stripe-transaction-config',
  standalone: true,
  imports: [
    ButtonModule,
    DropdownComponent,
    FieldsetModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    SharedModule,
    NgClass,
    ValidateInputDirective,
    SkeletonComponent,
    FloatLabelModule
  ],
  templateUrl: './stripe-transaction-config.component.html',
  styleUrl: './stripe-transaction-config.component.scss'
})
export class StripeTransactionConfigComponent {
  //input
  configs = input.required<TransactionConfigurationDto>();

  //local
  dashboardURL = AppConstants.appUrls.stripeDashboardUrl;
  stripeCurrencies = getStripeCurrencies();
  stripeForm = this.fb.group({
    accountName: ['', [Validators.required, Validators.pattern(AppConstants.regularExpressions.space)]],
    secretKey: ['', [Validators.required, Validators.pattern(AppConstants.regularExpressions.space)]],
    publishableKey: ['', [Validators.required, Validators.pattern(AppConstants.regularExpressions.space)]],
    clientKey: ['', [Validators.required, Validators.pattern(AppConstants.regularExpressions.space)]],
    currencyCode: [this.stripeCurrencies[0].Value, [Validators.required]],
  });
  validateInput = false;
  processing = false;
  loading = true;

  constructor(private fb: FormBuilder, private configService: SystemConfigService, @Inject(AppTokens.toaster) private toaster: IToaster, private onboardService:OnboardService) {
    effect(() => {
      const _configs = this.configs();
      if (_configs) {
        this.stripeFormControls.accountName.patchValue(_configs.stripeAccountName);
        this.stripeFormControls.secretKey.patchValue(_configs.stripeSecretKey);
        this.stripeFormControls.publishableKey.patchValue(_configs.stripePublishableKey);
        this.stripeFormControls.clientKey.patchValue(_configs.stripeClientKey);
        this.stripeFormControls.currencyCode.patchValue(_configs.currencyCode);
        this.loading = false;
      }
    });
  }

  get stripeFormControls() {
    return this.stripeForm.controls;
  }

  onCurrencyChange(value: string) {
    this.stripeFormControls.currencyCode.patchValue(value);
  }

  async save() {
    if (this.stripeForm.invalid) {
      this.validateInput = true;
      return;
    }

    this.processing = true;
    let payload = this.stripeForm.value as StripeConfigDto;
    payload.currencySymbol = getSymbolFromCurrencyCode(payload.currencyCode?.toUpperCase());
    payload.id = this.configs().configId;
    const res = await this.configService.saveStripeConfig(payload);
    this.processing = false;
    if (res.isSuccessful) {
     // when user make any minor change or send save call then this step will be consider as complete for On board.  
    this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.TransactionInformation);
     this.toaster.show(ToasterTypeEnum.Success, 'Successful', 'Configuration saved successfully.');
    } else {
      this.toaster.show(ToasterTypeEnum.Error, 'Error', res.response);
    }
  }
}
