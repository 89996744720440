import {patchState, signalStore, withHooks, withMethods, withState} from "@ngrx/signals";
import {inject} from "@angular/core";
import {TerminologiesDto} from "../../shared/dtos/on-board.dto";
import {TerminologyService} from "../services/terminology.service";

type TerminologyState = {
  terminologies: TerminologiesDto[];
}
export const terminologyStore = signalStore({providedIn: 'root'},
  withState<TerminologyState>({terminologies: [] as TerminologiesDto[]}),
  withMethods((store) => {
    const terminologyService = inject(TerminologyService);
    return {
      async load() {
        const terminologies = await terminologyService.getTerminologies();
        if (terminologies?.length) {
          patchState(store, {terminologies});
        }
      },
      update(terminologies: TerminologiesDto[]) {
        patchState(store, (state) => ({terminologies: terminologies}));
      }
    }
  }),
  withHooks({
    async onInit(store) {
      await store.load();
    }
  })
)
