import {Injectable} from "@angular/core";
import {ApiHandlerService} from "./base/api-handler.service";
import {lastValueFrom} from "rxjs";
import {API_ENDPOINTS} from "../../shared/utils/global";

@Injectable({
  providedIn: 'root'
})

export class ClassesService {
  constructor(private apiService: ApiHandlerService) {
  }

  async getOnlineClasses(studentId: number) {
    return await lastValueFrom(this.apiService.Get(API_ENDPOINTS.Classes.onlineClasses + '?studentId=' + studentId));
  }

  async joinClass(studentName: string, classId: number) {
    return await lastValueFrom(this.apiService.Post(API_ENDPOINTS.Classes.joinClass, {
      StudentName: studentName,
      ClassId: classId
    }));
  }
}
