
  <div class="model-content-wrapper">
    <header class="modal-header">
      @if ((this.configs.title ||'').length > 0) {
        <h4>{{this.configs.title}}</h4>
       }
  
      <button (click)="closeModal()" type="button" class="icon-button border-0" pButton
        [icon]="'fa-solid fa-xmark'"></button>
    </header>
  <div class="modal-body">
    @if(this.configs) {
      <div class="brand-logo-section">
        @if ((this.configs.description ||'').length > 0) { 
        <div class="card-info-title mb-3">
          <p class="text-500">{{this.configs.description}}</p>
          </div >
           }
        @if (isDataLoaded) {  
        <div class="brand-upload-card mb-5">
          <input type="file" class="hidden" id="upload-logo" accept="image/*" (change)="changeImage($event)">
          <label class="brand-upload-card-label" for="upload-logo">
      
            @if((this.configs.image || '').length > 0){
            <div class="brand-uploaded-logo-card">
              <img src="{{this.configs.image}}" class="img-responsive logoFull">
            </div>
            }@else{
              @if((this.configs.defaultImage||'').length> 0 ){
                <div class="brand-uploaded-logo-card">
                  <img src="{{this.configs.defaultImage}}" class="img-responsive logoFull">
                </div>
              }@else {
                <div>
                  <tr>
                    <td colspan="8">
                      <div class="flex flex-column justify-content-center align-items-center gap-2 p-10"> <img
                          src="../../../../assets/images/No-Data-Image.svg" alt="" height="200rem">
                        <div class="font-bold text-lg">No Records Found</div>
                      </div>
                    </td>
                  </tr>
                </div>  
              }
          
            }
            @if(logoLoader){
            <div class="loader-overlay">
              <div class="loader"></div>
            </div>
            }
            <i class="fa-solid fa-pen-to-square edit-icon"></i>
      
          </label>
      
        </div>}@else{
          <div class="m-3">
              <calimatic-skeleton [configs]="{height:'15rem',width:'100%',repeat:1,styleClass:'mb-2'}"></calimatic-skeleton>
            </div>
        }  
        
     
      </div>
    
    }@else {
      <div>
        <tr>
          <td colspan="8">
            <div class="flex flex-column justify-content-center align-items-center gap-2 p-10"> <img
                src="../../../../assets/images/No-Data-Image.svg" alt="" height="200rem">
              <div class="font-bold text-lg">No Image</div>
            </div>
          </td>
        </tr>
      </div> 
    }
  </div>
  <footer class="modal-footer">
  <label for="close" pButton label="Close" class="clm-secondary-btn" [loading]="isProcessing" (click)="closeModal()"></label>  
  <button type="button" [loading]="isProcessing" pButton label="Reset to default" (click)="resetDefaultImage()" class="clm-primary-btn" ></button>
  </footer>
  </div>

