<div class="board-content-wrapper">
  <div class="stripe-payment-wrapper">

    @if (!loading) {
      <form [formGroup]="stripeForm">
      <div class="s_p_header mb-4">
        <h4 >Add Stripe Informations</h4>
        <p class="text-400">Get the following details from your Stripe account. Use Stripe documentation or support if you need any help.</p>
      </div>
      <div class="s_p_content-form">
  
        <div class="flex flex-column gap-2 mb-5">
          <p-floatLabel>
            <input class="w-full" [forceValid]="validateInput" formControlName="accountName" pInputText
              validateInput="Account name is required" />
            <label for="account-name">Account Name</label>
          </p-floatLabel>
        </div>
        <div class="flex flex-column gap-2 mb-5">
          <p-floatLabel>
            <input class="w-full"
              [forceValid]="validateInput"
              formControlName="secretKey"
              pInputText
              validateInput="Secret key is required"/>
            <label for="account-name">Secret Key</label>
          </p-floatLabel>
        </div>
        <div class="flex flex-column gap-2 mb-5">
          <p-floatLabel>
            <input class="w-full"
              [forceValid]="validateInput"
              formControlName="publishableKey"
              pInputText
              validateInput="Publishable key is required"/>
            <label for="account-name">Publishable key</label>
          </p-floatLabel>
        </div>
        <div class="flex flex-column gap-2 mb-5">
          <p-floatLabel>
            <input class="w-full"
              [forceValid]="validateInput"
              formControlName="clientKey"
              pInputText
              validateInput="Client key is required"/>
            <label for="account-name">Client Key</label>
          </p-floatLabel>
        </div>
        <div class="flex flex-column gap-2 my-5 float-label-dropdown">
          <calimatic-dropdown (valueChange)="onCurrencyChange($event)" [configs]="{
          options:stripeCurrencies,
          label:'Text',
          value:'Value',
          selectable:true,
          floatLabel:'Select currency',
          filter:true,
          buttonClass:'w-full',
          appendTo:'body',
          virtualScroll: true,
          virtualScrollSize: '38'
        }" [value]="stripeFormControls.currencyCode.value.toLowerCase()">
          </calimatic-dropdown>
        </div>
  
        <div class="h-2rem"></div>
  
        <div class="card mb-5 p-0">
          <div class="card-body p-3 flex md:flex-row flex-column-reverse gap-4 align-items-center">
            <div>
              <h4 >Stripe Dashboard</h4>
              <p class="line-height-3">Allows businesses to manage payments, view transaction details, and access financial reports.</p>
            </div>
            <img class="flex-shrink-0" src="/assets/images/LOGO-850-stripe.png" width="180" alt="payment-image">
          </div>
          <div class="card-footer-with-link">
            <a href="{{dashboardURL}}" target="_blank">Manage Stripe Payments <i class="fa-solid fa-arrow-up-right-from-square ml-2"></i></a>
          </div>
        </div>
  
        <div class="text-right mt-4">
          <button pButton class="clm-primary-btn" [loading]="processing" [rounded]="true" label="Save" 
          (click)="save()" [disabled]="processing"></button>
        </div>
        
      </div>
    </form>
    }
    @else {
      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>
    }

  </div>


  <!-- <p-fieldset legend="">
    <ng-template pTemplate="header">
      <h4 class="mb-3 px-2">Stripe</h4>
    </ng-template>
    @if (!loading) {
      <form [formGroup]="stripeForm">
        <div class="flex flex-column gap-2 my-3">
          <label>Account Name</label>
          <input
            [forceValid]="validateInput"
            formControlName="accountName"
            pInputText
            placeholder="Enter your account name" validateInput="Account name is required"/>
        </div>
        <div class="flex flex-column gap-2 my-3">
          <label>Secret Key</label>
          <input
            [forceValid]="validateInput"
            formControlName="secretKey"
            pInputText
            placeholder="Enter stripe secret key"
            validateInput="Secret key is required"/>
        </div>
        <div class="flex flex-column gap-2 my-3">
          <label>Publishable key</label>
          <input
            [forceValid]="validateInput"
            formControlName="publishableKey"
            pInputText
            placeholder="Enter stripe publishable key"
            validateInput="Publishable key is required"/>
        </div>
        <div class="flex flex-column gap-2 my-3">
          <label>Client Key</label>
          <input
            [forceValid]="validateInput"
            formControlName="clientKey"
            pInputText
            placeholder="Enter stripe client key"
            validateInput="Client key is required"/>
        </div>
        <div class="flex flex-column gap-2 my-3">
          <label>Currency</label>
          <calimatic-dropdown (valueChange)="onCurrencyChange($event)" [configs]="{
          options:stripeCurrencies,
          label:'Text',
          value:'Value',
          placeholder:'Select currency',
          selectable:true,
          filter:true,
          buttonClass:'w-full'
        }" [value]="stripeFormControls.currencyCode.value">
          </calimatic-dropdown>
        </div>
        <div class="flex flex-column gap-2 my-3">
          <label>Stripe Dashboard URL</label>
          <a href="{{dashboardURL}}" target="_blank">{{ dashboardURL }}</a>
        </div>
        <div>
          <button [disabled]="processing" [loading]="processing" (click)="save()" pButton> &nbsp; Save</button>
        </div>
      </form>
    } @else {
      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>

      <calimatic-skeleton [configs]="{styleClass:'mt-3 mb-3',height:'1.5rem',width:'20rem'}"></calimatic-skeleton>
      <calimatic-skeleton [configs]="{styleClass:'mb-3',height:'3rem',width:'90%'}"></calimatic-skeleton>
    }
  </p-fieldset> -->
</div>
