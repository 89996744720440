import { Component, effect } from '@angular/core';
import { DashboardService } from '../../../../../core/services/dashboard.service';
import { CarouselModule } from 'primeng/carousel';
import { StudentPointsCountersDto } from '../../../../../shared/interfaces/student-points/IStudentPointWidget.interface';
import { TooltipModule } from 'primeng/tooltip';
import { MomentHelper, DateTimeFormats } from '../../../../../shared/services/moment-helper';

@Component({
  selector: 'calimatic-student-points-widget',
  standalone: true,
  imports: [CarouselModule,TooltipModule],
  templateUrl: './student-points-widget.component.html',
  styleUrl: './student-points-widget.component.scss'
})
export class StudentPointsWidgetComponent {
  formattedDate: string;


  isLoading:boolean=false;
  responsiveOptions = [
    {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    }
];
  StudentPoints : any[]=[];
  constructor(private dashboardService: DashboardService) {
    effect(async () => {
      this.formattedDate = MomentHelper.Now().format(DateTimeFormats.Monthly);

      await this.loadData();
    });
  }
  async loadData() {
    this.isLoading = true;
    const httpResponse = await this.dashboardService.getStudentPointCounter();
    if(httpResponse.isSuccessful){
      this.StudentPoints = this.mapStudentPointsToArray(httpResponse.response as StudentPointsCountersDto);
    }
    this.isLoading = false;

  }
  mapStudentPointsToArray(studentPoints: StudentPointsCountersDto) {
    const result = [];

    // Push main student points properties
    result.push({
        isStudentPoints: true,
        availablePoints: studentPoints.availablePoints,
        studentTotalBonusPoints: studentPoints.studentTotalBonusPoints,
        studentTotalRedeemedPoints: studentPoints.studentTotalRedeemedPoints,
        studentAchievedChallengesPoints: studentPoints.studentAchievedChallengesPoints,
    });

    // Push course-wise points
    for (const course of studentPoints.studentCourseWisePointsList) {
        result.push({
            isStudentPoints: false,
            courseId: course.courseId,
            courseName: course.courseName,
            courseChallengesAchievedPoints: course.courseChallengesAchievedPoints,
        });
    }

    return result;
}
}
