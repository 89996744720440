interface SelectListItem {
  Text: string;
  Value: string;
}

export const currencySymbols: { [key: string]: string } = {
  AED: 'د.إ',
  AUD: '$',
  BRL: 'R$',
  CAD: '$',
  CHF: 'CHF',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  DKK: 'kr',
  EUR: '€',
  GBP: '£',
  HKD: '$',
  IDR: 'Rp',
  INR: '₹',
  JPY: '¥',
  KRW: '₩',
  MXN: '$',
  MYR: 'RM',
  NOK: 'kr',
  NZD: '$',
  PEN: 'S/.',
  PHP: '₱',
  PLN: 'zł',
  RUB: '₽',
  SGD: '$',
  THB: '฿',
  TRY: '₺',
  USD: '$',
  ZAR: 'R'
};

export function getSymbolFromCurrencyCode(currencyCode: string): string {
  return currencySymbols[currencyCode] || currencyCode;
}

export function getNmiCurrencies(): SelectListItem[] {
  const list: SelectListItem[] = [
    {Text: "Select Currency", Value: ""},
    {Text: `UAE Dirham (${getSymbolFromCurrencyCode("AED")})`, Value: "AED"},
    {Text: `Australian Dollar (${getSymbolFromCurrencyCode("AUD")})`, Value: "AUD"},
    {Text: `Brazilian Real (${getSymbolFromCurrencyCode("BRL")})`, Value: "BRL"},
    {Text: `Canadian Dollar (${getSymbolFromCurrencyCode("CAD")})`, Value: "CAD"},
    {Text: `Swiss Franc (${getSymbolFromCurrencyCode("CHF")})`, Value: "CHF"},
    {Text: `Chilean Peso (${getSymbolFromCurrencyCode("CLP")})`, Value: "CLP"},
    {Text: `Yuan Renminbi (${getSymbolFromCurrencyCode("CNY")})`, Value: "CNY"},
    {Text: `Colombian Peso (${getSymbolFromCurrencyCode("COP")})`, Value: "COP"},
    {Text: `Danish Krone (${getSymbolFromCurrencyCode("DKK")})`, Value: "DKK"},
    {Text: `Euro (${getSymbolFromCurrencyCode("EUR")})`, Value: "EUR"},
    {Text: `Pound Sterling (${getSymbolFromCurrencyCode("GBP")})`, Value: "GBP"},
    {Text: `Hong Kong Dollar (${getSymbolFromCurrencyCode("HKD")})`, Value: "HKD"},
    {Text: `Indonesian Rupiah (${getSymbolFromCurrencyCode("IDR")})`, Value: "IDR"},
    {Text: `Indian Rupee (${getSymbolFromCurrencyCode("INR")})`, Value: "INR"},
    {Text: `Yen (${getSymbolFromCurrencyCode("JPY")})`, Value: "JPY"},
    {Text: `Won (${getSymbolFromCurrencyCode("KRW")})`, Value: "KRW"},
    {Text: `Mexican Peso (${getSymbolFromCurrencyCode("MXN")})`, Value: "MXN"},
    {Text: `Malaysian Ringgit (${getSymbolFromCurrencyCode("MYR")})`, Value: "MYR"},
    {Text: `Norwegian Krone (${getSymbolFromCurrencyCode("NOK")})`, Value: "NOK"},
    {Text: `New Zealand Dollar (${getSymbolFromCurrencyCode("NZD")})`, Value: "NZD"},
    {Text: `Nuevo Sol (${getSymbolFromCurrencyCode("PEN")})`, Value: "PEN"},
    {Text: `Philippine Peso (${getSymbolFromCurrencyCode("PHP")})`, Value: "PHP"},
    {Text: `Zloty (${getSymbolFromCurrencyCode("PLN")})`, Value: "PLN"},
    {Text: `Russian Ruble (${getSymbolFromCurrencyCode("RUB")})`, Value: "RUB"},
    {Text: `Singapore Dollar (${getSymbolFromCurrencyCode("SGD")})`, Value: "SGD"},
    {Text: `Baht (${getSymbolFromCurrencyCode("THB")})`, Value: "THB"},
    {Text: `Turkish Lira (${getSymbolFromCurrencyCode("TRY")})`, Value: "TRY"},
    {Text: `US Dollar (${getSymbolFromCurrencyCode("USD")})`, Value: "USD"},
    {Text: `Rand (${getSymbolFromCurrencyCode("ZAR")})`, Value: "ZAR"},
  ];
  return list.sort((a, b) => a.Text.localeCompare(b.Text));
}

export function getStripeCurrencies() {
  return [
    {Text: `US Dollar (${getSymbolFromCurrencyCode("USD")})`, Value: "usd"},
    {Text: `UAE Dirham (${getSymbolFromCurrencyCode("AED")})`, Value: "aed"},
    {Text: `Albania Lek (${getSymbolFromCurrencyCode("ALL")})`, Value: "all"},
    {Text: `Netherlands Antilles Guilder (${getSymbolFromCurrencyCode("ANG")})`, Value: "ang"},
    {Text: `Australian Dollar (${getSymbolFromCurrencyCode("AUD")})`, Value: "aud"},
    {Text: `Aruba Guilder (${getSymbolFromCurrencyCode("AWG")})`, Value: "awg"},
    {Text: `Azerbaijan Manat (${getSymbolFromCurrencyCode("AZN")})`, Value: "azn"},
    {Text: `Bosnia and Herzegovina Convertible Mark (${getSymbolFromCurrencyCode("BAM")})`, Value: "bam"},
    {Text: `Barbados Dollar (${getSymbolFromCurrencyCode("BBD")})`, Value: "bbd"},
    {Text: `Bangladeshi Taka (${getSymbolFromCurrencyCode("BDT")})`, Value: "bdt"},
    {Text: `Bulgarian Lev (${getSymbolFromCurrencyCode("BGN")})`, Value: "bgn"},
    {Text: `Burundian Franc (${getSymbolFromCurrencyCode("BIF")})`, Value: "bif"},
    {Text: `Bermuda Dollar (${getSymbolFromCurrencyCode("BMD")})`, Value: "bmd"},
    {Text: `Brunei Dollar (${getSymbolFromCurrencyCode("BND")})`, Value: "bnd"},
    {Text: `Bahamas Dollar (${getSymbolFromCurrencyCode("BSD")})`, Value: "bsd"},
    {Text: `Botswanan Pula (${getSymbolFromCurrencyCode("BWP")})`, Value: "bwp"},
    {Text: `Belarusian Ruble (${getSymbolFromCurrencyCode("BYN")})`, Value: "byn"},
    {Text: `Belize Dollar (${getSymbolFromCurrencyCode("BZD")})`, Value: "bzd"},
    {Text: `Canadian Dollar (${getSymbolFromCurrencyCode("CAD")})`, Value: "cad"},
    {Text: `Congolese Franc (${getSymbolFromCurrencyCode("CDF")})`, Value: "cdf"},
    {Text: `Swiss Franc (${getSymbolFromCurrencyCode("CHF")})`, Value: "chf"},
    {Text: `Chinese Yuan (${getSymbolFromCurrencyCode("CNY")})`, Value: "cny"},
    {Text: `Czech Koruna (${getSymbolFromCurrencyCode("CZK")})`, Value: "czk"},
    {Text: `Danish Krone (${getSymbolFromCurrencyCode("DKK")})`, Value: "dkk"},
    {Text: `Dominican Peso (${getSymbolFromCurrencyCode("DOP")})`, Value: "dop"},
    {Text: `Algerian Dinar (${getSymbolFromCurrencyCode("DZD")})`, Value: "dzd"},
    {Text: `Egyptian Pound (${getSymbolFromCurrencyCode("EGP")})`, Value: "egp"},
    {Text: `Ethiopian Birr (${getSymbolFromCurrencyCode("ETB")})`, Value: "etb"},
    {Text: `Euro (${getSymbolFromCurrencyCode("EUR")})`, Value: "eur"},
    {Text: `Fijian Dollar (${getSymbolFromCurrencyCode("FJD")})`, Value: "fjd"},
    {Text: `British Pound Sterling (${getSymbolFromCurrencyCode("GBP")})`, Value: "gbp"},
    {Text: `Georgian Lari (${getSymbolFromCurrencyCode("GEL")})`, Value: "gel"},
    {Text: `Gibraltar Pound (${getSymbolFromCurrencyCode("GIP")})`, Value: "gip"},
    {Text: `Gambian Dalasi (${getSymbolFromCurrencyCode("GMD")})`, Value: "gmd"},
    {Text: `Guyana Dollar (${getSymbolFromCurrencyCode("GYD")})`, Value: "gyd"},
    {Text: `Hong Kong Dollar (${getSymbolFromCurrencyCode("HKD")})`, Value: "hkd"},
    {Text: `Croatian Kuna (${getSymbolFromCurrencyCode("HRK")})`, Value: "hrk"},
    {Text: `Hungarian Forint (${getSymbolFromCurrencyCode("HUF")})`, Value: "huf"},
    {Text: `Indonesian Rupiah (${getSymbolFromCurrencyCode("IDR")})`, Value: "idr"},
    {Text: `Israeli New Shekel (${getSymbolFromCurrencyCode("ILS")})`, Value: "ils"},
    {Text: `Indian Rupee (${getSymbolFromCurrencyCode("INR")})`, Value: "inr"},
    {Text: `Icelandic Krona (${getSymbolFromCurrencyCode("ISK")})`, Value: "isk"},
    {Text: `Jamaican Dollar (${getSymbolFromCurrencyCode("JMD")})`, Value: "jmd"},
    {Text: `Japanese Yen (${getSymbolFromCurrencyCode("JPY")})`, Value: "jpy"},
    {Text: `Kenyan Shilling (${getSymbolFromCurrencyCode("KES")})`, Value: "kes"},
    {Text: `Cambodian Riel (${getSymbolFromCurrencyCode("KHR")})`, Value: "khr"},
    {Text: `Comorian Franc (${getSymbolFromCurrencyCode("KMF")})`, Value: "kmf"},
    {Text: `South Korean Won (${getSymbolFromCurrencyCode("KRW")})`, Value: "krw"},
    {Text: `Cayman Islands Dollar (${getSymbolFromCurrencyCode("KYD")})`, Value: "kyd"},
    {Text: `Kazakhstani Tenge (${getSymbolFromCurrencyCode("KZT")})`, Value: "kzt"},
    {Text: `Lao Kip (${getSymbolFromCurrencyCode("LAK")})`, Value: "lak"},
    {Text: `Lebanese Pound (${getSymbolFromCurrencyCode("LBP")})`, Value: "lbp"},
    {Text: `Sri Lankan Rupee (${getSymbolFromCurrencyCode("LKR")})`, Value: "lkr"},
    {Text: `Liberian Dollar (${getSymbolFromCurrencyCode("LRD")})`, Value: "lrd"},
    {Text: `Lesotho Loti (${getSymbolFromCurrencyCode("LSL")})`, Value: "lsl"},
    {Text: `Lithuanian Litas (${getSymbolFromCurrencyCode("LTL")})`, Value: "ltl"},
    {Text: `Latvian Lats (${getSymbolFromCurrencyCode("LVL")})`, Value: "lvl"},
    {Text: `Libyan Dinar (${getSymbolFromCurrencyCode("LYD")})`, Value: "lyd"},
    {Text: `Moroccan Dirham (${getSymbolFromCurrencyCode("MAD")})`, Value: "mad"},
    {Text: `Moldovan Leu (${getSymbolFromCurrencyCode("MDL")})`, Value: "mdl"},
    {Text: `Malagasy Ariary (${getSymbolFromCurrencyCode("MGA")})`, Value: "mga"},
    {Text: `Macedonian Denar (${getSymbolFromCurrencyCode("MKD")})`, Value: "mkd"},
    {Text: `Mongolian Tugrik (${getSymbolFromCurrencyCode("MNT")})`, Value: "mnt"},
    {Text: `Macanese Pataca (${getSymbolFromCurrencyCode("MOP")})`, Value: "mop"},
    {Text: `Mauritian Rupee (${getSymbolFromCurrencyCode("MUR")})`, Value: "mur"},
    {Text: `Maldivian Rufiyaa (${getSymbolFromCurrencyCode("MVR")})`, Value: "mvr"},
    {Text: `Malawian Kwacha (${getSymbolFromCurrencyCode("MWK")})`, Value: "mwk"},
    {Text: `Mexican Peso (${getSymbolFromCurrencyCode("MXN")})`, Value: "mxn"},
    {Text: `Malaysian Ringgit (${getSymbolFromCurrencyCode("MYR")})`, Value: "myr"},
    {Text: `Mozambican Metical (${getSymbolFromCurrencyCode("MZN")})`, Value: "mzn"},
    {Text: `Namibian Dollar (${getSymbolFromCurrencyCode("NAD")})`, Value: "nad"},
    {Text: `Nigerian Naira (${getSymbolFromCurrencyCode("NGN")})`, Value: "ngn"},
    {Text: `Nicaraguan Cordoba (${getSymbolFromCurrencyCode("NIO")})`, Value: "nio"},
    {Text: `Norwegian Krone (${getSymbolFromCurrencyCode("NOK")})`, Value: "nok"},
    {Text: `Nepalese Rupee (${getSymbolFromCurrencyCode("NPR")})`, Value: "npr"},
    {Text: `New Zealand Dollar (${getSymbolFromCurrencyCode("NZD")})`, Value: "nzd"},
    {Text: `Panamanian Balboa (${getSymbolFromCurrencyCode("PAB")})`, Value: "pab"},
    {Text: `Peruvian Nuevo Sol (${getSymbolFromCurrencyCode("PEN")})`, Value: "pen"},
    {Text: `Papua New Guinean Kina (${getSymbolFromCurrencyCode("PGK")})`, Value: "pgk"},
    {Text: `Philippine Peso (${getSymbolFromCurrencyCode("PHP")})`, Value: "php"},
    {Text: `Pakistani Rupee (${getSymbolFromCurrencyCode("PKR")})`, Value: "pkr"},
    {Text: `Polish Zloty (${getSymbolFromCurrencyCode("PLN")})`, Value: "pln"},
    {Text: `Paraguayan Guarani (${getSymbolFromCurrencyCode("PYG")})`, Value: "pyg"},
    {Text: `Qatari Riyal (${getSymbolFromCurrencyCode("QAR")})`, Value: "qar"},
    {Text: `Romanian Leu (${getSymbolFromCurrencyCode("RON")})`, Value: "ron"},
    {Text: `Rwandan Franc (${getSymbolFromCurrencyCode("RWF")})`, Value: "rwf"},
    {Text: `Saudi Riyal (${getSymbolFromCurrencyCode("SAR")})`, Value: "sar"},
    {Text: `Solomon Islands Dollar (${getSymbolFromCurrencyCode("SBD")})`, Value: "sbd"},
    {Text: `Seychellois Rupee (${getSymbolFromCurrencyCode("SCR")})`, Value: "scr"},
    {Text: `Swedish Krona (${getSymbolFromCurrencyCode("SEK")})`, Value: "sek"},
    {Text: `Singapore Dollar (${getSymbolFromCurrencyCode("SGD")})`, Value: "sgd"},
    {Text: `Saint Helena Pound (${getSymbolFromCurrencyCode("SHP")})`, Value: "shp"},
    {Text: `Sierra Leonean Leone (${getSymbolFromCurrencyCode("SLL")})`, Value: "sll"},
    {Text: `Somali Shilling (${getSymbolFromCurrencyCode("SOS")})`, Value: "sos"},
    {Text: `Surinamese Dollar (${getSymbolFromCurrencyCode("SRD")})`, Value: "srd"},
    {Text: `South Sudanese Pound (${getSymbolFromCurrencyCode("SSP")})`, Value: "ssp"},
    {Text: `Sao Tome and Principe Dobra (${getSymbolFromCurrencyCode("STD")})`, Value: "std"},
    {Text: `Salvadoran Colon (${getSymbolFromCurrencyCode("SVC")})`, Value: "svc"},
    {Text: `Swazi Lilangeni (${getSymbolFromCurrencyCode("SZL")})`, Value: "szl"},
    {Text: `Thai Baht (${getSymbolFromCurrencyCode("THB")})`, Value: "thb"},
    {Text: `Tajikistani Somoni (${getSymbolFromCurrencyCode("TJS")})`, Value: "tjs"},
    {Text: `Tongan Paʻanga (${getSymbolFromCurrencyCode("TOP")})`, Value: "top"},
    {Text: `Trinidad and Tobago Dollar (${getSymbolFromCurrencyCode("TTD")})`, Value: "ttd"},
    {Text: `Tunisian Dinar (${getSymbolFromCurrencyCode("TND")})`, Value: "tnd"},
    {Text: `Turkish Lira (${getSymbolFromCurrencyCode("TRY")})`, Value: "try"},
    {Text: `Tanzanian Shilling (${getSymbolFromCurrencyCode("TZS")})`, Value: "tzs"},
    {Text: `Ugandan Shilling (${getSymbolFromCurrencyCode("UGX")})`, Value: "ugx"},
    {Text: `Ukrainian Hryvnia (${getSymbolFromCurrencyCode("UAH")})`, Value: "uah"},
    {Text: `Uruguayan Peso (${getSymbolFromCurrencyCode("UYU")})`, Value: "uyu"},
    {Text: `Uzbekistani Som (${getSymbolFromCurrencyCode("UZS")})`, Value: "uzs"},
    {Text: `Venezuelan Bolivar (${getSymbolFromCurrencyCode("VEF")})`, Value: "vef"},
    {Text: `Vanuatu Vatu (${getSymbolFromCurrencyCode("VUV")})`, Value: "vuv"},
    {Text: `Western Samoan Tala (${getSymbolFromCurrencyCode("WST")})`, Value: "wst"},
    {Text: `Central African CFA Franc (${getSymbolFromCurrencyCode("XAF")})`, Value: "xaf"},
    {Text: `East Caribbean Dollar (${getSymbolFromCurrencyCode("XCD")})`, Value: "xcd"},
    {Text: `West African CFA Franc (${getSymbolFromCurrencyCode("XOF")})`, Value: "xof"},
    {Text: `CFP Franc (${getSymbolFromCurrencyCode("XPF")})`, Value: "xpf"},
    {Text: `Yemeni Rial (${getSymbolFromCurrencyCode("YER")})`, Value: "yer"},
    {Text: `South African Rand (${getSymbolFromCurrencyCode("ZAR")})`, Value: "zar"},
    {Text: `Zambian Kwacha (${getSymbolFromCurrencyCode("ZMW")})`, Value: "zmw"},
    {Text: `Zimbabwean Dollar (${getSymbolFromCurrencyCode("ZWL")})`, Value: "zwl"}
  ];
}
