import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'memoized',
  standalone: true
})
export class MemoizedPipe implements PipeTransform {

  transform(fn: Function, ...args: unknown[]) {
    return fn(...args);
  }

}
