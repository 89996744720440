import {CanActivateFn, Router} from "@angular/router";
import {AuthService} from "../auth/auth.service";
import {inject} from "@angular/core";
import {appRoutePaths} from "../../../app.routes";

export const authGuard: CanActivateFn = async (route, state) => {
  const permissionsService = inject(AuthService);
  const router = inject(Router);
  const authenticated = permissionsService.isAuthenticated();
  if (!authenticated) {
    await router.navigate([`/${appRoutePaths.login.v3}`], {
      queryParams:
        {['returnUrl']: state.url}
    });
  }
  return authenticated;
};
