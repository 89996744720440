<div (click)="visible = true" class="help-text-btn">
  <i class="fa-regular fa-circle-question"></i>
  <span class="md:inline hidden">{{ text() }}</span>
</div>

<p-dialog (onHide)="onHidden()" (onShow)="onShown()" [(visible)]="visible" [closable]="true" [draggable]="false"
          [modal]="true"
          [resizable]="false" [styleClass]="'clm-md-modal'" header="Header">
  <ng-template pTemplate="header">
    <header class="">
      <h5>{{ text() }}</h5>
    </header>
  </ng-template>
  <div class="modal-body help-modal">
    <video #videoPlayer class="dash_Animation_video" controls height="1302"
           poster="/assets/images/poster-image.png"
           width="1960">
      <source
        src="{{link()}}"
        type="video/mp4">
    </video>
  </div>
</p-dialog>
