import {Component, input, output} from '@angular/core';
import {DropdownModule} from "primeng/dropdown";
import {DropdownConfigsInterface} from "../../interfaces/dropdown/dropdown-configs.interface";
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ButtonModule} from 'primeng/button';
import {FormsModule} from "@angular/forms";
import { FloatLabelModule } from 'primeng/floatlabel';

@Component({
  selector: 'calimatic-dropdown',
  standalone: true,
  imports: [
    DropdownModule,
    OverlayPanelModule,
    ButtonModule,
    FormsModule,
    FloatLabelModule
  ],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss'
})
export class DropdownComponent {
  configs = input.required<Partial<DropdownConfigsInterface>>();
  value = input.required();
  valueChange = output<any>();
}
