@if (configs().isIconBtn) {
  <p-selectButton (ngModelChange)="valueChange.emit($event)" [options]="configs().options" [ngModel]="value()"
                  optionLabel="icon" [optionLabel]="configs().label"
                  [optionValue]="configs().value"
                  [styleClass]="'selectable-btngroup'">
    <ng-template let-item pTemplate>
      <i [class]="item.icon+' '+'relative'"></i>
    </ng-template>
  </p-selectButton>
} @else {
  <p-selectButton (ngModelChange)="valueChange.emit($event)"
                  [multiple]="false"
                  [ngModel]="value()"
                  [optionLabel]="configs().label"
                  [optionValue]="configs().value"
                  [styleClass]="'selectable-btngroup'"
                  [options]="configs().options"
                  [unselectable]="true"
                  ></p-selectButton>
}

