import {Directive, effect, ElementRef, HostListener, input, Renderer2} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[validateInput]',
  standalone: true
})
export class ValidateInputDirective {
  errorMessage = input<string>('', {alias: 'validateInput'});
  errorClasses = input<string[]>(['ng-invalid', 'ng-dirty']);
  forceValid = input<boolean>(false);
  validators = input<string[][]>();

  #validators: string[][] = [];
  #errorElement: HTMLElement | null;
  #defaultMessage = "This field is required";

  constructor(private el: ElementRef, private renderer: Renderer2, private control: NgControl) {
    effect(() => {
      //initial validator
      this.#validators.push(['required', this.errorMessage() || this.#defaultMessage]);

      if (this.validators()?.length) {
        this.#validators.push(...this.validators());
      }
      this.forceValid() && this.validator();
    });
  }

  @HostListener('input')
  @HostListener('blur')
  public inputListener = () => this.validator();


  //#region [Private]

  private validator() {
    if (this.control.invalid && (this.forceValid() || this.control.dirty || this.control.touched)) {
      (this.errorClasses() || []).forEach(x => {
        if (x?.length) {
          this.renderer.addClass(this.el.nativeElement, x);
        }
      });

      this.#validators.forEach(x => {
        if ((x?.length || 0) > 1 && this.control.errors[x[0]]) {
          this.removeErrorMessage();
          this.addErrorMessage(x[1]);
          return;
        }
      });
    } else {
      (this.errorClasses() || []).forEach(x => {
        if (x?.length) {
          this.renderer.removeClass(this.el.nativeElement, x);
        }
      });
      this.removeErrorMessage();
    }
  }

  private addErrorMessage(message: string) {
    if (!this.#errorElement) {
      this.#errorElement = this.renderer.createElement('span');
      this.renderer.addClass(this.#errorElement, 'form-invalid');
      this.renderer.addClass(this.#errorElement, 'block');
      const text = this.renderer.createText(message);
      this.renderer.appendChild(this.#errorElement, text);
      this.renderer.appendChild(this.el.nativeElement.parentNode, this.#errorElement);
    }
  }

  private removeErrorMessage() {
    if (this.#errorElement) {
      this.renderer.removeChild(this.el.nativeElement.parentNode, this.#errorElement);
      this.#errorElement = null;
    }
  }

  //#endregion [Private]
}

