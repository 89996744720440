@if (!loading) {
  <div class="card _countercards">
    <div class="card-action-wrapper">
      <span (click)="loadData()" class="action_btn refresh-btn" [pTooltip]="'Refresh widget'" tooltipPosition="bottom"
            [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-rotate"></i></span>
      @if (edit()) {
        <span (click)="onRemove.emit()" class="action_btn" [pTooltip]="'Remove widget'" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-xmark"></i></span>
      } @else {
        <span class="card-info-tooltip-top action_btn" [pTooltip]="widget().description" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}">
                 <i class="fa-solid fa-circle-info"></i>
        </span>
      }
    </div>

    <div class="counter-card-header">
      <h5>{{ widget().title }}</h5>
    </div>
    <div>
      <ul>
        @for (lead of leadCountByStatus; track $index) {
          <li>
            <span class="circle circle-{{$index + 1}}"></span>
            <span class="count_title">{{ lead.leadStatusName }}</span>
            <span class="counts">{{ lead.leadsCount }}</span>
          </li>
        }
      </ul>
    </div>
  </div>
} @else {
  <calimatic-counter-trio-skeleton></calimatic-counter-trio-skeleton>
}
