import {Component, effect, input, output} from '@angular/core';
import {BarChartComponent} from "../../charts/bar-chart/bar-chart.component";
import {ChartSkeletonComponent} from "../skeletons/chart-skeleton/chart-skeleton.component";
import {DropdownComponent} from "../../../../../shared/components/dropdown/dropdown.component";
import {DropdownDto} from "../../../../../shared/dtos/dropdown-dto";
import {StackBarChartDataDto} from "../../../../../shared/dtos/chart-data.dto";
import {DashboardService} from "../../../../../core/services/dashboard.service";
import {TooltipModule} from "primeng/tooltip";
import {AttendanceTypeEnum} from "../../../../../shared/enums/attendance-type.enum";
import {MultiSelectComponent} from "../../../../../shared/components/multi-select/multi-select.component";
import {MultiSelectDisplayEnums} from "../../../../../shared/enums/multi-select/multi-select.enums";
import {DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";
import {StackBarChartComponent} from "../../charts/stack-bar-chart/stack-bar-chart.component";

@Component({
  selector: 'calimatic-student-attendance-widget',
  standalone: true,
  imports: [
    BarChartComponent,
    ChartSkeletonComponent,
    DropdownComponent,
    TooltipModule,
    MultiSelectComponent,
    StackBarChartComponent
  ],
  templateUrl: './student-attendance.component.html',
  styleUrl: './student-attendance.component.scss'
})
export class StudentAttendanceComponent {

  //inputs
  widget = input.required<DashboardWidgetsDTO>();
  franchises = input.required<DropdownDto[]>();
  companyId = input.required<number>();
  isFranchise = input.required<boolean>();
  edit = input.required();

  //outputs
  onRemove = output();

  attendanceTypes: DropdownDto[] = [{
    name: 'Present',
    id: AttendanceTypeEnum.Present
  },
    {
      name: 'Absent',
      id: AttendanceTypeEnum.Absent
    },
    {
      name: 'Casual',
      id: AttendanceTypeEnum.Casual
    }, {
      name: 'Trial',
      id: AttendanceTypeEnum.Trial
    }, {
      name: 'Makeup',
      id: AttendanceTypeEnum.Makeup
    }, {
      name: 'Cancelled Class',
      id: AttendanceTypeEnum.CancelledClass
    }, {
      name: 'Left Early',
      id: AttendanceTypeEnum.LeftEarly
    }, {
      name: 'Expected Absent',
      id: AttendanceTypeEnum.ExpectedAbsent
    }, {
      name: 'First Day Ever',
      id: AttendanceTypeEnum.FirstDayEver
    }, {
      name: 'First Day at Location',
      id: AttendanceTypeEnum.FirstDayAtLocation
    }, {
      name: 'First Day in Class',
      id: AttendanceTypeEnum.FirstDayInClass
    }, {
      name: 'First Day in Course',
      id: AttendanceTypeEnum.FirstDayInCourse
    }, {
      name: 'First Day in Level',
      id: AttendanceTypeEnum.FirstDayInLevel
    }, {
      name: 'Excuse Absent',
      id: AttendanceTypeEnum.ExcuseAbsent
    }, {
      name: 'Unexcuse Absent',
      id: AttendanceTypeEnum.UnExcuseAbsent
    }, {
      name: 'Arrived Late',
      id: AttendanceTypeEnum.ArrivedLate
    }];

  selectedType: number[];
  selectedFranchise: number[] = [];
  loading = false;
  data: StackBarChartDataDto[] = [];
  protected readonly multiSelectDisplayEnums = MultiSelectDisplayEnums;
  protected readonly multiSelectDisplay = MultiSelectDisplayEnums;

  constructor(private dashboardService: DashboardService) {
    effect(async () => {
      this.selectedType = this.attendanceTypes.map(x => x.id);
      this.selectedFranchise = this.franchises().map(x => x.id);
      await this.loadData();
    });
  }

  async onTypeChange(value: any) {
    this.selectedType = value;
    await this.loadData();
  }

  async onFranchiseChange(value: any) {
    this.selectedFranchise = value;
    await this.loadData();
  }

  async loadData() {
    if (this.isFranchise()) {
      this.selectedFranchise = [this.companyId()];
    }

    if (this.selectedType?.length && this.selectedFranchise?.length) {
      this.loading = true;
      this.data = await this.dashboardService.getStudentAttendanceData(this.selectedType.toString(), this.selectedFranchise.toString());
      this.loading = false;
    }
  }
}
