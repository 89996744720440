<form [formGroup]="customUrlConfig" (ngSubmit)="onSubmitCustomURLConfigs()" (keyup.enter)="onSubmitCustomURLConfigs()">
    <div class="modal-content-wrapper">
        <header class="modal-header">
            <h4 >Custom URL Configuration</h4>
            <button (click)="closeModal()" type="button" class="icon-button border-0" pButton [icon]="'fa-solid fa-xmark'"></button>
        </header>
        <div class="modal-body">
            <div class="modal-body-content">
    
                <div class="flex flex-column gap-2 mb-4">
                    <label>Portal url <i class="fa-solid fa-circle-info ml-1 cursor-pointer" pTooltip="Get a custom Portal URL (Example – Instead of https://your-site-name-lms.calimatic.com, if you prefer something like https://portal.your-domain.com)" tooltipPosition="bottom"
                        [tooltipOptions]="{showDelay:500}"></i></label>
                    <p-inputGroup>
                        <p-inputGroupAddon>https://portal.</p-inputGroupAddon>
                        <p-floatLabel class="flex-grow-1">
                            <input class="w-full {{formControls.portalUrl.invalid && (formControls.portalUrl.dirty || formControls.portalUrl.touched) ? 'ng-invalid' : ''}}" 
                            pInputText id="portal-url" formControlName="portalUrl" placeholder="your-portal-domain" required />
                        </p-floatLabel>
                        <p-inputGroupAddon>.com</p-inputGroupAddon>
                    </p-inputGroup>
                    @if (formControls.portalUrl.invalid && (formControls.portalUrl.dirty || formControls.portalUrl.touched)) {
                        <span class="form-invalid">Please enter valid url</span>
                    }
                </div>

                <div class="flex flex-column gap-2 mb-4">
                    <label>Website url <i class="fa-solid fa-circle-info ml-1 cursor-pointer" pTooltip="Get a custom website url (Example – Instead of https://your-site-name-lms.calimatic.com, if you prefer something like https://secure.your-domain.com)" tooltipPosition="bottom"
                        [tooltipOptions]="{showDelay:500}"></i></label>
                    <p-inputGroup>
                        <p-inputGroupAddon>https://secure.</p-inputGroupAddon>
                        <p-floatLabel class="flex-grow-1">
                            <input class="w-full {{formControls.secureUrl.invalid && (formControls.secureUrl.dirty || formControls.secureUrl.touched) ? 'ng-invalid' : ''}}" 
                            pInputText id="secureUrl" formControlName="secureUrl" placeholder="your-secure-domain" />
                        </p-floatLabel>
                        <p-inputGroupAddon>.com</p-inputGroupAddon>
                    </p-inputGroup>
                    @if (formControls.secureUrl.invalid && (formControls.secureUrl.dirty || formControls.secureUrl.touched)) {
                        <span class="form-invalid">Please enter valid url</span>
                    }
                </div>

                <!-- <div class="flex flex-column gap-2 mb-4">
                    <label>Browser title</label>
                    <p-floatLabel>
                        <input class="w-full {{formControls.browserTitle.invalid && (formControls.browserTitle.dirty || formControls.browserTitle.touched) ? 'ng-invalid' : ''}}" 
                        pInputText id="portal-url" formControlName="browserTitle" />
                    </p-floatLabel>
                </div> -->
            </div>
        </div>
        <footer class="modal-footer">
            <button pButton type="button" (click)="closeModal()" class="clm-secondary-btn border-0" [rounded]="true" label="Cancel"></button>
            <button pButton type="submit" [disabled]="!customUrlConfig.valid" class="clm-primary-btn" [rounded]="true" label="Submit"></button>
        </footer>
    </div>
</form>