import {Component, effect, inject, Inject} from '@angular/core';
import {TableModule} from 'primeng/table';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {FormsModule} from '@angular/forms';
import {OnboardService} from '../../../../core/services/onboard.service';
import {CommonModule} from '@angular/common';
import {ToastModule} from 'primeng/toast';
import {TerminologiesDto} from '../../../../shared/dtos/on-board.dto';
import {AppTokens} from '../../../../shared/utils/global';
import {IToaster} from '../../../../shared/interfaces/toaster/IToaster';
import {ToasterTypeEnum} from '../../../../shared/enums/toaster-type.enum';
import {OnboardSetionStepsEnum} from '../../../../shared/enums/onboard.enum';
import {TerminologyService} from "../../../../core/services/terminology.service";
import {terminologyStore} from "../../../../core/stores/terminology.store";
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'calimatic-terminologies',
  standalone: true,
  imports: [TableModule, ButtonModule, InputTextModule, FormsModule, CommonModule, ToastModule],
  templateUrl: './terminologies.component.html',
  styleUrl: './terminologies.component.scss',
  providers:[DynamicDialogRef]
})
export class TerminologiesComponent {
  terminologies: TerminologiesDto[] = [];
  isFormInvalid: boolean = false;
  #terminologiesStore = inject(terminologyStore);

  // this bit used for hiding tabs which are not required for on boarding process
  isOnboardComponent:boolean = true;

  constructor(@Inject(AppTokens.toaster) private toasterService: IToaster,private ref: DynamicDialogRef, private terminologyService: TerminologyService, private onboardService: OnboardService) {
    effect(() => {
      this.terminologies = this.#terminologiesStore.terminologies();
    });
  }

  closeModal(){
    this.ref.close(true);
  }

  async saveTerminologies() {
    this.isFormInvalid = (this.terminologies || []).some(x => (x.name || '').trim() == '' || (x.label || '').trim() == '');
    if (this.isFormInvalid) {
      this.toasterService.show(ToasterTypeEnum.Error, 'Error', "fill the required fileds");
      return;
    } else {
      let res = await this.terminologyService.updateTerminology(this.terminologies)
      if (res.isSuccessful) {
        this.#terminologiesStore.update(this.terminologies);
        this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.Terminology);
        this.toasterService.show(ToasterTypeEnum.Success, 'Success', "Terminologies has been updated successfully.");
        this.closeModal();
      }
    }
  }

  onTerminologiesChange(event:any){
    if((event?.target?.value || '').trim() ==''){
      this.isFormInvalid =  true;
    }else{
      this.isFormInvalid =  false
    }
  }
}
