import {Component, effect, input, OnInit} from '@angular/core';
import {ChartModule} from 'primeng/chart';
import {ChartDataDto} from "../../../../../shared/dtos/chart-data.dto";

@Component({
  selector: 'calimatic-bar-chart',
  standalone: true,
  imports: [
    ChartModule
  ],
  templateUrl: './bar-chart.component.html',
  styleUrl: './bar-chart.component.scss'
})
export class BarChartComponent implements OnInit {
  data = input.required<ChartDataDto[]>();
  label = input.required<string>();
  displayLegend = input<boolean>(false);
  barThickness = input<string>('0.9');

  chartData: any;
  chartOpts: any;

  constructor() {
    effect(() => {
      if (this.data()) {
        this.chartData = this.prepareChartData();
      }
    });
  }

  ngOnInit() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.chartOpts = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: this.displayLegend(),
          labels: {
            color: textColor,
          }
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            display: false,
          }
        }
      }
    };
  }

  prepareChartData() {
    return {
      labels: this.data().map(x => x.key),
      datasets: [
        {
          label: this.label(),
          data: this.data().map(x => x.value),
          backgroundColor: ['#00589c', '#016fc4', '#1891c3', '#3ac0da', '#3dc6c3', '#50e3c2'],
          // borderColor: ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'],
          // borderWidth: 1
          borderRadius: 4,
          barPercentage: this.barThickness(),
        }
      ]
    };
  }
}
