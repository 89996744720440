export enum LoginTemplateEnums {
  standard = "standard",
  smavyTemplate = "smavyTemplate"
}

export enum LoginImageTypEnum {
  Image = 'image',
  Svg = 'svg'
}

export enum ForgotPasswordSlidesEnum {
  UserValidateForm = 1,
  OtpVerificationForm,
  PasswordResetForm,
  PasswordVerificationComplete
}


export enum LoginErrorCodeEnum {
  InvalidUserNameOrPassword = 1,
  InActiveOrDeleted,
  NotVerified
}
