import {Component, Inject, inject, OnInit} from '@angular/core';
import {FileUploadModule} from 'primeng/fileupload';
import {FormsModule} from '@angular/forms';
import {MessageService} from 'primeng/api';

import {ToastModule} from 'primeng/toast';
import {SkeletonModule} from 'primeng/skeleton';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TabViewModule} from 'primeng/tabview';
import {FloatLabelModule} from 'primeng/floatlabel';

import {ColorPickerModule} from 'ngx-color-picker';
import {CommonModule} from '@angular/common';
import {companyConfigStore} from '../../../../../core/stores/company-config-store';
import {PlatformBrandingDto} from '../../../../../shared/dtos/on-board.dto';
import {AppConstants, AppTokens} from '../../../../../shared/utils/global';
import {IToaster} from '../../../../../shared/interfaces/toaster/IToaster';
import {SystemConfigService} from '../../../../../core/services/system-config.service';
import {OnboardService} from '../../../../../core/services/onboard.service';
import {ToasterTypeEnum} from '../../../../../shared/enums/toaster-type.enum';
import {OnboardSetionStepsEnum} from '../../../../../shared/enums/onboard.enum';
import {LoginImageTypEnum} from '../../../../../shared/enums/login.enum';
import {InputTextModule} from 'primeng/inputtext';
import {SkeletonComponent} from '../../../../../shared/components/skeleton/skeleton.component';
import {Router} from '@angular/router';

@Component({
  selector: 'calimatic-platform-branding',
  standalone: true,
  templateUrl: './platform-branding.component.html',
  styleUrl: './platform-branding.component.scss',
  providers: [MessageService],
  imports: [FileUploadModule, FormsModule, ToastModule, SkeletonModule, ProgressSpinnerModule, TabViewModule, FloatLabelModule, SkeletonComponent, InputTextModule, ColorPickerModule, CommonModule]
})
export class PlatformBrandingComponent implements OnInit {

  configStore = inject(companyConfigStore);
  defaultLogo: string = '';
  platformBrandingConfigs: PlatformBrandingDto = {} as PlatformBrandingDto;
  defaultLoginImage: string = '';
  companyLogoMaxSize: number = 200 * 1024;
  allowedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
  allowFavIconType = ['image/x-icon'];
  defaultFavIcon: string = '';
  defaultLoginLogo: string = "";
  favIconSize: number = 20 * 1024;
  isPlatformConfigLoaded: boolean = false;

  //Reset button loaders
  resetLogoLoader: boolean = false;
  resetLoginImageLoader: boolean = false;
  resetFaviconLoader: boolean = false;
  resetLoginLogoLoader: boolean = false;
  //Image Upload Loaders
  logoLoader: boolean = false;
  loginImageLoader: boolean = false;
  faviconLoader: boolean = false;
  loginLogoLoader: boolean = false;

  currentFranchiseSecureLogoBgColor: string = "";
  currentMenuBarLogoBgColor: string = "";

  // this bit used for hiding tabs which are not required for on boarding process
  isOnboardComponent: boolean = false;

  constructor(@Inject(AppTokens.toaster) private toasterService: IToaster, private systemConfigService: SystemConfigService, private onboardService: OnboardService, private router: Router,
  ) {
    this.defaultLogo = AppConstants.defaultConfigs.companyLogo;
    const newLocal = this;
    newLocal.defaultLoginImage = AppConstants.defaultConfigs.loginImage;
    this.defaultFavIcon = AppConstants.defaultConfigs.favIcon;
    this.defaultLoginLogo = AppConstants.defaultConfigs.loginLogo;
  }

  async ngOnInit() {
    this.checkRoute();
    await this.getplatformConfig();
  }

  checkRoute() {
    const currentUrl = this.router.url;
    this.isOnboardComponent = (currentUrl.includes('white-labling')) ? false : true;
  }

  async getplatformConfig() {
    this.platformBrandingConfigs = await this.systemConfigService.getPlatformConfig();
    this.isPlatformConfigLoaded = true;
    this.currentFranchiseSecureLogoBgColor = this.platformBrandingConfigs.franchiseSecureLogoBackgroundColor;
    this.currentMenuBarLogoBgColor = this.platformBrandingConfigs.menuBarLogoBackgroundColor;
  }

  //#region company Logo configs
  async changeCompanyLogo(event: any) {
    const input = event?.target as HTMLInputElement;
    let brandlogoFile = (input && (input?.files || []).length > 0) ? input.files[0] : null;
    if (brandlogoFile) {
      if (!this.allowedImageTypes.includes(brandlogoFile.type)) {
        this.toasterService.show(ToasterTypeEnum.Error, 'Error', 'Only jpeg jpg and png file acceptable');
        return;
      }
      if (brandlogoFile?.size > this.companyLogoMaxSize) {
        this.toasterService.show(ToasterTypeEnum.Error, 'Error', 'Image size should not exceed above 200kb');
        return;
      }
      this.logoLoader = true;
      let res = await this.savePlatformBrandingLogo(brandlogoFile);
      this.logoLoader = false;
      if (res.isSuccessful) {
        this.platformBrandingConfigs.companyLogo = res.response as string;
        this.configStore.Update({companyLogo: res.response});
        // when user make any minor change or send save call then this step will be consider as complete for On board.
        this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.PlatformBrading);
      }
    }
  }

  async savePlatformBrandingLogo(brandingLogo: File) {
    return await this.systemConfigService.saveCompanyLogo(brandingLogo);
  }

  async resetDefaultCompanyLogo() {
    this.resetLogoLoader = true;
    let res = await this.systemConfigService.resetToDefaultCompanyLogo();
    this.resetLogoLoader = false;
    if (res.isSuccessful) {
      // when user make any minor change or send save call then this step will be consider as complete for On board.
      this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.PlatformBrading);
      this.platformBrandingConfigs.companyLogo = this.defaultLogo;
      this.configStore.Update({companyLogo: this.defaultLogo});
    }


  }

  //#endregion

  //#region Login Backgroud Image
  async changeLoginImage(event: any) {
    const input = event?.target as HTMLInputElement;
    let loginImage = (input && (input?.files || []).length > 0) ? input.files[0] : null;
    if (loginImage) {
      if (!this.allowedImageTypes.includes(loginImage.type)) {
        this.toasterService.show(ToasterTypeEnum.Error, 'Error', 'Only jpeg jpg and png files are acceptable');
        return;
      }
      this.loginImageLoader = true;
      let res = await this.saveLoginPageImage(loginImage);
      this.loginImageLoader = false;
      if (res.isSuccessful) {
        // when user make any minor change or send save call then this step will be consider as complete for On board.
        this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.PlatformBrading);
        this.platformBrandingConfigs.companyLoginImage = res.response as string;
        this.configStore.Update({
          loginImage: this.platformBrandingConfigs.companyLoginImage,
          loginImageType: LoginImageTypEnum.Image
        });
      }

    }
  }

  async saveLoginPageImage(loginImage: File) {
    return await this.systemConfigService.saveLoginPageImage(loginImage);
  }

  async resetLoginBgImage() {
    this.resetLoginImageLoader = true;
    let res = await this.systemConfigService.resetToDefaultLoginImage();
    this.resetLoginImageLoader = false;
    if (res.isSuccessful) {
      // when user make any minor change or send save call then this step will be consider as complete for On board.
      this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.PlatformBrading);
      this.platformBrandingConfigs.companyLoginImage = '';
      this.configStore.Update({loginImage: '', loginImageType: LoginImageTypEnum.Svg});
    }

  }

  //#endregion

  //#region Menu Bar background Color
  async saveMenuBarLogoBgColor(event: any) {
    // no need to send call if nothing changed
    if (this.platformBrandingConfigs.menuBarLogoBackgroundColor != this.currentMenuBarLogoBgColor) {
      let res = await this.systemConfigService.saveMenuBarLogoBgColor(this.platformBrandingConfigs.menuBarLogoBackgroundColor);
      if (res.isSuccessful) {
        // when user make any minor change or send save call then this step will be consider as complete for On board.
        this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.PlatformBrading);
        this.platformBrandingConfigs.menuBarLogoBackgroundColor = res.response as string;
        this.currentMenuBarLogoBgColor = this.platformBrandingConfigs.menuBarLogoBackgroundColor;
        this.configStore.Update({logoBg: this.platformBrandingConfigs.menuBarLogoBackgroundColor});
      }
    }
  }

  //#endregion

  //#region FrchiseSecureLogoBgColor
  async saveFranchiseSecureLogoBgColor(event: any) {
    // no need to send call if nothing changed
    if (this.platformBrandingConfigs.franchiseSecureLogoBackgroundColor != this.currentFranchiseSecureLogoBgColor) {
      let res = await this.systemConfigService.saveFranchiseSecureLogoBgColor(this.platformBrandingConfigs.franchiseSecureLogoBackgroundColor);
      if (res.isSuccessful) {
        // when user make any minor change or send save call then this step will be consider as complete for On board.
        this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.PlatformBrading);
        this.platformBrandingConfigs.franchiseSecureLogoBackgroundColor = res.response as string;
        this.currentFranchiseSecureLogoBgColor = this.platformBrandingConfigs.franchiseSecureLogoBackgroundColor;
        this.configStore.Update({websiteLogoBg: this.platformBrandingConfigs.franchiseSecureLogoBackgroundColor});
      }
    }
  }

  //#endregion

  //#region Fav Icon
  async changeFavIcon(event: any) {
    const input = event?.target as HTMLInputElement;
    let FavIconFile = (input && (input?.files || []).length > 0) ? input.files[0] : null;
    if (FavIconFile) {
      this.faviconLoader = true;
      let res = await this.saveFavIcon(FavIconFile);
      this.faviconLoader = false;
      if (res.isSuccessful) {
        // when user make any minor change or send save call then this step will be consider as complete for On board.
        this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.PlatformBrading);
        this.platformBrandingConfigs.favIcon = res.response as string;
        this.configStore.Update({brandingFavicon: this.platformBrandingConfigs.favIcon});
      }
    }
  }

  async saveFavIcon(brandingLogo: File) {
    return await this.systemConfigService.saveFavIcon(brandingLogo);
  }

  async resetDefaultFavIcon() {
    this.resetFaviconLoader = true;
    let res = await this.systemConfigService.resetToDefaultFavIcon();
    this.resetFaviconLoader = false;
    if (res.isSuccessful) {
      // when user make any minor change or send save call then this step will be consider as complete for On board.
      this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.PlatformBrading);
      this.platformBrandingConfigs.favIcon = this.defaultFavIcon;
      this.configStore.Update({brandingFavicon: this.defaultFavIcon});
    }


  }

  async changeLoginLogo(event: any) {
    const input = event?.target as HTMLInputElement;
    let loginLogoFile = (input && (input?.files || []).length > 0) ? input.files[0] : null;
    if (loginLogoFile) {
      if (!this.allowedImageTypes.includes(loginLogoFile.type)) {
        this.toasterService.show(ToasterTypeEnum.Error, 'Error', 'Only jpeg jpg and png file acceptable');
        return;
      }
      if (loginLogoFile?.size > this.companyLogoMaxSize) {
        this.toasterService.show(ToasterTypeEnum.Error, 'Error', 'Image size should not exceed above 200kb');
        return;
      }
      this.loginLogoLoader = true;
      let res = await this.saveLoginLogo(loginLogoFile);
      this.loginLogoLoader = false
      if (res.isSuccessful) {
        // when user make any minor change or send save call then this step will be consider as complete for On board.
        this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.PlatformBrading);
        this.platformBrandingConfigs.loginLogo = res.response as string;
        this.configStore.Update({loginLogo: this.platformBrandingConfigs.loginLogo});
      }
    }

  }

  async saveLoginLogo(loginLogoFile: File) {
    return await this.systemConfigService.saveLoginLogo(loginLogoFile);
  }

  async resetDefaultLoginLogo() {
    this.resetLoginLogoLoader = true;
    let res = await this.systemConfigService.resetToDefaultLoginLogo();
    this.resetLoginLogoLoader = false;
    if (res.isSuccessful) {
      // when user make any minor change or send save call then this step will be consider as complete for On board.
      this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.PlatformBrading);
      this.platformBrandingConfigs.loginLogo = this.defaultLoginLogo;
      this.configStore.Update({loginLogo: this.defaultLoginLogo});
    }
  }

  async saveBrandingTitle(event: any) {
    let title = event?.target?.value || '';
    let res = await this.systemConfigService.saveBrandingTitle(title);
    if (res.isSuccessful) {
      this.configStore.Update({brandingTitle: res.response});
      this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.PlatformBrading);
    }
  }
}
