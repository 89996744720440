export class OnboardExtenedDto {
  id: number
  code: number;
  name: string;
  complete: boolean;
  active: boolean;
  isLoading:boolean;
  steps: OnboardStepsDto[];
}

export class OnboardStepsDto {
  id: number;
  code: number;
  name: string;
  complete: boolean;
  sectionCode: number
  isLoading:boolean
}

export class TerminologiesDto {
  id: number;
  name: string;
  label: string;
  pluralLabel: string;
}

export class ModulesDto {
  isFranchiseChecked: boolean = false;
  isLmsChecked: boolean = false;
  isBillingChecked: boolean = false;
}


export class EmailSenderDto {
  sendgridKey: string;
  fromEmail: string;
  senderName: string;
  replyEmail: string
}

export class EmailFrequenyDto {

  isChallengeComplete: boolean;
  isCourseComplete: boolean;
  isBadgeComplete: boolean;
  pointAchievedRoles: string;
  isTaskComplete: boolean;
  notFeedback: boolean;
  attendanceEnrollmentMail: boolean;
  onlineAssessmentSubmit: boolean;
  timesheetEmail: boolean;
  expenseEmail: boolean;
  timeOffEmail: boolean;
  homeWorkAssessmentNotificationRoles: string;
  homeWorkAssessmentNotificationTzName: string
  staffTaskCompletePortal: boolean;
  abandonedCartInfoDailyEmail: boolean;
  classUpdate: boolean;
  joinWaitlistNotificationEmail: boolean;
  sendRefundEmail: boolean;
  enrollmentMailSecure: boolean;
  enrollmentMailPortal: boolean;
  enrollmentMailRecurringPayments: boolean;
  courseCompletationImage:string
  badgeCompletationImage:string
  challengeCompletationImage:string


}

export class EmailFrequencySettingsDTO {
  id: number = 0;
  toParent: boolean = false;
  toStudent: boolean = false;
  toBusinessExecutive: boolean = false;
  toBusinessCordinator: boolean = false;
  toBusinessAdmin: boolean = false;
  toFranhiseAdmin: boolean = false;
  toFranhiseExecutive: boolean = false;
  toFranhiseCoordinator: boolean = false;
  toStaff: boolean = false;
  toCC: boolean = false;
  emailAddress: string = '';
  toEmailAddress: string = '';
  emails: string[] = [];
  emailsTo: string[] = [];
  companyEmailList: string[] = [];
  type: number = 0;
  emailToRoles: string = '';
}

export class EmailFrequencyWithSettingsDto {
  emailSettings: EmailFrequencySettingsDTO;
  type: number;
  isSettingOn: boolean;
  labelName: string;
  pointAchievedRoles: string[];
  courseCompletationImage:string
  badgeCompletationImage:string
  challengeCompletationImage:string
}

export class EmailFrequencyPayloadDto {
  type: number;
  value: boolean;
  emailToRoles?: string;
  tzOffset?: string;
  tzAbbrv?: string
  tzName?: string

}

export class EmailFrequencySettingPayloadDto {
  type: number;
  payload: EmailFrequencySettingsDTO;
}

export class TimeTrackerEmailSettingDto {
  approvedRejectEmail: boolean;
  submitEmail: boolean;
  notification: boolean;
  moduleType: boolean;
}

export class EmailsSettingDto {
  ccEmails: string;
  enrollmentEmails: string
  crmEmail: string;
}

export class FormattedEmailsSettingDto {
  ccEmails: EmailSettingsFormattedDto[];
  enrollmentEmails: EmailSettingsFormattedDto[]
  crmEmail: EmailSettingsFormattedDto[];
}

export class EmailSettingsFormattedDto {
  name:string = "";
  value: number

}

export class EmailSettingsPayloadDto {
  type: number;
  emails: [];
}

export class EmailFrequencyAndSettingsDto {
  emailFrequencySetting: EmailFrequencySettingDto[];
  emailFrequency: EmailFrequenyDto;
}

export class EmailFrequencySettingDto {
  type: number = 0;
  emailAddress: string = "";
  frequencyEmailAccesses: EmailAccessesDto[] = []
}

export class EmailAccessesDto {
  roleType: number = 0;
}

export class HomeworkAssessmentsDto {
  Name: string;
  Code: number;
}

export class HomeworkAssessmentsResponseDto {
  roles: string;
  tzAbbrv: string;
  tzName: string;
  tzOffset: string;
}

export class TimeZoneDto {
  tzAbbrv: string;
  tzName: string;
  tzOffSet: number;
}


export class NotificationToStaffDto {
  roleGuid: string;
  roleType: number;
  privilegeCode: string;
}

export class NotificationToStaffResponseDto {
  defaultRoles: NotificationToStaffDto[];
  selectedRoles: string[];
  challengeComppletionPoints: number;
}


//platformConfigs
export class PlatformBrandingDto {
  companyLoginImage: string;
  companyLogo: string;
  favIcon: string;
  loginLogo: string;
  franchiseSecureLogoBackgroundColor: string;
  menuBarLogoBackgroundColor: string;
  title: string;
}


export class MobileAppConfigDto {
  applicationName: string;
  subtitle: string;
  fName: string;
  lName: string;
  email: string;
  phone: string;
  address: string;
  privacyPolicyUrl: string;
  emailTo: string;
}

export class CustomeURLConfigDto {
  portalUrl: string;
  secureUrl: string;
  browserTitle: string;
  favIcon: string;
  emailTo: string;
}

export class OnBoardingSettings{
  companyEnableOnboarding:boolean;
  isOnboardingComplete:boolean;

  constructor (){
    this.companyEnableOnboarding = true;
    this.isOnboardingComplete = false
  }
}

export class AddUpdateEmailPayloadDTO
{
   image:File   
   backlogType:number
}

export class ManageEmailImageConfigDto {
  image: string ='';
  defaultImage:string ='';
  hideResetOption:boolean = false;
  title:string ='';
  description:string ='';
  buttonLabel1:string;
  buttonlabel2:string;
 
}