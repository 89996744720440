export enum OnboardSectionEnum {
  Module=0,
  whiteLabeling,
  Terminologies,
  MarketPlaceSteps,
}

export enum OnboardSetionStepsEnum{
  FranchiseManagementModule = 1,
  LMSModule,
  BillingAndPaymentsModule,
  PlatformBrading,
  EmailConfig,
  CustomURL,
  BrandedMobileApp,
  Terminology,
  TransactionInformation,
  paymentInformation
}

export enum OnboardSectionNamesEnum{
  Module = "Required Module",
  whiteLabeling = "White Labeling",
  Terminologies = "Terminology" ,
  MarketPlaceSteps = "Marketplace Course", 
}

export enum OnboardSetionStepsNameEnum{
  FranchiseManagementModule = "Franchise Management Module",
  LMSModule = "LMS (Learning Management System) Module",
  BillingAndPaymentsModule = "Billing & Payments Module",
  PlatformBrading = "Platform Branding",
  EmailConfig = "Email Configuration",
  CustomURL = "Custom URL",
  BrandedMobileApp = "Branded Mobile App",
  Terminology = "App Terminologies",
  TransactionInformation = "Transaction information",
  paymentInformation = "payment information"
}

export enum OnboardComponentsEnum{
  EmailSender = 1,
  PlatformLabel,
  Terminology,
  PaymentGateway,
  BillingConfig,
}


export enum EmailFrenquencyToogleEnum{
  None=0,
  Course = 1,
  Level = 2,
  Challenge = 3,
  Inventory = 5,
  OnlineAssessment =6,
  Task = 7,
  AbandonedCartInfoDailyEmail = 8,
  AbandonedCartInfoDailyEmailCC = 9,
  Refund = 10,
  SecureEnrollmentEmail = 11,
  PortalEnrollmentEmail = 12,
  RecurringEnrollmentEmail = 13,
  WaitlistNotification = 14,
  AttendanceEnrollment = 15,
  ClassUpdate = 16,
  EmailToRoles = 17,
  HomeworkAssessmentDueDateNotification = 18,
}

export enum EmailTimeTrackerEnum{
  None=0,
  Cover = 1,
  TradeShift = 2,
  Expenses= 22,
  Timesheet = 23,
  TimeOff = 21,
}

export enum EmailSettingTabEnum{
  None = 0,
  CCEmails,
  EnrollmentInfoEmail,
  CRMEmail
}



export enum LMSDefaultRoles
{
    BusinessAdmin = 1,
    BusinessExecutive = 2,
    BusinessCoordinator = 3,
    BusinessInstructor = 4,
    FranchiseAdmin = 5,
    FranchiseExecutive = 6,
    FranchiseCoordinator = 7,
    FranchiseInstructor = 8,
    BusinessParent = 9,
    BusinessStudent = 10,
    FranchiseParent = 11,
    FranchiseStudent = 12,
    Lead = 13,
    Contact = 14,
}

