<div class="assessment-card">
  @if (edit()) {
    <span [pTooltip]="'remove widget'" (click)="onRemove.emit()" [tooltipOptions]="{showDelay:500}"
          class="remove-widget-btn"
          tooltipPosition="bottom"><i class="fa-solid fa-xmark"></i>
  </span>
  }

  @if (edit() || editOnHover()) {
    <span (click)="openModal()" [pTooltip]="'Edit widget'" [tooltipOptions]="{showDelay:500}"
          class="setting-widget-btn {{editOnHover() ? 'edit-on-hover' : ''}}"
          tooltipPosition="bottom">
          <i class="fa-solid fa-pen"></i>
  </span>
  }

  <a (click)="navigateToExternalLink(widget().link)" [icon]="widget().icon" [label]="widget().title"
     class="assessment-btn clm-primary-btn"
     pButton
     target="_blank">
  </a>
</div>
