import {Component, ElementRef, input, viewChild} from '@angular/core';
import {DialogModule} from "primeng/dialog";
import {PrimeTemplate} from "primeng/api";

@Component({
  selector: 'calimatic-help-video',
  standalone: true,
  imports: [
    DialogModule,
    PrimeTemplate
  ],
  templateUrl: './help-video.component.html',
  styleUrl: './help-video.component.scss'
})
export class HelpVideoComponent {

  //inputs
  text = input<string>('Learn more');
  link = input.required<string>();

  //html reference
  videoPlayer = viewChild.required<ElementRef>("videoPlayer");

  //local
  visible = false;

  onHidden() {
    if (this.videoPlayer()) {
      const video: HTMLVideoElement = this.videoPlayer().nativeElement;
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    }
  }

  async onShown() {
    if (this.videoPlayer()) {
      const video: HTMLVideoElement = this.videoPlayer().nativeElement;
      if (video) {
        await video.play();
      }
    }
  }
}
