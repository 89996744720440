@if (showForgotPasswordSection) {
  <p-button label="Back to login" styleClass="clm-secondary-btn" [rounded]="true" size="small" class="back-btn"
            (click)="showForgotPasswordSection = false">
    <i class="fa-solid fa-arrow-left-long mr-2"></i>
  </p-button>
}

<form (keyup.enter)="login()" [formGroup]="loginForm" class="h-full">
  <div class="login-form-wrapper pt-4 p-2">
    <div class="logo mb-5">
      <img [src]="companyConfigs().loginLogo" alt="" class="max-w-full">
    </div>
    @if ((companyName || '').length) {
      <div class="info-alert">
        <span> <b> Account: </b> <span id="login-company-name-span">{{ companyName }}</span> </span>
      </div>
    }
    @if (error?.length) {
      <div class="custom-errors-label">
        <div class="app-error-label">
          @if (loginResponse?.response?.errorCode == LoginErrorCodeEnum.NotVerified) {
            {{ error }}
            Please check your email for verification instructions or
            <p-button [styleClass]="'activation-link-btn'" [loading]="sendingActivationEmail"
                      (click)="resendVerificationEmail()" [link]="true"
                      label="click here"></p-button> to resend the verification email.
          } @else {
            {{ error }}
          }
        </div>
        <!--        <i (click)="error = ''" class="fa-regular fa-circle-xmark fa-lg"></i>-->
      </div>
    }
    @if (message?.length) {
      <div class="info-alert">
        {{ message }}
      </div>
    }

    <div class="login-form-card">

      @if (!showForgotPasswordSection) {
        <div class="login-card">
          <h2>Login</h2>
          <!-- username -->
          <div class="flex flex-column gap-2">
            <label for="username">Username</label>
            <input (blur)="fetchCompanyInfo()" aria-describedby="username-help"
                   class="{{formControls.userName.invalid && (formControls.userName.dirty || !formControls.userName.untouched) ? 'ng-invalid' : ''}} p-inputtext-lg"
                   formControlName="userName" id="username"
                   pInputText placeholder="Enter your username"/>
            @if (formControls.userName.invalid && (formControls.userName.dirty || !formControls.userName.untouched)) {
              <small class="form-invalid">Username is required</small>
            }
          </div>
          <!-- password -->
          <div class="flex flex-column gap-2">
            <label for="password">Password</label>
            <p-password
              [feedback]="false"
              [ngClass]="{'ng-valid' : formControls.password.invalid && (formControls.password.dirty || !formControls.password.untouched) }"
              [toggleMask]="true" aria-describedby="password"
              class="p-inputtext-lg"
              formControlName="password"
              id="password"
              placeholder="Enter your password"></p-password>
            @if (formControls.password.invalid && (formControls.password.dirty || !formControls.password.untouched)) {
              <small class="form-invalid">Password is required</small>
            }
          </div>

          <!-- forgot password -->
          <div class="forgot-password text-right">
            <a [ngStyle]="{color:companyConfigs().loginButtonColor}" class="no-underline cursor-pointer font-semibold"
               (click)="showForgotPassword()">Forgot password?</a>
          </div>

          <!-- submit -->
          <button (click)="login()" [loading]="loading"
                  type="button"
                  [ngStyle]="{background:companyConfigs().loginButtonColor,borderColor:companyConfigs().loginButtonColor}"
                  class="w-full btn-large clm-primary-btn" label="Log In" pButton></button>
        </div>
      } @else {
        <app-forgot-password class="w-full" (close)="showForgotPasswordSection = !$event"
                             (error)="forgotPasswordErrors($event)"
                             [companyConfigs]="companyConfigs()"></app-forgot-password>
      }

    </div>
    @if (!companyConfigs().isWhiteLabeled) {
      <div class="login-footer-wrapper">
        <a href="https://help.calimaticedtech.com" target="_blank" class="info-links">Knowledge Base</a>
        <p>© 2024 Caliber Technologies Inc. All rights reserved.</p>
        <p><a href="https://www.calimaticedtech.com/privacy-policy/" target="_blank">Privacy Policy</a> |
          <a href="https://www.calimaticedtech.com/terms-and-conditions/" target="_blank">Terms of Service</a></p>
      </div>
    }


    <!--    <app-language></app-language>-->
  </div>
</form>
