<form [formGroup]="brandedMobileAppForm" (ngSubmit)="onSubmitMobileConfig()" (keyup.enter)="onSubmitMobileConfig()">
    <div class="modal-content-wrapper">
        <header class="modal-header">
            <h4 >Branded Mobile App Configuration</h4>
            <button (click)="closeModal()" type="button" class="icon-button border-0" pButton [icon]="'fa-solid fa-xmark'"></button>
        </header>
        <div class="modal-body">
            <div class="modal-body-content">
                <div class="card card-info border-round-2xl mb-4">
                    <h5 class="m-0">Where this information will be used?</h5>
                    <div class="card-body">
                        <ol class="m-0 pl-4">
                            <li class="text-500 mb-2">Subtitle will be shown on Play store and App store when users click on our app on the detail page, it will be shown to users.</li>
                            <li class="text-500 mb-2">Play store and App store team require First name, Last name, Email, and Phone number as app review information. They will contact us in case of any queries.</li>
                            <li class="text-500">Email is also visible to users on Play store and App Store. Users can contact us using this email.</li>
                        </ol>
                    </div>
                </div>
                
                <div class="flex flex-wrap gap-3">
                    <div class="flex-grow-1 mb-4">
                        <p-floatLabel>
                            <input validateInput="App name is required" class="w-full" 
                            pInputText id="applicationName" formControlName="applicationName" />
                            <label for="applicationName">App Name</label>
                        </p-floatLabel>
                    </div>
                    <div class="flex-grow-1 mb-4">
                        <p-floatLabel>
                            <input validateInput="Subtitle is required" class="w-full" 
                            pInputText id="subtitle" formControlName="subtitle" />
                            <label for="subtitle">Subtitle</label>
                        </p-floatLabel>
                    </div>
                </div>
                <div class="flex flex-wrap gap-3">
                    <div class="flex-grow-1 mb-4">
                        <p-floatLabel>
                            <input class="w-full" validateInput="First Name is required"
                            pInputText id="fName" formControlName="fName" />
                            <label for="fName">First Name</label>
                        </p-floatLabel>
                    </div>
                    <div class="flex-grow-1 mb-4">
                        <p-floatLabel>
                            <input class="w-full" validateInput="Last Name is required"
                            pInputText id="lName" formControlName="lName" />
                            <label for="lName">Last Name</label>
                        </p-floatLabel>
                    </div>
                </div>
                <div class="flex flex-wrap gap-3">
                    <div class="flex-grow-1 mb-4">
                        <p-floatLabel>
                            <input class="w-full" validateInput="Email is required"
                            pInputText id="email" formControlName="email" />
                            <label for="email">Email</label>
                        </p-floatLabel>
                    </div>
                    <div class="flex-grow-1 mb-4">
                        <p-floatLabel>
                            <input class="w-full" validateInput="Phone is required"
                            pInputText id="phone" formControlName="phone" type="text" />
                            <label for="phone">Phone</label>
                        </p-floatLabel>
                    </div>
                </div>
                <div class="flex-grow-1 mb-4">
                    <p-floatLabel>
                        <input class="w-full" validateInput="Address is required"
                        pInputText id="address" formControlName="address" />
                        <label for="address">Address</label>
                    </p-floatLabel>
                </div>
                <div class="flex-grow-1 mb-4">
                    <p-floatLabel>
                        <input class="w-full" validateInput="Valid Privacy policy URL is required"
                        pInputText id="privacyPolicyUrl" formControlName="privacyPolicyUrl" />
                        <label for="privacyPolicyUrl">Privacy Policy URL</label>
                    </p-floatLabel>
                </div>
                
            </div>
        </div>
        <footer class="modal-footer">
            <button pButton (click)="closeModal()" type="button" class="clm-secondary-btn border-0" [rounded]="true" label="Cancel"></button>
            <button pButton [disabled]="!brandedMobileAppForm.valid" type="submit" class="clm-primary-btn" [rounded]="true" label="Submit"></button>
        </footer>
    </div>
</form>