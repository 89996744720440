import {LMSDefaultRoles} from "./onboard.enum";
import {UserProfileAvatarDto, UserProfileColorDto} from '../interfaces/user.interface';
import { KeyValueDto} from '../interfaces/roles.interface';


export enum UserRoleEnum {
  BusinessAdmin = 1,
  BusinessExecutive,
  BusinessCoordinator,
  BusinessInstructor,
  FranchiseAdmin,
  FranchiseExecutive,
  FranchiseCoordinator,
  FranchiseInstructor,
  BusinessParent,
  BusinessStudent,
  FranchiseParent,
  FranchiseStudent,
}

export function getUserRoles() {
  return [
    {Name: "Business Admin", Code: UserRoleEnum.BusinessAdmin},
    {Name: "Business Executive", Code: UserRoleEnum.BusinessExecutive},
    {Name: "Business Coordinator", Code: UserRoleEnum.BusinessCoordinator},
    {Name: "Business Instructor", Code: UserRoleEnum.BusinessInstructor},
    {Name: "Franchise Admin", Code: UserRoleEnum.FranchiseAdmin},
    {Name: "Franchise Executive", Code: UserRoleEnum.FranchiseExecutive},
    {Name: "Franchise Coordinator", Code: UserRoleEnum.FranchiseCoordinator},
    {Name: "Franchise Instructor", Code: UserRoleEnum.FranchiseInstructor},
    {Name: "Business Parent", Code: UserRoleEnum.BusinessParent},
    {Name: "Business Student", Code: UserRoleEnum.BusinessStudent},
    {Name: "Franchise Parent", Code: UserRoleEnum.FranchiseParent},
    {Name: "Franchise Student", Code: UserRoleEnum.FranchiseStudent},
  ] as UserRoleInterface[]
}

export function getUserPopupRoles(): UserRoleInterface[] {
  return [
    {Name: "Business Admin", Code: LMSDefaultRoles.BusinessAdmin},
    {Name: "Business Executive", Code: LMSDefaultRoles.BusinessExecutive},
    {Name: "Business Coordinator", Code: LMSDefaultRoles.BusinessCoordinator},
    {Name: "Business Instructor", Code: LMSDefaultRoles.BusinessInstructor},
    // { Name: "Business Parent", Code: LMSDefaultRoles.BusinessParent },
    // { Name: "Business Student", Code: LMSDefaultRoles.BusinessStudent },
  ];
}

export function getFranchiseRoles(): UserRoleInterface[] {
  return [
    {Name: "Franchise Admin", Code: UserRoleEnum.FranchiseAdmin},
    {Name: "Franchise Executive", Code: UserRoleEnum.FranchiseExecutive},
    {Name: "Franchise Coordinator", Code: UserRoleEnum.FranchiseCoordinator},
    {Name: "Franchise Instructor", Code: UserRoleEnum.FranchiseInstructor},
    // {Name: "Franchise Parent", Code: UserRoleEnum.FranchiseParent},
    // {Name: "Franchise Student", Code: UserRoleEnum.FranchiseStudent},
  
  ];
}
export interface UserRoleInterface {
  Name: string;
  Code: number;
}


export enum UserProfileTabEnum {
  PersonalInfo = 1,
  ChangeAvatar,
  ChangePassword,
  EmailPreference,
}

export enum UserProfileImageTypeEnum {
  Image = "Image",
  Avatar = "Avatar",
  Color = "Color"
}

export const avatarFaces = [
  {id: 1, imagSrc: '/assets/images/Avatar_faces/face1.svg'},
  {id: 2, imagSrc: '/assets/images/Avatar_faces/face2.svg'},
  {id: 3, imagSrc: '/assets/images/Avatar_faces/face3.svg'},
  {id: 4, imagSrc: '/assets/images/Avatar_faces/face4.svg'},
  {id: 5, imagSrc: '/assets/images/Avatar_faces/face5.svg'},
  {id: 6, imagSrc: '/assets/images/Avatar_faces/face6.svg'},
  {id: 7, imagSrc: '/assets/images/Avatar_faces/face7.svg'},
  {id: 8, imagSrc: '/assets/images/Avatar_faces/face8.svg'},
  {id: 9, imagSrc: '/assets/images/Avatar_faces/face9.svg'},
  {id: 10, imagSrc: '/assets/images/Avatar_faces/face10.svg'},
  {id: 11, imagSrc: '/assets/images/Avatar_faces/face11.svg'},
  {id: 12, imagSrc: '/assets/images/Avatar_faces/face12.svg'},
  {id: 13, imagSrc: '/assets/images/Avatar_faces/face13.svg'},
  {id: 14, imagSrc: '/assets/images/Avatar_faces/face14.svg'},
  {id: 15, imagSrc: '/assets/images/Avatar_faces/face15.svg'},
  {id: 16, imagSrc: '/assets/images/Avatar_faces/face16.svg'},
  {id: 17, imagSrc: '/assets/images/Avatar_faces/face17.svg'},
  {id: 18, imagSrc: '/assets/images/Avatar_faces/face18.svg'},
  {id: 19, imagSrc: '/assets/images/Avatar_faces/face19.svg'},
  {id: 20, imagSrc: '/assets/images/Avatar_faces/face20.svg'},
  {id: 21, imagSrc: '/assets/images/Avatar_faces/face21.svg'},
  {id: 22, imagSrc: '/assets/images/Avatar_faces/face22.svg'},
  {id: 23, imagSrc: '/assets/images/Avatar_faces/face23.svg'},
  {id: 24, imagSrc: '/assets/images/Avatar_faces/face24.svg'},
  {id: 25, imagSrc: '/assets/images/Avatar_faces/face25.svg'},
  {id: 26, imagSrc: '/assets/images/Avatar_faces/face26.svg'},
  {id: 27, imagSrc: '/assets/images/Avatar_faces/face27.svg'},
  {id: 28, imagSrc: '/assets/images/Avatar_faces/face28.svg'},
  {id: 29, imagSrc: '/assets/images/Avatar_faces/face29.svg'},
  {id: 30, imagSrc: '/assets/images/Avatar_faces/face30.svg'},
  {id: 31, imagSrc: '/assets/images/Avatar_faces/face31.svg'},
  {id: 32, imagSrc: '/assets/images/Avatar_faces/face32.svg'},
  {id: 33, imagSrc: '/assets/images/Avatar_faces/face33.svg'},
  {id: 34, imagSrc: '/assets/images/Avatar_faces/face34.svg'},
  {id: 35, imagSrc: '/assets/images/Avatar_faces/face35.svg'},
  {id: 36, imagSrc: '/assets/images/Avatar_faces/face36.svg'},
  {id: 37, imagSrc: '/assets/images/Avatar_faces/face37.svg'},
  {id: 38, imagSrc: '/assets/images/Avatar_faces/face38.svg'},
  {id: 39, imagSrc: '/assets/images/Avatar_faces/face39.svg'},
  {id: 40, imagSrc: '/assets/images/Avatar_faces/face40.svg'},
  {id: 41, imagSrc: '/assets/images/Avatar_faces/face41.svg'},
  {id: 42, imagSrc: '/assets/images/Avatar_faces/face42.svg'},
  {id: 43, imagSrc: '/assets/images/Avatar_faces/face43.svg'},
  {id: 44, imagSrc: '/assets/images/Avatar_faces/face44.svg'},
  {id: 45, imagSrc: '/assets/images/Avatar_faces/face45.svg'},
  {id: 46, imagSrc: '/assets/images/Avatar_faces/face46.svg'},
  {id: 47, imagSrc: '/assets/images/Avatar_faces/face47.svg'},
  {id: 48, imagSrc: '/assets/images/Avatar_faces/face48.svg'},
  {id: 49, imagSrc: '/assets/images/Avatar_faces/face49.svg'},
  {id: 50, imagSrc: '/assets/images/Avatar_faces/face50.svg'},
  {id: 51, imagSrc: '/assets/images/Avatar_faces/face51.svg'},
  {id: 52, imagSrc: '/assets/images/Avatar_faces/face52.svg'},
  {id: 53, imagSrc: '/assets/images/Avatar_faces/face53.svg'},
  {id: 54, imagSrc: '/assets/images/Avatar_faces/face54.svg'},
  {id: 55, imagSrc: '/assets/images/Avatar_faces/face55.svg'},
  {id: 56, imagSrc: '/assets/images/Avatar_faces/face56.svg'},
] as UserProfileAvatarDto[];

export const avatarColors = [
  {color: '#1e90ff'},
  {color: '#009966'},
  {color: '#8D6E63'},
  {color: '#558B2F'},
  {color: '#0D47A1'},
  {color: '#81D4FA'},
  {color: '#263238'},
  {color: '#8D6E63'},
  {color: '#0D47A1'},
  {color: '#827717'},
  {color: '#A1887F'},
  {color: '#8D6E63'},
  {color: '#D84315'},
  {color: '#37474F'},
  {color: '#21618C'},
  {color: '#641E16'},
  {color: '#186A3B'},
  {color: '#AF7AC5'},
  {color: '#34495E'},
  {color: '#515A5A'},
  {color: '#626567'},
  {color: '#566573'},
  {color: '#148F77'},
  {color: '#1F618D'},
  {color: '#3498DB'},
  {color: '#16A085'},
  {color: '#48C9B0'},
  {color: '#F1948A'},
  {color: '#B03A2E'},
  {color: '#239B56'},
  {color: '#f39c12'},
  {color: '#2ecc71'},
  {color: '#27ae60'},
  {color: '#e67e22'},
  {color: '#d35400'},
  {color: '#3498db'},
] as UserProfileColorDto[];

export enum UsersTabsEnum {
CompanyTab = 0,
FranchiseTab,
APIRoleTab,
APIUserAccessTab
}

export enum UsersAddBtnTextEnum {
  Company = "Add or Invite User",
  Franchise = "Add user",
  APIRole = "Add Role" ,
  APIUserAccess = "Add Access"
}
export enum UserActionsEnum{
  AddUser = "add_user",
  InviteUser = "invite_user",
  AddRole = "add_role",
  AddAccess= "add_access"
}


export function getBusinessRoles(): KeyValueDto[] {
  return [
    {name: "Business Admin", value: LMSDefaultRoles.BusinessAdmin},
    {name: "Business Executive", value: LMSDefaultRoles.BusinessExecutive},
    {name: "Business Coordinator", value: LMSDefaultRoles.BusinessCoordinator},
    {name: "Business Instructor", value: LMSDefaultRoles.BusinessInstructor},
    {name: "Business Parent", value: LMSDefaultRoles.BusinessParent },
    {name: "Business Student", value: LMSDefaultRoles.BusinessStudent },
  ];
}

