import {Component, input, output} from '@angular/core';
import {SelectButtonModule} from "primeng/selectbutton";
import {SelectButtonConfigsInterface} from "../../interfaces/select-button/select-button-configs.interface";
import {FormsModule} from "@angular/forms";
@Component({
  selector: 'calimatic-select-button',
  standalone: true,
  imports: [
    SelectButtonModule,
    FormsModule
  ],
  templateUrl: './select-button.component.html',
  styleUrl: './select-button.component.scss'
})
export class SelectButtonComponent {
  configs = input.required<Partial<SelectButtonConfigsInterface>>();
  value = input.required();

  valueChange = output();
}
