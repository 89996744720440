import {Component, input, OnInit, output} from '@angular/core';
import {TopFranchisesRevenueDto} from "../../../../../shared/dtos/top-franchises-revenue.dto";
import {DashboardService} from "../../../../../core/services/dashboard.service";
import {CounterTrioSkeletonComponent} from "../skeletons/counter-trio-skeleton/counter-trio-skeleton.component";
import {TooltipModule} from 'primeng/tooltip';
import {DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";

@Component({
  selector: 'calimatic-top-franchises-revenue-widget',
  standalone: true,
  imports: [
    CounterTrioSkeletonComponent,
    TooltipModule
  ],
  templateUrl: './top-franchises-revenue-widget.component.html',
  styleUrl: './top-franchises-revenue-widget.component.scss'
})
export class TopFranchisesRevenueWidgetComponent implements OnInit {
  widget = input.required<DashboardWidgetsDTO>();
  edit = input.required();
  onRemove = output();

  //local
  franchisesRevenue: TopFranchisesRevenueDto[];
  loading = false;

  constructor(private dashboardService: DashboardService) {
  }

  async ngOnInit() {
    await this.loadData();
  }

  async loadData() {
    this.loading = true;
    this.franchisesRevenue = await this.dashboardService.getTopFranchiseByRevenue();
    this.loading = false;
  }
}
