import {CommonModule} from '@angular/common';
import {Component, input} from '@angular/core';
import {LoginFormComponent} from '../../login-form/login-form.component';
import {SanitizePipe} from '../../../../../shared/pipes/sanitize.pipe';
import {CompanyConfigDto} from '../../../../../shared/dtos/company-config-dto';

@Component({
  selector: 'calimatic-default-template',
  standalone: true,
  imports: [
    CommonModule,
    LoginFormComponent,
    SanitizePipe
  ],
  templateUrl: './default-template.component.html',
  styleUrl: './default-template.component.scss'
})
export class DefaultTemplateComponent {
  companyConfigs = input.required<CompanyConfigDto>();
}
