import { LoginComponent } from './pages/auth/login/login.component';
import { Routes } from '@angular/router';
import { authGuard } from './core/services/guards/auth.guard';
import { DashboardComponent } from './pages/main/dashboard/dashboard.component';
import { menuGuard } from './core/services/guards/menu.guard';
import { MenuEnum, SubMenusEnum } from './shared/enums/menu.enum';
import { UrlResolver } from './shared/resolvers/url.resolver';
import { ShortUrlRedirectionGuard } from './core/services/guards/short-url-redirection.guard';
import { AccountActivationComponent } from './pages/auth/account-verification/account-activation.component';

export const appRoutePaths = {
  login: {
    v2: '',
    v3: 'login',
  },
  dashboard: {
    v2: '/Dashboard/Index',
    v3: 'dashboard',
  },
  studentBoard: {
    v2: '/TaskManager/Board',
    v3: 'student-board',
  },
  classBoard: {
    v2: '/TaskManager/ClassBoard',
    v3: 'class-board',
  },
  staffBoard: {
    v2: '/UserBoard/GetUserBoard',
    v3: 'staff-board',
  },
  systemConfig: {
    v2: '/UserManagement/SystemConfig',
    v3: 'config',
    children: {
      transactionConfig: {
        v2: `/UserManagement/SystemConfig/TransactionsConfig`,
        v3: 'config/transaction-config',
      },
    },
  },
  users: {
    v2: '/UserManagement/UserDetails',
    v3: 'users',
  },
  administrators: {
    v2: '/UserManagement/Administrators',
    v3: 'administrators',
  },
  billing: {
    v2: '/UserManagement/PaymentManagement',
    v3: 'billing',
  },
  permissions: {
    v2: '/UserManagement/SecurityPermission',
    v3: 'permissions',
  },
  audits: {
    v2: '/Audit/Index',
    v3: 'audits',
  },
  importSettings: {
    v2: '/UserManagement/ImportSettings',
    v3: 'import-settings',
  },
  importStaff: {
    v2: '/UserManagement/ImportStaff',
    v3: 'import-staff',
  },
  importStudents: {
    v2: '/UserManagement/ImportStudents',
    v3: 'import-students',
  },
  franchiseSetup: {
    v2: '/Franchise/Franchise',
    v3: 'franchise-setup',
  },
  franchiseContacts: {
    v2: '/Franchise/Contacts',
    v3: 'franchise-contacts',
  },
  franchiseEnrollments: {
    v2: '/LMSManage/franchiseCenter',
    v3: 'franchise-enrollments',
  },
  franchiseBoard: {
    v2: '/LMSManage/GetFranchiseBoardPartial',
    v3: 'franchise-board',
  },
  franchiseOnboarding: {
    v2: '/LMSManage/_onBoardingGrid',
    v3: 'franchise-onboarding',
  },
  franchiseBilling: {
    v2: '/Billing',
    v3: 'franchise-Billing',
  },
  franchiseAgreements: {
    v2: '/Franchise/GetAgreement',
    v3: 'franchise-agreements',
  },
  franchiseInventory: {
    v2: '/LMSManage/_Inventory?reqType=1',
    v3: 'franchise-inventory',
  },
  franchiseDocuments: {
    v2: '/Franchise/GetDocuments',
    v3: 'documents',
  },
  franchiseMap: {
    v2: '/Franchise/LocationsMap',
    v3: 'franchise-map',
  },
  courses: {
    v2: '/CoursePlayer/CoursePlayer',
    v3: 'courses',
  },
  central: {
    v2: '/Plan/BacklogProduct',
    v3: 'central',
  },
  classes: {
    v2: '/Courses/Classes',
    v3: 'classes',
  },
  coursesEnrollmentFormSetup: {
    v2: '/Courses/StudentEnrollmentForm',
    v3: 'courses-enrollment-form-setup',
  },
  events: {
    v2: '/Events',
    v3: 'events',
  },
  eventEnrollmentFormSetup: {
    v2: '/Events/GetEventEnrollmentFormSetup',
    v3: 'events-enrollment-form-setup',
  },
  eventCalendar: {
    v2: '/Events/GetEventsCalendar',
    v3: 'events-calendar',
  },
  newEventCalendar: {
    v2: '/Events/GetNewEventsCalendar',
    v3: 'events-new-calendar',
  },
  families: {
    v2: '/LMSManage/Families',
    v3: 'families',
  },
  students: {
    v2: '/LMSManage/StudentInfo',
    v3: 'students',
  },
  studentPoints: {
    v2: '/LMSManage/StudentPoints',
    v3: 'student-points',
  },
  calendar: {
    v2: '/LMSManage/GetCalender',
    v3: 'calendar',
  },
  ideas: {
    v2: '/Idea/Challenge',
    v3: 'ideas',
  },
  assessments: {
    v2: '/Assessment/StudentAssessment',
    v3: 'assessments',
  },
  payments: {
    v2: '/LMSManage/StudentPayments',
    v3: 'payments',
  },
  transactions: {
    v2: '/LMSManage/StudentsTransactions',
    v3: 'transactions',
  },
  blackout: {
    v2: '/LMSManage/BlackoutDates',
    v3: 'blackout',
  },
  drive: {
    v2: '/Drive',
    v3: 'drive',
  },
  attendance: {
    v2: '/LMSManage/FamiliesAttendance',
    v3: 'attendance',
  },
  checkInOut: {
    v2: '/CheckInOut',
    v3: 'check-inout',
  },
  partners: {
    v2: '/LMSManage/Families?partnerTab=true',
    v3: 'partners',
  },
  staff: {
    v2: '/LMSManage/ManageInstructor',
    v3: 'staff',
  },
  timeTracker: {
    v2: '/Timecard/Setup',
    v3: 'time-tracker',
  },
  timeSchedules: {
    v2: '/Timecard/Schedules',
    v3: 'time-schedules',
  },
  timeSheet: {
    v2: '/Timecard/TimeSheet',
    v3: 'time-sheet',
  },
  timeOff: {
    v2: '/Timecard/TimeOff',
    v3: 'time-off',
  },
  timeExpenses: {
    v2: '/Timecard/Expenses',
    v3: 'expenses',
  },
  approvals: {
    v2: '/Timecard/Approved',
    v3: 'approvals',
  },
  wages: {
    v2: '/Timecard/WagesDetails',
    v3: 'wages',
  },
  staffCheckInOut: {
    v2: '/CheckInOut/GroupCheckInOut',
    v3: 'staff-check-inout',
  },
  announcements: {
    v2: '/LMSManage/Announcements',
    v3: 'announcements',
  },
  articles: {
    v2: '/InternalArticles/Index',
    v3: 'articles',
  },
  internalArticles: {
    v2: '/InternalArticles',
    v3: 'internal-articles',
  },
  sms: {
    v2: '/LMSManage/SMS',
    v3: 'sms',
  },
  emails: {
    v2: '/LMSManage/Emails',
    v3: 'emails',
  },
  leads: {
    v2: '/LMSManage/Leads',
    v3: 'leads',
  },
  contactForms: {
    v2: '/LMSManage/ContactFormSetup',
    v3: 'contact-forms',
  },
  partyConfig: {
    v2: '/Party/PartyConfig',
    v3: 'party/config',
  },
  partyNewBooking: {
    v2: '/Party/NewBooking',
    v3: 'party/new-booking',
  },
  partyBookings: {
    v2: '/Party/PartyBookings',
    v3: 'party/bookings',
  },
  billingReport: {
    v2: '/StudentReports/BillingReport',
    v3: 'reports/billing',
  },
  enrollmentReport: {
    v2: '/StudentReports/EnrollmentReport?ClassAccess=true',
    v3: 'reports/enrollment',
  },
  classesReport: {
    v2: '/StudentReports/ClassesReport',
    v3: 'reports/classes',
  },
  familiesReport: {
    v2: '/StudentReports/RetentionReport',
    v3: 'reports/families',
  },
  eventsReport: {
    v2: '/StudentReports/EventsReport',
    v3: 'reports/events',
  },
  studentProgressReport: {
    v2: '/StudentReports/StudentProgressReport',
    v3: 'reports/student-progress',
  },
  resultReport: {
    v2: '/Grading/Results',
    v3: 'reports/result',
  },
  transcriptReport: {
    v2: '/Transcript/LoadTranscriptReportView',
    v3: 'reports/transcript',
  },
  reportCardReport: {
    v2: '/Transcript/StudentReportCardExport',
    v3: 'reports/report-card',
  },
  userProfile: {
    v2: 'profile',
    v3: 'profile',
  },
  semesters: {
    v2: '/LMSManage/StudentSemesters',
    v3: 'semesters',
  },
  activityFinder: {
    v2: '/LMSManage/Activity-finder',
    v3: 'activity-finder',
  },
  leadForm: {
    v3: 'Account/EmbeddedLeadContactForm',
    v3Extension: 'Account/EmbeddedLeadContactForm/',
    v3Extension2: 'App/Link/:id',
  },
  contactForm: {
    v3: 'Account/EmbeddedContactForm',
    v3Extension: 'Account/ContactEnrollmentForm',
  },
  enrollmentForm: {
    v3: 'Account/EmbeddedEnrolmentForm',
    v3Extension: 'account/enrollmentForm',
  },
  invitationForm: {
    v3: 'Account/InviteUserForm',
  },
  adminForm: {
    v3: 'Account/CreateFranchiseAdmin',
  },
  article: {
    v3: 'article',
  },
  courseCertificate: {
    v3: 'view-certificate',
  },
  message: {
    success: 'submission-success',
    error: 'submission-Error',
  },
};

export const routes: Routes = [
  //auth pages
  {
    path: appRoutePaths.login.v3,
    pathMatch: 'full',
    component: LoginComponent,
  },
  {
    path: 'account-activation',
    pathMatch: 'full',
    component: AccountActivationComponent,
  },
  {
    path: '',
    loadComponent: () => import('./pages/main/main.component').then((a) => a.MainComponent),
    children: [
      //default
      {
        path: '',
        pathMatch: 'full',
        component: DashboardComponent,
        canActivate: [authGuard],
        data: {menuId: [MenuEnum.Dashboard]},
      },
      //dashboard
      {
        path: appRoutePaths.dashboard.v3,
        pathMatch: 'full',
        component: DashboardComponent,
        canActivate: [authGuard],
        data: {menuId: [MenuEnum.Dashboard]},
        resolve: {
          resolver: UrlResolver,
        },
      },
      //board
      {
        path: appRoutePaths.studentBoard.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/main/boards/student-board/student-board.component').then((c) => c.StudentBoardComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.StudentBoard],
          v2Path: appRoutePaths.studentBoard.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.classBoard.v3,
        pathMatch: 'full',
        loadComponent: () => import('./pages/main/boards/class-board/class-board.component').then((c) => c.ClassBoardComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.ClassesBoard],
          v2Path: appRoutePaths.classBoard.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.staffBoard.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/main/boards/staff-board/staff-board.component').then(
            (c) => c.StaffBoardComponent
          ),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.StaffBoard],
          v2Path: appRoutePaths.staffBoard.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      //admin
      {
        path: appRoutePaths.systemConfig.v3,
        loadComponent: () =>
          import('./pages/main/system-config/system-config.component').then(
            (c) => c.SystemConfigComponent
          ),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.SystemConfig],
          v2Path: appRoutePaths.systemConfig.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.users.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/main/admin/users/users.component').then(
            (c) => c.UsersComponent
          ),
        canActivate: [authGuard, menuGuard],
        data: {subMenu: true, menuId: [SubMenusEnum.Users]},
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.activityFinder.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/admin/activity-finder/activity-finder.component'
          ).then((c) => c.ActivityFinderComponent),
        canActivate: [authGuard, menuGuard],
        data: {subMenu: true, menuId: [SubMenusEnum.ActivityFinder]},
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.administrators.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/admin/administrators/administrators.component'
          ).then((c) => c.AdministratorsComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Administrators],
          v2Path: appRoutePaths.administrators.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.billing.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/admin/calimatic-billing/calimatic-billing.component'
          ).then((c) => c.CalimaticBillingComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.CalimaticBilling],
          v2Path: appRoutePaths.billing.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.permissions.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/main/admin/permissions/permissions.component').then(
            (c) => c.PermissionsComponent
          ),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.SecurityPermissions],
          v2Path: appRoutePaths.permissions.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.audits.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/main/admin/audit/audit.component').then(
            (c) => c.AuditComponent
          ),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.AuditLogs],
          v2Path: appRoutePaths.audits.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.importSettings.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/admin/import-settings/import-settings.component'
          ).then((c) => c.ImportSettingsComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.ImportSettings],
          v2Path: appRoutePaths.importSettings.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.importStaff.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/main/admin/import-staff/import-staff.component').then(
            (c) => c.ImportStaffComponent
          ),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.ImportStaff],
          v2Path: appRoutePaths.importStaff.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.importStudents.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/admin/import-students/import-students.component'
          ).then((c) => c.ImportStudentsComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.ImportStudent],
          v2Path: appRoutePaths.importStudents.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },

      //franchises
      {
        path: appRoutePaths.franchiseSetup.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/franchises/franchise-setup/franchise-setup.component'
          ).then((c) => c.FranchiseSetupComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Setup],
          v2Path: appRoutePaths.franchiseSetup.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.franchiseContacts.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/franchises/franchise-contacts/franchise-contacts.component'
          ).then((c) => c.FranchiseContactsComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Contacts],
          v2Path: appRoutePaths.franchiseContacts.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.franchiseEnrollments.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/franchises/franchise-enrollment/franchise-enrollment.component'
          ).then((c) => c.FranchiseEnrollmentComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.FranchiseEnrollments],
          v2Path: appRoutePaths.franchiseEnrollments.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.franchiseBoard.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/franchises/franchise-board/franchise-board.component'
          ).then((c) => c.FranchiseBoardComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Board],
          v2Path: appRoutePaths.franchiseBoard.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.franchiseOnboarding.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/franchises/franchise-onboard/franchise-onboard.component'
          ).then((c) => c.FranchiseOnboardComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.OnBoarding],
          v2Path: appRoutePaths.franchiseOnboarding.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.franchiseBilling.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/franchises/franchise-billing/franchise-billing.component'
          ).then((c) => c.FranchiseBillingComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Billing],
          v2Path: appRoutePaths.franchiseBilling.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.franchiseAgreements.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/franchises/franchise-agreements/franchise-agreements.component'
          ).then((c) => c.FranchiseAgreementsComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Agreements],
          v2Path: appRoutePaths.franchiseAgreements.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.franchiseInventory.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/franchises/franchise-inventory/franchise-inventory.component'
          ).then((c) => c.FranchiseInventoryComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Inventory],
          v2Path: appRoutePaths.franchiseInventory.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.franchiseDocuments.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/franchises/franchise-documents/franchise-documents.component'
          ).then((c) => c.FranchiseDocumentsComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Documents],
          v2Path: appRoutePaths.franchiseDocuments.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.franchiseMap.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/franchises/franchise-map/franchise-map.component'
          ).then((c) => c.FranchiseMapComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Maps],
          v2Path: appRoutePaths.franchiseMap.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },

      //courses
      {
        path: appRoutePaths.courses.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/courses/course-player/course-player.component'
          ).then((c) => c.CoursePlayerComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Courses],
          v2Path: appRoutePaths.courses.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.central.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/main/courses/central/central.component').then(
            (c) => c.CentralComponent
          ),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Central],
          v2Path: appRoutePaths.central.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.classes.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/main/courses/classes/classes.component').then(
            (c) => c.ClassesComponent
          ),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Class],
          v2Path: appRoutePaths.classes.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.coursesEnrollmentFormSetup.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/courses/courses-enrollment-form-setup/courses-enrollment-form-setup.component'
          ).then((c) => c.CoursesEnrollmentFormSetupComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.EnrollmentFormSetup],
          v2Path: appRoutePaths.coursesEnrollmentFormSetup.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },

      //events
      {
        path: appRoutePaths.events.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/main/events/events.component').then(
            (c) => c.EventsComponent
          ),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Events],
          v2Path: appRoutePaths.events.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.eventEnrollmentFormSetup.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/events/event-enrollment-form-setup/event-enrollment-form-setup.component'
          ).then((c) => c.EventEnrollmentFormSetupComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.EventsEnrollmentFormSetup],
          v2Path: appRoutePaths.eventEnrollmentFormSetup.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.eventCalendar.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/events/event-calendar/event-calendar.component'
          ).then((c) => c.EventCalendarComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.EventsCalendar],
          v2Path: appRoutePaths.eventCalendar.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.newEventCalendar.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/main/events/new-event-calendar/new-event-calendar.component'
          ).then((c) => c.NewEventCalendarComponent),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.NewEventCalendar],
          v2Path: appRoutePaths.newEventCalendar.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },

      //families
      {
        path: appRoutePaths.families.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/main/families/families.component').then(
            (c) => c.FamiliesComponent
          ),
        canActivate: [authGuard, menuGuard],
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.FamiliesInfo],
          v2Path: appRoutePaths.families.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },

      //students
      {
        path: appRoutePaths.students.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/students/students.component').then(
            (c) => c.StudentsComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.StudentInfo],
          v2Path: appRoutePaths.students.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.studentPoints.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/students/student-points/student-points.component'
          ).then((c) => c.StudentPointsComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.StudentPoints],
          v2Path: appRoutePaths.studentPoints.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.calendar.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/students/calendar/calendar.component').then(
            (c) => c.CalendarComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Calendar],
          v2Path: appRoutePaths.calendar.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.ideas.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/students/ideas/ideas.component').then(
            (c) => c.IdeasComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Ideas],
          v2Path: appRoutePaths.ideas.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.assessments.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/students/assessments/assessments.component'
          ).then((c) => c.AssessmentsComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.StudentAssessments],
          v2Path: appRoutePaths.assessments.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.payments.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/students/payments/payments.component').then(
            (c) => c.PaymentsComponent
          ),
        data: {
          subMenu: true,
          menuId: [
            SubMenusEnum.StudentPayments,
            SubMenusEnum.FamiliesPayments,
            SubMenusEnum.FinancialPayments,
          ],
          v2Path: appRoutePaths.payments.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.transactions.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/students/transactions/transactions.component'
          ).then((c) => c.TransactionsComponent),
        data: {
          subMenu: true,
          menuId: [
            SubMenusEnum.Transactions,
            SubMenusEnum.FamiliesTransactions,
            SubMenusEnum.StudentTransactions,
            SubMenusEnum.FinancialTransactions,
          ],
          v2Path: appRoutePaths.transactions.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.blackout.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/students/blackout/blackout.component').then(
            (c) => c.BlackoutComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.StudentBlackoutDates],
          v2Path: appRoutePaths.blackout.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.attendance.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/students/attendance/attendance.component').then(
            (c) => c.AttendanceComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Attendance],
          v2Path: appRoutePaths.attendance.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.drive.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/students/drive/drive.component').then(
            (c) => c.DriveComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.StudentDrive],
          v2Path: appRoutePaths.drive.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.checkInOut.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/staff/check-inout/check-inout.component').then(
            (c) => c.CheckInoutComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.CheckInOut],
          v2Path: appRoutePaths.checkInOut.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.semesters.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/students/semesters/semesters.component').then(
            (c) => c.SemestersComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Semesters],
          v2Path: appRoutePaths.semesters.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },

      //partners
      {
        path: appRoutePaths.partners.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/partners/partners.component').then(
            (c) => c.PartnersComponent
          ),
        data: {
          subMenu: false,
          menuId: [MenuEnum.Partners],
          v2Path: appRoutePaths.partners.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },

      //staff
      {
        path: appRoutePaths.staff.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/staff/staff/staff.component').then(
            (c) => c.StaffComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.StaffInfo],
          v2Path: appRoutePaths.staff.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.timeTracker.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/staff/time-tracker/time-tracker.component').then(
            (c) => c.TimeTrackerComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.TimeTracker],
          v2Path: appRoutePaths.timeTracker.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.timeSchedules.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/staff/time-schedules/time-schedules.component'
          ).then((c) => c.TimeSchedulesComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.TimeTracker],
          v2Path: appRoutePaths.timeSchedules.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.timeSheet.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/staff/time-sheet/time-sheet.component').then(
            (c) => c.TimeSheetComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.TimeTracker],
          v2Path: appRoutePaths.timeSheet.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.timeOff.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/staff/time-off/time-off.component').then(
            (c) => c.TimeOffComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.TimeTracker],
          v2Path: appRoutePaths.timeOff.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.timeExpenses.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/staff/expenses/expenses.component').then(
            (c) => c.ExpensesComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.TimeTracker],
          v2Path: appRoutePaths.timeExpenses.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.approvals.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/staff/approvals/approvals.component').then(
            (c) => c.ApprovalsComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.TimeTracker],
          v2Path: appRoutePaths.approvals.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.wages.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/staff/wages/wages.component').then(
            (c) => c.WagesComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.TimeTracker],
          v2Path: appRoutePaths.wages.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.staffCheckInOut.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/staff/check-inout/check-inout.component').then(
            (c) => c.CheckInoutComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.StaffCheckInOut],
          v2Path: appRoutePaths.staffCheckInOut.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },

      //CRM
      {
        path: appRoutePaths.announcements.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/crm/announcements/announcements.component').then(
            (c) => c.AnnouncementsComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Announcements],
          v2Path: appRoutePaths.announcements.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.articles.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/crm/articles/articles.component').then(
            (c) => c.ArticlesComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Articles],
          v2Path: appRoutePaths.articles.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.internalArticles.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/crm/articles/articles.component').then(
            (c) => c.ArticlesComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.InternalArticles],
          v2Path: appRoutePaths.internalArticles.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.sms.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/crm/sms/sms.component').then(
            (c) => c.SmsComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.SMS],
          v2Path: appRoutePaths.sms.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.emails.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/crm/emails/emails.component').then(
            (c) => c.EmailsComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Emails],
          v2Path: appRoutePaths.emails.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.leads.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/crm/leads/leads.component').then(
            (c) => c.LeadsComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Leads],
          v2Path: appRoutePaths.leads.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.contactForms.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/crm/contact-form/contact-form.component').then(
            (c) => c.ContactFormComponent
          ),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.ContactForms],
          v2Path: appRoutePaths.contactForms.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },

      //parties
      {
        path: appRoutePaths.partyConfig.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/parties/party-config/party-config.component'
          ).then((c) => c.PartyConfigComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.PartyConfigurations],
          v2Path: appRoutePaths.partyConfig.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.partyNewBooking.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/parties/party-new-booking/party-new-booking.component'
          ).then((c) => c.PartyNewBookingComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.NewBooking],
          v2Path: appRoutePaths.partyNewBooking.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.partyBookings.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/parties/party-bookings/party-bookings.component'
          ).then((c) => c.PartyBookingsComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.PartyBookings],
          v2Path: appRoutePaths.partyBookings.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },

      //reports
      {
        path: appRoutePaths.billingReport.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/reports/billing-report/billing-report.component'
          ).then((c) => c.BillingReportComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.BillingReport],
          v2Path: appRoutePaths.billingReport.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.enrollmentReport.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/reports/enrollment-report/enrollment-report.component'
          ).then((c) => c.EnrollmentReportComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.EnrollmentsReport],
          v2Path: appRoutePaths.enrollmentReport.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.classesReport.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/reports/classes-report/classes-report.component'
          ).then((c) => c.ClassesReportComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.ClassesReport],
          v2Path: appRoutePaths.classesReport.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.familiesReport.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/reports/families-report/families-report.component'
          ).then((c) => c.FamiliesReportComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.FamiliesStudentsReport],
          v2Path: appRoutePaths.familiesReport.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.eventsReport.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/reports/events-report/events-report.component'
          ).then((c) => c.EventsReportComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.EventsReport],
          v2Path: appRoutePaths.eventsReport.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.studentProgressReport.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/reports/student-progress-report/student-progress-report.component'
          ).then((c) => c.StudentProgressReportComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.StudentProgressReport],
          v2Path: appRoutePaths.studentProgressReport.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.resultReport.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/reports/result-report/result-report.component'
          ).then((c) => c.ResultReportComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Result],
          v2Path: appRoutePaths.resultReport.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.transcriptReport.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/reports/transcript-report/transcript-report.component'
          ).then((c) => c.TranscriptReportComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.Transcript],
          v2Path: appRoutePaths.transcriptReport.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.reportCardReport.v3,
        pathMatch: 'full',
        canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import(
            './pages/main/reports/report-card-report/report-card-report.component'
          ).then((c) => c.ReportCardReportComponent),
        data: {
          subMenu: true,
          menuId: [SubMenusEnum.ReportCard],
          v2Path: appRoutePaths.reportCardReport.v2,
        },
        resolve: {
          resolver: UrlResolver,
        },
      },
      {
        path: appRoutePaths.userProfile.v3,
        pathMatch: 'full',
        // canActivate: [authGuard, menuGuard],
        loadComponent: () =>
          import('./pages/main/admin/user-profile/user-profile.component').then(
            (c) => c.UserProfileComponent
          ),
      },
      {
        path: 'stripe-connect',
        pathMatch: 'full',
        canActivate: [authGuard],
        loadComponent: () =>
          import('./pages/main/stripe-connect/stripe-connect.component').then(
            (c) => c.StripeConnectComponent
          ),
      },
    ],
  },
  {
    path: '',
    loadComponent: () =>
      import('./pages/external/external.component').then(
        (a) => a.ExternalComponent
      ),
    children: [
      //embeddedContactForm
      {
        path: appRoutePaths.contactForm.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/external/embedded-contact-form/embedded-contact-form.component'
          ).then((c) => c.EmbeddedContactFormComponent),
      },
      {
        path: appRoutePaths.contactForm.v3Extension,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/external/embedded-contact-form/embedded-contact-form.component'
          ).then((c) => c.EmbeddedContactFormComponent),
      },
      {
        path: appRoutePaths.enrollmentForm.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/external/enrollment-form/enrollment-form.component'
          ).then((c) => c.EnrollmentFormComponent),
      },
      {
        path: appRoutePaths.enrollmentForm.v3Extension,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/external/enrollment-form/enrollment-form.component'
          ).then((c) => c.EnrollmentFormComponent),
      },
      {
        path: `${appRoutePaths.courseCertificate.v3}`,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/external/course-certificate/course-certificate.component'
          ).then((c) => c.CourseCertificateComponent),
      },
      {
        path: `${appRoutePaths.article.v3}/:category/:id`,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/external/articles/articles.component').then(
            (c) => c.ArticlesComponent
          ),
      },
      {
        path: `${appRoutePaths.article.v3}/:id`,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/external/articles/articles.component').then(
            (c) => c.ArticlesComponent
          ),
      },
      {
        path: appRoutePaths.leadForm.v3Extension2,
        canActivate: [ShortUrlRedirectionGuard],
        loadComponent: () =>
          import('./pages/external/crm-form/crm-form.component').then(
            (c) => c.CrmFormComponent
          ),
      },
      {
        path: appRoutePaths.leadForm.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/external/crm-form/crm-form.component').then(
            (c) => c.CrmFormComponent
          ),
      },
      {
        path: appRoutePaths.leadForm.v3Extension,
        pathMatch: 'full',
        loadComponent: () =>
          import('./pages/external/crm-form/crm-form.component').then(
            (c) => c.CrmFormComponent
          ),
      },
      {
        path: appRoutePaths.adminForm.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/external/franchise-admin-form/franchise-admin-form.component'
          ).then((c) => c.FranchiseAdminFormComponent),
      },
      {
        path: appRoutePaths.invitationForm.v3,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/external/invite-user-form/invite-user-form.component'
          ).then((c) => c.InviteUserFormComponent),
      },
      {
        path: appRoutePaths.message.success,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/external/submission-message/submission-message.component'
          ).then((c) => c.SubmissionSuccessComponent),
      },
      {
        path: appRoutePaths.message.error,
        pathMatch: 'full',
        loadComponent: () =>
          import(
            './pages/external/submission-message/submission-message.component'
          ).then((c) => c.SubmissionSuccessComponent),
      },
    ],
  },
];
