@if (chartData && !loading) {
  <div class="clm-widget-item card">
    <div class="card-action-wrapper">
      @if (edit()) {
        <span (click)="onRemove.emit()" class="action_btn" [pTooltip]="'remove widget'" tooltipPosition="bottom"
              [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-xmark"></i></span>
      }
    </div>
    <div class="card-header">
      <h5>{{ widget().title }}
        <span class="card-icon-inline info-icon" [pTooltip]="widget().description" tooltipPosition="bottom"
        [tooltipOptions]="{showDelay:500}">
        <i class="fa-solid fa-circle-info"></i>
      </span>
      <span (click)="loadData()" class="card-icon-inline refresh-btn" [pTooltip]="'Refresh widget'" tooltipPosition="bottom"
            [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-rotate"></i></span>
      </h5>
    </div>
    <div class="card-body">
      <calimatic-pie-chart [data]="chartData"></calimatic-pie-chart>
    </div>
  </div>
} @else {
  <calimatic-chart-skeleton></calimatic-chart-skeleton>
}
