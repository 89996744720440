export enum SubMenuTypeEnum {
  sub = "sub",
  mega = "mega"
}


export enum MenuEnum {
  Dashboard = 1,
  Admin = 2,
  Boards = 3,
  FranchiseManagement = 4,
  Courses = 5,
  Events = 6,
  Families = 7,
  Students = 8,
  Staff = 9,
  CRM = 10,
  Reports = 11,
  Partners = 12,
  Parties = 13
}

export enum SubMenusEnum {
  // Admin
  SystemConfig = 1,
  Users,
  Administrators,
  CalimaticBilling,
  SecurityPermissions,
  AuditLogs,
  ImportSettings,
  ImportStaff,
  ImportStudent,
  // Franchises
  Setup,
  Contacts,
  FranchiseEnrollments,
  Board,
  OnBoarding,
  Agreements,
  Billing,
  Inventory,
  Documents,
  Maps,
  Ideas,

  // Courses
  Courses,
  Central,
  Class,
  EnrollmentFormSetup,
  Calendar,
  Transactions,
  Attendance,

  // Events
  Events,
  EventsEnrollmentFormSetup,
  EventsCalendar,

  // Families
  FamiliesInfo,
  FamiliesPayments,
  FamiliesTransactions,
  FamiliesCalendar,
  FamiliesAttendance,

  // Students
  StudentInfo,
  StudentPoints,
  StudentCalendar,
  StudentIdeas,
  StudentAssessments,
  StudentPayments,
  StudentBlackoutDates,
  StudentTransactions,
  CheckInOut,
  StudentAttendance,
  StudentDrive,

  // Staff
  StaffInfo,
  TimeTracker,
  StaffCheckInOut,

  // CRM
  ContactForms,
  Leads,
  Emails,
  SMS,
  Articles,
  InternalArticles,
  Announcements,

  // Parties
  PartyConfigurations,
  NewBooking,
  PartyBookings,

  // Reports
  BillingReport,
  EnrollmentsReport,
  ClassesReport,
  FamiliesStudentsReport,
  EventsReport,
  StudentProgressReport,
  Result,
  Transcript,
  ReportCard,

  // Curriculum
  CurriculumDocuments,

  // Financial
  FinancialTransactions,
  FinancialPayments,

  // Dashboard
  Dashboard,

  // Boards
  StudentBoard,
  ClassesBoard,
  StaffBoard,

  // Partners
  Partners,
  Semesters,
  NewEventCalendar,
  ActivityFinder
}

// this is a generic numbers enum, you can utilze it where you need apply checks on numbers, because hard coded numbers in checks does not looks good in code.
// you can aslo add your own number if it's missing and give it proper name like 20 make it Twenty = 20  .
export enum GenericNumbersEnum{
  Zero = 0,
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten

}
