import {AfterViewInit, Component, ElementRef, inject, input, OnDestroy, output, viewChild,} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EditorModule} from 'primeng/editor';
import {merge} from 'lodash';
import { FileUploadService } from '../../services/file-upload.service';
import { AuthService } from '../../../core/services/auth/auth.service';

declare var $: any;

interface SummernoteConfigsDTO {
  height: number;
  fontSizes: string[];
  toolbar: (string | string[])[][];
  popover: { image: (string | string[])[][], link: (string | string[])[][] };
  defaultFontName: string;
  fontNames: string[];
  fontNamesIgnoreCheck: string[];
  dialogsInBody: boolean;
}

@Component({
  selector: 'calimatic-summernote',
  standalone: true,
  imports: [EditorModule, ReactiveFormsModule, FormsModule],
  templateUrl: './summernote.component.html',
  styleUrl: './summernote.component.scss'
})
export class SummernoteComponent implements AfterViewInit, OnDestroy {

  //inputs
  content = input<string>('');
  //only required if user is not logged in (i.e. enrollment form)
  companyGuid = input<string>();
  configs = input<Partial<SummernoteConfigsDTO>>();
  container = viewChild.required<ElementRef>("container");

  //outputs
  onChange = output<string>();
  onBlur = output();
  onInit = output();
  onImageUpload = output<any>();
  onPaste = output<any>();

  //injections
  fileService = inject(FileUploadService)
  authService = inject(AuthService)
  private _defaultConfigs = {
    fontSizes: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '26', '28', '30', '32', '34', '36'],
    height: 300,
    toolbar: [
      ['style', ['style']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontname', ['fontname']],
      ['fontsize', ['fontsize']],
      ['color', ['color']],
      ['para', ['ol', 'ul', 'paragraph', 'height']],
      ['table', ['table']],
      ['insert', ['link']],
      ['insert', ['video']],
      ['insert', ['picture']],
      ['view', ['undo', 'redo', 'fullscreen', 'codeview']]],

    popover: {
      image: [
        ['imagesize', ['imageSize100', 'imageSize50', 'imageSize25']],
        ['float', ['floatLeft', 'floatRight', 'floatNone']],
        ['remove', ['removeMedia']]
      ],
      link: [
        ['link', ['linkDialogShow', 'unlink']]
      ]
    },
    defaultFontName: 'Arial',
    fontNames: [
      'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New',
      'Helvetica Neue', 'Helvetica', 'Impact', 'Lucida Grande', ' Sans-serif', 'Roboto', 'Proxima-Nova'],
    fontNamesIgnoreCheck: ['Arial', 'Roboto', 'Proxima-Nova'],
    dialogsInBody: true
  };
  private element: any;


ngAfterViewInit() {
    this.element = $(this.container().nativeElement);
    this.element.summernote({
      ...this.getConfigs(),
      callbacks: {
        onChange: (contents: string) => {
          this.onChange && this.onChange.emit(contents);
        },
        onBlur: () => {
          this.onBlur && this.onBlur.emit();
        },
        onImageUpload: async (files: FileList) => {
          if (files?.length) {
            for (const file of Array.from(files)) {
              try {
                const httpResponse = await this.fileService.uploadFile(
                  file,
                  this.companyGuid()
                    ? this.companyGuid()
                    : this.authService.getUserModal().CompanyGuid
                );
                this.element.summernote('insertImage', httpResponse.response);
              } catch (error) {
                console.error('Error uploading image:', error);
              }
            }
          }
        },
        onPaste: (e: any) => {
          if (e?.clipboardData) {
            const items = e.originalEvent?.clipboardData?.items;
            if (items?.length) {
              for (let i = 0; i < items.length; i++) {
                // Skip content if not image
                if (items[i].type.indexOf("image") == -1)
                  continue;

                e.originalEvent.clipboardData.clearData();
                console.log('Pasted text contains image which is not allowed. Use image uploader instead.');
              }
            }
          }

          this.onPaste && this.onPaste.emit(e);
        },
        onInit: () => {
          if (this.content()) {
            this.element.summernote('code', this.content());
          }
          this.onInit && this.onInit.emit();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.element?.summernote('destroy');
  }

  private getConfigs() {
    return merge({}, this._defaultConfigs, this.configs() || {});
  }
}
