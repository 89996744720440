import Moment from 'moment';

export const DateTimeFormats = {
  TimeFormat12: 'hh mm a',
  TimeFormat13: 'hh:mm a',
  TimeFormatWithAm: 'hh:mm a',
  TimeFormat12Col: 'h:mm A',
  TimeFormat24: 'hh mm',
  TimeDisplayFormat: 'LT', // 4:49 PM
  DateDisplayFormat: 'ddd ll', //Thu Jan 20, 2022
  DateDisplayFormatFull: 'dddd ll', //Thu Jan 20, 2022
  EmailDateDisplayFormat: 'MMMM D, YYYY', //Thu Jan 20, 2022
  DateDisplayFormat2: 'MMM D', //Jan 20
  DateDisplayFormat3: 'MMM D, YYYY', //Jan 20, 2022
  DateDisplayFormatSuffix: 'MMM Do, YYYY', //Jan 20th, 2022
  DateDisplayFormatSuffix2: 'MMMM Do, YYYY', //Jan 20th, 2022
  DateTimeFormat: 'MMM D, y, h:mm a', // Feb 3 , 2022, 4:49 pm,
  DateTimeFormat1: 'MMM D, YYYY h:mm a', // Feb 3 , 2022, 4:49 pm,
  DateDisplayFormat4: 'MM/DD/yyyy', // 09/18/2022
  DateTimeFormat5: 'MM/DD/yyyy h:mm a', // 09/18/2022 4:49 pm
  DayName: 'dddd', // monday,
  Monthly: 'MMMM D', //March 24
  Long24Time: 'HH:mm:ss.SSSSSSS',
  DateTimeFormat2:'MMM DD YYYY h:mmA',
  DateTimeFormat3: 'MMM-dd-yyyy hh:mm a' // May-16-2024 04:29 PM
}


export class MomentHelper {
  static units: any = {
    milliseconds: 'ms',
    seconds: 's',
    minutes: 'm',
    hours: 'h',
    days: 'd',
    weeks: 'w',
    month: 'M',
    years: 'y'
  };

  private static defaultConversionUnit: any = this.units.days;

  public static Now() {
    return Moment();
  }

  public static GetDate() {
    return Moment().toDate();
  }

  public static GetMoment(_date: any) {
    return Moment(_date);
  }

  public static Tomorrow() {
    return this.Now().add(1, this.defaultConversionUnit);
  }

  public static GetDay(date: Date) {
    return Moment(date).day();
  }

  public static Future(after: number, unit: any) {
    return this.Now().add(after, unit);
  }

  public static Past(amount: number, unit: any) {
    return this.Now().subtract(amount, unit);
  }

  public static Parse(date: Date, format?: string) {
    return format ? Moment(date, format) : Moment(date);
  }

  public static ParseStr(date: string, format?: string) {
    return format ? Moment(date, format) : Moment(date);
  }

  public static GetReadableDateTime(startDate: Date, endDate: Date) {
    return Moment.duration(this.Parse(startDate).diff(this.Parse(endDate))).humanize();
  }

  public static IsToday(date: Date, unit: any = this.units.days) {
    let momentDate = this.Parse(date);
    return momentDate.isSame(this.Now(), unit);
  }

  public static IsTodayDate(date: Date) {
    let today = this.Now()?.toDate()
    return this.IsSameDate(today, date);
  }

  public static IsTomorrowDate(date: Date) {
    let today = this.Tomorrow()?.toDate()
    return this.IsSameDate(today, date);
  }

  public static IsDayAfterTomorrowDate(date: Date, unit: any = this.units.days) {
    let tom = this.Tomorrow()?.toDate()

    tom.setHours(0);
    tom.setMinutes(0);
    tom.setSeconds(0);
    tom.setMilliseconds(0);
    date = new Date(date);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return this.IsAfterDate(date, tom, unit);
  }

  public static IsTomorrow(startDate: Date, unit: any = this.units.days) {
    let momentDate = this.Parse(startDate);
    return momentDate.isSame(this.Tomorrow(), unit);
  }

  public static IsDayAfterTomorrow(date: Date, unit: any = this.units.days) {
    return this.IsAfter(date, this.Now().add(1, unit).toDate(), unit);
  }

  public static IsBetween(date: Date, startDate: Date, endDate: Date) {
    return this.Parse(date).isBetween(this.Parse(startDate), this.Parse(endDate), this.units.days, '[]');
  }

  public static IsSameDate(date: Date, compareDate: Date) {
    let dt = new Date(date);
    let cdt = new Date(compareDate);
    return dt.getFullYear() === cdt.getFullYear() &&
      dt.getMonth() === cdt.getMonth() &&
      dt.getDate() === cdt.getDate();
  }

  // used for task
  public static Diff(start: Date, end: Date) {
    let dt = new Date(start);
    let cdt = new Date(end);

    if (dt.getFullYear() === cdt.getFullYear() &&
      dt.getMonth() === cdt.getMonth() &&
      dt.getDate() !== cdt.getDate())
      return cdt.getDate() - dt.getDate();
    else
      return this.Parse(cdt).diff(dt, 'days');
  }

  public static IsSame(date: Date, compareDate: Date, unit: any = this.units.days) {
    let momentDate = this.Parse(date);
    let momentCompareDate = this.Parse(compareDate);
    return momentDate.isSame(momentCompareDate, unit);
  }

  public static IsBefore(date: Date, compareDate: Date, unit: any = this.units.days) {
    let compareWith = this.Parse(compareDate);
    return this.Parse(date).isBefore(compareWith, unit);
  }

  public static IsBeforeDate(date: Date, compareDate: Date, unit: any = this.units.days) {
    compareDate.setHours(0);
    compareDate.setMinutes(0);
    compareDate.setSeconds(0);
    compareDate.setMilliseconds(0);
    date = new Date(date);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date < compareDate;
  }

  public static IsAfterDate(date: Date, compareDate: Date, unit: any = this.units.days) {

    return date > compareDate;
  }

  public static IsSameOrBefore(date: Date, compareDate: Date, unit: any = this.units.days) {
    let momentDate = this.Parse(date);
    let momentCompareDate = this.Parse(compareDate);
    return momentDate.isSameOrBefore(momentCompareDate, unit);
  }

  public static IsSameOrBeforeDate(date: Date, compareDate: Date) {
    let dt = new Date(date);
    let cdt = new Date(compareDate);

    return dt.getFullYear() <= cdt.getFullYear() &&
      dt.getMonth() <= cdt.getMonth() &&
      dt.getDate() <= cdt.getDate();
  }


  public static IsAfter(date: Date, compareDate: Date, unit: any = this.units.days) {
    let compareWith = this.Parse(compareDate);
    return this.Parse(date).isAfter(compareWith, unit);
  }

  public static IsSameOrAfter(date: Date, compareDate: Date, unit: any = this.units.days) {
    let momentDate = this.Parse(date);
    let momentCompareDate = this.Parse(compareDate);
    return momentDate.isSameOrAfter(momentCompareDate, unit);
  }

  public static IsSameOrAfterDate(date: Date, compareDate: Date) {
    let dt = new Date(date);
    let cdt = new Date(compareDate);

    return dt.getFullYear() >= cdt.getFullYear() &&
      dt.getMonth() >= cdt.getMonth() &&
      dt.getDate() >= cdt.getDate();
  }


  public static Subtract(date: Date, unit: any, val: any) {
    return this.Parse(date).subtract(val, unit);
  }

  public static addHoursToDate(date: Date, hours: number): Date {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  public static setTimeAsZero(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }

  public static setTimeAsDaysEnd(date: Date): Date {
    return new Date(date.setHours(23, 59, 59, 999));
  }

  public static addDaysToDate(date: Date, days: number): Date {
    return new Date(new Date(date).setDate(date?.getDate() + days));
  }

  public static substractDaysToDate(date: Date, days: number): Date {
    return new Date(new Date(date).setDate(date.getDate() - days));
  }

  public static subtractDaysToDate(date: Date, days: number): Date {
    return new Date(new Date(date).setDate(date.getDate() - days));
  }

  public static dateExistsBetween(dateToCheck: Date, fromDate: Date, toDate: Date): boolean {
    return Moment(dateToCheck).isBetween(fromDate, toDate);
  }

  public static fallBetween(filterStartDate: any, filterEndDate: any, eventStartDate: any, eventEndDate: any): boolean {
    return new Date(eventStartDate) <= new Date(filterEndDate) && new Date(eventEndDate) >= new Date(filterStartDate);
  }

  public static getDifferenceInMinutes(startDate: Date, endDate: Date): Number {
    let _hours = Moment(endDate).diff(startDate, this.units.days);
    return _hours >= 0 ? _hours : _hours * -1;
  }

  public static getTimeLogReportDifferenceInMinutes(startDate: Date, endDate: Date, units: any = this.units.days): number {
    let _hours = Moment(endDate).diff(startDate, units);
    return _hours >= 0 ? _hours : _hours * -1;
  }

  public static getDifferenceInDays(endDate: Date, startDate: Date, excludeTime: boolean = false) {
    if (excludeTime) return Moment(endDate).startOf('day').diff(Moment(startDate).startOf('day'), 'days');
    else return Moment(endDate).diff(Moment(startDate), 'days');
  }

  public static getDifferenceInUnits(endDate: Date, startDate: Date, units: any) {
    return Moment(endDate).diff(Moment(startDate), units);
  }

  public static getDayDatesBetweenRange(startDate: Date, endDate: Date, dayName: string) {
    let end = Moment(endDate);
    let currentDate = Moment(startDate);
    const matchingDates = [];
    while (currentDate <= end) {
      if (currentDate.format('dd').toUpperCase() === dayName) {
        matchingDates.push(currentDate.format('YYYY-MM-DD'));
      }
      currentDate.add(1, 'days');
    }
    return matchingDates;
  }

  public static getDayDateBetweenRange(startDate: Date, endDate: Date, day: number) {
    let end = Moment(endDate);
    let currentDate = Moment(startDate);
    while (currentDate <= end) {
      if (currentDate.toDate().getDay() === day) {
        return currentDate.format('MM/DD/yyyy');
      }
      currentDate.add(1, 'days');
    }
    return undefined;
  }

  public static calculateTimePast(date: Date) {
    if (!!date) return Moment(new Date(date)).fromNow();
    return '';
  }

  public static formatDate(date: Date, format: string): string {
    if (!!date && !!format) return Moment(date).format(format);
    return '';
  }

  public static addUsingMoment(date: any, unit: number, type: string) {
    // user have to pass week days or month as string to addition
    // units = number of days / months / weeks
    // type = week/days/day/month/isoWeek etc
    return Moment(date)
      .add(unit, type as any)
      .toISOString();
  }

  public static subtractUsingMoment(date: any, unit: number, type: string) {
    // user have to pass week days or month as string to addition
    return Moment(date)
      .subtract(unit, type as any)
      .toISOString();
  }

  public static getDateTime(date: any, timePart: string) {
    let dayDate = MomentHelper.GetMoment(date).format('MM/DD/yyyy');
    return MomentHelper.GetMoment(`${dayDate} ${timePart}`).toDate();
  }

  public static currentDayName(date: Date) {
    return this.Parse(date).format(DateTimeFormats.DayName);
  }

  public static mergeTimeWithCurrentDate(date: Date, time: string) {
    let merged = `${this.Parse(date).format('MM/DD/yyyy')} ${time}`;
    return this.GetMoment(merged).toDate();
  }

  public static isValidTime(time: string, format: string = 'HH:mm A') {
    return Moment(time, format, true).isValid();
  }


}
