import {CommonModule} from '@angular/common';
import {Component, inject, input} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {CardModule} from 'primeng/card';
import {InputTextModule} from 'primeng/inputtext';
import {MessagesModule} from 'primeng/messages';
import {PasswordModule} from 'primeng/password';
import {LanguageComponent} from '../../../../shared/components/language/language.component';
import {CompanyConfigDto} from "../../../../shared/dtos/company-config-dto";
import {AppConstants} from "../../../../shared/utils/global";
import {CompanyService} from "../../../../core/services/company.service";
import {AppUtils} from "../../../../shared/utils/app-utilities";
import {ForgotPasswordComponent} from '../../forgot-password/forgot-password.component';
import {AppLabelComponent} from "../../../../shared/components/app-label/app-label.component";
import {AuthService} from "../../../../core/services/auth/auth.service";
import {LoginErrorCodeEnum} from "../../../../shared/enums/login.enum";
import {appRoutePaths} from "../../../../app.routes";
import {BrowserStorageService} from "../../../../shared/services/browser-storage.service";
import {Router} from "@angular/router";
import {StyleClassModule} from "primeng/styleclass";

@Component({
  selector: 'calimatic-login-form',
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    CardModule,
    MessagesModule,
    CommonModule,
    LanguageComponent,
    ReactiveFormsModule,
    ForgotPasswordComponent,
    AppLabelComponent,
    StyleClassModule,
  ],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss'
})
export class LoginFormComponent {
  companyConfigs = input.required<CompanyConfigDto>();

  //local variables
  companyName = "";
  loginForm = new FormGroup({
    userName: new FormControl('', {
      validators: [Validators.required, Validators.pattern(AppConstants.regularExpressions.space)],
      updateOn: 'blur',
    }),
    password: new FormControl('', [Validators.required])
  });
  error = '';
  message = '';
  showForgotPasswordSection = false;
  loading = false;
  LoginErrorCodeEnum = LoginErrorCodeEnum;
  loginResponse: any;
  sendingActivationEmail = false;

  authService = inject(AuthService);
  companyService = inject(CompanyService);
  storageHelper = inject(BrowserStorageService);
  router = inject(Router);

  get formControls() {
    return this.loginForm.controls;
  }

  async login() {

    if (this.loginForm.invalid) {
      this.formControls.userName.markAsDirty();
      this.formControls.password.markAsDirty();
      return;
    }

    this.loading = true;
    let res = await this.authService.login(this.formControls.userName.value, this.formControls.password.value, AppUtils.getPortalUrl());
    this.loading = false;
    this.loginResponse = res;
    if (res.isSuccessful) {
      this.storageHelper.Add(AppConstants.browserStorage.token, res.response);
      await this.router.navigate([`/${appRoutePaths.dashboard.v3}`]);
    } else {
      this.error = res.response.message;
      this.message = '';
    }
  }

  async resendVerificationEmail() {
    if (this.loginResponse) {
      this.sendingActivationEmail = true;
      await this.authService.sendActivationEmail(this.loginResponse.response.companyGuid, this.loginResponse.response.userGuid);
      this.sendingActivationEmail = false;
      this.error = '';
      this.message = 'Account verification email has been sent';
    }
  }

  async fetchCompanyInfo() {
    const userName = this.formControls.userName.value?.trim();
    if (userName?.length) {
      this.companyName = await this.companyService.getNameByUserName(userName, AppUtils.getPortalUrl());
    }
  }

  showForgotPassword() {
    this.reset();
    this.showForgotPasswordSection = true;
  }

  forgotPasswordErrors(error: string) {
    this.reset();
    this.error = error;
  }

  private reset() {
    this.loginResponse = null;
    this.error = '';
    this.message = '';
  }
}
