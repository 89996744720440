import {Injectable} from "@angular/core";
import {BehaviorSubject, lastValueFrom, Subject} from "rxjs";
import {API_ENDPOINTS, AppConstants} from "../../shared/utils/global";
import {ActivateMenuInterface, MenuInterface, SubMenuInterface} from "../../shared/interfaces/menu.interface";
import {ApiHandlerService} from "./base/api-handler.service";
import {BrowserStorageService} from "../../shared/services/browser-storage.service";
import {MenuEnum} from "../../shared/enums/menu.enum";

@Injectable({
  providedIn: "root"
})
export class MenuService {
  activateMenu$ = new BehaviorSubject<ActivateMenuInterface>({code: 0, isSubMenu: false});
  private goToDashboard$ = new Subject();

  constructor(private apiService: ApiHandlerService, private storageHelper: BrowserStorageService) {
  }

  async getMenus(companyGuid: string) {
    let res = await lastValueFrom(this.apiService.Get(API_ENDPOINTS.company.menus, {companyGuid}));

    if (res.isSuccessful) {
      let menus = res.response as MenuInterface[];
      (menus || []).forEach((menu: MenuInterface) => {
        (menu.subMenus || []).forEach((x: SubMenuInterface) => {
          x.menu = menu;
        });

        //dashboard, Partners has no sub menu
        if (menu.isDirectLink && menu.subMenus?.length) {
          menu.link = menu.subMenus[0].link;
        }
      });
      return menus;
    }

    return null;
  }

  setActiveMenu(menu: number) {
    this.storageHelper.Add(AppConstants.browserStorage.activeMenu, menu.toString());
  }

  getActiveMenu() {
    return Number(this.storageHelper.Get(AppConstants.browserStorage.activeMenu) || MenuEnum.Dashboard.toString());
  }

  clearActiveMenu() {
    this.storageHelper.Delete(AppConstants.browserStorage.activeMenu);
  }

  setActiveSubMenu(subMenu: number) {
    this.storageHelper.Add(AppConstants.browserStorage.activeSubMenu, subMenu.toString());
  }

  getActiveSubMenu() {
    return Number(this.storageHelper.Get(AppConstants.browserStorage.activeSubMenu) || '0');
  }

  clearActiveSubMenu() {
    this.storageHelper.Delete(AppConstants.browserStorage.activeSubMenu);
  }

  goToDashboard() {
    this.goToDashboard$.next(true);
  }

  onGoToDashboard() {
    return this.goToDashboard$.asObservable();
  }

  activateMenu(payload: ActivateMenuInterface) {
    payload.code = Number(payload.code) || 0;
    this.activateMenu$.next(payload);
  }
}
