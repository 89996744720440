<div class="new-settings__content-container">
  @if(!isOnboardComponent){
      <header class="p-4">
          <h4 class="m-0">White Labeling</h4>
      </header>
  }
  <main>
      <div class="main-content-inner">
        @if(isPlatformConfigLoaded){
          <main class="branding-image-main">
            <div class="main-content">
              @if(isOnboardComponent){
                <p class="card-section-desc text-start text-500 px-3 mb-3">Get started by setting up Branding images</p>
              }
      
              <div class="main-tabs-card">
                <div class="">
                    <p-tabView class="{{isOnboardComponent?'':'tiny-tab'}}">
                        <p-tabPanel header="App Branding">
                          <div class="application-branding-wrapper">
                            <div class="brand-options">
      
                              <div class="brand-logo-section">
                                <div class="card-info-title mb-3">
                                  <h5>Logo</h5>
                                  <p class="text-500">Update your organization branding logo.</p>
                                </div>
                                <div class="brand-upload-card mb-5">
                                  <input type="file" class="hidden" id="upload-logo" accept="image/*" (change)="changeCompanyLogo($event)">
                                  <label class="brand-upload-card-label" for="upload-logo">
      
                                    @if(platformBrandingConfigs && platformBrandingConfigs.companyLogo){
                                    <div class="brand-uploaded-logo-card">
                                      <img src="{{platformBrandingConfigs.companyLogo}}" class="img-responsive logoFull">
                                    </div>
                                    }@else{
                                    <div class="brand-uploaded-logo-card">
                                      <img src="{{defaultLogo}}" class="img-responsive logoFull">
                                    </div>
                                    }
                                    @if(logoLoader){
                                    <div class="loader-overlay">
                                      <div class="loader"></div>
                                    </div>
                                    }
                                    <i class="fa-solid fa-pen-to-square edit-icon"></i>
      
                                  </label>
      
                                </div>
                                <div class="flex gap-3 mb-4 justify-content-end">
                                  <label for="upload-logo" pButton label="Edit" class="clm-secondary-btn" ></label>
                                  <button type="button" [loading]="resetLogoLoader" pButton label="Reset to default"
                                    (click)="resetDefaultCompanyLogo()" class="clm-primary-btn" ></button>
                                </div>
                              </div>
      
                              <hr />
      
                              <div class="card-info-title mb-4">
                                <h5>Brand Logo & Background color</h5>
                                <p class="text-500">Update the brand logo background color for <i>Main menu bar</i> and <i>website logo
                                    background</i>.</p>
                              </div>
                              <div class="card p-0">
                                <div class="card-body p-0">
                                  <div class="card-list-main">
                                    <ul class="card-list-items">
                                      <li class="card-list-item">
                                        <div>
                                          <label class="block">Menu Bar Logo Background</label>
                                          <p class="m-0 text-500">You can update the logo background color as per your need.</p>
                                        </div>
                                        <div class="clm-color-picker">
                                          <input [(colorPicker)]="this.platformBrandingConfigs.menuBarLogoBackgroundColor"
                                            (colorPickerClose)="saveMenuBarLogoBgColor($event)"
                                            [style.background]="this.platformBrandingConfigs.menuBarLogoBackgroundColor" readonly />
                                        </div>
                                      </li>
                                      <li class="card-list-item">
                                        <div>
                                          <label class="block">Website Logo Background Color</label>
                                          <p class="m-0 text-500">You can update the Website app logo background color as per your need.</p>
                                        </div>
                                        <div class="clm-color-picker">
                                          <input [(colorPicker)]="this.platformBrandingConfigs.franchiseSecureLogoBackgroundColor"
                                            (colorPickerClose)="saveFranchiseSecureLogoBgColor($event)"
                                            [style.background]="this.platformBrandingConfigs.franchiseSecureLogoBackgroundColor" readonly />
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
      
                              <hr />
      
                              <div class="brand-favicon-title">
                                <div class="card-info-title mb-3">
                                  <h5>Favicon & Title </h5>
                                  <p class="text-500">Update your organization favicon and title.</p>
                                </div>
      
                                <div class="flex flex-column gap-2 mb-4">
                                  <p-floatLabel>
                                    <input class="w-full" [(ngModel)]="platformBrandingConfigs.title" (change)="saveBrandingTitle($event)"
                                      pInputText id="browserTitle" placeholder="Enter browser title" />
                                  </p-floatLabel>
                                </div>
      
                                <div class="brand-upload-card mb-5">
                                  <input type="file" class="hidden" id="upload-FavIcon" accept="image/*" (change)="changeFavIcon($event)">
                                  <label class="brand-upload-card-label" for="upload-FavIcon">
      
                                    @if(platformBrandingConfigs && platformBrandingConfigs.favIcon){
                                    <div class="brand-uploaded-logo-card">
                                      <img src="{{platformBrandingConfigs.favIcon}}" class="img-responsive logoFull">
                                    </div>
                                    }@else{
                                    <div class="brand-uploaded-logo-card">
                                      <img src="{{defaultFavIcon}}" class="img-responsive logoFull">
                                    </div>
                                    }
                                    @if(faviconLoader){
                                    <div class="loader-overlay">
                                      <div class="loader"></div>
                                    </div>
                                    }
                                    <i class="fa-solid fa-pen-to-square edit-icon"></i>
      
                                  </label>
      
                                </div>
      
                                <div class="flex gap-3 mb-4 justify-content-end">
                                  <label for="upload-FavIcon" pButton label="Edit" class="clm-secondary-btn" ></label>
                                  <button type="button" pButton [loading]="resetFaviconLoader" label="Reset to default"
                                    (click)="resetDefaultFavIcon()" class="clm-primary-btn" ></button>
                                </div>
                              </div>
      
                            </div>
      
                            <div class="brand-preview-section">
                              <div class="brand-preview-wrapper">
                                <div class="brand-preview-inner-content">
                                  <img class="preview-bg" src="/assets/images/brand-preview.png" alt="brand-preview">
                                  <div class="logo-placeholder" [ngStyle]="{'background-color': platformBrandingConfigs.menuBarLogoBackgroundColor}">
                                    @if(platformBrandingConfigs  && platformBrandingConfigs.companyLogo){
                                      <img class="preview-logo" src="{{platformBrandingConfigs.companyLogo}}" alt="">
                                    }
                                    @else {
                                      <img class="preview-logo" src="{{defaultLogo}}" alt="">
                                    }
                                  </div>
                                  <div class="favicon-title-placeholder">
                                    <img class="preview-favicon" src="{{platformBrandingConfigs.favIcon || defaultFavIcon}}" alt="">
                                    <span class="preview-title ellipsis">{{platformBrandingConfigs.title}}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </p-tabPanel>
                        <p-tabPanel header="Login Branding">
                          <div class="application-branding-wrapper">
                            <div class="brand-options">
                              <div>
                                <div class="card-info-title mb-3">
                                  <h5>Login Image</h5>
                                  <p class="text-500">Update your organization Login image.</p>
                                </div>
                                <div class="brand-upload-card mb-5">
                                    <input type="file" class="hidden" id="upload-login-bg"  accept="image/*" (change)="changeLoginImage($event)">
                                    <label class="brand-upload-card-label" for="upload-login-bg"  >
                                        @if(this.platformBrandingConfigs && this.platformBrandingConfigs.companyLoginImage){
                                          <div class="brand-uploaded-login-card">
                                            <img src="{{this.platformBrandingConfigs.companyLoginImage}}" class="img-responsive logoFull">
                                          </div>
                                        }@else{
                                          <div class="brand-uploaded-login-card">
                                            <img src="{{defaultLoginImage}}" class="img-responsive logoFull">
                                          </div>
                                        }
                                        @if(loginImageLoader){
                                          <div  class="loader-overlay">
                                            <div class="loader"></div>
                                          </div>
                                        }
                                        <i class="fa-solid fa-pen-to-square edit-icon"></i>
      
                                    </label>
      
                                </div>
                                <div class="flex gap-3 mb-4 justify-content-end">
                                  <label for="upload-login-bg" pButton label="Edit" class="clm-secondary-btn" ></label>
                                  <button type="button" pButton [loading]="resetLoginImageLoader" label="Reset to default" (click)="resetLoginBgImage()" class="clm-primary-btn" ></button>
                                </div>
                              </div>
                              <hr/>
                              <div>
                                <div class="card-info-title mb-3">
                                  <h5>Login Logo</h5>
                                  <p class="text-500">Update you organization Login logo.</p>
                                </div>
                                <div class="brand-upload-card mb-5">
      
                                    <input type="file" class="hidden" id="upload-login-logo" accept="image/*" (change)="changeLoginLogo($event)" >
                                    <label class="brand-upload-card-label" for="upload-login-logo">
      
      
                                      @if(platformBrandingConfigs && platformBrandingConfigs.loginLogo){
                                        <div class="brand-uploaded-logo-card">
                                          <img src="{{platformBrandingConfigs.loginLogo}}" class="img-responsive logoFull">
                                        </div>
                                      }@else{
                                        <div class="brand-uploaded-logo-card">
                                          <img src="{{defaultLoginLogo}}" class="img-responsive logoFull">
                                        </div>
                                      }
                                      @if(loginLogoLoader){
                                        <div class="loader-overlay">
                                          <div class="loader"></div>
                                        </div>
                                        }
                                        <i class="fa-solid fa-pen-to-square edit-icon"></i>
      
                                    </label>
      
                                </div>
      
                                <div class="flex gap-3 mb-4 justify-content-end">
                                  <label for="upload-login-logo" pButton label="Edit" class="clm-secondary-btn"></label>
                                  <button pButton type="button" [loading]="resetLoginLogoLoader" label="Reset to default" (click)="resetDefaultLoginLogo()" class="clm-primary-btn" ></button>
                                </div>
                              </div>
                            </div>
      
                            <div class="brand-preview-section">
                              <div class="brand-preview-wrapper">
                                <div class="brand-preview-inner-content">
                                  <img class="preview-bg" src="/assets/images/login-preview.png" alt="login-preview">
                                  <div class="login-left-img-placeholder">
                                    @if(this.platformBrandingConfigs && this.platformBrandingConfigs.companyLoginImage){
                                      <img src="{{platformBrandingConfigs.companyLoginImage || defaultLoginImage}}" alt="">
                                    }
                                    @else {
                                      <img src="{{defaultLoginImage}}" alt="">
                                    }
                                  </div>
                                  <div class="login-page-logo">
                                    @if(platformBrandingConfigs && platformBrandingConfigs.loginLogo){
                                      <img src="{{platformBrandingConfigs.loginLogo || defaultLoginLogo}}" alt="">
                                    }
                                    @else {
                                      <img src="{{defaultLoginLogo}}" alt="">
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </p-tabPanel>
                      </p-tabView>
                    </div>
                  </div>
      
            </div>
          </main>
          }@else {
            <div class="mt-4">
              <calimatic-skeleton [configs]="{height:'2.5rem',width:'100%',repeat:1, styleClass:'mb-3'}"></calimatic-skeleton>
              <div class="flex gap-3">
                <calimatic-skeleton [configs]="{height:'4rem',width:'12rem',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
                <calimatic-skeleton [configs]="{height:'4rem',width:'12rem',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
              </div>
              <div class="mt-4 app-branding-image flex lg:flex-row flex-column">
                <div>
                  <calimatic-skeleton [configs]="{height:'2rem',width:'10rem',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
                  <calimatic-skeleton [configs]="{height:'2rem',width:'100%',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
                  <calimatic-skeleton [configs]="{height:'12rem',width:'100%',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
                  <hr />
                  <calimatic-skeleton [configs]="{height:'2rem',width:'10rem',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
                  <calimatic-skeleton [configs]="{height:'2rem',width:'100%',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
                  <calimatic-skeleton [configs]="{height:'12rem',width:'100%',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
                </div>
                <div>
                  <calimatic-skeleton [configs]="{height:'20rem',width:'100%',repeat:1, styleClass:'mb-2'}"></calimatic-skeleton>
                </div>
              </div>
            </div>
          }
      </div>
  </main>
</div>


