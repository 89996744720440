<div class="clm-widget-item card">
  <div class="card-action-wrapper">
    @if (edit()) {
      <span (click)="onRemove.emit()" class="action_btn" [pTooltip]="'remove widget'" tooltipPosition="bottom"
            [tooltipOptions]="{showDelay:500}"><i class="fa-solid fa-xmark"></i></span>
    }
  </div>
  <div class="card-header">
    <h5>Links</h5>
  </div>
  <div class="card-body">
    <calimatic-quick-links [menus]="menus()"></calimatic-quick-links>
  </div>
</div>
