import {Component, input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginFormComponent} from '../../login-form/login-form.component';
import {CompanyConfigDto} from "../../../../../shared/dtos/company-config-dto";
import {HttpClient} from '@angular/common/http';
import {SanitizePipe} from "../../../../../shared/pipes/sanitize.pipe";
import {fadeInOut} from '../../../../../shared/animation/animation';
import {LoginImageTypEnum} from "../../../../../shared/enums/login.enum";

@Component({
  selector: 'calimatic-standard-template',
  standalone: true,
  templateUrl: './standard-template.component.html',
  styleUrl: './standard-template.component.scss',
  imports: [
    CommonModule,
    LoginFormComponent,
    SanitizePipe
  ],
  animations: [fadeInOut]
})
export class StandardTemplateComponent implements OnInit {
  companyConfigs = input.required<CompanyConfigDto>();

  loginImageTypes: string[] = ['jpeg', 'png', 'jpg'];
  showSvgIndex: number = 0;
  showFirst: boolean = true;

  firstSvg = "";
  secondSvg = "";
  thirdSvg = "";
  fourthSvg = "";
  svgLength = 0;

  isMouseOver = false;
  selectedSlider: any;
  protected readonly loginImageTypEnum = LoginImageTypEnum;

  constructor(private httpClient: HttpClient) {
  }

  get svgItems(): number[] {
    return Array(this.svgLength).fill(0).map((x, i) => i);
  }

  ngOnInit() {
    this.loadSvg();
  }

  onMouseOver() {
    this.isMouseOver = true;
  }

  onMouseOut() {
    this.isMouseOver = false;
  }

  getSelectedSvg() {
    switch (this.showSvgIndex) {
      case 0:
        return this.firstSvg;
      case 1:
        return this.secondSvg;
      case 2:
        return this.thirdSvg;
      case 3:
        return this.fourthSvg;
      default:
        return this.firstSvg;
    }
  }

  selectSlider(item: any) {
    this.showSvgIndex = item;
    this.getSelectedSvg();
  }

  private loadSvg() {
    this.svgLength = 0;
    if ((this.companyConfigs().loginImage || '').length == 0) {
      if (this.companyConfigs().loginFirstSvg?.length) {
        this.svgLength++;
        this.httpClient
          .get(`assets/images/svgs/${this.companyConfigs().loginFirstSvg}/first-svg.svg`, {responseType: 'text'})
          .subscribe(value => {
            this.firstSvg = value;
          });
      }
      if (this.companyConfigs().loginSecondSvg?.length) {
        this.svgLength++;
        this.httpClient
          .get(`assets/images/svgs/${this.companyConfigs().loginSecondSvg}/second-svg.svg`, {responseType: 'text'})
          .subscribe(value => {
            this.secondSvg = value;
          });
      }
      if (this.companyConfigs().loginThirdSvg?.length) {
        this.svgLength++;
        this.httpClient
          .get(`assets/images/svgs/${this.companyConfigs().loginThirdSvg}/third-svg.svg`, {responseType: 'text'})
          .subscribe(value => {
            this.thirdSvg = value;
          });
      }
      if (this.companyConfigs().loginFourthSvg?.length) {
        this.svgLength++;
        this.httpClient
          .get(`assets/images/svgs/${this.companyConfigs().loginFourthSvg}/fourth-svg.svg`, {responseType: 'text'})
          .subscribe(value => {
            this.fourthSvg = value;
          });
      }

      this.rotateSvg();
    }
  }

  private rotateSvg() {
    setInterval(() => {
      if (!this.isMouseOver) {
        switch (this.svgLength) {
          case 2:
            this.showSvgIndex = (this.showSvgIndex + 1) % 2;
            break;
          case 3:
            this.showSvgIndex = (this.showSvgIndex + 1) % 3;
            break;
          case 4:
            this.showSvgIndex = (this.showSvgIndex + 1) % 4;
            break;
          default:
            this.showSvgIndex = (this.showSvgIndex + 1) % 2;
        }
        this.showFirst = !this.showFirst;
      }
    }, 3000);
  }
}
