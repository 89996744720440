import {DropdownDto} from "../../dtos/dropdown-dto";

export const DashboardEnrollmentTypes = [
  {
    name: 'Courses/Classes',
    id: 1
  },
  {
    name: 'Events',
    id: 2
  }
] as DropdownDto[];
