import {Component, effect, Inject, input} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {DropdownComponent} from "../../../../../../shared/components/dropdown/dropdown.component";
import {FieldsetModule} from "primeng/fieldset";
import {FormBuilder, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {InputTextModule} from "primeng/inputtext";
import {SharedModule} from "primeng/api";
import {SkeletonComponent} from "../../../../../../shared/components/skeleton/skeleton.component";
import {ValidateInputDirective} from "../../../../../../shared/directives/validate-input.directive";
import {TransactionConfigurationDto} from "../../../../../../shared/dtos/transaction-config.dto";
import {AppConstants, AppTokens} from "../../../../../../shared/utils/global";
import {SystemConfigService} from "../../../../../../core/services/system-config.service";
import {IToaster} from "../../../../../../shared/interfaces/toaster/IToaster";
import {ToasterTypeEnum} from "../../../../../../shared/enums/toaster-type.enum";
import {PlaidConfigDto} from "../../../../../../shared/dtos/plaid-config.dto";
import { FloatLabelModule } from 'primeng/floatlabel';
import { OnboardSetionStepsEnum } from '../../../../../../shared/enums/onboard.enum';
import { OnboardService } from '../../../../../../core/services/onboard.service';

@Component({
  selector: 'calimatic-plaid-transaction-config',
  standalone: true,
  imports: [
    ButtonModule,
    DropdownComponent,
    FieldsetModule,
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    SharedModule,
    SkeletonComponent,
    ValidateInputDirective,
    FloatLabelModule
  ],
  templateUrl: './plaid-transaction-config.component.html',
  styleUrl: './plaid-transaction-config.component.scss'
})
export class PlaidTransactionConfigComponent {

  //input
  configs = input.required<TransactionConfigurationDto>();

  //local
  dashboardURL = AppConstants.appUrls.plaidDashboardUrl;
  plaidForm = this.fb.group({
    secretKey: ['', [Validators.required, Validators.pattern(AppConstants.regularExpressions.space)]],
    clientKey: ['', [Validators.required, Validators.pattern(AppConstants.regularExpressions.space)]],
  });
  validateInput = false;
  processing = false;
  loading = true;

  constructor(private fb: FormBuilder, private configService: SystemConfigService, @Inject(AppTokens.toaster) private toaster: IToaster,private onboardService:OnboardService) {
    effect(() => {
      const _configs = this.configs();
      if (_configs) {
        this.plaidFormControls.secretKey.patchValue(_configs.plaidSecretKey);
        this.plaidFormControls.clientKey.patchValue(_configs.plaidClientKey);
        this.loading = false;
      }
    });
  }

  get plaidFormControls() {
    return this.plaidForm.controls;
  }

  async save() {

    if (this.plaidForm.invalid) {
      this.validateInput = true;
      return;
    }

    this.processing = true;
    let payload = this.plaidForm.value as PlaidConfigDto;
    payload.id = this.configs().configId;
    const res = await this.configService.savePlaidConfig(payload);
    this.processing = false;
    if (res.isSuccessful) {
    // when user make any minor change or send save call then this step will be consider as complete for On board.  
    this.onboardService.OnEmitOnboardStepSave(OnboardSetionStepsEnum.TransactionInformation);  
    this.toaster.show(ToasterTypeEnum.Success, 'Successful', 'Configuration saved successfully.');
    } else {
      this.toaster.show(ToasterTypeEnum.Error, 'Error', res.response);
    }
  }
}
