<div class="new-settings__content-container">
  @if(isOnboardComponent){
    <header class="p-4">
        <h4 class="m-0">Terminology configurations</h4>
        <button (click)="saveTerminologies()" [disabled]="isFormInvalid" class="clm-primary-btn" label="Save terminologies"
                pButton></button>
    </header>
  }
  <main>
    <div class="main-content-inner">
      <div class="main-content">
        <div class="main-headings-form">
          <p class="main-subtitle text-start text-500 px-3 mb-3">Get started by setting up
            terminologies in the platform as per your needs</p>
        </div>
        <div class="main-tabs-card terminology-table">
          <p-table [tableStyle]="{ 'min-width': '50rem' }" [value]="terminologies">
            <ng-template pTemplate="header">
              <tr>
                <th>#</th>
                <th>Default Label</th>
                <th class="w-18rem">Label</th>
                <th class="w-18rem">Label Values</th>
              </tr>
            </ng-template>
            <ng-template let-terminology pTemplate="body">
              <tr>
                <td>{{ terminology.id }}</td>
                <td>{{ terminology.name }}</td>
                <td>
                  <div class="relative inline-block">
                    <input [(ngModel)]="terminology.label"
                           [ngClass]="{'ng-invalid': ((terminology.label||'').trim())=='' }"
                           (input)="onTerminologiesChange($event)"
                           class=""
                           pInputText/>
                    <span class="focus-border"></span>
                  </div>
                </td>
  
                <td>
                  <div class="relative inline-block">
                    <input [(ngModel)]="terminology.pluralLabel"
                           [ngClass]="{'ng-invalid': ((terminology.pluralLabel||'').trim())=='' }"
                           class=""
                           (input)="onTerminologiesChange($event)"
                             pInputText/>
                    <span class="focus-border"></span>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>

        @if(!isOnboardComponent){
          <div class="text-right mt-3">
            <button (click)="saveTerminologies()" [disabled]="isFormInvalid" class="clm-primary-btn" label="Save terminologies"
                  pButton></button>
          </div>
        }
      </div>
    </div>
  </main>
</div>

