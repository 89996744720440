import { Injectable } from '@angular/core';
import { ApiHandlerService } from './base/api-handler.service';
import { lastValueFrom } from 'rxjs';
import { API_ENDPOINTS } from '../../shared/utils/global';
import { EmbeddedFormDto } from '../../shared/dtos/dynamic-form.dto';

@Injectable({
  providedIn: 'root',
})
export class EmbeddedFormService {
  constructor(private http: ApiHandlerService) {}
  async getEmbeddedFormData(key: string) {
    return lastValueFrom(
      this.http.Get(`${API_ENDPOINTS.embeddedForm.getForm}?sourceKey=${key}`)
    );
  }
  async getEmbeddedContactFormData(key: string) {
    return lastValueFrom(
      this.http.Get(
        `${API_ENDPOINTS.embeddedForm.getContactForm}?sourceKey=${key}`
      )
    );
  }
  async getEmbeddedContactFormDataByCmpGuid(cmpGuid: string) {
    return lastValueFrom(
      this.http.Get(
        `${API_ENDPOINTS.embeddedForm.getContactFormByGuid}?companyGuid=${cmpGuid}`
      )
    );
  }
  async getEmbeddedEnrollmentFormData(key: string) {
    return lastValueFrom(
      this.http.Get(
        `${API_ENDPOINTS.embeddedForm.getEnrollmentForm}?sourceKey=${key}`
      )
    );
  }
  async getEmbeddedEnrollmentFormByCmpGuid(companyGuid: string) {
    return lastValueFrom(
      this.http.Get(
        `${API_ENDPOINTS.embeddedForm.getEnrollmentFormByCmpGuid}?companyGuid=${companyGuid}`
      )
    );
  }
  async saveEmbeddedForm(formData: EmbeddedFormDto) {
    return lastValueFrom(
      this.http.Post(`${API_ENDPOINTS.embeddedForm.saveForm}`, formData)
    );
  }
  async saveEmbeddedContactForm(formData: EmbeddedFormDto) {
    return lastValueFrom(
      this.http.Post(`${API_ENDPOINTS.embeddedForm.saveContactForm}`, formData)
    );
  }
  async getShortUrlRedirection(shortCode: string) {
    return lastValueFrom(
      this.http.Get(
        `${API_ENDPOINTS.embeddedForm.getRedirectionPath}?shortCode=${shortCode}`
      )
    );
  }
  async saveEnrollmentForm(data: {
    companyGuid: string;
    defaultFields: string;
    educationalDetails: string;
    employmentDetails: string;
    partnerDetails: string;
    contactId: number;
  }) {
    return lastValueFrom(
      this.http.Post(`${API_ENDPOINTS.embeddedForm.saveEnrollmentForm}`, data)
    );
  }
}
