import {Component, input, output} from '@angular/core';
import {QuickLinksComponent} from "../../quick-links/quick-links.component";
import {MenuInterface} from "../../../../../shared/interfaces/menu.interface";
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'calimatic-quick-links-widget',
  standalone: true,
  imports: [
    QuickLinksComponent,
    TooltipModule
  ],
  templateUrl: './quick-links-widget.component.html',
  styleUrl: './quick-links-widget.component.scss'
})
export class QuickLinksWidgetComponent {
  menus = input.required<MenuInterface[]>();
  edit = input.required<boolean>();
  onRemove = output();
}
