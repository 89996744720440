import {Component, inject, input, output} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {InputTextModule} from "primeng/inputtext";
import {ReactiveFormsModule} from "@angular/forms";
import {TooltipModule} from "primeng/tooltip";
import {DashboardWidgetsDTO} from "../../../../../shared/dtos/dashboard-widgets.dto";
import {NgClass} from "@angular/common";
import {ExternalLinkDto} from "../../../../../shared/dtos/external-link.dto";
import {RouterLink} from "@angular/router";
import {AuthService} from '../../../../../core/services/auth/auth.service';

@Component({
  selector: 'calimatic-external-widget',
  standalone: true,
  imports: [
    ButtonModule,
    DialogModule,
    InputTextModule,
    ReactiveFormsModule,
    TooltipModule,
    NgClass,
    RouterLink
  ],
  templateUrl: './external-widget.component.html',
  styleUrl: './external-widget.component.scss'
})
export class ExternalWidgetComponent {

  //inputs
  widget = input.required<DashboardWidgetsDTO>();
  edit = input.required<boolean>();
  editOnHover = input<boolean>();

  //outputs
  onRemove = output();
  open = output<ExternalLinkDto>();
  close = output();
  authService = inject(AuthService);
  protected readonly window = window;

  openModal() {
    this.open.emit({
      name: this.widget().title,
      link: this.widget().link,
      type: this.widget().code,
      icon: this.widget().icon
    } as ExternalLinkDto);
  }

  navigateToExternalLink(externalLink: string) {

    externalLink = `https://${externalLink}`;

    if (this.authService.isFranchiseSecureUrl(externalLink)) {
      externalLink = this.prepareFranchiseSecureUrl(externalLink);
    }
    this.window.open(externalLink, '_blank');
  }

  prepareFranchiseSecureUrl(externalLink: string) {
    const userModal = this.authService.getUserModal();
    // remove franchise secure domain url if any exists and replace it with original franchise secure url of user
    if (userModal.IsFranchiseCompany) {
      externalLink = externalLink.replace(/^https?:\/\/[^\/]+\/center\/[^\/]+/, userModal.FranchiseSecureUrl);
    } else {
      externalLink = externalLink.replace(/^https?:\/\/[^\/]+/, userModal.FranchiseSecureUrl);
    }
    // replace secure franchise Id in url with current user secure franchise Id
    const url = new URL(externalLink);
    url.searchParams.set('FranchiseId', (userModal.SecureCompanyId || '').toString());
    url.searchParams.set('token', this.authService.getAccessToken())
    return url.toString();
  }

}
